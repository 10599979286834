import { AbstractComponent } from "appworks/components/abstract-component";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Layers } from "appworks/graphics/layers/layers";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { PIXIElement } from "appworks/graphics/pixi/group";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Services } from "appworks/services/services";
import { fadeIn } from "appworks/utils/animation/fade";
import { Contract } from "appworks/utils/contracts/contract";
import { Point } from "appworks/utils/geom/point";

export class SlingoReelHighlightComponent extends AbstractComponent {
    public highlightLayer: Layers = Layers.get("MatrixBackground");

    protected highlights: Map<Point, PIXIElement> = new Map();

    public highlightSymbol(xyPos: Point): Contract {
        const sprite = this.getHighlightSprite();
        sprite.setDualPosition(this.getHighlightPosition(xyPos));
        this.highlightLayer.add(sprite);

        this.highlights.set(xyPos, sprite);

        return this.getHighlightAnimationContract(sprite);
    }

    public clearHighlight(xyPos: Point) {
        this.highlights.forEach((highlight: PIXIElement, position: Point) => {
            if (position.equals(xyPos)) {
                highlight.destroy();
                this.highlights.delete(position);
            }
        });
    }

    public clearHighlights() {
        this.highlights.forEach((sprite: PIXIElement) => sprite.destroy());
        this.highlights.clear();
    }

    protected getHighlightSprite(): Sprite {
        return Services.get(GraphicsService).createSprite(`highlight_matched`);
    }

    protected getHighlightPosition(xyPos: Point): DualPosition {
        return Layers.get("MatrixContent").getPosition(`symbol_${xyPos.x}_${xyPos.y}`);
    }

    protected getHighlightAnimationContract(highlight: PIXIElement): Contract {
        return fadeIn(highlight, 125);
    }
}