import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { Signal } from "signals";

export class StepperComponent {

    public onUpdate: Signal;

    public formatFunction: (value: number) => string;

    protected label?: FlexiText;

    protected decButton: FlexiButton;
    protected incButton: FlexiButton;
    protected selectedIndex: number = 0;
    protected minValue: number = 0;
    protected maxValue: number = 10;
    protected values: number[];

    protected enabled: boolean = true;

    constructor(decButton: FlexiButton, incButton: FlexiButton, label?: FlexiText) {
        this.onUpdate = new Signal();

        this.decButton = decButton;
        this.incButton = incButton;
        this.label = label;

        if (this.decButton) {
            this.decButton.on(ButtonEvent.CLICK, this.decClickHandler);
        }
        if (this.incButton) {
            this.incButton.on(ButtonEvent.CLICK, this.incClickHandler);
        }
    }

    public setVisible(visible: boolean) {
        if (this.decButton) {
            this.decButton.setVisible(visible);
        }
        if (this.incButton) {
            this.incButton.setVisible(visible);
        }
        if (this.label) {
            this.label.setVisible(visible);
        }
    }

    public getSelectedValue() {
        return this.values ? this.values[this.selectedIndex] : null;
    }

    public getSelectedIndex() {
        return this.selectedIndex;
    }

    /**
     * @param values - an optional array of values
     */
    public setValues(values: number[]): void {
        this.values = values;
        this.minValue = 0;
        this.maxValue = this.values.length - 1;
        this.update();
    }

    public setLinearValues(start: number, end: number, increment: number) {
        const values = [];
        for (let value = start; value <= end; value += increment) {
            values.push(value);
        }

        this.values = values;
        this.minValue = 0;
        this.maxValue = this.values.length - 1;
        this.update();
    }

    public setSelectedIndex(index: number) {
        this.selectedIndex = index;
        this.update();
    }

    public setSelectedValue(value: number) {
        if (this.values) {
            const index = this.values.indexOf(value);
            if (index > -1) {
                this.setSelectedIndex(index);
            } else {
                this.setDirectValue(value);
            }
        }
    }

    public setMinIndex(value: number) {
        this.minValue = value;
        this.update();
    }

    public setMaxIndex(value: number) {
        this.maxValue = value;
        this.update();
    }

    /**
     * Update should be called any time the value or range limits are changed
     */
    public update() {
        if (this.selectedIndex > this.maxValue) {
            this.selectedIndex = this.maxValue;
        }

        if (this.selectedIndex < this.minValue) {
            this.selectedIndex = this.minValue;
        }

        this.setEnabled(this.enabled);
        this.updateText();
    }

    /**
     * Updates the text field to the selectedItem
     */
    public updateText() {
        let text: string;
        const value: number = this.getSelectedValue();

        if (value !== null) {

            if (value === Infinity) {
                text = Services.get(TranslationsService).get("infinity");
            } else if (this.formatFunction) {
                text = this.formatFunction(value);
            } else {
                text = value.toString();
            }
        } else {
            text = "";
        }

        this.setText(text);
    }

    public setText(text: string) {
        if (this.label) {
            this.label.setText(text);
        }
    }

    public setValueVisible(visible: boolean) {
        if (this.label) {
            this.label.setVisible(visible);
        }
    }

    public setEnabled(enable: boolean) {
        this.enabled = enable;

        if (this.incButton) {
            this.incButton.setEnabled(this.enabled && !this.isAtMax());
        }

        if (this.decButton) {
            this.decButton.setEnabled(this.enabled && !this.isAtMin());
        }
    }

    public getMaxIndex(): number {
        return this.values ? this.values.length - 1 : 0;
    }

    public max() {
        this.setSelectedIndex(this.getMaxIndex());
        this.update();
    }

    public isAtMax(): boolean {
        return this.getSelectedIndex() === this.getMaxIndex();
    }

    public isAtMin(): boolean {
        return this.getSelectedIndex() === 0;
    }

    public isAtALimit(): boolean {
        return this.isAtMax() || this.isAtMin();
    }

    public min() {
        this.setSelectedIndex(0);
        this.update();
    }

    public decrease() {
        this.selectedIndex--;

        this.update();
    }

    public increase() {
        this.selectedIndex++;

        this.update();
    }

    public destroy() {
        if (this.decButton) {
            this.decButton.off(ButtonEvent.CLICK, this.decClickHandler);
        }
        if (this.incButton) {
            this.incButton.off(ButtonEvent.CLICK, this.incClickHandler);
        }
        this.onUpdate.removeAll();
    }

    /**
     * Handler for when decrement button is pressed
     */
    protected decClickHandler = () => {
        this.decrease();

        this.onUpdate.dispatch(this.getSelectedValue());
    }

    /**
     * Handler for when increment button is pressed
     */
    protected incClickHandler = () => {
        this.increase();

        this.onUpdate.dispatch(this.getSelectedValue());
    }

    protected setDirectValue(value: number) {
        let text: string;

        if (value === Infinity) {
            text = Services.get(TranslationsService).get("infinity");
        } else if (this.formatFunction) {
            text = this.formatFunction(value);
        } else {
            text = value.toString();
        }

        this.setText(text);
        this.selectedIndex = -1;
    }
}
