import { ValueList } from "appworks/utils/value-list";

export class SlotBetModel {
    // Free bet / promotion - i.e do not charge for spins
    public freeBet: boolean = false;

    public creditsPerLine: ValueList<number> = new ValueList();
    public creditSizes: ValueList<number> = new ValueList();
    public selectedLines: number[];

    // For games, such as ways, where the bet is not based on lines
    public fixedBetCost: number = 0;

    // Games with additional bets that should be included in the total stake
    public additionalBet: number = 0;

    // This is a bet which is deducted from balance, but not used when calculating wins; for example "golden bet" or bonus buy
    public extraBet: number = 0;

    // For games with bet variants, such as golden bet, bonus buys etc; this can be used to select the type of bet. The value is an abrbitrary string to be used by the integration
    public betType: string = "";

    // Features which can be bought, and their cost as a multiplier of total stake
    public buyFeatures: Array<{ type: string, cost: number }> = [];
}
