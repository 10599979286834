import { Gameplay } from "appworks/model/gameplay/gameplay";
import { RequestBuilder } from "appworks/server/builders/request-builder";
import { AxiosRequestConfig } from "axios";
import { PickRequestPayload } from "slotworks/model/gameplay/requests/pick-request-payload";

export class SlingoPickRequestBuilder implements RequestBuilder {
    public filter(data: any): boolean {
        return data instanceof PickRequestPayload;
    }

    public build(gameplay: Gameplay, data: any) {
        return {
            method: "put",
            url: "/actions/CHOOSE_CELL",
            data: {
                cellNumber: data.pick
            }
        } as AxiosRequestConfig;
    }

}
