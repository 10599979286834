import { Exit } from "appworks/state-machine/data/exit";
export abstract class Decision {

    /**
     * Called when this decision is queried.
     *
     * @return {Exit}
     */
    public evaluate(): Exit {
        return Exit.True;
    }
}
