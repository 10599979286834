import { Gameplay } from "appworks/model/gameplay/gameplay";
import { Record } from "appworks/model/gameplay/records/record";
import { Result } from "appworks/model/gameplay/records/results/result";
import { RecordBuilder } from "appworks/server/builders/record-builder";
import { RequestBuilder } from "appworks/server/builders/request-builder";
import { ResultBuilder } from "appworks/server/builders/result-builder";
import { Services } from "appworks/services/services";
import Logger = require("js-logger");
import { SentryService } from "../analytics/sentry-service";

// TODO: V6, merge this with commsManager
class BuilderManager {

    private requestBuilders: RequestBuilder[] = [];
    private recordBuilders: RecordBuilder[] = [];
    private resultBuilders: ResultBuilder[] = [];

    public addRequestBuilder(builder: RequestBuilder) {
        this.requestBuilders.push(builder);
    }

    public addRecordBuilder(builder: RecordBuilder) {
        this.recordBuilders.push(builder);
    }

    public addResultBuilder(builder: ResultBuilder) {
        this.resultBuilders.push(builder);
    }

    public addRequestBuilders(...builders: RequestBuilder[]) {
        this.requestBuilders.push(...builders);
    }

    public addRecordBuilders(...builders: RecordBuilder[]) {
        this.recordBuilders.push(...builders);
    }

    public addResultBuilders(...builders: ResultBuilder[]) {
        this.resultBuilders.push(...builders);
    }

    public removeRequestBuilder(builder: RequestBuilder) {
        this.requestBuilders.splice(this.requestBuilders.indexOf(builder), 1);
    }

    public removeRecordBuilder(builder: RecordBuilder) {
        this.recordBuilders.splice(this.recordBuilders.indexOf(builder), 1);
    }

    public removeResultBuilder(builder: ResultBuilder) {
        this.resultBuilders.splice(this.resultBuilders.indexOf(builder), 1);
    }

    /**
     * Parses a request object sent from the game into an object fit to send to the server (IE an Axios http request config)
     *
     * @param data {any}
     */
    public buildRequest(gameplay: Gameplay, data: any): any {
        for (const builder of this.requestBuilders) {
            if (builder.filter(data)) {
                return builder.build(gameplay, data);
            }
        }

        return null;
    }

    /**
     * Parses response data from the server into a Record.
     *
     * @param gameplay {Gameplay}
     * @param response {any}
     */
    public buildRecord(gameplay: Gameplay, request: any, response: any): Record[] {

        let builtRecords: Record[] = [];

        for (const builder of this.recordBuilders) {
            if (builder.filter(request, response)) {
                const newRecords = builder.build(gameplay, request, response);
                this.log("record: " + builder.id, newRecords);
                if (newRecords) {
                    builtRecords = builtRecords.concat(newRecords);
                }
            }
        }

        builtRecords.forEach((x) => Services.get(SentryService).addRecordBreadcrumb(x));

        return builtRecords;
    }

    /**
     * Parses data (derived from the response) into a Result.
     * May also mutate the Record
     *
     * @param record {Record}
     * @param data {any}
     */
    public buildResults(record: Record, data: any, additionalData?: any): Result[] {
        const results: Result[] = [];

        for (const builder of this.resultBuilders) {
            if (builder.filter(data, record, additionalData)) {
                results.push(...builder.build(record, data, additionalData));
            }
        }

        return results;
    }

    /**
     * Used to verify builders have been set up.
     */
    public hasBuilders() {
        return this.recordBuilders.length > 0 && this.requestBuilders.length > 0 && this.resultBuilders.length > 0;
    }

    /**
     * Resets the builder manager to it's initial builderless state
     */
    public clearBuilders() {
        this.requestBuilders = [];
        this.recordBuilders = [];
        this.resultBuilders = [];
    }

    private log(message: string, record: Record[]) {
        Logger.info("%c Builder manager ", "background: #D74C2E; color: #fff", message, record);
    }
}

export const builderManager = new BuilderManager();
