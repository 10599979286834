import { StepperComponent } from "appworks/components/ui/stepper-component";
import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { AbstractControl } from "../../abstract-control";

export class StepperControl extends AbstractControl {

    protected stepper: StepperComponent;

    constructor(protected downButton: FlexiButton, protected upButton: FlexiButton, protected valueText?: FlexiText) {
        super();

        this.stepper = new StepperComponent(downButton, upButton, valueText);
    }

    public destroy(): void {
        this.stepper.destroy();
    }

    public setEnabled(enabled: boolean) {

        this.stepper.setEnabled(enabled);

        super.setEnabled(enabled);
    }
}
