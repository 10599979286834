import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { AbstractControl } from "../../abstract-control";

type ValueTypes = number[] | string[];
type FormatMethod = (input: string | number) => string;

export class RadioControl extends AbstractControl {

    protected disabledTextAlpha: number = 0.2;

    protected values: ValueTypes;
    protected current: number | string;
    protected formatMethod: FormatMethod;
    protected focusIndex: number;

    constructor(protected buttons: FlexiButton[], protected labels: FlexiText[], protected leftArrow?: FlexiButton, protected rightArrow?: FlexiButton) {
        super();

        this.rightArrow?.on(ButtonEvent.POINTER_UP, () => this.setValues(this.values, this.current, this.focusIndex + 1));
        this.leftArrow?.on(ButtonEvent.POINTER_UP, () => this.setValues(this.values, this.current, this.focusIndex - 1));
    }

    public setEnabled(enabled: boolean): void {
        this.buttons.forEach((x) => x.setEnabled(enabled));
        this.labels.forEach((x) => x.setAlpha((enabled) ? 1.0 : this.disabledTextAlpha));
        this.leftArrow?.setEnabled(enabled);
        this.rightArrow?.setEnabled(enabled);
        super.setEnabled(enabled);
    }

    protected setValues(values: ValueTypes, current: number | string, focusIndex?: number): void {
        if (values) {
            this.values = values;
            this.current = current;
            this.focusIndex = (focusIndex !== undefined) ? Math.max(Math.min(focusIndex, values.length - this.buttons.length), 0) : 0;

            for (let i = 0; i < this.values.length || i < this.labels.length || i < this.buttons.length; i++) {
                if (this.values[this.focusIndex + i] === undefined) {
                    if (this.buttons[i]) {
                        this.buttons[i].setVisible(false);
                    }
                } else if (this.values[this.focusIndex + i] !== undefined && this.buttons[i] !== undefined) {
                    if (this.labels[i]) {
                        if (this.formatMethod) {
                            this.labels[i].setText(this.formatMethod(this.values[this.focusIndex + i]));
                        } else {
                            this.labels[i].setText(this.values[this.focusIndex + i].toString());
                        }
                    }
                    this.buttons[i].on(ButtonEvent.POINTER_UP, () => {
                        this.onChange(this.focusIndex + i, this.values[this.focusIndex + i]);
                    });
                } else if (this.labels[i] !== undefined) {
                    this.labels[i].setText("");
                }
            }

            if (current !== undefined) {
                this.onChange(values.indexOf(current as never), current);
            }

            this.leftArrow?.setVisible(this.focusIndex > 0);
            this.rightArrow?.setVisible(this.buttons.length < values.length && this.focusIndex < this.values.length - this.buttons.length);
        } else {
            throw new Error("Invalid data set for radio control");
        }
    }

    protected setFormatMethod(method: FormatMethod): void {
        this.formatMethod = method;
    }

    protected onChange(index: number, value: number | string): void {
        if (index !== -1) {
            this.current = value;
            this.buttons.forEach((button, i) => {
                button.setSelected(this.focusIndex + i === index);
                button.setEnabled(this.enabled);
            });
        }
    }
}
