import { Exit } from "appworks/state-machine/data/exit";
import { StateMachine } from "appworks/state-machine/state-machine";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import * as Logger from "js-logger";

export abstract class State {
    public active: boolean;

    protected stateMachine: StateMachine;
    protected cancelGroup: CancelGroup = new CancelGroup();

    /**
     * Called once on startup
     */
    public setup(stateMachine: StateMachine) {
        if (this.stateMachine) {
            throw new Error("Setup being called twice on state: " + this.constructor.name);
        }
        this.stateMachine = stateMachine;
    }

    /**
     * Call when the state completes naturally (for example an animation completes)
     */
    public complete() {
        this.cancelGroup.clear();
        this.stateMachine.changeState(Exit.Complete, this);
    }

    /**
     * Call when the state has accepted the users request to exit
     */
    public skip() {
        this.cancelGroup.cancel();
        this.stateMachine.changeState(Exit.Skip, this);
    }

    /**
     * Call when something when wrong during the state
     */
    public error() {
        this.cancelGroup.clear();
        this.stateMachine.changeState(Exit.Error, this);
    }

    /**
     * Executed when a user attempts to exit the state manually (for example by pressing the spin button)
     */
    public onSkip(cascaded?: boolean) {
        // skip
    }

    /**
     * Executed automatically when the state is entered
     */
    public onEnter(cascadeSkip?: boolean) {
        Logger.debug("onEnter not implemented on ", this);
    }

    /**
     * Executed automatically by the state machine when the state is exited (via any means)
     */
    public onExit() {
        Logger.debug("onExit not implemented on ", this);
    }
}
