import { Components } from "appworks/components/components";
import { Layers } from "appworks/graphics/layers/layers";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { fadeIn } from "appworks/utils/animation/fade";
import { SlingoFooterComponent } from "slingo/components/slingo-footer-component";
import { IdleState } from "slotworks/state-machine/standard/states/idle-state";

export class SlingoIdleState extends IdleState {
    public onEnter(): void {
        super.onEnter();
        Components.get(SlingoFooterComponent)?.showDefaultText();
    }

    public onExit(): void {
        super.onExit();
        Services.get(TransactionService).setTotalWin(0);

        // We hide this in SlingoGameOverState, make sure it's showing again
        const totalWinText = Layers.get("BetBar").getText("total_win_value");
        if (totalWinText) { fadeIn(totalWinText).execute(); }
    }
}