
import { ButtonElement, ButtonEvent } from "appworks/graphics/elements/button-element";
import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { Signal } from "signals";

export class ToggleElement {

    public onChanged: Signal;

    private onButton: FlexiButton;
    private offButton: FlexiButton;

    private checked: boolean;
    private visible: boolean = true;

    constructor() {
        this.onChanged = new Signal();
    }

    public addButton(button: FlexiButton, buttonState: "on" | "off") {
        if (buttonState === "on" && !this.onButton) {
            this.onButton = button;
            this.onButton.on(ButtonEvent.CLICK, this.onButtonHandler);
        } else if (buttonState === "off" && !this.offButton) {
            this.offButton = button;
            this.offButton.on(ButtonEvent.CLICK, this.offButtonHandler);
        }

        this.setChecked(true);
    }

    public getButtonElement(buttonState: string) {
        let flexiButton = this.onButton;
        if (buttonState === "off") {
            flexiButton = this.offButton;
        }
        const targets = flexiButton.getTargets();
        for (const target of targets) {
            if (target instanceof ButtonElement) {
                return target;
            }
        }
    }

    public setChecked(on: boolean) {
        this.checked = on;
        this.setVisible(this.visible);
    }

    public getChecked() {
        return this.checked;
    }

    public setEnabled(enabled: boolean) {
        if (this.onButton) {
            this.onButton.setEnabled(enabled);
        }

        if (this.offButton) {
            this.offButton.setEnabled(enabled);
        }
    }

    public setVisible(visible: boolean) {
        if (visible) {
            if (this.onButton) {
                this.onButton.setVisible(this.checked);
            }

            if (this.offButton) {
                this.offButton.setVisible(!this.checked);
            }
        } else {
            if (this.onButton) {
                this.onButton.setVisible(false);
            }

            if (this.offButton) {
                this.offButton.setVisible(false);
            }
        }
        this.visible = visible;
    }

    public destroy() {
        if (this.onButton) {
            this.onButton.off(ButtonEvent.CLICK, this.onButtonHandler);
            this.onButton.destroy();
            this.onButton = null;
        }

        if (this.offButton) {
            this.offButton.off(ButtonEvent.CLICK, this.onButtonHandler);
            this.offButton.destroy();
            this.offButton = null;
        }

        this.onChanged.removeAll();
    }

    private onButtonHandler = () => {
        this.setChecked(false);

        this.onChanged.dispatch(true);

        Services.get(SoundService).event(SoundEvent.toggle_on_press);
    }

    private offButtonHandler = () => {
        this.setChecked(true);

        this.onChanged.dispatch(false);

        Services.get(SoundService).event(SoundEvent.toggle_off_press);
    }

}
