import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";

const infinityAlias: string = "infinity";

export function formatMoney(value: number): string {
    if (value === Infinity && Services.get(TranslationsService).get(infinityAlias) !== infinityAlias) {
        return Services.get(TranslationsService).get(infinityAlias);
    }
    return Services.get(CurrencyService).format(value);
}

export function formatMoneyAsCurrency(value: number): string {
    if (value === Infinity && Services.get(TranslationsService).get(infinityAlias) !== infinityAlias) {
        return Services.get(TranslationsService).get(infinityAlias);
    }
    return Services.get(CurrencyService).format(value, undefined, false);
}

export function formatMoneyAsCoins(value: number): string {
    if (value === Infinity && Services.get(TranslationsService).get(infinityAlias) !== infinityAlias) {
        return Services.get(TranslationsService).get(infinityAlias);
    }
    return Services.get(CurrencyService).format(value, undefined, true);
}

export function formatPercentage(value: number): string {
    return `${Math.max(0, Math.min(100, value))} %`;
}

export function formatMinutes(value: number): string {
    const minutes = value % 60;
    const hours = Math.floor(value / 60);

    if (hours > 0) {
        if (minutes > 0) {
            return printHours(hours) + " " + printMinutes(minutes);
        } else {
            return printHours(hours);
        }
    } else {
        return printMinutes(minutes);
    }
}

function printHours(n: number) {
    if (n === 1) {
        return n + " " + Services.get(TranslationsService).get("gen.hour");
    } else {
        return n + " " + Services.get(TranslationsService).get("gen.hours");
    }
}

function printMinutes(n: number) {
    if (n === 1) {
        return n + " " + Services.get(TranslationsService).get("gen.minute");
    } else {
        return n + " " + Services.get(TranslationsService).get("gen.minutes");
    }
}

export function leadZeroes(n: number, digits: number) {
    let out = n.toString();
    while (out.length < digits) {
        out = "0" + out;
    }
    return out;
}
