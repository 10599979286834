import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";

export function SlingoGameProgressResultDisplay(record: SlingoRecord, result: SlingoGameProgressResult) {
    const container = document.createElement("div");

    container.style.fontSize = "14px";

    for (const key in result) {
        if (["cashWon", "resultType"].indexOf(key) !== -1) { continue; }

        container.innerHTML += `${key}: ${result[key]}`;
        container.innerHTML += "<br>";
    }

    return container;
}
