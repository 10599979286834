import { BitmapText } from "appworks/graphics/pixi/bitmap-text";
import { Container } from "appworks/graphics/pixi/container";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { Model } from "appworks/model/model";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { HitArea } from "appworks/utils/geom/hit-area";
import { Point } from "appworks/utils/geom/point";
import { KeyInput } from "appworks/utils/key-input";
import { KeyboardCode } from "appworks/utils/keyboard";
import { Circle } from "pixi.js";
import { slotModel } from "slotworks/model/slot-model";
import { HTMLText } from "../pixi/html-text";

export class ButtonElement extends Container {

    public static DISABLED_TINT: number = undefined;
    public static OVER_TINT: number = undefined;
    public static DOWN_SCALE: number = 1;

    public downScale: number;
    public overTint: number;
    public disabledTint: number;
    public hitSprite: Sprite;

    private states: Map<ButtonState, Sprite>;
    private labels: Map<ButtonState, BitmapText | Text | HTMLText>;
    private currentDisplay: Sprite;
    private currentState: ButtonState = ButtonState.Up;
    private enabled: boolean;
    private selected: boolean;
    private currentScale: number;
    private circularHitArea: boolean;

    private keyboardShortcutListener: KeyInput;
    private keyboardShortcutMap: Map<Function, Function>;
    private shortcutKey: string;

    constructor(shortcutKey?: string) {
        super();
        this.states = new Map<ButtonState, Sprite>();
        this.labels = new Map<ButtonState, BitmapText | Text | HTMLText>();
        this.enabled = true;
        this.selected = false;

        this.interactive = true;
        this.buttonMode = true;

        this.downScale = ButtonElement.DOWN_SCALE;
        this.overTint = ButtonElement.OVER_TINT;
        this.disabledTint = ButtonElement.DISABLED_TINT;

        this.shortcutKey = shortcutKey;
        this.keyboardShortcutListener = new KeyInput();
        this.keyboardShortcutMap = new Map();

        this.on(ButtonEvent.POINTER_UP.getPIXIEventString(), () => {
            this.changeState(ButtonState.Up);
        });
        this.on(ButtonEvent.POINTER_UP_OUTSIDE.getPIXIEventString(), () => {
            this.changeState(ButtonState.Up);
        });
        this.on(ButtonEvent.POINTER_OUT.getPIXIEventString(), () => {
            this.changeState(ButtonState.Up);
        });
        this.on(ButtonEvent.POINTER_OVER.getPIXIEventString(), () => {
            this.changeState(ButtonState.Over);
        });
        this.on(ButtonEvent.POINTER_DOWN.getPIXIEventString(), () => {
            this.changeState(ButtonState.Down);
        });
        this.on(ButtonEvent.POINTER_OVER.getPIXIEventString(), this.playOverSound);
        this.on(ButtonEvent.POINTER_DOWN.getPIXIEventString(), this.playPressSound);
        this.on(ButtonEvent.POINTER_UP.getPIXIEventString(), this.playUpSound);
    }

    public on(event: ButtonEvent | string | symbol, callback: Function) {
        if (event instanceof ButtonEvent) {
            if (event === ButtonEvent.CLICK) {
                this.addKeyboardShortcut(callback);
            }

            return super.on(event.getPIXIEventString(), callback);
        } else if (typeof (event) === "string") {
            return super.on(event, callback);
        } else {
            return super.on(event.toString(), callback);
        }
    }

    public off(event: ButtonEvent | string | symbol, callback: Function) {
        if (event instanceof ButtonEvent) {
            if (event === ButtonEvent.CLICK) {
                this.removeKeyboardShortcut(callback);
            }

            return super.off(event.getPIXIEventString(), callback);
        } else if (typeof (event) === "string") {
            return super.off(event, callback);
        } else {
            return super.off(event.toString(), callback);
        }
    }

    public addState(state: ButtonState | string, target: Sprite) {

        let buttonState: ButtonState;

        if (typeof state === "string") {
            buttonState = ButtonState.FromString(state);
        } else {
            buttonState = state;
        }

        this.states.set(buttonState, target);

        target.visible = false;

        if (target.anchor.x !== 0.5 && target.anchor.y !== 0.5) {
            target.anchor.set(0.5, 0.5);
            target.landscape.x += target.landscape.width * 0.5;
            target.landscape.y += target.landscape.height * 0.5;
            target.portrait.x += target.portrait.width * 0.5;
            target.portrait.y += target.portrait.height * 0.5;
        }

        this.addChild(target);

        if (!this.hitArea) {
            this.setHitArea(target);
        }

        this.update();
    }

    public addLabelForState(label: BitmapText | Text | HTMLText, state: ButtonState) {
        this.addChild(label);

        label.landscape.x -= this.landscape.x;
        label.landscape.y -= this.landscape.y;
        label.portrait.x -= this.portrait.x;
        label.portrait.y -= this.portrait.y;

        label.visible = false;

        this.labels.set(state, label);
        this.updateLabels();
    }

    public getLabels(): Array<Text | BitmapText | HTMLText> {
        return Array.from(this.labels.values());
    }

    public getSprites(): Sprite[] {
        return Array.from(this.states.values());
    }

    public setLabelText(text: string) {
        this.labels.forEach((label) => {
            label.text = text;
        });
    }

    public setHitArea(hitArea: HitArea, circular?: boolean): void {
        if (circular !== undefined) {
            this.circularHitArea = circular;
        }

        if (hitArea instanceof Sprite) {
            this.hitSprite = hitArea;
            const hitBounds = hitArea.getBounds();

            const localCoords = this.toLocal({ x: hitBounds.x, y: hitBounds.y } as any);

            hitBounds.x = localCoords.x;
            hitBounds.y = localCoords.y;

            if (this.circularHitArea) {
                const circle = new Circle(hitBounds.x + hitBounds.width * 0.5, hitBounds.y + hitBounds.height * 0.5, Math.max(hitBounds.width, hitBounds.height) * 0.5);
                this.hitArea = circle;
            } else {
                this.hitArea = hitBounds;
            }

        } else {
            this.hitArea = hitArea as any;
        }
    }

    public updateTransform() {
        this.updateScales();

        super.updateTransform();

        // TODO v6 - Prioritise hitArea over hitSprite instead?
        this.setHitArea(this.hitSprite || this.hitArea);
    }

    public setEnabled(enabled: boolean) {
        if (this.selected) {
            enabled = false;
        }
        this.interactive = enabled;
        this.enabled = enabled;

        if (this.enabled) {
            this.currentState = ButtonState.Up;
        }

        this.update();
    }

    public isEnabled(): boolean {
        return this.enabled;
    }

    public setSelected(selected: boolean) {
        this.selected = selected;

        if (selected) {
            this.currentState = ButtonState.Selected;
        } else {
            this.currentState = ButtonState.Up;
        }

        this.update();

        this.setEnabled(!selected);
    }

    public setVisible(enabled: boolean) {
        this.visible = enabled;

        this.setLabelsVisibility(enabled);
    }

    public setAlpha(alpha: number) {
        this.alpha = alpha;
    }

    public destroy() {
        this.currentDisplay = null;
        this.states = null;

        this.removeAllListeners();

        if (this.keyboardShortcutListener) {
            this.keyboardShortcutListener.destroy();
            this.keyboardShortcutListener = null;
            this.keyboardShortcutMap.clear();
        }

        while (this.children.length) {
            this.removeChildAt(0);
        }

        if (this.parent) {
            this.parent.removeChild(this);
        }
    }

    public clone(newID?: string) {
        const clonedButton = new ButtonElement();
        clonedButton.name = this.name;
        clonedButton.landscape = this.landscape.clone();
        clonedButton.portrait = this.portrait.clone();

        this.states.forEach((state, id) => {
            const clonedState = new Sprite(state.texture);

            clonedState.anchor = state.anchor.clone();
            clonedState.landscape = state.landscape.clone();
            clonedState.portrait = state.portrait.clone();

            clonedButton.addState(id, clonedState);
        });

        if (newID) {
            clonedButton.name = newID;
        } else {
            clonedButton.name = this.name;
        }

        clonedButton.enabled = this.enabled;
        clonedButton.selected = this.selected;

        return clonedButton;
    }

    public contract(event: ButtonEvent): Contract<any> {
        return new Contract<void>((resolve) => {
            const onClick = () => {
                this.off(event, onClick);
                resolve();
            };
            this.on(event, onClick);
        });
    }

    private addKeyboardShortcut(callback: Function) {
        if (this.shortcutKey === null) {
            return;
        }
        const listener = (key: string) => {
            if (uiFlags.has(UIFlag.EXTERNAL_DISABLE)) {
                return;
            }
            if (this.enabled && this.visible && (key === this.shortcutKey)) {
                if (key === KeyboardCode.SPACE && this.name === "spin" && !Model.read().settings.spaceToSpin) {
                    return;
                }
                callback();
            }
        };

        this.keyboardShortcutMap.set(callback, listener);

        this.keyboardShortcutListener.onKeyUp.add(listener);
        if (this.name === "spin") {
            if (slotModel.read().regulations.autoplayAllowed) {
                this.keyboardShortcutListener.onKeyHeld.add(listener);
            }
        } else {
            this.keyboardShortcutListener.onKeyHeld.add(listener);
        }
    }

    private removeKeyboardShortcut(callback: Function) {
        if (this.shortcutKey === null || !this.keyboardShortcutMap.has(callback)) {
            return;
        }
        this.keyboardShortcutListener.onKeyUp.remove(this.keyboardShortcutMap.get(callback));
        this.keyboardShortcutMap.delete(callback);
    }

    private update() {
        if (!this.enabled) {
            if (!this.selected) {
                this.currentState = ButtonState.Disabled;
            }

            this.buttonMode = false;
        } else {
            this.buttonMode = true;
        }

        if (this.states && this.states.has(ButtonState.All)) {
            this.currentDisplay = this.states.get(ButtonState.All);
            this.currentDisplay.visible = true;
        } else {
            this.updateMultistateButton();
        }

        if (this.landscape) {
            this.landscape.width = this.states?.get(this.currentState)?.landscape?.width ?? 0;
            this.landscape.height = this.states?.get(this.currentState)?.landscape?.height ?? 0;
        }
        if (this.portrait) {
            this.portrait.width = this.states?.get(this.currentState)?.portrait?.width ?? 0;
            this.portrait.height = this.states?.get(this.currentState)?.portrait?.height ?? 0;
        }

        if (this.labels?.get(this.currentState)) {
            if (this.landscape) {
                this.landscape.width = 0;
                this.landscape.height = 0;
            }
            if (this.portrait) {
                this.portrait.width = 0;
                this.portrait.height = 0;
            }
        }

        this.updateLabels();
    }

    private updateScales() {
        this.states.forEach((sprite) => {
            sprite.landscape.scale = new Point(this.landscape.scale.x * this.currentScale, this.landscape.scale.y * this.currentScale);
            sprite.portrait.scale = new Point(this.portrait.scale.x * this.currentScale, this.portrait.scale.y * this.currentScale);

            if (this.hitSprite) {
                this.hitSprite.landscape.scale = sprite.landscape.scale.clone();
                this.hitSprite.portrait.scale = sprite.portrait.scale.clone();
            }
        });
    }

    private updateMultistateButton() {
        if (this.currentDisplay) {
            this.currentDisplay.tint = 0xffffff;
        }

        let nextDisplay: Sprite;
        if (this.states) {
            if (this.states.has(this.currentState)) {
                nextDisplay = this.states.get(this.currentState);
            } else if (this.currentState === ButtonState.Disabled) {
                nextDisplay = this.states.get(ButtonState.Up);
                if (this.disabledTint) {
                    nextDisplay.tint = this.disabledTint;
                }
            } else if (this.currentState === ButtonState.Over) {
                nextDisplay = this.states.get(ButtonState.Up);
                if (this.overTint) {
                    nextDisplay.tint = this.overTint;
                }
            } else if (this.currentState === ButtonState.Down) {
                nextDisplay = this.states.get(ButtonState.Up);
            } else if (this.states.has(ButtonState.Up)) {
                nextDisplay = this.states.get(ButtonState.Up);
            }
        }

        if (nextDisplay) {
            nextDisplay.visible = true;
        }

        if (this.currentState === ButtonState.Down) {
            this.currentScale = this.downScale;
        } else {
            this.currentScale = 1;
        }

        if (this.currentDisplay && this.currentDisplay !== nextDisplay) {
            this.currentDisplay.visible = false;
        }
        this.currentDisplay = nextDisplay;
    }

    private updateLabels() {
        if (this.labels.has(this.currentState)) {
            this.labels.forEach((label: Text, state: ButtonState) => {
                if (state === this.currentState) {
                    label.visible = true;
                } else {
                    label.visible = false;
                }
            });
        }
    }

    private setLabelsVisibility(visible: boolean) {
        if (!visible) {
            this.labels.forEach((label: Text, state: ButtonState) => {
                label.visible = false;
            });
        } else {
            this.updateLabels();
        }
    }

    private changeState(state: ButtonState) {
        this.currentState = state;

        this.update();
    }

    private playUpSound = () => {
        Services.get(SoundService).event(SoundEvent.button_up_NAME, this.name);
    }

    private playPressSound = () => {
        Services.get(SoundService).event(SoundEvent.button_press_NAME, this.name);
    }

    private playOverSound = () => {
        Services.get(SoundService).event(SoundEvent.button_over_NAME, this.name);
    }
}

export class ButtonState {
    public static Up: ButtonState = new ButtonState("up");
    public static Over: ButtonState = new ButtonState("over");
    public static Down: ButtonState = new ButtonState("down");
    public static Disabled: ButtonState = new ButtonState("disabled");
    public static Selected: ButtonState = new ButtonState("selected");
    public static All: ButtonState = new ButtonState("all");

    public static FromString(str: string = "up"): ButtonState {
        return ButtonState.buttonStates.get(str);
    }

    private static buttonStates: Map<string, ButtonState>;

    public label: string;

    constructor(label: string) {
        this.label = label;

        if (!ButtonState.buttonStates) {
            ButtonState.buttonStates = new Map<string, ButtonState>();
        }

        ButtonState.buttonStates.set(label, this);
    }
}

export class ButtonEvent {

    public static CLICK: ButtonEvent = new ButtonEvent("pointertap");
    public static POINTER_UP: ButtonEvent = new ButtonEvent("pointerup");
    public static POINTER_UP_OUTSIDE: ButtonEvent = new ButtonEvent("pointerupoutside");
    public static POINTER_OUT: ButtonEvent = new ButtonEvent("pointerout");
    public static POINTER_OVER: ButtonEvent = new ButtonEvent("pointerover");
    public static POINTER_DOWN: ButtonEvent = new ButtonEvent("pointerdown");
    public static POINTER_MOVE: ButtonEvent = new ButtonEvent("pointermove");

    private id: string;

    constructor(id: string) {
        this.id = id;
    }

    public getPIXIEventString(): string {
        if (!(window as any).PointerEvent) {

            if ("ontouchstart" in window) {
                if (this.id === "pointerdown") {
                    return "touchstart";
                }
                if (this.id === "pointerup") {
                    return "touchend";
                }
                if (this.id === "pointerupoutside") {
                    return "touchendoutside";
                }
                if (this.id === "pointermove") {
                    return "touchmove";
                }
                if (this.id === "pointertap") {
                    return "tap";
                }
            } else {
                if (this.id === "pointerdown") {
                    return "mousedown";
                }
                if (this.id === "pointerup") {
                    return "mouseup";
                }
                if (this.id === "pointerupoutside") {
                    return "mouseupoutside";
                }
                if (this.id === "pointerout") {
                    return "mouseout";
                }
                if (this.id === "pointerover") {
                    return "mouseover";
                }
                if (this.id === "pointermove") {
                    return "mousemove";
                }
                if (this.id === "pointertap") {
                    return "click";
                }
            }
        }

        return this.id;
    }

    public getDOMEventString(): string {
        if (!(window as any).PointerEvent) {

            if ("ontouchstart" in window) {
                if (this.id === "pointerdown") {
                    return "touchstart";
                }
                if (this.id === "pointerup") {
                    return "touchend";
                }
                if (this.id === "pointerupoutside") {
                    return "touchcancel";
                }
                if (this.id === "pointermove") {
                    return "touchmove";
                }
            } else {
                if (this.id === "pointerdown") {
                    return "mousedown";
                }
                if (this.id === "pointerup") {
                    return "mouseup";
                }
                if (this.id === "pointermove") {
                    return "mousemove";
                }
            }
        }

        if (this.id === "pointertap") {
            return "touchstart";
        }

        return this.id;
    }
}
