import { ResultBuilder } from "appworks/server/builders/result-builder";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { SlingoResponse } from "slingo/integration/slingo-schema";

export class SlingoReelSpinResultBuilder implements ResultBuilder {
    public filter(data: SlingoResponse, record?: SlingoRecord): boolean {
        return data.spinResult && Boolean(data.spinResult.reelSequence);
    }

    public build(record: SlingoRecord, data: SlingoResponse): SlingoReelSpinResult[] {
        const result = new SlingoReelSpinResult();

        result.patterns = data.spinResult.patternWin.numberOfPatterns;
        result.symbols = data.spinResult.reelSequence.symbols;
        result.matches = [];
        for (const match of data.spinResult.matchedNumbers) {
            result.matches.push({
                matchedValue: match,
                reelIndex: result.symbols.indexOf(match.toString())
            })
        }

        return [result];
    }

}
