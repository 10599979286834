import { deepClone } from "appworks/utils/collection-utils";
import { Signal } from "signals";
import { SettingsModel } from "./settings/settings-model";

export class ClientModel {

    public onUpdate: Signal = new Signal();

    private settings: SettingsModel = new SettingsModel();

    public read() {
        return {
            settings: deepClone(this.settings)
        };
    }

    public write(model: {
        settings?: Partial<SettingsModel>
    }) {
        this.settings = { ...this.settings, ...model.settings };

        this.onUpdate.dispatch();
    }
}
