import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { SlingoRecord } from "../../../model/records/slingo-record";
import { symbolColors } from "../record/slingo-record-display";

export function SlingoReelSpinResultDisplay(record: SlingoRecord, result: SlingoReelSpinResult) {
    const container = document.createElement("div");

    const table = document.createElement("table");
    table.style.width = "70%";
    table.style.height = "10%";
    table.style.marginLeft = "auto";
    table.style.marginRight = "auto";
    container.appendChild(table);

    result.symbols.forEach((value: string) => {
        const symbol = document.createElement("td");
        symbol.style.border = "solid 1px #fff";
        symbol.style.textAlign = "center";
        symbol.style.verticalAlign = "middle";
        symbol.innerText = value;
        table.appendChild(symbol);

        symbol.style.backgroundColor = (result.matches.find(match => match.matchedValue.toString() === value)) ?
            symbolColors.matched :
            symbolColors.unmatched;
    });

    for (const match of result.matches) {
        container.innerHTML += "<br>" + JSON.stringify(match);
    }

    return container;
}
