import { gameState } from "appworks/model/game-state";
import { commsManager } from "appworks/server/comms-manager";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { uiFlags, UIFlag } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { SlingoPurchaseEntryRequestPayload } from "slingo/model/requests/slingo-purchase-entry-request-payload";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";

export class SlingoPurchaseEntryState extends State {
    public onEnter(): void {
        this.sendRequest().then(() => this.complete());
    }

    public complete(): void {
        const gameplay = gameState.getCurrentGame();
        gameplay.setToLatestRecord();

        if (gameplay.getTotalWagered() === 0) {
            // Purchase request must have been unsuccessful
            // i.e. account limits, insufficient funds
            return this.error();
        }

        Services.get(SoundService).customEvent(SlingoSoundEvent.play_game);

        Services.get(TransactionService).setBalance(gameplay.balance);
        super.complete();
    }

    protected sendRequest(): Contract<void> {
        return new Sequence([
            () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, true)),
            () => commsManager.request(new SlingoPurchaseEntryRequestPayload()),
            () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, false))
        ]);
    }
}