import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { Layers } from "appworks/graphics/layers/layers";
import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { StakeListRadioControl } from "appworks/ui/controls/standard/radio/stake-list-radio-control";
import { ControlFactory } from "../control-factory";

export class StakeListRadioControlFactory implements ControlFactory {
    public filter(layer: Layers): boolean {
        return Boolean(layer.getFlexiButton("stake_0"));
    }

    public build(layer: Layers): AbstractControl[] {
        // Get buttons and labels
        const buttons: FlexiButton[] = [];
        const labels: FlexiText[] = [];
        let i = 0;
        let button = layer.getFlexiButton("stake_" + i);
        while (button) {
            buttons.push(button);

            const label = layer.getFlexiText("stake_label_" + i)
            if (label) { labels.push(label); }

            i++;
            button = layer.getFlexiButton("stake_" + i);
        }

        return [new StakeListRadioControl(
            buttons,
            labels,
            layer.getFlexiButton("arrow_back"),
            layer.getFlexiButton("arrow_forward")
        )];
    }

}