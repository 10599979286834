import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { deviceInfo } from "appworks/utils/device-info";
import { Text } from "../pixi/text";

export class FlexiInputText extends FlexiText {

    public selected: boolean;

    private defaultText: string = "";

    private currentText: string;

    private onKeyDown: Array<() => void> = [];

    private readonly enterKeyCode: string = "Enter";

    public addTarget(target: Text | HTMLElement) {
        if (target instanceof HTMLElement) {
            target.addEventListener("input", this.onHTMLInputChange as any);
            target.addEventListener("focus", this.select);
            target.addEventListener("blur", this.deselect);
            if (deviceInfo.isAppleDevice()) {
                target.addEventListener("focusout", this.deselect);
            }
            target.addEventListener("keydown", this.onEnterKeyDown);
        } else {
            throw new Error("Not implemented");
        }

        super.addTarget(target);
    }

    public setDefaults(text: string) {
        this.defaultText = text;

        this.setText(text);
    }

    public setText(text: string) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                const input = target as HTMLInputElement;
                input.value = text;
                input.setAttribute("value", input.value);
            } else {
                super.setText(text);
            }
        }
    }

    public setHighlight(highlight: boolean) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                const input = target as HTMLInputElement;
                input.classList.toggle("highlight", highlight);
            } else {
                throw new Error("Not implemented");
            }
        }
    }

    public focus() {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                const input = target as HTMLInputElement;
                input.focus();
            } else {
                throw new Error("Not implemented");
            }
        }
    }

    public getText(): string {
        return this.currentText;
    }

    public on(event: InputTextEvent, handler: () => void) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                if (event === InputTextEvent.CHANGE) {
                    target.addEventListener("input", handler as any);
                } else if (event === InputTextEvent.BLUR) {
                    target.addEventListener("blur", handler as any);
                }
            } else {
                throw new Error("Not implemented");
            }
        }

        if (event === InputTextEvent.ENTERKEYDOWN) {
            this.onKeyDown.push(handler);
        }
    }

    public off(event: InputTextEvent, handler: () => void) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                if (event === InputTextEvent.CHANGE) {
                    target.removeEventListener("input", handler as any);
                } else if (event === InputTextEvent.BLUR) {
                    target.removeEventListener("blur", handler as any);
                }
            } else {
                throw new Error("Not implemented");
            }
        }

        if (event === InputTextEvent.ENTERKEYDOWN) {
            if (this.onKeyDown.indexOf(handler) > -1) {
                this.onKeyDown.splice(this.onKeyDown.indexOf(handler), 1);
            }
        }
    }

    public destroy() {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.removeEventListener("input", this.onHTMLInputChange as any);
                target.removeEventListener("focus", this.select);
                target.removeEventListener("blur", this.deselect);
                if (deviceInfo.isAppleDevice()) {
                    target.removeEventListener("focusout", this.deselect);
                }
                target.removeEventListener("keydown", this.onEnterKeyDown);
            } else {
                throw new Error("Not implemented");
            }
        }

        this.targets = [];
        this.onKeyDown = [];

        super.destroy();
    }

    public setMinMaxRange(min: number, max: number) {
        const htmlElement = this.targets[0] as HTMLElement;
        htmlElement.setAttribute("min", min.toString());
        htmlElement.setAttribute("max", max.toString());
    }

    private onEnterKeyDown = (event: KeyboardEvent) => {
        if (event.key === this.enterKeyCode) {
            this.onKeyDown.forEach((handler: () => void) => {
                handler();
            });
        }
    }

    private onHTMLInputChange = (event: any) => {
        let text;

        const input = event.target as HTMLInputElement;
        text = input.value;

        this.currentText = text;
    }

    private select = (event: any) => {
        this.selected = true;
    }

    private deselect = (event: any) => {
        this.selected = false;

        if (this.currentText === "" || this.currentText === "NaN") {
            this.currentText = this.defaultText;
        }
    }
}

export class InputTextEvent {
    public static CHANGE: InputTextEvent = new InputTextEvent("change");
    public static BLUR: InputTextEvent = new InputTextEvent("blur");
    public static ENTERKEYDOWN: InputTextEvent = new InputTextEvent("keydown");

    constructor(public id: string) {
    }
}
