import { Result } from "appworks/model/gameplay/records/results/result";
import { SlingoCompletionReason } from "../../integration/slingo-schema";

export class SlingoGameProgressResult extends Result {
    public resultType: string = "SlingoGameProgressResult";

    public completionReason: SlingoCompletionReason;
    public totalWin: number;

    public standardSpins: number;
    public standardSpinsUsed: number;
    public standardSpinsRemaining: number;

    public purchaseSpins: number;
    public purchaseSpinsUsed: number;
    public purchaseSpinsRemaining: number;
    public hasFirstPurchaseSpin: boolean;
    public purchaseStake: number;
    public nextPurchaseStake: number;
    public standardStake: number;
    public totalStake: number;

    public freeSpins: number;
    public freeSpinsUsed: number;
    public freeSpinsRemaining: number;

    public matchedNumbers: number[];
    public matchedPatterns: number;
}
