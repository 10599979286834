import { FlexiSlider } from "appworks/graphics/elements/flexi-slider";
import { SliderElement } from "appworks/graphics/elements/slider-element";
import { Layers } from "appworks/graphics/layers/layers";
import { VolumeSliderControl } from "appworks/ui/controls/standard/slider/volume-slider-control";
import { ControlFactory } from "appworks/ui/factories/control-factory";

export class VolumeSliderControlFactory implements ControlFactory {
    public filter(layer: Layers): boolean {
        return (
            layer.getSprite("volume_track") != null &&
            layer.getSprite("volume_fill") != null &&
            layer.getSprite("volume_handle") != null &&
            layer.getFlexiText("volume_label") != null
        );
    }

    public build(layer: Layers): VolumeSliderControl[] {
        const sliderElement = new SliderElement(
            layer,
            layer.getSprite("volume_track"),
            layer.getSprite("volume_fill"),
            layer.getSprite("volume_handle")
        );

        const slider = new FlexiSlider();
        slider.addTarget(sliderElement);

        const label = layer.getFlexiText("volume_label");

        return [new VolumeSliderControl(slider, label)];
    }
}
