import { ci } from "appworks/debug/ci";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { FlexiToggle, ToggleEvent } from "appworks/graphics/elements/flexi-toggle";
import { Layers } from "appworks/graphics/layers/layers";
import { Scene } from "appworks/graphics/layers/scene";
import { CenterPivot } from "appworks/graphics/pixi/group";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import * as TweenJS from "appworks/utils/tween";
import { Signal } from "signals";
import { Components } from "../components";
import { PromptComponent } from "./prompt-component";

export enum LoadType {
    DISABLE_BUTTON = "disableButton",
    LOADING_SCENE = "loadingScene",
    HIDE_BUTTON = "hideButton"
}

// TODO: Ditch this garbage component and make a usable one
export class PromptContentSubcomponent {
    public layer = Layers.get("Prompts");

    public timeOutTime: number;
    public continueButton: FlexiButton;

    // By default is a continue button is present, the prompt will never honor the timeout set. Set this on to still honor timeouts
    public dontBlockTimeouts: boolean;

    public onHide: Signal = new Signal();

    protected clickToSkip: boolean;

    protected loadRaceType: LoadType = LoadType.DISABLE_BUTTON;

    protected buttons = new Map<FlexiButton, () => void>();
    protected toggles = new Map<FlexiToggle, (value: boolean) => void>();
    protected scene: Scene;

    protected continueButtonName: string;
    protected textContents: Map<string, string> = new Map<string, string>();
    protected buttonContents = new Map<string, () => void>();
    protected hiddenButtons: string[] = [];
    protected toggleContents = new Map<string, (value: boolean) => void>();
    protected countups = new Map<string, CountupDefinition>();
    protected countupContract: Contract<void>;

    protected spinnerTween: TweenJS.Tween;

    constructor(protected scenes: string[], time: number, layer?: Layers) {
        this.timeOutTime = time;

        if (layer) {
            this.layer = layer;
        }

        this.scene = this.layer.getFirstValidScene(this.scenes);

        if (!this.scene) {
            throw new Error("No scene found in entire list for prompt. [" + this.scenes.join(", ") + "]");
        }
    }

    public show(): Contract<void> {

        const setupContents = () => {
            if (this.textContents) {
                this.textContents.forEach((value, index) => {
                    this.populateTextField(index, value);
                });
            }

            if (this.countups) {
                this.countups.forEach((countup: CountupDefinition, contentName: string) => {
                    this.populateTextField(contentName, Services.get(CurrencyService).format(0));
                });
            }

            if (this.continueButtonName) {
                this.continueButton = this.setButton(this.continueButtonName);
            }

            this.hiddenButtons.forEach((name) => {
                const button = this.layer.getFlexiButton(name);
                button.setVisible(false);
            });
        };

        return new Contract<void>((resolve) => {
            this.layer.setScene(this.scene, setupContents).then(() => {
                if (this.buttonContents.size > 0) {
                    this.buttonContents.forEach((value, index, map) => {
                        this.setButton(index, value);
                    });
                }

                if (this.toggleContents.size > 0) {
                    this.toggleContents.forEach((value, index, map) => {
                        this.setToggle(index, value);
                    });
                }

                if (this.countups.size > 0) {
                    const countupContracts: Array<() => Contract<void>> = [];

                    this.countups.forEach((countup: CountupDefinition, contentName: string) => {
                        countupContracts.push(() => this.startCountup(countup, contentName));
                    });

                    this.countupContract = new Parallel<void>(countupContracts).then(() => {
                        this.enableButtons();
                    });
                } else {
                    this.enableButtons();
                }

                if (this.layer.getSprite("spinner")) {
                    this.spinnerTween = new TweenJS.Tween(CenterPivot(this.layer.getSprite("spinner")))
                        .to({ rotation: Math.PI * 2 }, 500)
                        .repeat(Infinity)
                        .start();
                }

                const coverIds = ["cover", "landscape_cover", "portrait_cover"];
                for (const coverId of coverIds) {
                    const cover = this.layer.getSprite(coverId) || this.layer.getShape(coverId);
                    if (cover) {
                        cover.interactive = true;
                        cover.cursor = "default";
                    }
                }

                resolve(null);
            });
        });
    }

    public setText(key: string, value: string) {
        this.textContents.set(key, value);
    }

    public addContinueButton(name: string = "continue"): void {
        this.continueButtonName = name;
    }

    public hasContinueButton() {
/////////////////////////////////////////////////////////
////////////////////////////
/////////////////////////////
/////////
//////////////////
        return this.continueButton;
    }

    public addContinueListener(upHandler: () => void) {
        if (this.hasContinueButton()) {
            this.continueButton.on(ButtonEvent.CLICK, () => {
                Services.get(SoundService).event(SoundEvent.prompt_continue_press);
                this.continueButton.setEnabled(false);
                upHandler();
            });
        }
    }

    public hideContinueButton() {
        if (this.hasContinueButton()) {
            this.continueButton.setVisible(false);
        }
    }

    // Use for hiding buttons which aren't ever needed in the prompt, for example something which is hidden in certain jurisdictions or configurations
    public hideButton(name: string) {
        const button = this.layer.getFlexiButton(name);
        if (button) {
            button.setVisible(false);
        }
        this.hiddenButtons.push(name);
    }

    public disableContinueButton() {
        if (this.hasContinueButton()) {
            this.continueButton.setEnabled(false);
        }
    }

    public enableContinueButton() {
        if (this.hasContinueButton()) {
            this.continueButton.setVisible(true);
            this.continueButton.setEnabled(true);
        }
    }

    public addButton(name: string, upHandler?: () => void) {
        this.buttonContents.set(name, upHandler);
    }

    public addToggle(name: string, toggleHandler: (value: boolean) => void) {
        this.toggleContents.set(name, toggleHandler);
    }

    public countupText(name: string, value: number, time: number) {
        this.countups.set(name, new CountupDefinition(value, time));
    }

    public setClickToSkip(clickToSkip: boolean) {
        this.clickToSkip = clickToSkip;
    }

    public hide(): Contract<void> {
        // TODO: Ugly hack, this subcomponent needs to be removed and prompt component refactored in V6
        Components.get(PromptComponent).promptClosed();

        if (!this.onHide) {
            return Contract.empty();
        }

        return new Contract<void>((resolve) => {
            this.layer.defaultScene().then(() => {
                this.onHide.dispatch();
                this.destroy();
                resolve(null);
            });
        });
    }

    public destroy() {
        // TODO: Ugly hack, this subcomponent needs to be removed and prompt component refactored in V6
        Components.get(PromptComponent).promptClosed();

        if (this.spinnerTween) {
            this.spinnerTween.stop();
            delete this.spinnerTween;
        }

        this.buttons.forEach((value, index, map) => {
            index.off(ButtonEvent.CLICK, value);
            index.destroy();
        });

        this.toggles.forEach((value, index, map) => {
            index.off(ToggleEvent.CHANGE, value);
            index.destroy();
        });

        if (this.countupContract) {
            this.countupContract.cancel();
            this.countupContract = null;
        }

        this.buttons = null;
        this.toggles = null;

        this.onHide.dispose();
        this.onHide = null;
    }

    public setLoadType(type: LoadType) {
        this.loadRaceType = type;
    }

    public getLoadRaceType(): LoadType {
        return this.loadRaceType;
    }

    public getClickToSkip(): boolean {
        return this.clickToSkip;
    }

    public enableButtons() {
        this.buttons.forEach((value: any, button: FlexiButton) => {
            button.setEnabled(true);
        });
    }

    public disableButtons() {
        this.buttons.forEach((value: any, button: FlexiButton) => {
            button.setEnabled(false);
        });
    }

    protected startCountup(countup: CountupDefinition, contentName: string) {
        return new Contract<void>((resolve) => {
            if (countup.value > 0) {
                Services.get(SoundService).event(SoundEvent.prompt_countup_start);
                Services.get(SoundService).event(SoundEvent.prompt_countup_start_SCENE, this.scene.name);
            }

            const onComplete = () => {
                if (countup.value > 0) {
                    Services.get(SoundService).event(SoundEvent.prompt_countup_end);
                    Services.get(SoundService).event(SoundEvent.prompt_countup_end_SCENE, this.scene.name);
                }

                this.populateTextField(contentName, Services.get(CurrencyService).format(countup.value));
                resolve(null);
                document.body.removeEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), skipCountup, true);
            };

            const tickObj = { value: 0 };
            const tween = new TweenJS.Tween(tickObj)
                .to({ value: countup.value }, countup.time)
                .onUpdate(() => {
                    this.populateTextField(contentName, Services.get(CurrencyService).format(tickObj.value));
                })
                .onComplete(onComplete);

            const skipCountup = () => {
                tween.stop();
                onComplete();
            };

            document.body.addEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), skipCountup, true);

            tween.start();
        });
    }

    protected setButton(name: string, upHandler?: () => void): FlexiButton {
        const button = this.layer.getFlexiButton(name);
        if (button) {
            if (upHandler) {
                const scopedListener = () => upHandler();
                button.on(ButtonEvent.CLICK, scopedListener);
                this.buttons.set(button, scopedListener);
            }

            button.setEnabled(false);
        }

        return button;
    }

    protected setToggle(name: string, toggleHandler: (value: boolean) => void) {
        const toggle = this.layer.getFlexiToggle(name);
        if (toggle) {
            toggle.setValue(false);

            const handler = () => {
                const toggleValue = toggle.getValue();
                toggleHandler(toggleValue);
            };

            toggle.on(ToggleEvent.CHANGE, handler);

            this.toggles.set(toggle, handler);
        }
    }

    protected populateTextField(id: string, text: string) {
        const textField = this.layer.getFlexiText(id) || this.layer.getBitmapText(id);

        if (textField) {
            textField.setText(text);
        }
    }
}

class CountupDefinition {
    constructor(public value: number, public time: number) {
    }
}
