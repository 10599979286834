import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { AbstractSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/abstract-symbol-behaviour";
import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";

export abstract class SymbolBehaviourDriver {

    public symbolId: string;

    protected proxy: SymbolBehaviourDriver;

    protected behaviours: AbstractSymbolBehaviour[] = [];

    public addBehaviour(behaviour: AbstractSymbolBehaviour) {
        this.behaviours.push(behaviour);
    }

    public removeAllBehaviours() {
        for (let i = this.behaviours.length; i >= 0; i--) {
            this.behaviours.splice(i, 1);
        }
    }

    public removeBehaviour(behaviour: AbstractSymbolBehaviour) {
        for (let i = this.behaviours.length; i >= 0; i--) {
            if (this.behaviours[i] === behaviour) {
                this.behaviours.splice(i, 1);
            }
        }
    }

    public removeBehaviourOfType<T extends AbstractSymbolBehaviour>(behaviourType: { new(...args: any[]): T }) {
        for (let i = this.behaviours.length; i >= 0; i--) {
            if (this.behaviours[i] instanceof behaviourType) {
                this.behaviours.splice(i, 1);
            }
        }
    }

    public getBehavioursOfType<T extends AbstractSymbolBehaviour>(behaviourType: { new(...args: any[]): T }): T[] {
        if (this.proxy) {
            return this.proxy.getBehavioursOfType<T>(behaviourType);
        }
        return this.behaviours.filter((behaviour) => behaviour instanceof behaviourType) as T[];
    }

    public getFirstBehaviourOfType<T extends AbstractSymbolBehaviour>(behaviourType: { new(...args: any[]): T }): T | undefined {
        return this.getBehavioursOfType(behaviourType)[0];
    }

    public getBehaviourByName<T extends AbstractSymbolBehaviour>(behaviourName: string): T {
        if (this.proxy) {
            return this.proxy.getBehaviourByName<T>(behaviourName);
        }
        return this.behaviours.find((behaviour) => behaviour.behaviourName === behaviourName) as T;
    }

    public getFirstBehaviour(): AbstractSymbolBehaviour {
        if (this.proxy) {
            return this.proxy.getFirstBehaviour();
        }
        return this.behaviours[0];
    }

    /**
     * Sometimes a symbol should not handle it's own behaviours, and instead
     * a proxy takes over. For example with a stack symbol
     */
    public setProxy(proxy: SymbolBehaviourDriver) {
        this.proxy = proxy;
    }

    public getProxy() {
        return this.proxy;
    }

    // TODO: Reduce cyclomatic complexity
    // tslint:disable-next-line:cyclomatic-complexity
    public executeBehaviour(method: SymbolBehaviourMethod, result?: SymbolWinResult, ...args: any[]): Contract<void> {

        if (this.proxy) {
            return this.proxy.executeBehaviour(method, result, ...args);
        }

        const methods = [];

        for (const behaviour of this.behaviours) {

            let behaviourActive = true;

            if (method !== SymbolBehaviourMethod.SymbolChange && method !== SymbolBehaviourMethod.UpdateTransform) {
                if (behaviour.validSymbols) {
                    behaviourActive = false;

                    for (const validSymbol of behaviour.validSymbols) {

                        if (this.symbolId === validSymbol) {
                            behaviourActive = true;
                            break;
                        }
                    }
                }

                if (behaviour.invalidSymbols) {
                    for (const invalidSymbol of behaviour.invalidSymbols) {

                        if (this.symbolId === invalidSymbol) {
                            behaviourActive = false;
                            break;
                        }
                    }
                }
            }

            if (behaviourActive) {
                // TODO: Convert this to map lookups
                if (method === SymbolBehaviourMethod.Static) {
                    behaviour.static();
                } else if (method === SymbolBehaviourMethod.Idle) {
                    behaviour.idle();
                } else if (method === SymbolBehaviourMethod.Stick) {
                    methods.push(() => behaviour.stick());
                } else if (method === SymbolBehaviourMethod.Land) {
                    methods.push(() => behaviour.land());
                } else if (method === SymbolBehaviourMethod.AnticipateLand) {
                    methods.push(() => behaviour.anticipateLand());
                } else if (method === SymbolBehaviourMethod.Highlight) {
                    methods.push(() => behaviour.highlight(result));
                } else if (method === SymbolBehaviourMethod.Win) {
                    methods.push(() => behaviour.win(result));
                } else if (method === SymbolBehaviourMethod.Cycle) {
                    methods.push(() => behaviour.cycle(result));
                } else if (method === SymbolBehaviourMethod.SymbolChange) {
                    behaviour.changeSymbol();
                } else if (method === SymbolBehaviourMethod.UpdateTransform) {
                    behaviour.updateTransform();
                } else if (method === SymbolBehaviourMethod.HighlightDim) {
                    methods.push(() => behaviour.highlightDim(result));
                } else if (method === SymbolBehaviourMethod.WinDim) {
                    methods.push(() => behaviour.winDim(result));
                } else if (method === SymbolBehaviourMethod.CycleDim) {
                    methods.push(() => behaviour.cycleDim(result));
                } else if (method === SymbolBehaviourMethod.Expand) {
                    methods.push(() => behaviour.expand(...args));
                } else if (method === SymbolBehaviourMethod.Remove) {
                    methods.push(() => behaviour.remove());
                } else if (method === SymbolBehaviourMethod.Anticipate) {
                    methods.push(() => behaviour.anticipate());
                }
            }
        }

        if (!methods.length) {
            return Contract.empty();
        } else {
            return new Parallel(methods);
        }
    }
}

export enum SymbolBehaviourMethod {
    Static,
    Idle,
    Land,
    AnticipateLand,
    Highlight,
    Win,
    Cycle,
    SymbolChange,
    UpdateTransform,
    HighlightDim,
    WinDim,
    CycleDim,
    Expand,
    Remove,
    Anticipate,
    Stick
}
