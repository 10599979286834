import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { ScriptedAnimation } from "appworks/graphics/animation/scripted-animation";
import { Layers } from "appworks/graphics/layers/layers";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { Position } from "appworks/graphics/pixi/position";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Model } from "appworks/model/model";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { contains, deepClone, normalizeIndex, wrapIndex } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { EasingFactory } from "appworks/utils/easing-factory";
import { Point } from "appworks/utils/geom/point";
import { Timer } from "appworks/utils/timer";
import { Signal } from "signals";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { ReelSubcomponent } from "slotworks/components/matrix/reel/reel-subcomponent";
import { SymbolComponentType, SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";
import { slotDefinition } from "slotworks/model/slot-definition";
import { JurisdictionService } from "slotworks/services/jurisdiction/jurisdiction-service";
import { ReelSpinnerResultBuffer } from "./reel-spinner-result-buffer";
import { StandardReelSpinnerResultBuffer } from "./standard-reel-spinner-result-buffer";

export class ReelSpinner<T extends SymbolSubcomponent = SymbolSubcomponent> {
    /**
     * Override the layer the anticipation animations are searched for in (SymbolAnimations by default)
     */
    public static anticipationLayer: Layers | Layers[];
    public static minRepeats = 7;

    /**
     * Spin stage to begin reel blur, defaults to "repeat" stage
     */
    public static blurStartStage: number = -1;
    /**
     * Spin stage to end reel blur, defaults to "stop" stage
     */
    public static blurEndStage: number = -1;

    public static blurStartStageName: string;
    public static blurEndStageName: string;

    public static bufferType: { new(...args: any[]): ReelSpinnerResultBuffer } = StandardReelSpinnerResultBuffer;

    public static minSpinTimeMode: ("delayFirstReelStop" | "staggerStops") = "delayFirstReelStop";

    /**
     * Toggle to true during freespins, respins etc where min spin time regs don't apply
     */
    public static disableMinSpinTime: boolean = false;

    // When min spin time is too short because the totalSpinTime estimate is wrong, use this number to hard code a usual spin time
    // For example, if a spin takes approx 500ms, set this to 500, then a min spin time of 2500 will add a 2000ms delay
    public static baseSpinTime: number = 0;

    public static getTotalSpinTime(spinStages: SpinStage[]) {
        if (ReelSpinner.baseSpinTime) {
            return ReelSpinner.baseSpinTime;
        }
        const reelSpinTime = spinStages.reduce<number>((total, stage) => {
            if (stage.t) {
                total += stage.t;
            } else if (stage.s !== 0) {
                const a = ((stage.v * stage.v) - (stage.u * stage.u)) / (2 * stage.s);
                total += Math.abs((stage.v !== stage.u) ? ((stage.v - stage.u) / a) : (stage.s / stage.u));
            }
            return total;
        }, 0);

        return reelSpinTime + ReelSpinner.getStopGap() * (slotDefinition.matrixGrid.length - 1);
    }

    /**
     * Adds a minSpin stage to spin stages in order to ensure a minimum spin time
     */
    public static setMinSpinTime(minDuration: number) {
        if (ReelSpinner.stageSets.size === 0) {
            return;
        }

        ReelSpinner.stageSets.forEach((stageSet) => {
            if (stageSet.spin) {
                stageSet.spin.forEach((spinStages) => {
                    if (spinStages) {
                        switch (this.minSpinTimeMode) {
                            case "delayFirstReelStop": {
                                const stageIndex: number = spinStages.findIndex((sta) => sta.name === "minSpin");
                                if (stageIndex >= 0) {
                                    spinStages.splice(stageIndex, 1);
                                }

                                const minSpinTime = minDuration - ReelSpinner.getTotalSpinTime(spinStages);

                                const repeatIndex = spinStages.findIndex((sta) => sta.name === "repeat");
                                const v = (repeatIndex >= 0) ? spinStages[repeatIndex].v : 0.01;

                                if (minSpinTime > 0) {
                                    const stage: SpinStage = {
                                        name: "minSpin",
                                        t: minSpinTime,
                                        s: Math.round(minSpinTime * v)
                                    } as SpinStage;

                                    spinStages.splice(repeatIndex, 0, stage);
                                }

                                break;
                            }

                            case "staggerStops": {
                                const stopStage = spinStages.find(stage => stage.name === "stop");
                                const stopEvent = stopStage.events.find(event => event.name === SpinStageEvents.STOP);
                                stopEvent.delay = Math.max(stopEvent.delay, minDuration / slotDefinition.matrixGrid.length);
                                break;
                            }
                        }
                    }
                });
            }
        });
    }

    /**
     * Add a set of stages which can be switched to at runtime
     */
    public static addStageSet(stageSet: StageSet) {
        ReelSpinner.stageSets.set(stageSet.id, stageSet);
    }

    /**
     * Change the pre defined stage set to use
     */
    public static setStageSet(id: string) {
        ReelSpinner.currentStageSet = id;
    }

    private static currentStageSet: string;
    private static stageSets: Map<string, StageSet> = new Map();

    // Calculates time between reels 0 and 1 stopping, for use in calculating total spin time
    private static getStopGap() {
        const spinStages = ReelSpinner.stageSets.get(ReelSpinner.currentStageSet)?.spin[0];
        let count = false;
        const gapUntilNextReelStop = spinStages.reduce<number>((total, stage) => {
            // Start counting stage durations
            if (stage.events && stage.events.find((event) => event.name === SpinStageEvents.START)) {
                count = true;
            }
            // Stop counting stage durations
            if (stage.events && stage.events.find((event) => event.name === SpinStageEvents.STOP)) {
                count = false;
            }
            if (count) {
                if (stage.t) {
                    total += stage.t;
                } else {
                    const a = ((stage.v * stage.v) - (stage.u * stage.u)) / (2 * stage.s);
                    total += Math.abs((stage.v !== stage.u) ? ((stage.v - stage.u) / a) : (stage.s / stage.u));
                }
            }
            return total;
        }, 0);
        return gapUntilNextReelStop;
    }

    public index: number;

    public onStageEvent: Signal = new Signal();
    public landSignal: Signal = new Signal();
    public onMove: Signal = new Signal();
    public onAnticipationStart: Signal = new Signal();
    public onAnticipationEnd: Signal = new Signal();
    public onComplete: Signal = new Signal();
    public anticipating: boolean = false;
    public wasAnticipating: boolean = false;

    protected matrix: AbstractMatrixComponent<T>;
    protected reel: ReelSubcomponent;

    protected allSymbols: T[];
    protected symbolPositions: DualPosition[];

    protected topExtendedHiddenSymbol: T;
    protected topHiddenSymbol: T;
    protected bottomHiddenSymbol: T;

    // The current temporary reel strip, including buffers, results etc
    protected reelstrip: string[];
    // The reel strip seen when spinning
    protected spinReelstrip: string[];

    protected result: ReelstripPosition;
    // The position in the reel strip the last result & start buffer was spliced in at. Needs to be removed as soon as it is no longer visible
    protected reelPatchPosition: number = -1;

    protected doneSpinEvents: SpinStageEvents[];

    protected resultBuffer: ReelSpinnerResultBuffer;

    // Configs
    protected stages: SpinStage[];

    // State
    protected animating: boolean;
    protected started: boolean;
    protected stage: number = 0;
    protected stopTimeout: number;
    protected stopping: boolean;
    protected waitingToStop: boolean;
    protected repeatCount: number = 0;
    protected landing: boolean;

    // Motion variables
    protected startTime: number;
    protected target: SpinStage;
    // Current position is somewhere between where a stage starts and ends, depending on time
    protected currentPosition: number = 0;
    // Fixed position is where the reel should be between stages
    protected fixedPosition: number = 0;
    // The last distance update
    protected lastDistance: number = 0;

    // Contract resolves
    protected startedResolve: Function;
    protected completeResolve: Function;

    protected anticipationAnimations: Array<{ index: number, anim: AnimatedSprite | ScriptedAnimation | SpineContainer }>;

    protected matrixLayer: Layers;
    protected animationLayer: Layers;
    protected anticipationLayer: Layers;

    protected symbolType: SymbolComponentType<T>;

    constructor(index: number, matrix: AbstractMatrixComponent<T>, reel: ReelSubcomponent, reelstop: number, reelstrip: string[]) {
        this.index = index;

        this.matrix = matrix;
        this.reel = reel;

        this.symbolType = this.matrix.symbolType;

        this.resultBuffer = new ReelSpinner.bufferType();
        this.resultBuffer.init(this.reel);

        this.matrixLayer = this.reel.getMatrixLayer();
        this.animationLayer = this.reel.getAnimationLayer();
        this.anticipationLayer = this.matrix.anticipationLayer || this.animationLayer;

        this.setReelstrip(reelstrip);

        this.fixedPosition = this.currentPosition = reelstop;

        this.getSymbolPositions();
        this.addHiddenSymbols();

        this.enableHiddenSymbols();

        this.updateSymbolPositions();

        // Default blur stages
        if (ReelSpinner.blurStartStage === -1) {
            ReelSpinner.blurStartStage = this.getSpinStages().indexOf(this.getSpinStages().find((stage) => stage.name === KeySpinStages.REPEAT));
        }
        if (ReelSpinner.blurEndStage === -1) {
            ReelSpinner.blurEndStage = this.getSpinStages().indexOf(this.getSpinStages().find((stage) => stage.name === KeySpinStages.STOP));
        }
    }

    public setReelstrip(reelstrip: string[]): void {
        this.reelstrip = [...reelstrip];
        this.spinReelstrip = [...reelstrip];
    }

    /**
     * Start the reel spinning using last reel strip (either from result or from constructor)
     */
    public start(jumpStart: boolean = false, quickSpin: boolean = false): Contract<void> {
        ReelSpinner.setMinSpinTime(ReelSpinner.disableMinSpinTime ? 0 : Services.get(JurisdictionService).getMinimumSpinTime());

        this.doneSpinEvents = [];

        this.enableHiddenSymbols();
        this.getSymbolPositions();

        this.started = false;
        this.anticipating = false;
        this.wasAnticipating = false;

        Services.get(SoundService).event(SoundEvent.reel_N_spin_start, this.reel.index.toString());
        Services.get(SoundService).event(SoundEvent.reel_any_spin_start);

        this.startTime = Timer.time;
        this.target = { s: 0, t: 0, u: 0, v: 0, a: 0 } as SpinStage;
        this.lastDistance = 0;
        this.currentPosition = this.fixedPosition;

        let startBuffer = null;

        if (this.result) {

            const currentGrid = this.getCurrentGrid();

            startBuffer = this.resultBuffer.generateStartBuffer({ index: Math.floor(this.fixedPosition), reelstrip: this.reelstrip }, currentGrid);

            this.reelstrip = [...this.spinReelstrip];
            let validPosition = true;

            do {
                validPosition = true;
                // Make sure we don't start in the middle of a stack, or a stacked symbol matching the top of the start buffer
                this.fixedPosition = this.resultBuffer.getRandomStartPosition(this.reelstrip, currentGrid);
                const topSymbol = slotDefinition.getSymbolDefinition(startBuffer[0] || this.topHiddenSymbol.symbolId);
                const firstFakeSymbol = slotDefinition.getSymbolDefinition(wrapIndex(this.fixedPosition - 1, this.reelstrip));
                if (topSymbol.height > 1 && firstFakeSymbol.name === topSymbol.name) {
                    validPosition = false;
                } else if (firstFakeSymbol.height > 1) {
                    for (let nextSymbolIndex = 1; nextSymbolIndex < firstFakeSymbol.height; nextSymbolIndex++) {
                        const nextSymbol = slotDefinition.getSymbolDefinition(wrapIndex(this.fixedPosition - 1 - nextSymbolIndex, this.reelstrip));
                        if (nextSymbol.name !== firstFakeSymbol.name) {
                            validPosition = false;
                        }
                    }

                }
            } while (!validPosition);

            this.log(this.reel.index, this.fixedPosition, this.reelstrip);

            this.reelstrip.splice(this.fixedPosition, 0, ...currentGrid);
            this.fixedPosition++;

            // Splice in start buffer
            if (startBuffer) {
                this.reelstrip.splice(this.fixedPosition, 0, ...startBuffer);
                this.fixedPosition += startBuffer.length;
            }

            // Current grid doesn't exist in reelstrip, use modified one
            this.reelPatchPosition = this.fixedPosition;
        }

        this.currentPosition = this.fixedPosition;

        this.setStages(quickSpin ? this.getQuickSpinStages() : this.getSpinStages());

        if (jumpStart) {
            for (let stageIndex = 0; stageIndex < this.stages.length; stageIndex++) {
                const stage = this.stages[stageIndex];
                if (stage.name === KeySpinStages.REPEAT) {
                    this.stage = stageIndex;
                }
            }
        }

        this.animating = true;

        return new Contract<void>((resolve) => {
            this.startedResolve = () => resolve(null);
            this.nextStage();
        });
    }

    /**
     * Stops the reel on the specified index within the specified reelstrip
     *
     * @param index {number}
     * @param reelstrip {string[]}
     */
    public stop(index: number, reelstrip: string[], quickSpin: boolean): Contract<void> {
        if (!this.stopping) {

            // Get stages again in case they've changes
            this.setStages(quickSpin ? this.getQuickSpinStages() : this.getSpinStages());
            for (let stageIndex = 0; stageIndex < this.stages.length; stageIndex++) {
                const stage = this.stages[stageIndex];
                if (stage.name === KeySpinStages.REPEAT) {
                    this.stage = stageIndex;
                }
            }

            this.repeatCount = Math.max(this.repeatCount, Math.ceil(ReelSpinner.minRepeats * 0.5));

            if (!this.waitingToStop) {
                this.result = { index, reelstrip };
                this.waitingToStop = true;
            }
        }

        return new Contract<void>((resolve) => {
            this.completeResolve = () => {
                Services.get(SoundService).event(SoundEvent.reel_N_spin_end, this.reel.index.toString());
                Services.get(SoundService).event(SoundEvent.reel_any_spin_end);
                resolve(null);
            };
        });
    }

    /**
     * Quickly stops the reel on the specified index within the specified reelstrip
     *
     * @param index {number}
     * @param reelstrip {string[]}
     */
    public skip(index: number, reelstrip: string[]): Contract<void> {
        if (this.animating) {
            if (!this.stopping) {
                this.repeatCount = ReelSpinner.minRepeats;
                this.waitingToStop = false;
                this.stopping = true;

                Timer.clearTimeout(this.stopTimeout);

                Services.get(SoundService).event(SoundEvent.reel_N_skip_start, this.reel.index.toString());
                Services.get(SoundService).event(SoundEvent.reel_any_skip_start);

                this.result = { index, reelstrip };

                this.setStages(this.getSkipStages());

                this.stages[0] = { ...this.stages[0] };

                const distanceToWhole = Math.ceil(this.currentPosition - 1) - this.currentPosition;

                const distanceToGo = this.target.s - (this.currentPosition - this.fixedPosition);

                if (this.target.name === KeySpinStages.STOP && Math.abs(distanceToGo) < Math.abs(this.stages[0].s)) {
                    const difference = Math.floor(this.stages[0].s) - Math.floor(distanceToGo);
                    this.stages[0].s -= difference;
                }

                this.stages[0].s += distanceToWhole;

                this.fixedPosition = this.currentPosition;
                this.currentPosition = 0;
                this.startTime = Timer.time;
                this.lastDistance = 0;
                this.target = { s: 0, t: 0, u: 0, v: 0, a: 0 } as SpinStage;

                this.nextStage();
            } else if (this.isInstantSkip()) {
                this.stages = deepClone(this.stages);
                this.stages.forEach((stage) => {
                    stage.t = 0;
                });
                this.target.t = 0;
                while (this.animating) {
                    this.update();
                }
            }
        }
        return new Contract<void>((resolve) => {
            this.completeResolve = () => {
                Services.get(SoundService).event(SoundEvent.reel_N_skip_end, this.reel.index.toString());
                Services.get(SoundService).event(SoundEvent.reel_any_skip_end);
                resolve(null);
            };
        });
    }

    /**
     * Slowly stops the reel on the specified index within the specified reelstrip
     *
     * @param index {number}
     * @param reelstrip {string[]}
     */
    public anticipate(index: number, reelstrip: string[]): Contract<void> {
        const anticipationLayers = this.getAnticipationLayers();

        this.anticipating = true;

        this.anticipationAnimations = [];
        for (const layer of anticipationLayers) {
            const anticipationAnimation = layer.getAnimatedSprite("anticipation_" + this.reel.index);
            const scriptedAnticipationAnimation = layer.getScriptedAnimation("anticipation_" + this.reel.index);
            const spineAnticipation = layer.getSpine("anticipation_" + this.reel.index);

            if (anticipationAnimation) {
                anticipationAnimation.visible = true;
                anticipationAnimation.loop = true;
                anticipationAnimation.gotoAndPlay();
                this.anticipationAnimations.push({ index: this.reel.index, anim: anticipationAnimation });
            }
            if (scriptedAnticipationAnimation) {
                scriptedAnticipationAnimation.play();
                this.anticipationAnimations.push({ index: this.reel.index, anim: scriptedAnticipationAnimation });
            }
            if (spineAnticipation) {
                this.anticipationAnimations.push({ index: this.reel.index, anim: spineAnticipation });
                spineAnticipation.play();
            }
        }

        this.onAnticipationStart.dispatch(this.reel.index);

        this.result = { index, reelstrip };

        this.setStages(this.getAnticipationStages());

        const distanceToWhole = Math.ceil(this.currentPosition - 1) - this.currentPosition;

        this.stages[0] = { ...this.stages[0] };
        this.stages[0].s += distanceToWhole;

        this.fixedPosition = this.currentPosition;
        this.currentPosition = 0;
        this.startTime = Timer.time;
        this.lastDistance = 0;
        this.target = { s: 0, t: 0, u: 0, v: 0, a: 0 } as SpinStage;

        this.nextStage();

        return new Contract<void>((resolve) => {
            this.completeResolve = () => {
                Services.get(SoundService).event(SoundEvent.reel_N_anticipate_end, this.reel.index.toString());
                Services.get(SoundService).event(SoundEvent.reel_any_anticipate_end);

                resolve(null);
            };

            if (!this.animating) {
                resolve(null);
            }
        });
    }

    public update() {

        if (this.animating) {

            const elapsedTime = (Timer.time - this.startTime);

            this.move(elapsedTime, this.target.t);

            if (elapsedTime >= this.target.t) {

                this.nextStage();
            }
        }

    }

    /**
     * If the reels jump, we have to update the result the spinner thinks it's looking at
     */
    public jump(index: number, reelstrip: string[]) {
        this.fixedPosition = this.currentPosition = index;
        this.reelstrip = [...reelstrip];
        this.result = { index, reelstrip };
        this.reset();
    }

    public nextSymbol() {
        this.currentPosition = this.currentPosition >= this.reelstrip.length - 1 ? 0 : this.currentPosition + 1;
        this.updateSymbolPositions();
    }

    public previousSymbol() {
        this.currentPosition = this.currentPosition <= 0 ? this.reelstrip.length - 1 : this.currentPosition - 1;
        this.updateSymbolPositions();
    }

    public getCurrentPosition() {
        return this.currentPosition;
    }

    public updateSymbolPositions() {
        let reelIndex = Math.floor(this.currentPosition);
        let offset = 1 - (this.currentPosition - reelIndex);

        if (offset === 1) {
            offset = 0;
            reelIndex--;
        }

        for (let i = 0; i < this.allSymbols.length; i++) {
            const symbol = this.allSymbols[i];
            const position = this.symbolPositions[i].clone();
            const nextPosition = this.symbolPositions[i + 1].clone();

            const lerpedPosition = position.lerp(nextPosition, offset);

            symbol.setTransform(lerpedPosition);
            symbol.setSymbol(wrapIndex(reelIndex + i - 1, this.reelstrip));
        }
    }

    protected spinComplete() {
        this.reset();

        if (this.anticipating) {
            this.anticipating = false;
            this.wasAnticipating = true;
            this.onAnticipationEnd.dispatch(this.reel.index);
        }

        this.completeResolve();

        this.onComplete.dispatch(this.reel.index);
    }

    protected reset() {
        this.currentPosition = this.fixedPosition;
        this.updateSymbolPositions();

        this.repeatCount = 0;
        this.animating = false;
        this.waitingToStop = false;
        this.stopping = false;

        this.reel.unblur();
        this.updateSymbolVelocities(0);

        if (this.anticipationAnimations) {
            for (const anticipationAnimation of this.anticipationAnimations) {
                if (anticipationAnimation && anticipationAnimation.anim) {
                    if (anticipationAnimation.anim instanceof AnimatedSprite) {
                        anticipationAnimation.anim.visible = false;
                    }

                    anticipationAnimation.anim.stop();
                }

                Services.get(SoundService).event(SoundEvent.reel_N_anticipate_skip, anticipationAnimation.index.toString());
                Services.get(SoundService).event(SoundEvent.reel_any_anticipate_skip);
                this.anticipationAnimations = null;
            }
        }
    }

    protected move(elapsedTime: number, totalTime: number) {

        elapsedTime = this.applyEasing(elapsedTime, totalTime);

        const distance = (this.target.u * elapsedTime) + (0.5 * this.target.a) * (elapsedTime * elapsedTime);

        this.currentPosition = this.fixedPosition + distance;

        // Wrap current position
        while (this.currentPosition >= this.reelstrip.length) {
            this.currentPosition -= this.reelstrip.length;
        }
        while (this.currentPosition < 0) {
            this.currentPosition += this.reelstrip.length;
        }

        this.onMove.dispatch(distance - this.lastDistance);
        this.updateSymbolVelocities(distance - this.lastDistance);

        this.lastDistance = distance;

        this.updateSymbolPositions();

        this.clearStartPatch();
    }

    /**
     * When spinning after a result, the last result appears in the current reelstrip, but should be removed as soon as it's off screen, so it doesn't spin past during the spin
     */
    protected clearStartPatch() {
        if (this.reelPatchPosition > -1) {

            const patchSize = this.reelstrip.length - this.spinReelstrip.length;

            const currentReelPosition = Math.floor(this.currentPosition);
            const minTargetPosition = normalizeIndex(this.reelPatchPosition - ((this.reel.size + 2) + (patchSize - this.reel.size)), this.reelstrip);
            const maxTargetPosition = normalizeIndex(this.reelPatchPosition + (this.reel.size + 2), this.reelstrip);

            let spliceSafe = false;

            // Check temporary reelstrip elements are out of view
            if (minTargetPosition < maxTargetPosition) {
                spliceSafe = currentReelPosition <= minTargetPosition || currentReelPosition >= maxTargetPosition;
            } else {
                spliceSafe = currentReelPosition >= minTargetPosition && currentReelPosition <= maxTargetPosition;
            }

            if (spliceSafe) {
                // If removing elements before current position, move current position up so view remains the same
                if (currentReelPosition > this.reelPatchPosition) {
                    const spliceSize = patchSize;
                    this.currentPosition -= spliceSize;
                    this.fixedPosition -= spliceSize;
                }

                const oldGrid = this.getCurrentGrid();

                this.reelstrip = [...this.spinReelstrip];
                this.reelPatchPosition = -1;

                this.updateSymbolPositions();

                const newGrid = this.getCurrentGrid();

                if (newGrid.join(",") !== oldGrid.join(",")) {
                    throw new Error("Internal error");
                }
            }
        }
    }

    protected applyEasing(elapsedTime: number, totalTime: number) {
        const timeRatio = elapsedTime / totalTime;

        // Due to frame time, we can end up with timeRatios greater than 1.
        if (this.target.easing && timeRatio <= 1) {
            const easing = EasingFactory(this.target.easing);
            elapsedTime = easing(timeRatio) * totalTime;
        }

        return elapsedTime;
    }

    // When each stage is complete, this gets the reel ready for the next stage, and decides which stage that will be
    // Special signals also fire here
    // TODO: Reduce cyclomatic complexity
    // tslint:disable-next-line:cyclomatic-complexity
    protected nextStage() {
        if (this.stage > 0) {
            // Update fixed position
            this.fixedPosition += this.target.s;

            // Fix floating point errors
            const diff = Math.abs(this.fixedPosition - Math.round(this.fixedPosition));
            if (diff > 0 && diff < 0.01) {
                this.fixedPosition = Math.round(this.fixedPosition);
            }

            // Wrap position
            while (this.fixedPosition >= this.reelstrip.length) {
                this.fixedPosition -= this.reelstrip.length;
            }
            while (this.fixedPosition < 0) {
                this.fixedPosition += this.reelstrip.length;
            }
        }

        const currentStage = this.stages[this.stage];
        const previousStage = this.stages[this.stage - 1];

        if (ReelSpinner.blurStartStage && ReelSpinner.blurEndStageName && currentStage) {
            const blurStage = this.stages.find((stage) => stage.name === ReelSpinner.blurStartStageName);
            const unblurStage = this.stages.find((stage) => stage.name === ReelSpinner.blurEndStageName);

            if (blurStage && currentStage.name === blurStage.name) {
                this.reel.blur();
            } else if (unblurStage && currentStage.name === unblurStage.name) {
                this.reel.unblur();
            }
        } else {
            // Blurred symbols
            if (!this.stopping && this.stage >= ReelSpinner.blurStartStage && this.stage < ReelSpinner.blurEndStage) {
                this.reel.blur();
            } else {
                this.reel.unblur();
            }
        }

        if (this.stage === this.stages.length) {
            this.spinComplete();
        } else {
            if (currentStage.events) {
                currentStage.events.forEach((event) => {
                    if (!contains(this.doneSpinEvents, event.name)) {
                        this.doneSpinEvents.push(event.name);

                        const dispatchStageEvent = () => {
                            this.onStageEvent.dispatch(this.reel.index, event.name);
                        };

                        if (event.delay > 0) {
                            Timer.setTimeout(dispatchStageEvent, event.delay);
                        } else {
                            dispatchStageEvent();
                        }
                    }
                });
            }

            if (currentStage.name !== KeySpinStages.REPEAT) {
                Services.get(SoundService).event(SoundEvent.spinner_stage_complete, currentStage.name);
                Services.get(SoundService).event(SoundEvent.reel_N_spinner_stage_complete, this.reel.index.toString(), currentStage.name);
            }

            // Lands
            if (currentStage && currentStage.name === KeySpinStages.STOP) {
                this.landing = true;
                // Should send land signal if there is no specific land stage
                if (!this.hasLandStage()) {
                    this.landSignal.dispatch(this.reel.index);
                }
            }

            // Should send land signal when "land" stage is complete, if present
            if (this.target && (this.hasLandStage() && this.target.name === KeySpinStages.LAND)) {
                Services.get(SoundService).event(SoundEvent.reel_N_land, this.reel.index.toString());
                Services.get(SoundService).event(SoundEvent.reel_any_land);

                const settings = Model.read().settings;
                if (settings.turboSpin) {
                    Services.get(SoundService).event(SoundEvent.reel_any_land_turbo);
                } else if (settings.quickSpin) {
                    Services.get(SoundService).event(SoundEvent.reel_any_land_quick);
                } else {
                    Services.get(SoundService).event(SoundEvent.reel_any_land_normal);
                }

                this.landSignal.dispatch(this.reel.index);
            }

            if (currentStage.name === KeySpinStages.STOP) {
                this.stopping = true;
            }

            this.setTarget(currentStage);

            this.landing = false;

            if (currentStage.name === KeySpinStages.REPEAT) {
                this.repeatCount++;
            }

            if (currentStage.name !== KeySpinStages.REPEAT || this.waitingToStop) {
                if (currentStage.name === KeySpinStages.REPEAT) {
                    if (this.stopping || this.repeatCount < ReelSpinner.minRepeats) {
                        this.stage--;
                    }
                }

                this.stage++;
            }

            if (currentStage.name === KeySpinStages.REPEAT && !this.started) {
                this.started = true;
                this.startedResolve();
            }
        }
    }

    // Sets up the next stage and kicks it off
    protected setTarget(target: SpinStage) {
        // Calculate how far past the previous stage we've gone
        const overshootTime = (Timer.time - this.startTime) - this.target.t;

        this.target = { ...target };

        if (this.landing) {
            // Add a buffer between the current reel display (including hidden symbol) and the result, to factor in things like stacks and alternating blanks etc
            // Pass the spin distance into the buffer calculator so it can factor that in
            let indexAfterHidden = Math.floor(this.fixedPosition) - 1;
            if (indexAfterHidden < 0) {
                indexAfterHidden += this.reelstrip.length;
            }

            const bufferSize = this.resultBuffer.getBufferSize(
                this.result,
                { index: indexAfterHidden, reelstrip: this.reelstrip },
                Math.abs(this.getRemainingDistance())
            );
            if (this.target.t && this.target.s !== 0) {
                this.target.t *= (Math.abs(this.target.s) + bufferSize) / Math.abs(this.target.s);
            }
            this.target.s -= bufferSize;

            this.spliceInResults();
        }

        // Calculate velocities / timings
        if (this.target.t !== undefined) {
            this.target.a = 0;
            this.target.u = this.target.v = this.target.s / Math.max(1, this.target.t);
        } else {
            this.target.a = ((this.target.v * this.target.v) - (this.target.u * this.target.u)) / (2 * this.target.s);
            this.target.t = Math.abs((this.target.v !== this.target.u) ? ((this.target.v - this.target.u) / this.target.a) : (this.target.s / this.target.u));
        }

        // Snap current position to where we should be according to the stages
        this.currentPosition = this.fixedPosition;
        // Start the new stage at the overlapped time, to smooth out overshoots
        this.startTime = Timer.time - overshootTime;

        if (this.target.t === 0) {
            this.target.a = 0;
            this.move(1, 1);
        }
    }

    /**
     * Splices the results into the reel when it's time to stop
     */
    protected spliceInResults() {
        // Cancel removal of last result from temp strip, if it hasn't already happened already
        this.reelPatchPosition = -1;

        const resultsToSpliceIn: string[] = [];

        const currentIndex = Math.round(this.fixedPosition);
        const remainingS = this.getRemainingDistance();

        const numberOfSymbols = Math.abs(remainingS);

        for (let i = 0; i < numberOfSymbols; i++) {
            // Splice in an extra hidden symbol if reels are spinning up
            resultsToSpliceIn.push(wrapIndex(this.result.index + i - 1, this.result.reelstrip));
        }

        this.log(this.reel.index, "STRIP BEFORE", this.reelstrip, currentIndex);

        let pushIndex;

        if (remainingS < 0) {
            // Spinning forwards
            pushIndex = currentIndex - 1;

            if (pushIndex < 0) {
                pushIndex += this.reelstrip.length;
            } else {
                this.fixedPosition += numberOfSymbols;
                this.currentPosition += numberOfSymbols;
            }
        } else {
            // Spinning backwards
            pushIndex = currentIndex + this.reel.size;
            if (pushIndex > this.reelstrip.length) {
                pushIndex -= this.reelstrip.length;
            }
        }

        this.resultBuffer.generateResultBuffer(resultsToSpliceIn, this.result, { index: pushIndex, reelstrip: this.reelstrip });

        if (remainingS >= 0) {
            if (pushIndex <= this.fixedPosition) {
                this.fixedPosition += resultsToSpliceIn.length;
                this.currentPosition += resultsToSpliceIn.length;
            }
        }

        this.log("STRIP PUSHING IN ", [...resultsToSpliceIn].reverse(), "AT", pushIndex, "BECAUSE REEL WILL STOP AT", currentIndex, "+", numberOfSymbols);

        while (resultsToSpliceIn.length) {
            this.reelstrip.splice(pushIndex, 0, resultsToSpliceIn.pop());
        }

        this.log(this.reel.index, "STRIP AFTER", this.reelstrip, currentIndex);
        this.log("STRIP TO SHOW ON STOP",
            this.reel.index,
            this.reelstrip.slice(0, this.currentPosition).reverse(),
            this.reelstrip.slice(this.currentPosition, this.reelstrip.length).reverse(),
            this.getRemainingDistance());
    }

    protected getRemainingDistance() {
        let remainingS = this.target.s;
        for (let stageIndex = this.stage + 1; stageIndex < this.stages.length; stageIndex++) {
            remainingS += this.stages[stageIndex].s;
        }

        return Math.round(remainingS);
    }

    protected updateSymbolVelocities(distance: number) {
        const velocity = Math.abs(distance);
        for (const symbol of this.allSymbols) {
            symbol.setVelocity(velocity);
        }
    }

    protected addHiddenSymbols() {
        // Create hidden symbol
        this.topHiddenSymbol = new this.symbolType(new Point(this.reel.index, -1), this.matrixLayer, this.animationLayer);

        this.allSymbols = [this.topHiddenSymbol, ...(this.reel.getSymbols() as T[])];

        this.topExtendedHiddenSymbol = new this.symbolType(new Point(this.reel.index, -2), this.matrixLayer, this.animationLayer);
        this.bottomHiddenSymbol = new this.symbolType(new Point(this.reel.index, this.reel.size + 1), this.matrixLayer, this.animationLayer);

        this.allSymbols.unshift(this.topExtendedHiddenSymbol);
        this.allSymbols.push(this.bottomHiddenSymbol);
    }

    protected enableHiddenSymbols() {
        this.topHiddenSymbol.setVisible(true);
        this.matrix.addHiddenSymbol(this.topHiddenSymbol);

        this.topExtendedHiddenSymbol.setVisible(true);
        this.matrix.addHiddenSymbol(this.topExtendedHiddenSymbol);
        this.bottomHiddenSymbol.setVisible(true);
        this.matrix.addHiddenSymbol(this.bottomHiddenSymbol);
    }

    protected getSymbolPositions() {
        const referenceSymbolPosition = this.matrixLayer.getPosition(`symbol_${this.reel.index}_0`);
        const offsetReferenceSymbolPosition = this.matrixLayer.getPosition(`symbol_${this.reel.index}_1`) ?? referenceSymbolPosition.clone().addPosition(new DualPosition(new Position(0, referenceSymbolPosition.landscape.height), new Position(0, referenceSymbolPosition.portrait.height)))

        const landscapeSpacing = new Point(
            offsetReferenceSymbolPosition.landscape.x - referenceSymbolPosition.landscape.x - referenceSymbolPosition.landscape.width,
            offsetReferenceSymbolPosition.landscape.y - referenceSymbolPosition.landscape.y - referenceSymbolPosition.landscape.height
        );

        const portraitSpacing = new Point(
            offsetReferenceSymbolPosition.portrait.x - referenceSymbolPosition.portrait.x - referenceSymbolPosition.portrait.width,
            offsetReferenceSymbolPosition.portrait.y - referenceSymbolPosition.portrait.y - referenceSymbolPosition.portrait.height
        );

        let topHiddenSymbolPosition = this.matrixLayer.getPosition(`top_hidden_symbol_${this.reel.index}`);
        let bottomHiddenSymbolPosition = this.matrixLayer.getPosition(`bottom_hidden_symbol_${this.reel.index}`);

        if (!topHiddenSymbolPosition) {
            topHiddenSymbolPosition = referenceSymbolPosition.clone();
            topHiddenSymbolPosition.landscape.x -= (topHiddenSymbolPosition.landscape.width + landscapeSpacing.x);
            topHiddenSymbolPosition.portrait.x -= (topHiddenSymbolPosition.portrait.width + portraitSpacing.x);
            topHiddenSymbolPosition.landscape.y -= (topHiddenSymbolPosition.landscape.height + landscapeSpacing.y);
            topHiddenSymbolPosition.portrait.y -= (topHiddenSymbolPosition.portrait.height + portraitSpacing.y);
        }

        if (!bottomHiddenSymbolPosition) {
            bottomHiddenSymbolPosition = referenceSymbolPosition.clone();
            bottomHiddenSymbolPosition.landscape.x += (bottomHiddenSymbolPosition.landscape.width + landscapeSpacing.x) * this.reel.size;
            bottomHiddenSymbolPosition.portrait.x += (bottomHiddenSymbolPosition.portrait.width + portraitSpacing.x) * this.reel.size;
            bottomHiddenSymbolPosition.landscape.y += (bottomHiddenSymbolPosition.landscape.height + landscapeSpacing.y) * this.reel.size;
            bottomHiddenSymbolPosition.portrait.y += (bottomHiddenSymbolPosition.portrait.height + portraitSpacing.y) * this.reel.size;
        }

        // Get positions of standard symbols
        this.symbolPositions = [];
        this.symbolPositions.push(topHiddenSymbolPosition);

        for (const symbol of this.reel.getSymbols()) {
            const x = symbol.gridPosition.x;
            const y = symbol.gridPosition.y;

            const positionRect = this.matrixLayer.getPosition(`symbol_${x}_${y}`);
            this.symbolPositions.push(positionRect);
        }

        this.symbolPositions.push(bottomHiddenSymbolPosition);

        let extendedTopHiddenSymbolPosition = this.matrixLayer.getPosition(`extended_top_hidden_symbol_${this.reel.index}`);
        let extendedBottomHiddenSymbolPosition = this.matrixLayer.getPosition(`extended_bottom_hidden_symbol_${this.reel.index}`);

        if (!extendedTopHiddenSymbolPosition) {
            extendedTopHiddenSymbolPosition = referenceSymbolPosition.clone();
            extendedTopHiddenSymbolPosition.landscape.x -= (extendedTopHiddenSymbolPosition.landscape.width + landscapeSpacing.x) * 2;
            extendedTopHiddenSymbolPosition.portrait.x -= (extendedTopHiddenSymbolPosition.portrait.width + portraitSpacing.x) * 2;
            extendedTopHiddenSymbolPosition.landscape.y -= (extendedTopHiddenSymbolPosition.landscape.height + landscapeSpacing.y) * 2;
            extendedTopHiddenSymbolPosition.portrait.y -= (extendedTopHiddenSymbolPosition.portrait.height + portraitSpacing.y) * 2;
        }

        if (!extendedBottomHiddenSymbolPosition) {
            extendedBottomHiddenSymbolPosition = referenceSymbolPosition.clone();
            extendedBottomHiddenSymbolPosition.landscape.x += (extendedBottomHiddenSymbolPosition.landscape.width + landscapeSpacing.x) * (this.reel.size + 1);
            extendedBottomHiddenSymbolPosition.portrait.x += (extendedBottomHiddenSymbolPosition.portrait.width + portraitSpacing.x) * (this.reel.size + 1);
            extendedBottomHiddenSymbolPosition.landscape.y += (extendedBottomHiddenSymbolPosition.landscape.height + landscapeSpacing.y) * (this.reel.size + 1);
            extendedBottomHiddenSymbolPosition.portrait.y += (extendedBottomHiddenSymbolPosition.portrait.height + portraitSpacing.y) * (this.reel.size + 1);
        }

        this.symbolPositions.unshift(extendedTopHiddenSymbolPosition);
        this.symbolPositions.push(extendedBottomHiddenSymbolPosition);
    }

    protected getCurrentGrid() {
        const currentGrid = [];

        // Splice in current grid
        for (let y = -1; y < this.reel.size + 1; y++) {
            let symbolId;
            if (y < 0) {
                symbolId = this.topHiddenSymbol.symbolId;
            } else if (y >= this.reel.size) {
                symbolId = this.bottomHiddenSymbol.symbolId;
            } else {
                symbolId = this.reel.getSymbolAt(y).symbolId;
            }

            currentGrid.push(symbolId);
        }

        return currentGrid;
    }

    protected getAnticipationLayers(): Layers[] {
        const anticipationLayers = [];
        if (!ReelSpinner.anticipationLayer) {
            anticipationLayers.push(this.anticipationLayer);
        } else if (ReelSpinner.anticipationLayer instanceof Layers) {
            anticipationLayers.push(ReelSpinner.anticipationLayer);
        } else {
            for (const layer of ReelSpinner.anticipationLayer) {
                anticipationLayers.push(layer);
            }
        }

        return anticipationLayers;
    }

    protected setStages(stages: SpinStage[]) {
        this.stages = [...stages];
        if (this.stages[this.stages.length - 1].name === KeySpinStages.STOP) {
            this.stages.push({ s: 0, t: 0.0001 } as SpinStage);
        }
        this.stage = 0;
    }

    protected getSpinStages() {
        const stageSet = ReelSpinner.stageSets.get(ReelSpinner.currentStageSet);
        return this.getStages(stageSet?.spin);
    }

    protected getQuickSpinStages() {
        const stageSet = ReelSpinner.stageSets.get(ReelSpinner.currentStageSet);
        return this.getStages(stageSet?.quickSpin || stageSet?.spin);
    }

    protected getSkipStages() {
        const stageSet = ReelSpinner.stageSets.get(ReelSpinner.currentStageSet);
        return this.getStages(stageSet?.skip || stageSet?.spin);
    }

    protected getAnticipationStages() {
        const stageSet = ReelSpinner.stageSets.get(ReelSpinner.currentStageSet);
        return this.getStages(stageSet?.anticipate || stageSet?.spin);
    }

    protected getStages(stages: SpinStage[][]) {
        let spinStages = stages[0];

        stages = stages;

        if (stages.length > this.reel.index) {
            spinStages = stages[this.reel.index];
        }

        return spinStages;
    }

    protected hasLandStage() {
        return this.stages.find((stage) => stage.name === KeySpinStages.LAND);
    }

    protected isInstantSkip() {
        return this.getSkipStages().reduce((totalStopTime, stage) => totalStopTime + stage.t, 0) === 0;
    }

    protected log(...args: any[]) {
        // Logger.info("%c Reel spinner ", "background: #f4e242; color: #333", ...args);
    }

}

export interface SpinStage {
    name: string;
    s?: number;
    u?: number;
    v?: number;
    a?: number;
    t?: number;
    easing?: string;
    events?: SpinStageEvent[];
}

export interface SpinStageEvent {
    name: SpinStageEvents;
    delay: number;
}

export enum SpinStageEvents {
    START = "start",
    STOP = "stop",
    SKIP = "skip",
    ANTICIPATE = "anticipate",
    BLUR = "blur",
    UNBLUR = "unblur"
}

export enum KeySpinStages {
    STOP = "stop",
    REPEAT = "repeat",
    LAND = "land"
}

export interface ReelstripPosition {
    index: number;
    reelstrip: string[];
}

export interface StageSet {
    id: string;
    spin: SpinStage[][];
    quickSpin: SpinStage[][];
    skip: SpinStage[][];
    anticipate: SpinStage[][];
}
