import { Record } from "appworks/model/gameplay/records/record";
import { FreespinRecord } from "slotworks/model/gameplay/records/freespin-record";
import { DataProcessorSupplement } from "slotworks/model/gameplay/supplements/data-processor-supplement";

export class FreespinSymbolSubstituteSupplement implements DataProcessorSupplement {

    public substitutions: Map<string, string> = new Map<string, string>();

    public active: boolean = true;

    /**
     * Substitutes symbols during freespins
     *
     * @param record {Record}
     */
    public process(record: Record) {

        if (!this.active) {
            return;
        }
        if (record && record instanceof FreespinRecord) {
            if (record.grid) {
                for (const reel of record.grid) {
                    for (let y = 0; y < reel.length; y++) {
                        this.substitutions.forEach((substitute: string, symbol: string) => {
                            if (reel[y] === symbol) {
                                reel[y] = substitute;
                            }
                        });
                    }
                }
            }
        }

    }
}
