import { Layers } from "appworks/graphics/layers/layers";
import { Text } from "appworks/graphics/pixi/text";
import { gameState } from "appworks/model/game-state";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { slotModel } from "slotworks/model/slot-model";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";

export class SlingoStakeTextControl extends AbstractControl {
    protected label: Text;
    protected value: Text;
    protected combined: Text;

    constructor(protected layer: Layers) {
        super();

        this.label = layer.getText("slingo_stake_label");
        this.value = layer.getText("slingo_stake_value");
        this.combined = layer.getText("slingo_stake");

        this.update();

        slotModel.onUpdate.add(() => this.update());
        gameState.getCurrentGame().onRecordAdded.add(() => this.update());
        gameState.onNewGame.add(() => {
            gameState.getCurrentGame().onRecordAdded.add(() => this.update());
        });
    }

    protected update() {
        const gameplay = gameState.getCurrentGame();
        const initialStake = Services.get(SlotBetService).getTotalStake();
        const totalStaked = gameplay.hasRecords() ? gameplay.getTotalWagered() : 0;

        if (this.value) {
            this.value.text = Services.get(CurrencyService).format(Math.max(initialStake, totalStaked));
        }

        if (this.label) {
            this.label.text = Services.get(TranslationsService).get(totalStaked > initialStake ? "total_staked" : "stake") + ":";
        }

        if (this.combined) {
            this.combined.text = `${Services.get(TranslationsService).get(totalStaked > initialStake ? "total_staked" : "stake")}: ${Services.get(CurrencyService).format(Math.max(initialStake, totalStaked))}`;
        }
    }
}