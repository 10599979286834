import { PositionConfig } from "appworks/config/asset-config-schema";
import { AnimationService } from "appworks/graphics/animation/animation-service";
import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Layers } from "appworks/graphics/layers/layers";
import { generateButton } from "appworks/graphics/layers/populators/generate-button";
import { generateToggle } from "appworks/graphics/layers/populators/generate-toggle";
import { ParticleService } from "appworks/graphics/particles/particle-service";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { ParticleContainer } from "appworks/graphics/pixi/particle-container";
import { Position } from "appworks/graphics/pixi/position";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { Scene } from "../scene";

class SpritePopulator {

    // TODO: Reduce cyclomatic complexity
    // tslint:disable-next-line:cyclomatic-complexity
    public populateSprites(scene: Scene, layer: Layers) {
        const layerScene = (scene.name === "default" ? layer.id : `${layer.id}-${scene.name}`);
        const sprites = scene.config.sprites;

        if (sprites) {
            for (const id in sprites) {
                if (sprites.hasOwnProperty(id)) {
                    const graphicConfig = sprites[id].landscape;
                    const portraitPosition = sprites[id].portrait;
                    let name = id.substring(id.lastIndexOf("/") + 1);

                    if (name.slice(0, 4) === "btn-") {
                        // Buttons
                        const buttonMetaData = name.substring(4).split("-");
                        const buttonName = buttonMetaData[0];

                        if (buttonMetaData.length === 1 || buttonMetaData[1] === "up") {
                            generateButton(buttonName, id, layer, graphicConfig, portraitPosition);
                        } else {
                            let buttonPath = id.substring(id.lastIndexOf("/") + 1);
                            buttonPath = buttonPath.substr(4);
                            generateButton(buttonPath, id, layer, graphicConfig, portraitPosition);
                        }
                    } else if (name.slice(0, 9) === "particle-") {
                        // particles
                        const particleInfo = name.split("-");
                        const particleId = particleInfo[1];
                        const particleInstanceName = particleInfo.length > 2 ? particleInfo[2] : particleId;

                        const particleContainer = new ParticleContainer();
                        const particle = Services.get(ParticleService).add(particleId, particleContainer);

                        this.setPosition(particleContainer, graphicConfig, portraitPosition, true);

                        particleContainer.name = particleInstanceName;

                        layer.addParticle(particleInstanceName, particle.id);
                        layer.add(particleContainer, false, true, particleInstanceName);
                    } else if (name.slice(0, 5) === "anim-") {
                        // Animations
                        const animOptions = name.split("-");

                        const animationId = animOptions[1];
                        let instanceId = animationId;
                        let autoplay = true;

                        if (animOptions.length > 2) {
                            instanceId = animOptions[2];
                            if (instanceId === "true" || instanceId === "false") {
                                autoplay = instanceId === "true";
                                instanceId = animationId;
                            }
                        }
                        if (animOptions.length > 3) {
                            autoplay = animOptions[3] === "true";
                        }

                        if (Services.get(AnimationService).hasScriptedAnimationFactory(animationId)) {
                            const animPosition = new DualPosition();
                            this.setPosition(animPosition, graphicConfig, portraitPosition);

                            const anim = Services.get(AnimationService).createScriptedAnimation(animationId, animPosition);

                            layer.addScriptedAnimation(instanceId, anim);

                            if (autoplay) {
                                anim.play();
                            } else {
                                anim.getContainer().visible = false;
                            }

                            layer.add(anim.getContainer(), false, true, animationId);

                        } else {
                            const anim = Services.get(GraphicsService).createAnimation(animationId);

                            this.setPosition(anim, graphicConfig, portraitPosition);

                            anim.name = instanceId;

                            if (autoplay) {
                                anim.play();
                            } else {
                                anim.visible = false;
                            }

                            layer.add(anim, false, true, anim.name);
                        }
                    } else if (name.slice(0, 6) === "spine-") {
                        // Animations
                        const spineOptions = name.split("-");

                        const spineId = spineOptions[1];
                        let instanceId = spineId;
                        let animationName: string | number | undefined;
                        let loopAnimation = false;

                        if (spineOptions.length > 2) {
                            instanceId = spineOptions[2];
                        }
                        if (spineOptions.length > 3) {
                            animationName = isNaN(Number(spineOptions[3])) ? spineOptions[3] : Number(spineOptions[3]);
                        }
                        if (spineOptions.length > 4) {
                            loopAnimation = spineOptions[4] === "true";
                        }

                        const spineContainer = new SpineContainer(spineId, new DualPosition(
                            (sprites[id].landscape) ? new Position(0, 0, graphicConfig.width, graphicConfig.height) : Position.unavailable(),
                            (sprites[id].portrait) ? new Position(0, 0, portraitPosition.width, portraitPosition.height) : Position.unavailable()
                        ));
                        spineContainer.name = instanceId;

                        layer.add(spineContainer, false, true, instanceId);

                        this.setPosition(spineContainer, graphicConfig, portraitPosition, true);

                        spineContainer.setupSpine();

                        if (animationName !== undefined) {
                            if (loopAnimation) {
                                spineContainer.play(animationName);
                            } else {
                                spineContainer.playOnce(animationName).execute();
                            }
                        }

                    } else if (name.slice(0, 6) === "shape-") {
                        // Shapes
                        const shapeParams = name.slice(6).split("-");

                        const shape = new SmartShape();
                        shape.name = shapeParams[0];

                        shape.shapeFillColor = parseInt(shapeParams[1], 16);
                        shape.shapeFillAlpha = shapeParams.length > 2 ? parseFloat(shapeParams[2]) / 100 : 1;
                        shape.shapeStrokeColor = shapeParams.length > 3 ? parseInt(shapeParams[3], 16) : shape.shapeFillColor;
                        shape.shapeStrokeSize = shapeParams.length > 4 ? parseFloat(shapeParams[4]) : 0;
                        shape.shapeStrokeAlpha = shapeParams.length > 5 ? parseFloat(shapeParams[5]) / 100 : shape.shapeFillAlpha;
                        shape.shapeCornerRadius = shapeParams.length > 6 ? parseFloat(shapeParams[6]) : 0;

                        this.setPosition(shape, graphicConfig, portraitPosition);

                        layer.add(shape, false, true, shape.name);

                        if (shape.name === "clickblocker") {
                            shape.interactive = true;
                        }
                    } else if (name.slice(0, 4) === "tgl-") {
                        // Toggle buttons
                        generateToggle(name.substring(4), id, graphicConfig, portraitPosition, layer);
                    } else {

                        // Skip i18n sprites which are not for the current language
                        if (name.slice(0, 5) === "i18n-") {

                            // Skip if this sprite is baked for current language
                            const i18nSpriteName = `${layerScene}/${name}-${Services.get(TranslationsService).languageCode.slice(0, 2)}`;
                            if (sprites[i18nSpriteName]) {
                                continue;
                            }

                            if (name.split("-")[2] !== undefined && name.split("-")[2] !== Services.get(TranslationsService).languageCode.slice(0, 2)) {
                                continue;
                            }
                            name = name.split("-")[1];
                        }

                        // Sprites
                        const sprite = Services.get(GraphicsService).createSprite(id);

                        this.setPosition(sprite, graphicConfig, portraitPosition);

                        sprite.name = name;

                        layer.add(sprite, false, true, sprite.name);
                        if (sprite.name === "clickblocker") {
                            sprite.interactive = true;
                        }
                    }
                }
            }
        }
    }

    private setPosition(target: { landscape: Position, portrait: Position }, landscape: PositionConfig, portrait: PositionConfig, positionOnly: boolean = false) {
        if (landscape) {
            target.landscape.x = landscape.x;
            target.landscape.y = landscape.y;
            if (!positionOnly) {
                target.landscape.width = landscape.width;
                target.landscape.height = landscape.height;
            }
        } else {
            target.landscape = Position.unavailable();
        }

        if (portrait) {
            target.portrait.x = portrait.x;
            target.portrait.y = portrait.y;
            if (!positionOnly) {
                target.portrait.width = portrait.width;
                target.portrait.height = portrait.height;
            }
        } else {
            target.portrait = Position.unavailable();
        }
    }
}

export const spritePopulator = new SpritePopulator();
