import { AbstractComponent } from "appworks/components/abstract-component";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { Layers } from "appworks/graphics/layers/layers";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { Position } from "appworks/graphics/pixi/position";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Timer } from "appworks/utils/timer";
import { isDualPositionObject } from "appworks/utils/type-utils";
import { Signal, SignalBinding } from "signals";

export interface PaginationComponentConfig {
    contentLayerName?: string;
    uiLayerName?: string;
    mode: PaginationMode;
    paginationLayerOrder: string;
}

export class PaginationComponent extends AbstractComponent {

    public onVisibilityChange: Signal = new Signal();

    protected contentLayer: Layers;
    protected uiLayer: Layers;

    protected uiSceneName: string;
    protected contentSceneNames: string[];

    protected mode: PaginationMode;

    protected page: number;
    protected totalPages: number;

    protected scrollOrigin: DualPosition;
    protected pageWidthLandscape: number;
    protected pageWidthPortrait: number;

    protected cancelGroup: CancelGroup;

    protected nextButton: FlexiButton;
    protected prevButton: FlexiButton;
    protected closeButton: FlexiButton;
    protected pageNumber: FlexiText;

    protected pageJumpListeners: Map<FlexiButton, () => any> = new Map<FlexiButton, () => any>();

    protected contentListener: SignalBinding;

    protected isVisible: boolean;

    protected originalLayerOrder: string;
    protected paginationLayerOrder: string;

    protected autoScrollDelay: number;
    protected autoScrollTimer: number;

    protected config: PaginationComponentConfig = {
        mode: PaginationMode.PAGIFY,
        paginationLayerOrder: "default"
    }

    constructor(config?: Partial<PaginationComponentConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }

        if (!this.config.contentLayerName) {
            this.contentLayer = Layers.get("PaytableContent");
        } else {
            this.contentLayer = Layers.get(this.config.contentLayerName);
        }
        if (!this.config.uiLayerName) {
            this.uiLayer = Layers.get("PaytableCommon");
        } else {
            this.uiLayer = Layers.get(this.config.uiLayerName);
        }

        this.contentSceneNames = [];
        this.mode = this.config.mode;

        this.cancelGroup = new CancelGroup();

        for (let i = 1; i < Infinity; i++) {
            const page = "page_" + i.toString();
            if (!this.contentLayer.hasScene(page)) {
                break;
            }
            this.contentSceneNames.push(page);
        }

        this.totalPages = this.contentSceneNames.length;

        this.contentListener = this.contentLayer.onSceneEnter.add(this.onSceneEnter, this);

        this.paginationLayerOrder = this.config.paginationLayerOrder;

        this.hide();
    }

    public toggleVisible() {
        if (this.isVisible) {
            this.hide();
        } else {
            this.show();
        }
    }

    public show = () => {
        if (!this.isVisible) {
            this.originalLayerOrder = Layers.getCurrentLayerOrderId();
            if (this.originalLayerOrder !== this.paginationLayerOrder) {
                Layers.setLayerOrder(this.paginationLayerOrder);
            }

            this.isVisible = true;
            this.onVisibilityChange.dispatch(this.isVisible);
            this.onShow();
        }
    }

    public hide = () => {
        Timer.clearTimeout(this.autoScrollTimer);

        if (this.isVisible) {
            if (this.originalLayerOrder !== this.paginationLayerOrder) {
                Layers.setLayerOrder(this.originalLayerOrder);
            }
            this.isVisible = false;
            this.onVisibilityChange.dispatch(this.isVisible);
            this.onHide();
        }
    }

    public enable() {
        this.updateControls();
    }

    public disable() {
        if (this.nextButton) {
            this.nextButton.setEnabled(false);
        }

        if (this.prevButton) {
            this.prevButton.setEnabled(false);
        }

        if (this.closeButton) {
            this.closeButton.setEnabled(false);
        }
    }

    public destroy() {
        Timer.clearTimeout(this.autoScrollTimer);

        if (this.nextButton) {
            this.nextButton.destroy();
            this.nextButton = null;
        }

        if (this.prevButton) {
            this.prevButton.destroy();
            this.prevButton = null;
        }

        if (this.closeButton) {
            this.closeButton.destroy();
            this.closeButton = null;
        }

        if (this.pageNumber) {
            this.pageNumber.destroy();
            this.pageNumber = null;
        }

        this.pageJumpListeners.clear();

        this.cancelGroup.cancel();

        if (this.contentListener) {
            this.contentListener.detach();
            this.contentListener = null;
        }
    }

    public setControls(nextButton: FlexiButton, previousButton: FlexiButton, closeButton: FlexiButton, pageNumber?: FlexiText) {
        this.nextButton = nextButton;
        this.prevButton = previousButton;
        this.closeButton = closeButton;
        this.pageNumber = pageNumber;
    }

    // Set duration = 0 to disable (default)
    public autoScroll(duration: number) {
        this.autoScrollDelay = duration;
        Timer.clearTimeout(this.autoScrollTimer);
        if (this.autoScrollDelay > 0) {
            this.autoScrollTimer = Timer.setTimeout(() => {
                if ((this.page + 1) >= this.totalPages) {
                    this.jumpToPage(0);
                } else {
                    this.nextPage();
                }
            }, this.autoScrollDelay);
        }
    }

    public nextPage = () => {
        this.page++;
        this.update();
    }

    public prevPage = () => {
        this.page--;
        this.update();
    }

    public jumpToPage(page: number) {
        this.page = page;

        this.update();
    }

    protected onShow() {
        this.page = 0;

        if (this.mode === PaginationMode.SCROLL) {
            this.setupScrollableContent();
        }

        if (!this.nextButton) {
            this.setControls(
                this.uiLayer.getFlexiButton("next"),
                this.uiLayer.getFlexiButton("previous"),
                this.uiLayer.getFlexiButton("close")
            );
        }

        this.update();
    }

    protected onHide() {
        this.contentLayer.defaultScene().execute();
        // this.uiLayer.defaultScene().execute();
        this.destroy();
    }

    protected onSceneEnter(scene: string): void {
        const page: number = parseInt(scene.replace(/\D/g, "")) - 1;
        if (this.contentSceneNames[page]) {
            this.page = page;
            this.updateControls();
        }
    }

    protected reset() {
        if (this.nextButton) {
            this.nextButton.off(ButtonEvent.CLICK, this.nextPage);
        }
        if (this.prevButton) {
            this.prevButton.off(ButtonEvent.CLICK, this.prevPage);
        }
        if (this.closeButton) {
            this.closeButton.off(ButtonEvent.CLICK, this.hide);
        }

        this.pageJumpListeners.forEach((listener, button) => button.off(ButtonEvent.CLICK, listener));
        this.pageJumpListeners.clear();
    }

    protected update() {
        this.autoScroll(this.autoScrollDelay);

        switch (this.mode) {
            case PaginationMode.PAGIFY:
                this.disable();
                this.contentLayer.setScene(this.contentSceneNames[this.page]).then(() => this.updateControls());
                break;
            case PaginationMode.SCROLL:
                const landscapeTarget = this.scrollOrigin.landscape.x + this.pageWidthLandscape * (this.totalPages - this.page - 1);
                const portraitTarget = this.scrollOrigin.portrait.x + this.pageWidthLandscape * (this.totalPages - this.page - 1);

                this.cancelGroup.cancel();

                this.cancelGroup.tween(this.contentLayer.container.landscape)
                    .to({ x: landscapeTarget }, Math.abs((landscapeTarget - this.contentLayer.container.x) / this.pageWidthLandscape) * 250)
                    .start();

                this.cancelGroup.tween(this.contentLayer.container.portrait)
                    .to({ x: portraitTarget }, Math.abs((portraitTarget - this.contentLayer.container.x) / this.pageWidthPortrait) * 250)
                    .start();

                this.updateControls();

                break;
        }

        if (this.pageNumber) {
            this.pageNumber.setText((this.page + 1) + "/" + (this.totalPages));
        }
    }

    protected updateControls() {
        this.reset();

        if (this.nextButton) {
            this.nextButton.on(ButtonEvent.CLICK, this.nextPage);

            this.nextButton.setEnabled(this.page < this.totalPages - 1);
        }

        if (this.prevButton) {
            this.prevButton.on(ButtonEvent.CLICK, this.prevPage);

            this.prevButton.setEnabled(this.page > 0);
        }

        if (this.closeButton) {
            this.closeButton.on(ButtonEvent.CLICK, this.hide);
            this.closeButton.setEnabled(true);
        }

        for (let i = 0; i < this.totalPages; i++) {
            const pageIndex = i;
            const pageName = this.contentSceneNames[pageIndex];
            const button = this.uiLayer.getFlexiButton(pageName);
            if (button) {
                button.setEnabled(true);

                const listener = () => this.jumpToPage(pageIndex);
                button.on(ButtonEvent.CLICK, listener);
                this.pageJumpListeners.set(button, listener);

                if (pageIndex === this.page) {
                    button.setEnabled(false);
                }
            }
        }
    }

    protected setupScrollableContent() {
        const bounds = this.contentLayer.bounds;
        this.pageWidthLandscape = bounds.landscape.width;
        this.pageWidthPortrait = bounds.portrait.width;

        this.contentLayer.defaultScene().execute();

        for (const scene of this.contentSceneNames) {
            for (const child of this.contentLayer.container.children) {
                if (isDualPositionObject(child)) {
                    child.landscape.x -= this.pageWidthLandscape;
                    child.portrait.x -= this.pageWidthPortrait;
                }
            }

            this.contentLayer.jumpToScene(scene, false);
        }

        this.scrollOrigin = new DualPosition(this.contentLayer.container.landscape, this.contentLayer.container.portrait);
        this.contentLayer.container.landscape.x = this.scrollOrigin.landscape.x + this.pageWidthLandscape * (this.totalPages - 1);
        this.contentLayer.container.portrait.x = this.scrollOrigin.portrait.x + this.pageWidthPortrait * (this.totalPages - 1);

        const mask = new SmartShape();
        mask.shapeFillColor = 0xff0000;
        mask.shapeFillAlpha = 1;
        mask.landscape = new Position(bounds.landscape.x, bounds.landscape.y, bounds.landscape.width, bounds.landscape.height);
        mask.portrait = new Position(bounds.portrait.x, bounds.portrait.y, bounds.portrait.width, bounds.portrait.height);

        this.contentLayer.container.mask = mask;
    }
}

export enum PaginationMode {
    PAGIFY = "pagify",
    SCROLL = "scroll"
}
