import { Lerp } from "appworks/utils/math/lerp";
import { Point } from "./point";

export class Rectangle {

    public x: number;
    public y: number;
    public width: number;
    public height: number;

    constructor(x = 0, y = 0, width = 0, height = 0) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    public setPosition<T extends { x: number, y: number }>(position: T) {
        this.x = position.x;
        this.y = position.y;
    }

    public setSize<T extends { width: number, height: number }>(size: T) {
        this.width = size.width;
        this.height = size.height;
    }

    /**
     * Linear interpolation between this rectangle and target, at point t (between 0 and 1)
     */
    public lerp(target: Rectangle, t: number): Rectangle {
        const lerpRect = new Rectangle();

        lerpRect.x = Lerp(this.x, target.x, t);
        lerpRect.y = Lerp(this.y, target.y, t);
        lerpRect.width = Lerp(this.width, target.width, t);
        lerpRect.height = Lerp(this.height, target.height, t);

        return lerpRect;
    }

    public clone(): Rectangle {
        return new Rectangle(this.x, this.y, this.width, this.height);
    }

    public toString(): string {
        return this.x.toString() + "," + this.y.toString() + "," + this.width.toString() + "," + this.height.toString();
    }

    public add(rect: Rectangle): Rectangle {
        return new Rectangle(this.x + rect.x, this.y + rect.y, this.width + rect.width, this.height + rect.height);
    }

    public subtract(rect: Rectangle): Rectangle {
        return new Rectangle(this.x - rect.x, this.y - rect.y, this.width - rect.width, this.height - rect.height);
    }

    public multiply(rect: Rectangle): Rectangle {
        return new Rectangle(this.x * rect.x, this.y * rect.y, this.width * rect.width, this.height * rect.height);
    }

    public divide(rect: Rectangle): Rectangle {
        return new Rectangle(this.x / rect.x, this.y / rect.y, this.width / rect.width, this.height / rect.height);
    }

    public addPosition(rect: Rectangle | Point): Rectangle {
        return new Rectangle(this.x + rect.x, this.y + rect.y, this.width, this.height);
    }

    public subtractPosition(rect: Rectangle | Point): Rectangle {
        return new Rectangle(this.x - rect.x, this.y - rect.y, this.width, this.height);
    }

    public multiplyPosition(rect: Rectangle | Point): Rectangle {
        return new Rectangle(this.x * rect.x, this.y * rect.y, this.width, this.height);
    }

    public dividePosition(rect: Rectangle | Point): Rectangle {
        return new Rectangle(this.x / rect.x, this.y / rect.y, this.width, this.height);
    }

    public addSides(rect: Rectangle): Rectangle {
        return new Rectangle(this.x, this.y, this.width + rect.width, this.height + rect.height);
    }

    public subtractSides(rect: Rectangle): Rectangle {
        return new Rectangle(this.x, this.y, this.width - rect.width, this.height - rect.height);
    }

    public multiplySides(rect: Rectangle): Rectangle {
        return new Rectangle(this.x, this.y, this.width * rect.width, this.height * rect.height);
    }

    public divideSides(rect: Rectangle): Rectangle {
        return new Rectangle(this.x, this.y, this.width / rect.width, this.height / rect.height);
    }
}
