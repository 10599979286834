import { AppWorks } from "appworks/appworks";
import { Setup } from "appworks/boot/setup";
import { Services } from "appworks/services/services";
import { SettingsService } from "appworks/services/settings/settings-service";
import { SlotBetService } from "./services/bet/slot-bet-service";

export class SlotWorks {

    public static go(
        setups: Setup | Setup[],
        assetworksData: any
    ) {
        const setupsArr = (Array.isArray(setups)) ? setups : [setups];
        setupsArr.forEach((x) => x.setupServices());

        Services.init();

        Services.get(SettingsService).load();

        Services.get(SlotBetService).load();

        AppWorks.go(setups, assetworksData);
    }

    private constructor() { }
}
