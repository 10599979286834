import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { commsManager } from "appworks/server/comms-manager";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { SlingoFooterComponent } from "slingo/components/slingo-footer-component";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";
import { SlingoHighlightType, SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoJokerResult } from "slingo/model/results/slingo-joker-result";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";
import { showAutoCompletedPrompt } from "slingo/util/slingo-show-auto-completion-prompt";
import { BonusResult } from "slotworks/model/gameplay/records/results/bonus-result";
import { PickRequestPayload } from "slotworks/model/gameplay/requests/pick-request-payload";

export class SlingoPickJokerState extends State {
    public onEnter(cascadeSkip?: boolean): void {
        GamingRealms.setWrapperGameState(so.GameState.GAME_IDLE);

        const result = gameState.getCurrentGame().getLatestResultOfType(SlingoJokerResult);
        const ticketMatrix = Components.get(SlingoTicketMatrixComponent);
        result.jokerCells.forEach(value => ticketMatrix.highlightSymbol(
            value,
            SlingoHighlightType.JOKER,
            () => this.onPick(value)
        ).execute());
        result.superJokerCells.forEach(value => ticketMatrix.highlightSymbol(
            value,
            SlingoHighlightType.SUPER_JOKER,
            () => this.onPick(value)
        ).execute());

        Components.get(SlingoFooterComponent)?.showPickText();

        uiFlags.set(UIFlag.SPINNING, false);
    }

    public complete(): void {
        GamingRealms.setWrapperGameState(so.GameState.GAME_BUSY);

        Components.get(SlingoFooterComponent)?.clear();

        const gameplay = gameState.getCurrentGame();
        gameplay.setToLatestRecord();

        const record = gameplay.getCurrentRecord();
        const progressResult = record.getFirstResultOfType(SlingoGameProgressResult);

        new Sequence([
            () => Components.get(SlingoTicketMatrixComponent).checkWinlines(),
            () => Components.get(SlingoLadderComponent).stepToLevel(
                gameplay.getLatestResultOfType(SlingoLadderResult).total
            ),
            () => this.updateTotalWin(),
            () => showAutoCompletedPrompt(progressResult.completionReason)
        ]).then(() => super.complete());
    }

    protected onPick(value: number) {
        uiFlags.set(UIFlag.SPINNING, true);

        new Parallel([
            () => this.sendPickRequest(value),
            () => Components.get(SlingoTicketMatrixComponent).dabSymbol(value),
            () => Components.get(SlingoTicketMatrixComponent).clearHighlights()
        ]).then(() => this.complete());
    }

    protected sendPickRequest(value: number): Contract<void> {
        const payload = new PickRequestPayload();
        payload.pick = value;
        return new Sequence([
            () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, true)),
            () => commsManager.request(payload),
            () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, false))
        ]);
    }

    protected updateTotalWin(tickTime: number = 500): Contract {
        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord();

        // Update total win, but exclude wins from bonuses that haven't been played yet
        const bonusResults = record.getResultsOfType(BonusResult);
        let pendingBonusWin = 0;
        bonusResults.forEach(result => pendingBonusWin += result.played ? 0 : result.cashWon);

        return Services.get(TransactionService).setWinnings(0, gameplay.getTotalWin() - pendingBonusWin, tickTime);
    }
}