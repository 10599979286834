import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { Position } from "appworks/graphics/pixi/position";
import { Services } from "appworks/services/services";
import { Contract } from "appworks/utils/contracts/contract";
import { Filter } from "pixi.js";
import { AbstractSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/abstract-symbol-behaviour";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";

export class BaseAnimateSymbolBehaviour extends AbstractSymbolBehaviour {

    public ANIMATE_BEHIND: boolean = false;

    public delay: number = 0;
    public tintColor: number;
    public filters: Filter[];

    /**
     * If set to true, animations will always loop and subsequence calls to static are ignored. Calling animate again will return an empty contract
     */
    public continuous: boolean = false;

    protected masterSymbol: string = "symbol_0_0";

    protected defaultAnimation: string;

    protected animation: AnimatedSprite;
    protected animationContract: Contract<void>;

    protected offset: DualPosition;

    /**
     * Base symbol animtion behaviour, to be extended
     *
     * @param symbol {SymbolSubcomponent}
     * @param [defaultAniamtion="win"] {string}
     */
    constructor(symbol: SymbolSubcomponent, defaultAnimation: string = "win_over") {
        super(symbol);
        this.defaultAnimation = defaultAnimation;
    }

    public static() {
        if (!this.continuous) {
            this.cleanUp();
        }
    }

    public animate(animSuffix: string | string[], defaultAnimations: string[] = [this.defaultAnimation], autoReset: boolean = false, loop: boolean = false) {
        if (this.continuous && this.isAnimating()) {
            return Contract.empty();
        }

        this.resolveUnfinishedAnimation();

        const animNameList = this.getAnimationList(animSuffix, defaultAnimations);

        // Generate aniamtion to play overtop
        let animation: AnimatedSprite = Services.get(GraphicsService).createAnimation(animNameList);

        if (!animation) {
            return Contract.empty();
        }

        animation.stop();
        animation.visible = false;

        this.symbol.setLayer(this.animationLayer);
        this.animationLayer.add(animation, false, this.ANIMATE_BEHIND);

        this.behaviourSprite = animation;

        // Calculate relative size for animation versus static
        const masterRect = this.matrixLayer.getPosition(this.masterSymbol);
        let animRect: DualPosition;

        animNameList.push(this.symbol.symbolId);
        while (!animRect && animNameList.length > 0) {
            animRect = this.matrixLayer.getPosition(animNameList.shift());
        }

        if (!animRect) {
            animRect = new DualPosition(
                new Position(0, 0, animation.landscape.width, animation.landscape.height),
                new Position(0, 0, animation.portrait.width, animation.portrait.height)
            );
        }

        // Offset describes how different the animation is to the static, in both scale and position
        // so that when the static is moved or scaled, the animation will still match
        this.offset = new DualPosition(
            new Position(animRect.landscape.x - masterRect.landscape.x,
                animRect.landscape.y - masterRect.landscape.y,
                animRect.landscape.width / masterRect.landscape.width,
                animRect.landscape.height / masterRect.landscape.height),
            new Position(animRect.portrait.x - masterRect.portrait.x,
                animRect.portrait.y - masterRect.portrait.y,
                animRect.portrait.width / masterRect.portrait.width,
                animRect.portrait.height / masterRect.portrait.height)
        );
        this.animation = animation;

        this.updateTransform();

        this.behaviourSprite.visible = true;

        if (this.continuous) {
            loop = true;
        }
        animation.loop = loop;

        this.applyEffects();

        this.startAnimation();

        this.animationContract = new Contract<void>((resolve) => {
            animation.onComplete.addOnce(() => {
                if (!loop) {
                    animation.stop();
                    animation.visible = false;
                    this.animationLayer.remove(this.animation);
                    if (autoReset) {
                        this.symbol.setLayer(this.matrixLayer);
                        this.animation = animation = null;
                    }
                }
                resolve(null);
            });
        });

        return this.animationContract;
    }

    public cleanUp() {
        if (this.animation) {
            this.delay = 0;
            this.animation.filters = null;
            this.animation.onComplete.removeAll();
            this.animation.stop();
            this.animationLayer.remove(this.animation);
            this.animation = null;
            this.symbol.setLayer(this.matrixLayer);
            this.animationContract.cancel();
            this.animationContract = null;
        }
    }

    public updateTransform(): void {
        super.updateTransform();

        if (this.behaviourSprite) {

            this.behaviourSprite.landscape.x += this.offset.landscape.x;
            this.behaviourSprite.landscape.y += this.offset.landscape.y;
            this.behaviourSprite.landscape.width *= this.offset.landscape.width;
            this.behaviourSprite.landscape.height *= this.offset.landscape.height;

            // Adjust for if this is the middle of a stack
            let stackOffset = this.symbol.getStackOffsetLandscapeY();
            if (stackOffset) {
                this.behaviourSprite.landscape.y -= stackOffset;
            }

            this.behaviourSprite.portrait.x += this.offset.portrait.x;
            this.behaviourSprite.portrait.y += this.offset.portrait.y;
            this.behaviourSprite.portrait.width *= this.offset.portrait.width;
            this.behaviourSprite.portrait.height *= this.offset.portrait.height;

            // Adjust for if this is the middle of a stack
            stackOffset = this.symbol.getStackOffsetPortraitY();
            if (stackOffset) {
                this.behaviourSprite.portrait.y -= stackOffset;
            }
        }
    }

    public isAnimating() {
        return !!this.animation;
    }

    protected getAnimationList(animSuffix: string | string[], defaultAnimations: string[] = [this.defaultAnimation]) {
        const animNameList: string[] = [];
        if (typeof animSuffix === "string") {
            animNameList.push(this.symbol.symbolId + animSuffix);
        } else {
            for (const suffix of animSuffix) {
                animNameList.push(this.symbol.symbolId + suffix);
            }
        }
        if (this.symbol.symbolId.indexOf("_expanded") > -1) {
            animNameList.push(defaultAnimations + "_expanded");
        }
        animNameList.push(...defaultAnimations);

        return animNameList;
    }

    protected resolveUnfinishedAnimation(): void {
        // Resolve existing animations before starting a new one
        if (this.animationContract) {
            if (this.continuous) {
                return null;
            }
            this.animationContract.forceResolve(null);
        }
        this.cleanUp();
    }

    protected startAnimation() {
        if (this.delay > 0) {
            this.animation.gotoAndPlayDelayed(0, this.delay);
        } else {
            this.animation.gotoAndPlay(0);
        }
    }

    /**
     * Extend / replace to apply custom effects to symbol animations
     */
    protected applyEffects() {
        if (this.tintColor) {
            this.animation.tint = this.tintColor;
        }
        if (this.filters) {
            this.animation.filters = null;
            this.animation.filters = this.filters;
        }
    }
}
