export abstract class Flexi<T> {
    protected targets: T[] = [];

    public hasTargets() {
        return this.targets.length;
    }

    public getTargets() {
        if (this.targets.length > 0) {
           return this.targets;
        }
    }

    public addTarget(target: T) {
        if (target) {
            if (this.targets.indexOf(target) === -1) {
                this.targets.push(target);
            }
        }
    }

    public addTargets(targets: T[]) {
        if (targets) {
            if (targets.length) {
                for (const target of targets) {
                    this.addTarget(target);
                }
            }
        }
    }

    public destroy() {
        // Destroy method
    }
}
