import { BaseSetup } from "appworks/boot/base-setup";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { commsManager } from "appworks/server/comms-manager";
import { Services } from "appworks/services/services";
import { GMRHTTPConnector } from "gaming-realms/integration/connector/gmr-http-connector";
import { GMRCanvasService } from "gaming-realms/services/gmr-canvas-service";
import { SlingoDebugSetup } from "./slingo-debug-setup";
import { SlingoUISetup } from "./slingo-ui-setup";
import { TranslationsService } from "appworks/services/translations/translations-service";

export class SlingoSetup extends BaseSetup {
    public setupComms(): void {
        commsManager.connector = new GMRHTTPConnector();
    }

    public setupI18N(): void {
        super.setupI18N();

        Services.get(TranslationsService).setAlias("err.con", "SERVER_ERROR");
    }

    public setupDebug() {
        super.setupDebug();
        SlingoDebugSetup();
    }

    public setupUI(): void {
        SlingoUISetup();
    }

    public setupServices(): void {
        super.setupServices();

        Services.deregister(CanvasService);
        Services.register(new GMRCanvasService());
    }
}
