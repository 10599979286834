import { deepClone } from "appworks/utils/collection-utils";
import { Signal } from "signals";
import { AutoplayModel } from "./autoplay/autoplay-model";
import { SlotBetModel } from "./bets/slot-bet-model";
import { RegulationsModel } from "./regulations/regulations-model";

export class SlotClientModel {

    public readonly onUpdate: Signal = new Signal();

    private regulations: RegulationsModel = new RegulationsModel();

    private bet: SlotBetModel = new SlotBetModel();
    private autoplay: AutoplayModel = new AutoplayModel();

    private rtp: number; // TODO V6 remove this, moved into regulationsmodel

    public read() {
        const model = {
            regulations: deepClone(this.regulations) as RegulationsModel,
            bet: deepClone(this.bet) as SlotBetModel,
            autoplay: deepClone(this.autoplay) as AutoplayModel,
            rtp: this.rtp
        };

        return model;
    }

    public write(model: {
        regulations?: Partial<RegulationsModel>,
        bet?: Partial<SlotBetModel>,
        autoplay?: Partial<AutoplayModel>,
        rtp?: number
    }) {
        this.regulations = { ...this.regulations, ...model.regulations };
        this.bet = { ...this.bet, ...model.bet };
        this.autoplay = { ...this.autoplay, ...model.autoplay };

        if (model.rtp) { this.rtp = model.rtp; }

        this.onUpdate.dispatch();
    }
}
