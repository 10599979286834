export class AbstractControl {

    public enabled: boolean;

    constructor(element?: any) {
        //
    }

    public setEnabled(enabled: boolean): void {
        this.enabled = enabled;
    }

    public destroy(): void {
        //
    }
}
