import { Setup } from "appworks/boot/setup";
import { ParticleConfig } from "appworks/config/asset-config-schema";
import { StandardI18nAliases } from "appworks/services/translations/standard-i18n-aliases";
import { Contract } from "appworks/utils/contracts/contract";
import { logger } from "appworks/utils/logger";

declare var window: any;

//////////////////////////////////////////////////////////////////////////////
/////////////////////
//////////

export abstract class EmptySetup implements Setup {
    public setupServices(): void {
        //
    }

    public setupLayers(): void {
        //
    }

    public setupDebug(): void {
        //
    }

    public setupCanvas(): void {
        //
    }

    public setupComponents(): void {
        //
    }

    public setupComms(): void {
        //
    }

    public setupUI(): void {
        //
    }

    public setupStates(): void {
        //
    }

    public setupTransitions(): void {
        //
    }

    public setupSound(): void {
        //
    }

    public setupAsync(): Contract<void> {
        return Contract.empty();
    }

    public setupI18N(): void {
        StandardI18nAliases();
    }

    public setupGameDefinition(): void {
        //
    }

    public setupParticles(): { [id: string]: ParticleConfig } {
        return {};
    }

    public onReady(): void {
        //
    }
}
