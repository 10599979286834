import { Layers } from "appworks/graphics/layers/layers";
import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { TimeControl } from "appworks/ui/controls/standard/text/time-control";
import { slotModel } from "slotworks/model/slot-model";
import { ControlFactory } from "../control-factory";

export class TimeControlFactory implements ControlFactory {
    public filter(layer: Layers): boolean {
        if (layer.getFlexiText("time")) {
            return true;
        }
        return false;
    }

    public build(layer: Layers): AbstractControl[] {
        const text = layer.getFlexiText("time");
        
        if (slotModel.read().regulations.showClock) {
            return [new TimeControl(text)];
        } else {
            text.setText("");
            return [];
        }
    }
}
