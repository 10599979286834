import { Graphics } from "pixi.js";
import { Position } from "../pixi/position";
import { RenderOrientation } from "../pixi/render-orientation";

export class SmartShape extends Graphics {

    public shapeFillColor: number;
    public shapeFillAlpha: number;
    public shapeStrokeColor: number;
    public shapeStrokeSize: number;
    public shapeStrokeAlpha: number;
    public shapeCornerRadius: number;

    public landscape: Position = new Position();
    public portrait: Position = new Position();

    private lastPosition: Position;

    public updateTransform() {
        const position = this.getOrientationPosition();

        if (position.unavailable) {
            this.lastPosition = null;
            this.clear();
            return;
        }

        if (!position.equals(this.lastPosition)) {
            this.lastPosition = position.clone();

            (this.transform as any).position.x = position.x;
            (this.transform as any).position.y = position.y;

            this.draw();
        }

        super.updateTransform();
    }

    private draw() {
        this.clear();
        this.lineStyle(this.shapeStrokeSize === 0 ? 1 : this.shapeStrokeSize, this.shapeStrokeColor, this.shapeStrokeSize === 0 ? 0 : this.shapeStrokeAlpha);
        this.beginFill(this.shapeFillColor, this.shapeFillAlpha);

        const adjustedShapeWidth = this.getOrientationPosition().width;
        const adjustedShapeHeight = this.getOrientationPosition().height;

        if (this.shapeCornerRadius > 0) {
            this.drawRoundedRect(0, 0, adjustedShapeWidth, adjustedShapeHeight, this.shapeCornerRadius);
        } else {
            this.drawRect(0, 0, adjustedShapeWidth, adjustedShapeHeight);
        }

        this.endFill();
    }

    // @ts-ignore
    get x() {
        return this.position.x;
    }

    set x(value) {
        throw new Error("Do not change x and y manually, please use landscape.x or portrait.x");
    }

    // @ts-ignore
    get y() {
        return this.position.y;
    }

    set y(value) {
        throw new Error("Do not change x and y manually, please use landscape.x or portrait.x");
    }

    private getOrientationPosition() {
        return RenderOrientation.isLandscape() ? this.landscape : this.portrait;
    }
}
