import { Container } from "appworks/graphics/pixi/container";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Point } from "appworks/utils/geom/point";
import { DisplayObject } from "pixi.js";
import { SmartShape } from "../elements/smart-shape";
import { BitmapText } from "./bitmap-text";
import { DualPosition } from "./dual-position";
import { Position } from "./position";
import { Text } from "./text";

export type PIXIElement = Sprite | Container | Text | BitmapText | SmartShape;

export function Group(targets: PIXIElement | PIXIElement[], containerName: string = "tempContainer"): PIXIGroup {
    if (targets === null) {
        throw new Error("Trying to group a null object");
    }

    if ((targets as any).length) {
        targets = [...(targets as PIXIElement[])];
        const parent = targets[0].parent;
        const origDepth = parent.getChildIndex(targets[0]);
        const container = new PIXIGroup();
        container.originalHierarchy = [...parent.children];

        let groupDepth = origDepth;
        for (const target of targets) {
            if (parent.getChildIndex(target) < origDepth) {
                groupDepth--;
            }
        }

        for (const target of targets) {
            container.addChild(target);
        }

        const position = new DualPosition(
            new Position(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, 0, 0),
            new Position(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, 0, 0)
        );

        // Finds the leftmost and rightmost pixels to determine containers width and height
        for (const target of targets) {
            position.landscape.x = Math.min(position.landscape.x, target.landscape.x);
            position.landscape.y = Math.min(position.landscape.y, target.landscape.y);
            position.landscape.width = Math.max(position.landscape.width, target.landscape.x + target.landscape.width);
            position.landscape.height = Math.max(position.landscape.height, target.landscape.y + target.landscape.height);
            position.portrait.x = Math.min(position.portrait.x, target.portrait.x);
            position.portrait.y = Math.min(position.portrait.y, target.portrait.y);
            position.portrait.width = Math.max(position.portrait.width, target.portrait.x + target.portrait.width);
            position.portrait.height = Math.max(position.portrait.height, target.portrait.y + target.portrait.height);
        }

        // Turn bottom right most pixel into width and height
        position.landscape.width -= position.landscape.x;
        position.landscape.height -= position.landscape.y;
        position.portrait.width -= position.portrait.x;
        position.portrait.height -= position.portrait.y;

        container.landscape = position.landscape.clone();
        container.portrait = position.portrait.clone();

        for (const target of targets) {
            target.landscape.x -= position.landscape.x;
            target.landscape.y -= position.landscape.y;
            target.portrait.x -= position.portrait.x;
            target.portrait.y -= position.portrait.y;
        }

        container.name = containerName;
        parent.addChildAt(container, groupDepth);

        return container;
    } else {
        return targets as PIXIGroup;
    }
}

// TODO(steven): 6.x - Remove useDualPivotFix argument and always use the dualPivot
// when calculating each child's original position.
export function Ungroup(
    container: PIXIElement | PIXIGroup, 
    containerName: string = "tempContainer", 
    relayer: boolean = false,
    useDualPivotFix = false
) {
    if (container && container.name === containerName) {
        const containerParent = container.parent;

        const children = [...container.children];
        for (const child of children) {
            if (child instanceof Sprite || child instanceof Container || child instanceof SmartShape || child instanceof BitmapText || child instanceof Text) {
                if (useDualPivotFix) {
                    const { dualPivot } = container as Container;
                    child.landscape.x += container.landscape.x - dualPivot.landscape.x;
                    child.landscape.y += container.landscape.y - dualPivot.landscape.y;
                    child.portrait.x += container.portrait.x - dualPivot.portrait.x;
                    child.portrait.y += container.portrait.y - dualPivot.portrait.y;
                } else {
                    child.landscape.x += container.landscape.x - container.pivot.x;
                    child.landscape.y += container.landscape.y - container.pivot.y;
                    child.portrait.x += container.portrait.x - container.pivot.x;
                    child.portrait.y += container.portrait.y - container.pivot.y;
                }
            } else {
                child.x += container.x - container.pivot.x;
                child.y += container.y - container.pivot.y;
            }
            containerParent.addChild(child);
        }

        // relayer attempts to put grouped objects back in the layer order there were in before being grouped
        if (relayer) {
            if ((container as PIXIGroup).originalHierarchy) {
                for (const child of (container as PIXIGroup).originalHierarchy) {
                    if (child && child.parent === containerParent) {
                        containerParent.addChild(child);
                    }
                }
            }
        }

        containerParent.removeChild(container);
        container.destroy();
    }
}

export function CenterPivot(targets: PIXIElement | PIXIElement[], containerName?: string, pivotPoint?: Point): PIXIElement {
    if (!containerName) {
        containerName = "tempContainer";
    }

    const target = Group(targets, containerName);

    if (pivotPoint === undefined || pivotPoint === null) {
        pivotPoint = new Point(0.5, 0.5);
    }

    if (!(target instanceof Text) && !(target instanceof BitmapText)) {
        if ((target as any).anchor) {
            const anchorable = target as any;
            if (anchorable.anchor.x !== pivotPoint.x || anchorable.anchor.y !== pivotPoint.y) {
                anchorable.anchor.set(pivotPoint.x, pivotPoint.y);
                target.landscape.x += target.landscape.width * pivotPoint.x;
                target.landscape.y += target.landscape.height * pivotPoint.y;
                target.portrait.x += target.portrait.width * pivotPoint.x;
                target.portrait.y += target.portrait.height * pivotPoint.y;
            }
        } else {
                target.dualPivot = new DualPosition(
                    new Position(target.landscape.width * pivotPoint.x, target.landscape.height * pivotPoint.y),
                    new Position(target.portrait.width * pivotPoint.x, target.portrait.height * pivotPoint.y)
                );
                target.landscape.x += target.landscape.width * pivotPoint.x;
                target.landscape.y += target.landscape.height * pivotPoint.y;
                target.portrait.x += target.portrait.width * pivotPoint.x;
                target.portrait.y += target.portrait.height * pivotPoint.y;
        }
    }

    return target;
}

export function ResetPivot(target: PIXIElement, containerName: string = "tempContainer") {
    if (!(target instanceof Text)) {
        if ((target as any).anchor) {
            const anchorable = target as any;
            target.landscape.x -= target.landscape.width * anchorable.anchor.x;
            target.landscape.y -= target.landscape.height * anchorable.anchor.y;
            target.portrait.x -= target.portrait.width * anchorable.anchor.x;
            target.portrait.y -= target.portrait.height * anchorable.anchor.y;
            anchorable.anchor.set(0);
        } else {
            if (target?.transform) {
                target.landscape.x -= target.pivot.x;
                target.landscape.y -= target.pivot.y;
                target.portrait.x -= target.pivot.x;
                target.portrait.y -= target.pivot.y;
                target.pivot.set(0, 0);
            }
        }
    }
}

class PIXIGroup extends Container {
    public originalHierarchy: DisplayObject[];

    public destroy(options?: {
        children?: boolean;
        texture?: boolean;
        baseTexture?: boolean;
    }) {
        this.originalHierarchy = null;

        super.destroy(options);
    }
}
