import { Contract } from "./contracts/contract";

/**
 * Starts with a count which is decreased every time complete is called. Contract resolves when count reaches 0
 * Example:
 * const myHandler = new RaceHandler(3);
 * const contract = myHandler.start();
 * myHandler.complete();
 * myHandler.complete();
 * myHandler.complete(); // Contract resolves
 */
export class RaceHandler {

    protected raceComplete: boolean;

    private resolve: () => void;

    constructor(private count: number) {
    }

    public start(): Contract<void> {
        return new Contract<void>((resolve) => {
            this.resolve = resolve;
            if (this.count === 0) {
                this.raceComplete = true;
                resolve();
            }
        });
    }

    public complete() {
        this.count--;
        if (this.count === 0 && this.resolve) {
            this.raceComplete = true;
            this.resolve();
        }
    }

    public isComplete() {
        return this.raceComplete;
    }
}
