import { deepClone } from "appworks/utils/collection-utils";
import { Signal } from "signals";
import { GMRStakeConfig } from "gaming-realms/integration/gmr-schema";
import { SlingoGameConfig, SlingoPayoutConfig } from "slingo/integration/slingo-schema";
import { SlingoResponsibleGamingModel } from "./slingo-responsible-gaming-model";

class SlingoModel {
    public readonly onUpdate: Signal = new Signal();

    private gameConfig: SlingoGameConfig;
    private payoutConfig: SlingoPayoutConfig;
    private stakeConfig: GMRStakeConfig;
    private responsibleGaming = new SlingoResponsibleGamingModel();

    private lastStakeTime: number = 0;
    private gameInstanceId: number = null;

    public read() {
        return {
            gameConfig: deepClone(this.gameConfig),
            payoutConfig: deepClone(this.payoutConfig),
            stakeConfig: deepClone(this.stakeConfig),
            responsibleGaming: deepClone(this.responsibleGaming),
            lastStakeTime: this.lastStakeTime,
            gameInstanceId: this.gameInstanceId
        };
    }

    public write(model: {
        gameConfig?: Partial<SlingoGameConfig>,
        payoutConfig?: Partial<SlingoPayoutConfig>,
        stakeConfig?: Partial<GMRStakeConfig>
        responsibleGaming?: Partial<SlingoResponsibleGamingModel>,
        stakeTime?: number,
        gameInstanceId?: number
    }) {
        this.gameConfig = { ...this.gameConfig, ...model.gameConfig };
        this.payoutConfig = { ...this.payoutConfig, ...model.payoutConfig };
        this.stakeConfig = { ...this.stakeConfig, ...model.stakeConfig };
        this.responsibleGaming = { ...this.responsibleGaming, ...model.responsibleGaming };

        if (model.stakeTime !== undefined) { this.lastStakeTime = model.stakeTime; }
        if (model.gameInstanceId !== undefined) { this.gameInstanceId = model.gameInstanceId; }

        this.onUpdate.dispatch();
    }
}
export const slingoModel = new SlingoModel();