import { PIXIElement } from "appworks/graphics/pixi/group";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { fadeIn, fadeOut } from "appworks/utils/animation/fade";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Timer } from "appworks/utils/timer";
import { SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoJokerResult } from "slingo/model/results/slingo-joker-result";
import { FooterComponent } from "slotworks/components/footer/footer-component";

export class SlingoFooterComponent extends FooterComponent {
    protected messageList: string[];
    protected messageIndex: number;
    protected messageCycleInterval: number;

    public showDefaultText() {
        this.clear();

        const translations = Services.get(TranslationsService);

        let i: number = 0;
        const msgs: string[] = [];
        while (translations.exists(`footer_idle_${i}`)) {
            msgs.push(translations.get(`footer_idle_${i}`));
            i++;
        }

        this.startTextCycle(msgs);
    }

    public showPickText() {
        const lastRecord = gameState.getCurrentGame().getCurrentRecord() as SlingoRecord;
        const pickResult = lastRecord.getFirstResultOfType(SlingoJokerResult);

        if (pickResult.superJokerCells.length > 0) {
            this.showMiscWin("footer_superjoker", pickResult);
        } else {
            this.showMiscWin("footer_joker", pickResult);
        }
    }

    public showSpinStart(betValue?: number, isRequest: boolean = true): void {
        const gameplay = gameState.getCurrentGame();
        const lastRecord = gameplay.getCurrentRecord();

        if (!(lastRecord instanceof SlingoRecord)) {
            return;
        }

        const progress = lastRecord.getFirstResultOfType(SlingoGameProgressResult);

        switch (lastRecord.state) {
            case SlingoState.STANDARD_SPIN:
                this.setText(
                    Services.get(TranslationsService).get("footer_spin_standard",
                        { spin: progress.standardSpinsUsed + (isRequest ? 1 : 0), total: progress.standardSpins }
                    ));
                break;

            case SlingoState.FREE_SPIN:
                this.setText(Services.get(TranslationsService).get("footer_spin_free"));
                break;

            case SlingoState.PURCHASE_SPIN:
                this.setText(Services.get(TranslationsService).get("footer_spin_purchase"));
                break;

            default:
                this.clear();
                break;
        }
    }

    public showPotentialWin(numbers: number[], winValue: string) {
        const numbersCopy = [...numbers];
        let numbersString = "";
        while (numbersCopy.length) {
            numbersString += numbersCopy.shift();
            if (numbersCopy.length > 1) { numbersString += ", "; }
            if (numbersCopy.length === 1) { numbersString += " & "; }
        }

        this.setText(
            Services.get(TranslationsService).get(
                "footer_potential_win",
                { cells: numbersString, amount: winValue }
            )
        );
    }

    public showSlingoCelebration(lines: number) {
        this.setText(
            Services.get(TranslationsService).get(
                "footer_slingo_celebration_" + lines
            )
        );
    }

    public clear(): void {
        this.setText("", true, 0);
    }

    protected startTextCycle(messages: string[], cycleTime: number = 5000) {
        this.messageList = messages;

        const nextMsg = () => {
            this.setText(this.messageList[this.messageIndex], false);
            this.messageIndex++;

            if (this.messageIndex >= this.messageList.length) {
                this.messageIndex = 0;
            }
        };
        this.messageCycleInterval = Timer.setInterval(nextMsg, cycleTime);
        nextMsg();
    }

    protected setText(text: string, clearCycle: boolean = true, fadeTime: number = 150): void {
        if (clearCycle) {
            Timer.clearInterval(this.messageCycleInterval);
            this.messageIndex = 0;
            this.messageList = [];
        }

        if (this.textValue === text) { return; }

        this.textValue = text;
        if (this.text) {
            const textFadable = this.text.getTargets()[0] as PIXIElement;
            new Sequence([
                () => fadeOut(textFadable, fadeTime),
                () => Contract.wrap(() => this.text.setText(text)),
                () => fadeIn(textFadable, fadeTime)
            ]).execute();
        }
    }
}