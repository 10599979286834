import { Signal } from "signals";
import { Service } from "../service";

export class AbstractBetService extends Service {

    public onBetChange: Signal;

    public init(): void {
        throw new Error("Method not implemented.");
    }

    public getTotalStake(): number {
        return null;
    }
}
