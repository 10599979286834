import { Gameplay } from "appworks/model/gameplay/gameplay";
import { InitRequestPayload } from "appworks/model/gameplay/requests/init-request-payload";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoPurchaseEntryRequestPayload } from "slingo/model/requests/slingo-purchase-entry-request-payload";
import { SlingoResponse } from "slingo/integration/slingo-schema";
import { SlingoRecordBuilder } from "./slingo-record-builder";

export class SlingoPurchaseEntryRecordBuilder extends SlingoRecordBuilder {
    public id: string = "SlingoPurchaseEntryRecordBuilder";

    public filter(request: InitRequestPayload, response: any): boolean {
        return request instanceof SlingoPurchaseEntryRequestPayload;
    }

    public build(gameplay: Gameplay, request: any, response: any): SlingoRecord[] {
        if (!response) { return []; }
        
        const record = super.build(gameplay, request, response)[0];
        const responseData = response.data as SlingoResponse;

        record.wager = Math.round(responseData.gameProgress.totalStake * 100);

        return [record];
    }
}
