import { Layers } from "appworks/graphics/layers/layers";
import { AbstractSceneTransition } from "appworks/graphics/layers/scene-transitions/abstract-scene-transition";
import { fadeIn, fadeOut } from "appworks/utils/animation/fade";
import { Contract } from "appworks/utils/contracts/contract";

export type FadeTransitionType = "in" | "out" | "both";

export class FadeTransition extends AbstractSceneTransition {

    protected fadeType: FadeTransitionType;
    protected duration: number;

    constructor(layer: Layers, duration: number = 400, fadeType: FadeTransitionType = "both") {
        super(layer);

        this.duration = duration;
        this.fadeType = fadeType;
    }

    public in(): Contract<void> {
        if (this.layer && this.fadeType === "in" || this.fadeType === "both") {
            return fadeIn(this.layer.container, this.duration);
        }

        return Contract.empty();
    }

    public out(): Contract<void> {
        if (this.layer && this.fadeType === "out" || this.fadeType === "both") {
            return fadeOut(this.layer.container, this.duration);
        }

        return Contract.empty();
    }
}
