import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";

export class FreespinRecord extends SpinRecord {

    /** ID for debugging purposes */
    public id: string = "FreespinRecord";

    // If there are multiple freespin types, use this to identify them
    public name: string = "";
}
