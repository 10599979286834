import { gameState } from "appworks/model/game-state";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";

export class SlingoFreeSpinsRemainingDecision extends Decision {
    public evaluate(): Exit {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const gameProgressResult = record.getFirstResultOfType(SlingoGameProgressResult);

        if (gameProgressResult && gameProgressResult.freeSpinsRemaining > 0) {
            return Exit.True;
        }

        return Exit.False;
    }
}