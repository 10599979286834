import { SceneConfig } from "appworks/config/asset-config-schema";
import { DOMLayer } from "appworks/graphics/layers/dom-layer";
import { SceneTransition } from "appworks/graphics/layers/scene-transitions/scene-transition";
import { asArray } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { DualPosition } from "../pixi/dual-position";
import { Position } from "../pixi/position";

export class Scene {

    public positionMap: Map<string, DualPosition> = new Map();

    private transitions: Map<string, SceneTransition[]> = new Map();

    private activeTransitions: SceneTransition[];
    private transitionState: TransitionState;

    constructor(public name: string, public layer: string, public config?: SceneConfig) {
        if (this.config) {
            if (this.config.positions) {
                for (const positionName in this.config.positions) {
                    if (this.config.positions.hasOwnProperty(positionName)) {
                        const positionConfig = this.config.positions[positionName];

                        const position: DualPosition = new DualPosition(null, null);
                        if (positionConfig.landscape) {
                            position.landscape = new Position
                                (positionConfig.landscape.x,
                                    positionConfig.landscape.y,
                                    positionConfig.landscape.width,
                                    positionConfig.landscape.height);
                        } else {
                            position.landscape = Position.unavailable();
                        }
                        if (positionConfig.portrait) {
                            position.portrait = new Position(
                                positionConfig.portrait.x,
                                positionConfig.portrait.y,
                                positionConfig.portrait.width,
                                positionConfig.portrait.height);
                        } else {
                            position.portrait = Position.unavailable();
                        }

                        this.positionMap.set(positionName, position);
                    }
                }
            }
        }
    }

    public setTransitions(transitions: SceneTransition | SceneTransition[], scene: string) {
        transitions = asArray(transitions);
        this.transitions.set(scene, transitions);
    }

    public addTransition(transitions: SceneTransition | SceneTransition[], scene: string) {
        transitions = asArray(transitions);
        if (this.transitions.has(scene)) {
            this.transitions.set(scene, [...this.transitions.get(scene), ...transitions]);
        } else {
            this.setTransitions(transitions, scene);
        }
    }

    public in(toScene: string = "*"): Contract<void> {
        let transitions: SceneTransition[];
        if (this.transitions.has(toScene)) {
            transitions = this.transitions.get(toScene);
        } else if (this.transitions.has("*")) {
            transitions = this.transitions.get("*");
        }

        if (transitions) {
            this.transitionState = TransitionState.IN;
            this.activeTransitions = transitions;

            return new Sequence([
                () => new Parallel([
                    ...transitions.map((transition) => () => transition.in()),
                ]),
                () => Contract.wrap(() => {
                    this.clearTransitionState();
                })
            ]);
        }

        return Contract.empty();
    }

    public out(fromScene: string = "*"): Contract<void> {
        let transitions: SceneTransition[];
        if (this.transitions.has(fromScene)) {
            transitions = this.transitions.get(fromScene);
        } else if (this.transitions.has("*")) {
            transitions = this.transitions.get("*");
        }

        if (transitions) {
            this.transitionState = TransitionState.OUT;
            this.activeTransitions = transitions;

            return new Sequence([
                () => new Parallel([
                    ...transitions.map((transition) => () => transition.out()),
                ]),
                () => Contract.wrap(() => {
                    this.clearTransitionState();
                })
            ]);
        }

        return Contract.empty();
    }

    public skipTransition() {
        if (this.transitionState) {
            if (this.transitionState === TransitionState.IN) {
                this.activeTransitions.forEach(transition => transition.skipIn());
            } else {
                this.activeTransitions.forEach(transition => transition.skipOut());
            }

            this.clearTransitionState();
        }
    }

    public hasCanvas(): boolean {
        return this.config !== null;
    }

    public hasDOMScene(): boolean {
        return DOMLayer.hasDOMScene(this.name, this.layer);
    }

    public clearDOM() {
        DOMLayer.setDOMScene(this.name, this.layer, false);
    }

    public showDOM() {
        if (this.hasDOMScene()) {
            DOMLayer.setDOMScene(this.name, this.layer, true);
        }
    }

    public destroyTransitions() {
        if (this.activeTransitions) {
            this.activeTransitions.forEach(transition => transition.destroy());
            this.activeTransitions = null;
        }
    }

    private clearTransitionState() {
        this.transitionState = null;
    }
}

export enum TransitionState {
    IN = "in",
    OUT = "out"
}
