import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Gameplay } from "appworks/model/gameplay/gameplay";
import { commsManager } from "appworks/server/comms-manager";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Timer } from "appworks/utils/timer";
import { GMRAlertComponent } from "gaming-realms/components/gmr-alert-component";
import { SlingoFooterComponent } from "slingo/components/slingo-footer-component";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { slingoModel } from "slingo/model/slingo-model";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { SpinRequestPayload } from "slotworks/model/gameplay/requests/spin-request-payload";
import { slotDefinition } from "slotworks/model/slot-definition";

interface RequestSlingoSpinStateConfig {
    slingoMatrixComponentType: { new (...args: any[]): MatrixComponent };
}

export class SlingoRequestSpinState extends State {
    protected config: RequestSlingoSpinStateConfig = {
        slingoMatrixComponentType: MatrixComponent
    };
    protected lastRecord: SlingoRecord;

    constructor(config?: Partial<RequestSlingoSpinStateConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }
    }

    public onEnter(cascadeSkip?: boolean): void {
        this.lastRecord = gameState.getCurrentGame().getCurrentRecord() as SlingoRecord;

        new Parallel([
            () => Contract.wrap(() => uiFlags.set(UIFlag.SPINNING, true)),
            () => Components.get(this.config.slingoMatrixComponentType).startTransition(),
            () => Components.get(SlingoSpinsCounterComponent).decrement(),
            () => Contract.wrap(() => Components.get(SlingoFooterComponent)?.showSpinStart()),
            () => this.sendRequest()
        ]).then(() => {
            // If the server responded with an error, no new record will have been built
            if (gameState.getCurrentGame().getCurrentRecord() === this.lastRecord) {
                this.error();
            } else {
                this.complete();
            }
        });
    }

    public complete(): void {
        if (gameState.getCurrentGame().getCurrentRecord().wager) {
            slingoModel.write({ stakeTime: Timer.time });
        }

        super.complete();
    }

    public error(): void {
        const gameplay = gameState.getCurrentGame();
        const gameProgress = gameplay.getLatestResultOfType(SlingoGameProgressResult);

        if (gameProgress.standardSpinsRemaining + gameProgress.freeSpinsRemaining > 0) {
            // This state is set up to error back to PurchaseSpinOrCollectState, to handle wager errors on purchase spin requests.
            // So if we're not in purchase spins, we're a bit stuck... don't know when this would happen but just in case,
            // bomb the game out completely.
            Components.get(GMRAlertComponent).error("SERVER_ERROR", true).execute();
            return;
        }

        const fakeRecord = new SpinRecord();
        fakeRecord.grid = gameplay.getLatestResultOfType(SlingoReelSpinResult).symbols.map(sym => {
            return [isNaN(parseInt(sym)) ? sym : `numbers/${sym}`];
        });
        fakeRecord.reelset = slotDefinition.reelsets.get("slingo");
        Gameplay.dataProcessorSupplements.get().forEach(supplement => supplement.process(fakeRecord));

        const matrix = Components.get(this.config.slingoMatrixComponentType);
        matrix.skipTransition(fakeRecord);

        super.error();
    }

    protected sendRequest(): Contract<void> {
        return new Sequence([
            () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, true)),
            () => commsManager.request(new SpinRequestPayload()),
            () => Contract.wrap(() => {
                uiFlags.set(UIFlag.AWAITING_RESPONSE, false);

                const gameplay = gameState.getCurrentGame();
                gameplay.setToLatestRecord();

                // Don't update balance if state is complete, it will update before any bonuses etc are played out
                const record = gameplay.getCurrentRecord() as SlingoRecord;
                if (record.state !== SlingoState.COMPLETE) {
                    Services.get(TransactionService).setBalance(gameplay.balance);
                }
            })
        ]);
    }
}