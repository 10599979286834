import { Layers } from "appworks/graphics/layers/layers";
import { UILayer } from "appworks/ui/ui-layer";
import { UIMenuButton } from "appworks/ui/ui-menu-button";
import { UIToggle } from "appworks/ui/ui-toggle";
import { ControlFactory } from "./factories/control-factory";
import { UIFlagState } from "./flags/ui-flag-state";
import { uiFlags, UIMenuFlag } from "./flags/ui-flags";
import Logger = require("js-logger");

export interface UIScene {
    scene: string;
    // Auto show when this flag state is met - defaults to UIFlagState.NEVER
    show?: UIFlagState;
    // Enable when this flag state is met, otherwise disable - defaults to match show
    enable?: UIFlagState;
}

export class UI {

    public static readonly factories: ControlFactory[] = [];

    public static layer(layer: Layers, uiScenes: UIScene[]) {
        if (this.initialised) {
            throw new Error("UI layers must all be created before UI.init()");
        }

        let uiLayer = this.layers.find(l => l.layer.id === layer.id);
        if (uiLayer) {
            uiLayer.uiScenes.push(...uiScenes);
        } else {
            uiLayer = new UILayer(layer, uiScenes);
        }

        this.layers.push(uiLayer);
    }

    public static button(layer: Layers, name: string, flag: UIMenuFlag, offFlags?: UIMenuFlag[], enabled?: UIFlagState) {
        if (this.initialised) {
            throw new Error("UI menu buttons must all be created before UI.init()");
        }
/////////////////////////
/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////
//////////////////
        this.buttons.push(new UIMenuButton(layer, name, flag, offFlags, enabled));
    }

    public static toggle(layer: Layers, name: string, flag: UIMenuFlag, offFlags?: UIMenuFlag[]) {
        if (this.initialised) {
            throw new Error("UI toggles must all be created before UI.init()");
        }
/////////////////////////
/////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////
//////////////////
        this.toggles.push(new UIToggle(layer, name, flag, offFlags));
    }

    public static register(...factories: ControlFactory[]): void {
        if (this.started) {
            throw new Error("UI register must take place before UI.start()");
        }
        this.factories.push(...factories);
    }

    public static deregister<T extends ControlFactory>(type: { new(...args: any[]): T }): void {
        if (this.started) {
            throw new Error("UI de-register must take place before UI.start()");
        }
        this.factories.forEach((fac, i) => {
            if (fac instanceof type) {
                this.factories.splice(i, 1);
            }
        });
    }

    public static init() {
        this.initialised = true;
    }

    public static start() {
        this.layers.forEach((layer) => layer.start());
        this.started = true;
        uiFlags.init();
    }

    // Just for debugging which scenes are active for each UILayer
    public static getCurrentActiveScenes(): Array<{ layer: string, scene: string, enabled: boolean }> {
        return this.layers.map((layer) => {
            const currentSceneName = layer.layer.getCurrentScene().name;
            const currentUiScene = layer.uiScenes.find((uiScene) => uiScene.scene === currentSceneName);
            const enabled = currentUiScene && currentUiScene.enable.test();
            return { layer: layer.layer.id, scene: currentSceneName, enabled };
        });
    }

    private static readonly layers: UILayer[] = [];
    private static readonly buttons: UIMenuButton[] = [];
    private static readonly toggles: UIToggle[] = [];
    private static initialised: boolean = false;
    private static started: boolean = false;
}
