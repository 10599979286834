import { logger } from "appworks/utils/logger";
import { Timer } from "appworks/utils/timer";
import { Signal } from "signals";

declare const __DEBUG__: boolean;

export type Resolver<T> = (value?: T) => void;
export type Canceller = (callback: () => void) => void;
export type Executor<T> = (resolve: Resolver<T>, cancelCallback?: Canceller) => (void | Contract<any>);

export class Contract<T = void> {
    public static empty(): Contract<any> {
        return new Contract<any>((resolve) => resolve(null));
    }

    public static never(): Contract<any> {
        return new Contract<any>((resolve) => {
            // never resolve
        });
    }

    public static wrap<T>(method?: () => T): Contract<any> {
        return new Contract<any>((resolve) => {
            let result = null;
            if (method) {
                result = method();
            }
            resolve(result);
        });
    }

    public static wrapPromise<T>(promise: Promise<T>): Contract<T> {
        return new Contract((resolve) => {
            promise.then((response: T) => resolve(response));
        });
    }

    public static getTimeoutContract(duration: number): Contract<any> {
        return new Contract<number>((resolve) => {
            Timer.setTimeout(resolve, duration);
        });
    }

    public static getDelayedContract<T>(delay: number, callback: () => Contract<T>): Contract<T> {
        const delayedContract: Contract<T> = new Contract((resolve) => {
            if (delay) {
                Timer.setTimeout(() => {
                    if (!delayedContract.isCancelled()) {
                        callback().then(resolve);
                    }
                }, delay);
            } else {
                callback().then(resolve);
            }
        });

        return delayedContract;
    }

    public onCancelled: Signal = new Signal();

    private executor: Executor<T>;
    private timeoutId: number;
    private cancelled: boolean;
    private fulfilled: boolean;
    private cancelAfterResolve: boolean;
    private onfulfilled: (value: T) => void;
    private cancelCallback?: () => void;

    /**
     * A Contract is like a Promise however the results can be returned immediately (syncronously)
     * A Contract will ALWAYS be fulfilled, unless it is cancelled manually
     * A Contract is only executed when "then" or "execute" is called (and never both)
     * If "then" or "execute" aren't called on a contract by the next "tick", an exception is thrown
     */
    constructor(executor: Executor<T>, cancelAfterResolve: boolean = false) {
        this.executor = executor;
        this.cancelAfterResolve = cancelAfterResolve;

        if (__DEBUG__) {
            let e: any;
            e = null;
            // This has a big performance penalty, so only uncomment if you get the error and need help debugging
            // e = new Error();

            this.timeoutId = Timer.setTimeout(() => {
                throw new Error("Contract never executed " + (e?.stack || " -- Uncomment line in Contract.ts to see where"));
            }, 1);
        }
    }

    /**
     * When a contract has resolved, call onfulfilled with the value passed to resolve
     */
    public then(onfulfilled: (value: T) => void) {
        if (__DEBUG__) {
            Timer.clearTimeout(this.timeoutId);
        }

        if (this.executor) {
            this.onfulfilled = onfulfilled;

            this.fulfilled = false;
            this.executor(
                (value: T) => {
                    if (this.cancelled) {
                        logger.debug("Cancelled contract resolved");
                    } else {
                        if (!this.fulfilled) {
                            this.fulfilled = true;
                        } else {
                            const e = new Error();
                            throw new Error("Contract resolved twice " + e.stack);
                        }
                        this.onfulfilled(value);
                        this.onfulfilled = null;

                        if (this.cancelAfterResolve) {
                            this.cancel();
                        }
                    }
                },
                (cancelCallback: () => void) => {
                    this.cancelCallback = cancelCallback;
                }
            );
            this.executor = null;
        }

        return this;
    }

    /**
     * Execute the contract without anything waiting for resolve
     */
    public execute() {
        if (__DEBUG__) {
            Timer.clearTimeout(this.timeoutId);
        }

        const resolver = () => null;

        const canceller = (cancelCallback: () => void) => {
            this.cancelCallback = cancelCallback;
        };

        if (this.executor) {
            this.executor(resolver, canceller);
            this.executor = null;
        }

        return this;
    }

    /**
     * Cancel a contract. It can never be resolved
     */
    public cancel() {
        if (__DEBUG__) {
            Timer.clearTimeout(this.timeoutId);
        }

        if (this.cancelCallback && !this.cancelled) {
            this.cancelCallback();
        }

        this.onCancelled.dispatch();

        this.cancelled = true;
        this.onfulfilled = null;
        this.cancelCallback = undefined;
    }

    public isCancelled() {
        return this.cancelled;
    }

    /**
     * Force a contract to resolve
     * If the contract subsequently resolves itself, it will be ignored
     * If the contract has already been resolved, this call will be ignored
     * If the contract has not yet been executed, this call will be ignored
     */
    public forceResolve(value?: T) {
        if (!this.cancelled && !this.fulfilled && this.onfulfilled) {
            this.fulfilled = true;
            this.onfulfilled(value);
            this.onfulfilled = null;
        }
    }
}
