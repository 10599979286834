import { FlexiToggle } from "appworks/graphics/elements/flexi-toggle";
import { ToggleElement } from "appworks/graphics/elements/toggle-element";
import { Layers } from "appworks/graphics/layers/layers";
import { ToggleControl } from "appworks/ui/controls/standard/toggle/toggle-control";
import { ControlFactory } from "../control-factory";

export class UIToggleControlFactory implements ControlFactory {
    public filter(layer: Layers) {
        const elements = layer.allElements;
        for (const p in elements) {
            if (elements.hasOwnProperty(p) && elements[p] instanceof ToggleElement && p.indexOf("ui_") > -1) {
                return true;
            }
        }

        return false;
    }

    public build(layer: Layers) {
        const elements = layer.allElements;

        const controls = [];

        for (const p in elements) {
            if (elements.hasOwnProperty(p) && elements[p] instanceof ToggleElement && p.indexOf("ui_") > -1) {
                const flexiToggle = new FlexiToggle();
                flexiToggle.addTarget(elements[p]);
                controls.push(new ToggleControl(flexiToggle));
            }
        }

        return controls;
    }
}
