import { Layers } from "appworks/graphics/layers/layers";
import { SpinControl } from "appworks/ui/controls/standard/button/spin-control";
import { ControlFactory } from "../control-factory";

export class SpinControlFactory implements ControlFactory {
    public filter(layer: Layers) {
        if (layer.getFlexiButton("spin")) {
            return true;
        }

        return false;
    }

    public build(layer: Layers) {
        return [new SpinControl(layer.getFlexiButton("spin"))];
    }
}
