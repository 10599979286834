import { gameState } from "appworks/model/game-state";
import { Record } from "appworks/model/gameplay/records/record";
import { CloseRequestPayload } from "appworks/model/gameplay/requests/close-request-payload";
import { RequestPayload } from "appworks/model/gameplay/requests/request-payload";
import { commsManager } from "appworks/server/comms-manager";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { FreespinWinResult } from "slotworks/model/gameplay/records/results/freespin-win-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { RecordDisplay, RecordDisplayFunction } from "./record-display";
import { RecordTree } from "./record-tree";
import { RequestButton, RequestButtons } from "./request-buttons";
import { ResultDisplay, ResultDisplayFunction } from "./result-display";

export class IntegrationDebugger {
    public static USE_DEFAULT_BUTTONS: boolean = true;

    public static addRequest(label: string, requestType: new (...args: any[]) => RequestPayload, parameters?: string[], values?: { [key: string]: any }) {
        this.customRequests.push({ label, requestType, parameters, values });
    }

    public static addResultDisplayFunction(resultType: string, resultDisplay: ResultDisplayFunction) {
        this.customResultDisplays.set(resultType, resultDisplay);
    }

    public static addRecordDisplayFunction(recordId: string, recordDisplay: RecordDisplayFunction) {
        this.customRecordDisplays.set(recordId, recordDisplay);
    }

    public static addButtonEnabledCondition(button: string, condition: (request: RequestPayload, record: Record) => boolean): void {
        this.buttonEnableConditions.set(button, { button, condition });
    }

    private static customRequests: RequestButton[] = [];
    private static customRecordDisplays: Map<string, RecordDisplayFunction> = new Map();
    private static customResultDisplays: Map<string, ResultDisplayFunction> = new Map();
    private static buttonEnableConditions: Map<string, { button: string, condition: (request: RequestPayload, record: Record) => boolean }> = new Map();

    private recordTree: RecordTree;
    private recordDisplay: RecordDisplay;
    private resultDisplay: ResultDisplay;
    private requestButtons: RequestButtons;

    public start() {
        this.recordTree = new RecordTree();
        this.recordDisplay = new RecordDisplay(IntegrationDebugger.customRecordDisplays);
        this.resultDisplay = new ResultDisplay(IntegrationDebugger.customResultDisplays);
        this.requestButtons = new RequestButtons(IntegrationDebugger.customRequests, IntegrationDebugger.USE_DEFAULT_BUTTONS);

        this.requestButtons.select.add((payload: RequestPayload, button: RequestButton) => {
            if (button.label === "Spin and End") {
                new Sequence([
                    () => this.sendRequest(payload),
                    (record: Record) => {
                        if (record instanceof SpinRecord && record.getResultsOfType(FreespinWinResult).length === 0) {
                            return this.sendRequest(new CloseRequestPayload(), false);
                        }
                    }
                ]).execute();
            } else {
                this.sendRequest(payload).execute();
            }
        });

        this.recordTree.select.add((record: Record) => this.recordDisplay.show(record));
        this.recordTree.select.add((record: Record) => this.resultDisplay.show(record));

        this.recordTree.update();

        gameState.getCurrentGame().onRecordAdded.add(() => this.recordTree.update());

        gameState.onNewGame.add(() => {
            this.recordTree.update();
            gameState.getCurrentGame().onRecordAdded.add(() => this.recordTree.update());
        });    

        IntegrationDebugger.addButtonEnabledCondition("Spin and End", (request: RequestPayload, record: Record) => {
            if (record instanceof SpinRecord && record.getResultsOfType(FreespinWinResult).length) {
                return false;
            } else {
                return true;
            }
        });

        this.requestButtons.updateButtonEnabled(null, null, IntegrationDebugger.buttonEnableConditions);
    }

    public toggle() {
        if (!this.recordDisplay || !this.recordDisplay || !this.resultDisplay || !this.requestButtons) {
            this.start();
        } else {
            this.recordTree.toggle();
            this.recordDisplay.toggle();
            this.resultDisplay.toggle();
            this.requestButtons.toggle();

            this.recordTree.update();
        }
        this.requestButtons.updateButtonEnabled(null, null, IntegrationDebugger.buttonEnableConditions);
    }

    protected sendRequest(payload: RequestPayload, clear: boolean = true) {
        return new Contract<Record | void>((resolve) => {
            if (gameState.getCurrentGame().hasRecords() && gameState.getCurrentGame().isComplete()) {
                gameState.newGame();
            }

            this.requestButtons.setEnabled(false);

            commsManager.request(payload).then(() => {
                gameState.getCurrentGame().setToLatestRecord();
                const record = gameState.getCurrentGame().getCurrentRecord();
                this.requestButtons.updateButtonEnabled(payload, record, IntegrationDebugger.buttonEnableConditions);
                this.requestButtons.setEnabled(true);
                if (record) {
                    this.recordTree.update();
                } else if (clear) {
                    this.clear();
                }
                resolve(record);
            });
        });
    }

    protected clear() {
        this.recordTree.clear();
        this.recordDisplay.clear();
        this.resultDisplay.clear();
    }
}
