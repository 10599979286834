import { Orientation } from "appworks/graphics/canvas/orientation";
import { deviceInfo } from "appworks/utils/device-info";
import { Point, Renderer, Text as PIXIText, TextMetrics, TextStyle } from "pixi.js";
import { DualPosition } from "./dual-position";
import { RenderOrientation } from "./render-orientation";
import { TextPosition } from "./text-position";
import { logger } from "appworks/utils/logger";

export class Text extends PIXIText {
    public static autoShrink: boolean = true;
    public static ignoreDropshadowBug: boolean = true;
    public static renderAtFullResolution: boolean = false;
    public static minFontSize: number = 8;
    public static minPadding: number = 0;

    public landscape: TextPosition = new TextPosition();
    public portrait: TextPosition = new TextPosition();

    public autoShrink: boolean = Text.autoShrink;

    private lastText: string;
    private lastOrientation: TextPosition;

    private appleRefresh: boolean = false;

    constructor(text: string, style?: Partial<TextStyle>, canvas?: HTMLCanvasElement) {
        super(text, style, canvas);

        this.resolution = 1;

        style.padding = Math.max(style.padding, Text.minPadding);
    }

    public getPosition(orientation: Orientation) {
        return orientation === Orientation.PORTRAIT ? this.portrait : this.landscape;
    }

    public updateTransform() {
        const position = this.getOrientationPosition();

        this.move(position.x, position.y, position.align);

        this.scale.x = position.scale.x;
        this.scale.y = position.scale.y;

        super.updateTransform();
    }

    // @ts-ignore
    get x() {
        return this.position.x;
    }

    set x(value) {
        throw new Error("Do not change x and y manually, please use landscape.x or portrait.x");
    }

    // @ts-ignore
    get y() {
        return this.position.y;
    }

    set y(value) {
        throw new Error("Do not change x and y manually, please use landscape.x or portrait.x");
    }

    public setDualPosition(dualPosition: DualPosition) {
        this.landscape.x = dualPosition.landscape.x;
        this.landscape.y = dualPosition.landscape.y;
        this.landscape.width = dualPosition.landscape.width;
        this.landscape.height = dualPosition.landscape.height;
        this.portrait.x = dualPosition.portrait.x;
        this.portrait.y = dualPosition.portrait.y;
        this.portrait.width = dualPosition.portrait.width;
        this.portrait.height = dualPosition.portrait.height;
    }

    // Fix pixi bug where text resolution cannot be set independent to the rest of the game
    public _render(renderer: Renderer) {

        const position = this.getOrientationPosition();

        if (position.unavailable) {
            return;
        }

        if (Text.renderAtFullResolution) {
            const rendererResolution = renderer.resolution;
            renderer.resolution = 1;
            super._render(renderer);
            renderer.resolution = rendererResolution;
        } else {
            super._render(renderer);
        }
    }

    public updateText(respectDirty: boolean = true): void {

        // this.iOSFix(); // Removed as of PIXI 5.3.2 - Left commented out for now in case of false positive

        if (this.text !== this.lastText) {
            this.lastText = this.text;
            if (this.autoShrink) {
                this.autoResize();
            }
        }

        super.updateText(respectDirty);
    }

    /**
     * This only exists as a way to force `updateText` to call `autoResize`.
     *
     * Useful when resizing text position's width & height programatically.
     */
    public forceAutoResize() {
        if (this.autoShrink) {
            this.autoResize();
            super.updateText(true);
        }
    }

    public getWidth() {
        return this.getTextDimensions().width;
    }

    /**
     * **Warning**: This has cross references to filters, replace them if you don't wish to share them with the cloned text
     */
    public clone() {
        const cloned = new Text(this.text, { ...this.style });
        cloned.landscape = this.landscape.clone();
        cloned.portrait = this.portrait.clone();
        cloned.filters = [...this.filters];

        return cloned;
    }

    protected autoResize() {
        if (!this.autoShrink) { return; }

        const position = this.getOrientationPosition();

        this.style.wordWrapWidth = position.width;
        this.style.fontSize = position.fontSize;

        if (this.style.fontSize > Text.minFontSize) {
            let textDimensions = this.getTextDimensions();

            while (textDimensions.width > position.width ||
                textDimensions.height > position.height) {
                this.style.fontSize--;

                this.updateText();

                if (this.style.fontSize <= Text.minFontSize) {
                    break;
                }

                textDimensions = this.getTextDimensions();
            }        
        }

        // Uncomment to see console logs when text resizes happen, to identify any that might be causing lag/slowdown
        // const diff = position.fontSize - this.style.fontSize;
        // if (diff > 3) {
        //     logger.log(`TEXT RESIZE ${position.fontSize}->${this.style.fontSize}: ${this.name} "${this.text}"`);
        // }
    }

    protected move(x: number, y: number, align: string) {
        this.anchor = new Point(0, 0) as any;

        (this.transform as any).position.x = x;
        (this.transform as any).position.y = y;

        if (align) {
            this.style.align = align;
        }
        align = this.style.align;

        let anchor;

        // Alignment
        if (align === "center") {
            anchor = new Point(0.5, 0.5);
        } else if (align === "right") {
            anchor = new Point(1, 0.5);
        } else {
            anchor = new Point(0, 0.5);
        }

        this.anchor = anchor as any;

        (this.transform as any).position.x += this.getOrientationPosition().width * this.anchor.x;
        (this.transform as any).position.y += this.getOrientationPosition().height * this.anchor.y;
    }

    protected iOSFix() {
        // This fixes iOS not updating gradient text. Can be removed when fixed in pixi
        if (!this.appleRefresh) {
            if (deviceInfo.isAppleDevice() || deviceInfo.isSafari()) {
                if (this.style && this.style.fillGradientStops && this.style.fillGradientStops.length > 1) {
                    const targetText = this.text;
                    this.text = "";
                    this.appleRefresh = true;
                    try {
                        this.updateTransform();
                        (this as any).updateText();
                        (this as any).updateTexture();
                    } catch (e) {
                        // Sometimes an error is thrown. Since this is temporary anyway, we just ignore it
                    }
                    this.text = targetText;
                    this.appleRefresh = false;
                }
            }
        }
    }

    protected getTextDimensions() {
        return TextMetrics.measureText(this.text, this.style);
    }

    protected getOrientationPosition() {
        const orientation = RenderOrientation.isLandscape() ? this.landscape : this.portrait;
        if (this.lastOrientation !== orientation) {
            this.lastText = "";
            this.lastOrientation = orientation;
        }
        return orientation;
    }
}
