import { asArray } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Easing, Tween } from "appworks/utils/tween";
import { TweenContract } from "../contracts/tween-contract";

export interface Fadable {
    alpha: number;
}

const defaultDuration: number = 500;
const defaultEasing: (k: number) => number = Easing.Linear.None;

export function fadeIn<T extends Fadable>(
    targetOrTargets: T | T[], 
    duration = defaultDuration, 
    easing = defaultEasing
): Contract<void> {
    return fade(targetOrTargets, 0, 1, duration, easing);
}

export function fadeOut<T extends Fadable>(
    targetOrTargets: T | T[], 
    duration = defaultDuration, 
    easing = defaultEasing
): Contract<void> {
    return fade(targetOrTargets, 1, 0, duration, easing);
}

export function fade<T extends Fadable>(
    targetOrTargets: T | T[],
    fromAlpha: number,
    toAlpha: number,
    duration = defaultDuration,
    easing = defaultEasing
): Contract<void> {
    const targets = asArray(targetOrTargets);
    targets.forEach((target) => target.alpha = fromAlpha);

    return new Parallel(targets.map((target) => {
        const fadeTween = new Tween(target)
            .to({ alpha: toAlpha }, duration)
            .easing(easing);
        fadeTween.onComplete(() => target.alpha = toAlpha);
        return () => TweenContract.wrapCancellableTween(fadeTween);
    }));
}
