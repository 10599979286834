import { Result } from "appworks/model/gameplay/records/results/result";

export class FreespinWinResult extends Result {

    /** For debugging purposes */
    public resultType: string = "FreespinWinResult";

    // If there are multiple freespin types, use this to identify them
    public name: string;
    public freespinsWon: number;
}
