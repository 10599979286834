import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { AbstractControl } from "../../abstract-control";

declare const __GAMENAME__: string;

export class GameNameLabelControl extends AbstractControl {
    constructor(protected label: FlexiText) {
        super();

        this.setGameName();
    }

    protected setGameName = () => {
        this.label.setText(__GAMENAME__);
    }
}
