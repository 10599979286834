import { Point } from "appworks/utils/geom/point";
import { ValueList } from "appworks/utils/value-list";
import { SymbolConfig, SymbolDefinition } from "slotworks/model/symbol-definition";

class SlotDefinition {
    public reelsets: Map<string, string[][]>;

    public lines: ValueList<Point[]> = new ValueList();

    public matrixGrid: number[];

    public symbolDefinitions: SymbolDefinition[];

    public bestSymbol: SymbolDefinition;

    private highestSymbolPayout: number = 0;

    public getSymbolDefinition(id: string): SymbolDefinition {
        for (const symbol of this.symbolDefinitions) {
            if (symbol.id === id || symbol.aliases.indexOf(id) > -1) {
                return symbol;
            }
        }

        // Any request for a symbol with a _ in it's name, which has no matching definition, will try again without anything after and including the last _
        // For example a quickhit value of 100, QH_100, will search for QH if QH_100 isn't found, and QUICK_HIT_100 will search for QUICK_HIT
        if (id.lastIndexOf("_") > -1) {
            const prefix = id.slice(0, id.lastIndexOf("_"));
            const fallbackSymbol = this.getSymbolDefinition(prefix);
            if (fallbackSymbol) {
                return fallbackSymbol;
            }
        }
        throw new Error("Symbol not found in symbol definitions: " + id);
    }

    public processReelSets(reelsets: { [id: string]: number[][] }) {
        this.reelsets = new Map<string, string[][]>();

        for (const reelsetId in reelsets) {

            if (reelsets.hasOwnProperty(reelsetId)) {
                const reelset = reelsets[reelsetId];

                const parsedReelset = [];

                for (const reel of reelset) {

                    const parsedReel: string[] = [];
                    parsedReelset.push(parsedReel);

                    for (const symbolId of reel) {
                        parsedReel.push(this.symbolDefinitions[symbolId].id);
                    }
                }

                this.reelsets.set(reelsetId, parsedReelset);
            }
        }
    }

    public processSymbols(symbols: SymbolConfig[]) {
        this.symbolDefinitions = [];
        this.bestSymbol = null;
        this.highestSymbolPayout = 0;

        let index = 0;
        for (const symbol of symbols) {
            const symbolDef = new SymbolDefinition(symbol, index++);
            this.symbolDefinitions.push(symbolDef);

            // Helpfully keep a reference to the highest paying symbol
            if (symbolDef.payouts && !symbol.scatter) {
                symbolDef.payouts.forEach((value: number) => {
                    if (value > this.highestSymbolPayout) {
                        this.highestSymbolPayout = value;
                        this.bestSymbol = symbolDef;
                    }
                });
            }
        }
    }
}

export const slotDefinition = new SlotDefinition();
