import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Services } from "appworks/services/services";
import { PlayingSound, SoundService } from "appworks/services/sound/sound-service";
import { KeyInput } from "appworks/utils/key-input";
import { KeyboardCode } from "appworks/utils/keyboard";
import { Timer } from "appworks/utils/timer";
import Logger = require("js-logger");

export class SoundDebugger {

    private container: HTMLDivElement;
    private checkbox: HTMLInputElement;

    private trackedSounds: PlayingSound[];
    private trackedSoundElements: HTMLElement[];

    constructor() {
        this.trackedSounds = [];
        this.trackedSoundElements = [];

        const keyInput = new KeyInput();
        keyInput.onKeyUp.add((key: string, e: KeyboardEvent) => {
            if (e.shiftKey) {
                if (key === KeyboardCode.O) {
                    this.toggle();
                }
            }
        });
    }

    public start() {
        this.container = document.createElement("div");
        this.container.id = "SoundDebugger";

        this.container.style.position = "fixed";
        this.container.style.top = this.container.style.right = "0";
        this.container.style.zIndex = "900";
        this.container.style.width = "25vw";
        this.container.style.height = "25vh";
        this.container.style.paddingTop = "1vh";
        this.container.style.paddingLeft = "1vw";
        this.container.style.overflow = "auto";
        this.container.style.pointerEvents = "all";

        this.container.style.backgroundColor = "rgba(50,50,50,.5)";
        this.container.style.border = "solid 1px white";

        this.container.style.fontSize = "16px";
        this.container.style.fontFamily = "Courier New";

        this.container.innerHTML = `<span style="color: white">Show complete</span>`;

        this.checkbox = document.createElement("input");
        this.checkbox.type = "checkbox";
        this.container.appendChild(this.checkbox);

        const log = document.createElement("button");
        log.innerHTML = "SAVE";
        log.onclick = () => Logger.info(this.container.innerText);
        this.container.appendChild(log);

        this.container.appendChild(document.createElement("hr"));

        Services.get(CanvasService).rootNode.appendChild(this.container);
    }

    public toggle() {
        if (!this.container) {
            this.start();
            this.update();
        } else {
            if (this.container.parentElement) {
                Services.get(CanvasService).rootNode.removeChild(this.container);
            } else {
                Services.get(CanvasService).rootNode.appendChild(this.container);
            }
        }
    }

    public update() {
        if (!this.container) {
            return;
        }

        const playingSoundMap = (Services.get(SoundService) as any).playingSounds;

        playingSoundMap.forEach((playingSounds) => {
            playingSounds.forEach((playingSound) => {
                if (this.trackedSounds.indexOf(playingSound) === -1) {
                    this.trackedSounds.push(playingSound);
                }
            });
        });

        this.trackedSounds.forEach((playingSound, index) => {
            this.addSoundInfo(playingSound, index);
        });

        Timer.setTimeout(() => this.update(), 0);
    }

    private addSoundInfo(sound: PlayingSound, index: number) {
        const soundSprite = Services.get(SoundService).getSoundSprites()[0];

        if (!this.trackedSoundElements[index]) {
            const newElement = document.createElement("div");
            newElement.style.whiteSpace = "pre";
            this.trackedSoundElements[index] = newElement;
        }

        const element = this.trackedSoundElements[index];

        element.style.display = "block";
        element.style.color = "white";

        if (!soundSprite.playing(sound.howlerId)) {
            element.style.color = "#333";
            if (!this.checkbox.checked) {
                element.style.display = "none";
                return;
            }
        }

        let output = "";

        // Sound volume
        const volume = soundSprite.volume(sound.howlerId) as number * 100;
        output += `[${this.pad(volume.toString(), 3)}%] `;

        // Sound name
        output += this.pad(sound.config.id, 20);

        // Sound progress
        let progress = " [";

        const duration = soundSprite.duration(sound.howlerId);
        const seek = Services.get(SoundService).getSeek(sound.config.id, sound.howlerId);
        const percent = seek / duration;
        for (let i = 1; i <= 10; i++) {
            if ((i / 10) >= percent) {
                progress += "-";
            } else {
                progress += "=";
            }
        }

        progress += "] " + Math.floor(seek * 100) / 100;
        output += progress;

        element.style.width = "100%";
        element.innerHTML = output;

        this.container.appendChild(element);
    }

    private pad(value: string, length: number) {
        let out = value.slice(0, Math.min(value.length, length));

        while (out.length < length) {
            out += " ";
        }

        return out;
    }
}
