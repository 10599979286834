import { ValueList } from "appworks/utils/value-list"

export class SlingoResponsibleGamingModel {
    public extraSpins: ValueList<number> = new ValueList();
    public maxSpinPrice: ValueList<number> = new ValueList();
    public maxTotalStake: ValueList<number> = new ValueList();
    public maxTotalLoss: ValueList<number> = new ValueList();

    constructor() {
        this.extraSpins.values = [0, 2, 4, 6, 8];
        this.maxSpinPrice.values = [1, 2, 5, 10, 25];
        this.maxTotalStake.values = [2, 5, 10, 20, 50];
        this.maxTotalLoss.values = [10, 20, 30, 40, 50];
    }
}