import { AssetConfigSchema } from "appworks/config/asset-config-schema";
import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Service } from "appworks/services/service";
import { Services } from "appworks/services/services";
import { asArray } from "appworks/utils/collection-utils";
import { Rectangle } from "appworks/utils/geom/rectangle";
import * as Logger from "js-logger";
import { Graphics, Texture } from "pixi.js";
import { AnimationService } from "./animation/animation-service";
import { CanvasService } from "./canvas/canvas-service";
import { SpineService } from "./spine/spine-service";

declare const __DEBUG__: boolean;

export class GraphicsService extends Service {

    private textures: Map<string, Texture> = new Map<string, Texture>();

    // A list of all the images from the assets/static folder
    private staticImageNames: string[] = [];

    public init(): void {
        // Do nothing. Handled in setup due to assetConfig requirements.
/////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
//////////////////
    }

    /**
     * Call this after preloading.
     *
     * Creates a pixi renderer (WebGL with canvas fallback), an HTML canvas element and a pixi stage container
     * Uses graphics config to intialize factory methods
     * Starts render loop (requestAnimationFrame)
     *
     * @param config {GraphicsConfigSchema}
     */
    public setup(config: AssetConfigSchema) {
        const animationConfig = config.layouts.all.animations;

/////////////////////////
////////////////////////
//////////////////////////////////////
/////////
//////////////////

        Services.get(AnimationService).setup(animationConfig);
    }

    /**
     * Registers a texture with the graphics manager
     *
     * @param id {string}
     * @param texture {Texture}
     */
    public addTexture(id: string, texture: Texture) {
        if (id.indexOf("/") === -1) {
            this.staticImageNames.push(id);
        }
        this.textures.set(id, texture);
    }

    /**
     * Check for the existence of a texture
     * @param id {string}
     */
    public hasTexture(id: string): boolean {
        return this.textures.has(id);
    }

    /**
     * Extract and download a texture as an image
     * @param regex {string}
     */
    public extractTextures(regex: string): void {
        this.textures.forEach((texture, id) => {
            if (id.match(new RegExp(regex)) !== null) {
                Services.get(CanvasService).renderer.plugins.extract.canvas(new Sprite(texture)).toBlob((b) => {
                    const a = document.createElement("a");
                    document.body.append(a);
                    a.download = id;
                    a.href = URL.createObjectURL(b);
                    a.click();
                    a.remove();
                });
            }
        });
    }

    /**
     * Extract and download all textures as images
     */
    public extractAllTextures(): void {
        this.extractTextures(".");
    }

    /**
     * Returns a reference to a texture previously registered
     * Don't use this method for sprite creation, instead use createSprite factory method
     *
     * @param id {string}
     * @returns {Texture}
     */
    public getTexture(id: string): Texture {
        if (!this.hasTexture(id)) {
            throw new Error("Texture `" + id + "` does not exist in texture cache");
        }
        return this.textures.get(id);
    }

    public getTextureWithFallback(ids: string[]): Texture | null {
        while (ids.length) {
            const id = ids.shift();
            try {
                return this.getTexture(id);
            } catch (err) {
                //
            }
        }
    }

    /**
     * Destroys a texture and removes it from the cache
     *
     * @param id {string}
     * @param texture {Texture}
     */
    public destroyTexture(id: string) {
        const texture = this.getTexture(id);
        texture.destroy(true);

        this.textures.delete(id);
    }

    /**
     * Factory method to instantiate a new Sprite from a given ID
     * Note the textures it uses are from the pixi cache, but the sprite object itself is a new object in memory
     *
     * @param id {string}
     * @returns {Sprite}
     */
    public createSprite(id: string): Sprite;

    /**
     * Factory method to instantiate a new Sprite from a given ID
     * Note the textures it uses are from the pixi cache, but the sprite object itself is a new object in memory
     *
     * @param id {string}
     * @returns {Sprite}
     */
    public createSprite(id: string): Sprite {
        if (!this.textures.has(id)) {
            throw new Error("Invalid texture id: " + id);
        }
        return new Sprite(this.textures.get(id));
    }

    public createBlankSprite(): Sprite {
        return new Sprite(Texture.WHITE);
    }

    /**
     * Create a sprite, if the sprite is not found it tries the next one in the list.
     * This continues until one is found. If none are found, null is returned.
     * List is read from 0 to end
     *
     * @param ids {string[]}
     */
    public createSpriteWithFallback(ids: string[]): Sprite | null {
        while (ids.length) {
            try {
                return this.createSprite(ids.shift());
            } catch (e) {
                continue;
            }
        }

        return null;
    }

    /**
     * Creates an animation by name
     */
    public createAnimation(id: string): AnimatedSprite;

    /**
     * Goes through the list and returns the first valid animation
     */
    public createAnimation(ids: string[]): AnimatedSprite;

    /**
     * Factory method to instantiate a new AnimatedSprite.
     * Note the textures it uses are from the pixi cache, but the sprite object itself is a new object in memory
     */
    public createAnimation(ids: string | string[]): AnimatedSprite {

        let animation = null;

        if (typeof ids === "string") {
            animation = Services.get(AnimationService).generateAnimation(ids);
        } else {
            animation = Services.get(AnimationService).generateAnimationWithFallbacks(ids);
        }

        return animation;
    }

    public createSpine(name: string) {
        return Services.get(SpineService).createSpine(name);
    }

    /**
     * Factory method to instantiate a new AnimatedSprite combining frames over multiple animations
     * Config (fps, loop etc) is taken from first in the list
     */
    public createMergedAnimation(ids: string[]): AnimatedSprite {
        let animation = null;

        animation = Services.get(AnimationService).generateAnimation(ids);

        return animation;
    }

    public createRectangle(rectangle: Rectangle, fillColor: number = 0, fillAlpha: number = 1, strokeThickness: number = 0, strokeColor: number = 0, strokeAlpha: number = 1) {
        const graphics = new Graphics();

        graphics.beginFill(fillColor, fillAlpha);
        if (strokeThickness > 0) {
            graphics.lineStyle(strokeThickness, strokeColor, strokeAlpha);
        }
        graphics.drawRect(rectangle.x, rectangle.y, rectangle.width, rectangle.height);
        graphics.endFill();

        return graphics;

    }

    // List all of the images from the assets/static folder
    public listStaticImageNames(): string[] {
        return this.staticImageNames;
    }

    private listAllFonts(config: AssetConfigSchema) {
        const fontDictionary = new Map<string, boolean>();

        Logger.info("Used fonts:");

        for (const layout of [config.layouts.desktop, config.layouts.mobile]) {
            if (layout.graphics) {
                for (const layerSceneName in layout.graphics) {
                    if (layout.graphics.hasOwnProperty(layerSceneName)) {
                        const layerScene = layout.graphics[layerSceneName];
                        if (layerScene.text) {
                            for (const textName in layerScene.text) {
                                if (layerScene.text.hasOwnProperty(textName)) {
                                    const text = layerScene.text[textName];
                                    if (text.landscape?.fontFamily) {
                                        fontDictionary.set(text.landscape.fontFamily + "-" + text.landscape.fontVariant, true);
                                    }
                                    if (text.portrait?.fontFamily) {
                                        fontDictionary.set(text.portrait.fontFamily + "-" + text.portrait.fontVariant, true);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        fontDictionary.forEach((value, index) => Logger.info(index));
    }
}
