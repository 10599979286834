
/**
* Handles changes caused by client, such as bet stakes and user balance changes.
*
* @class ClientModel
* @typedef {ClientModel}
*/
export class ClientModel {
    private static pstakeFromGameHub: number[];
    private static pnewBalance: number;
    private static hasStakesData = false;


    static getStakeFromGameHub(): number[] {
        return this.pstakeFromGameHub;
    }

    static setStakeFromGameHub(amount: number[]) {
        this.pstakeFromGameHub = amount;
        this.hasStakesData = true;
    }

    static hasStakes(): boolean {
        return this.hasStakesData;
    }

    static getNewBalance(): number {
        return this.pnewBalance;
    }

    static setNewBalance(amount: number) {
        this.pnewBalance = amount;
    }

    public static getClass(): any {
        return ClientModel;
    }
}   