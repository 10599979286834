import { Components } from "appworks/components/components";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { CenterPivot, PIXIElement } from "appworks/graphics/pixi/group";
import { gameState } from "appworks/model/game-state";
import { CloseRequestPayload } from "appworks/model/gameplay/requests/close-request-payload";
import { commsManager } from "appworks/server/comms-manager";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { State } from "appworks/state-machine/states/state";
import { uiFlags, UIFlag } from "appworks/ui/flags/ui-flags";
import { fadeOut } from "appworks/utils/animation/fade";
import { scaleIn } from "appworks/utils/animation/scale";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { logger } from "appworks/utils/logger";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { GMRAlertComponent } from "gaming-realms/components/gmr-alert-component";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { SlingoFooterComponent } from "slingo/components/slingo-footer-component";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { SlingoHighlightType, SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { slingoModel } from "slingo/model/slingo-model";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";
import { GetPotentialSlingoWins, SlingoPotentialWin } from "slingo/util/slingo-get-potential-wins";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { JurisdictionService } from "slotworks/services/jurisdiction/jurisdiction-service";

export class SlingoPurchaseSpinOrCollectState extends State {
    protected layer: Layers;

    protected potentialWins: SlingoPotentialWin[];
    protected potentialWinInterval: number;

    public onEnter(cascadeSkip?: boolean): void {
        this.layer = Layers.get("Collect");

        uiFlags.set(UIFlag.SPINNING, false);

        const gameplay = gameState.getCurrentGame();
        Services.get(TransactionService).setTotalWin(gameplay.getTotalWin());

        const rgLimit = this.checkResponsibleGamingLimits();

        if (rgLimit) {
            logger.log("RESPONSIBLE GAMING LIMIT HIT: " + rgLimit);

            if (rgLimit === "ExtraSpins") {
                this.collect(); // If no more spins just end with no prompt
            } else {
                Components.get(GMRAlertComponent).info(rgLimit, "ACCOUNT_LIMIT_EXCEEDED_TITLE").then(() => this.collect());
            }
        } else {
            this.layer.setScene("collect").then(() => {
                const collectButton = this.layer.getButton("collect");
                const purchaseButton = this.layer.getButton("purchase");

                collectButton.on(ButtonEvent.CLICK, () => this.collect());
                purchaseButton.on(ButtonEvent.CLICK, () => {
                    Services.get(SoundService).customEvent(SlingoSoundEvent.purchase_spin);
                    this.showSpinPriceWarning().then((result: boolean) => {
                        if (result) { this.purchaseSpin(); }
                    });
                });

                const translations = Services.get(TranslationsService);
                const currency = Services.get(CurrencyService);

                const gameplay = gameState.getCurrentGame();
                const totalWin = gameplay.getTotalWin();
                const gameProgressResult = gameplay.getCurrentRecord().getFirstResultOfType(SlingoGameProgressResult);
                collectButton.setLabelText(
                    translations.get(totalWin > 0 ? "slingo_collect" : "end_game", {
                        value: currency.format(totalWin)
                    })
                );
                purchaseButton.setLabelText(
                    translations.get("slingo_buy_spin", {
                        value: currency.format(gameProgressResult.nextPurchaseStake)
                    })
                );
                this.checkPurchaseButtonEnabled();

                const rgSpinLimit = slingoModel.read().responsibleGaming.extraSpins.currentValue;
                Components.get(SlingoSpinsCounterComponent).setValue(
                    (rgSpinLimit ?? gameProgressResult.purchaseSpins) - gameProgressResult.purchaseSpinsUsed
                ).execute();

                const minWinLevel = this.getMinWinLevel();

                this.potentialWins = GetPotentialSlingoWins(
                    (gameplay.getCurrentRecord() as SlingoRecord).ticketGrid,
                    gameProgressResult.matchedNumbers,
                    minWinLevel
                );
                if (this.potentialWins?.length > 0 && GamingRealms.wrapperConfig.getOperatorConfig().isYouCouldWinEnabled()) {
                    this.startPotentialWinCycle();
                } else {
                    this.hideElementsIfNoPotentialWins();
                }
            });
        }
    }

    public complete(): void {
        const gameplay = gameState.getCurrentGame();
        gameplay.setToLatestRecord();

        Timer.clearInterval(this.potentialWinInterval);
        this.potentialWins = null;
        Components.get(SlingoTicketMatrixComponent).clearHighlights().execute();
        Components.get(SlingoLadderComponent).clearHighlights().execute();

        super.complete();
    }

    protected getMinWinLevel() {
        const ladderWins = slingoModel.read().payoutConfig.patternPayouts;
        return ladderWins.findIndex(win => win > 0);
    }

    protected startPotentialWinCycle(intervalTime: number = 6000) {
        const nextWin = () => {
            const win = this.potentialWins.pop();
            this.showPotentialWin(win);
            this.potentialWins.unshift(win);
        }

        this.potentialWinInterval = Timer.setInterval(nextWin, intervalTime);
        nextWin();
    }

    protected hideElementsIfNoPotentialWins() {
        this.layer.getSprite("potential_win_frame").visible = false;
    }

    protected showPotentialWin(win: SlingoPotentialWin) {
        const winValue = this.getPotentialWinValueForLadderLevel(win.lines);
        const winFormatted = Services.get(CurrencyService).format(winValue);

        const ticketMatrix = Components.get(SlingoTicketMatrixComponent);
        const ladder = Components.get(SlingoLadderComponent);

        Components.get(SlingoFooterComponent)?.showPotentialWin(win.numbers, winFormatted);

        new Parallel([
            () => ticketMatrix.clearHighlights(),
            () => ladder.clearHighlights()
        ]).then(() => {
            win.numbers.forEach(num => {
                ticketMatrix.highlightSymbol(num, SlingoHighlightType.MATCHED).execute()
            });
            ladder.highlightLevel(win.lines);

            this.layer.getText("potential_win_body").text = Services.get(TranslationsService).get("you_could_win") + " " + winFormatted;

            this.animatePotentialWinDisplay().execute();
        });
    }

    /**
     * This is set up for cumulative ladder payouts like in Lucky Joker, which it seems might not be the norm
     * Probably worth adding a flag to switch between this logic, and usual slingo logic which I think just gives one payout at the end
     */
    protected getPotentialWinValueForLadderLevel(level: number): number {
        const stake = Services.get(SlotBetService).getTotalStake() / 100;
        const ladderPayouts = slingoModel.read().payoutConfig.patternPayouts.map((val) => val *= stake);

        const currentTotalWin = gameState.getCurrentGame().getTotalWin();
        const currentLadderWin = ladderPayouts[Components.get(SlingoLadderComponent).getCurrentLevel() - 1] || 0;
        const potentialLadderWin = ladderPayouts[level - 1];

        return potentialLadderWin + currentTotalWin - currentLadderWin;
    }

    protected animatePotentialWinDisplay(scaleEasing = Easing.Back.Out): Contract {
        const elements = this.getPotentialWinDisplayElements();

        elements.forEach(el => {
            el.landscape.scale.set(0);
            el.portrait.scale.set(0);
            el.alpha = 1;
        });

        Services.get(SoundService).customEvent(SlingoSoundEvent.attract_popup);

        return new Sequence([
            () => scaleIn(elements, 500, scaleEasing),
            () => Contract.getTimeoutContract(2000),
            () => fadeOut(elements, 250)
        ]);
    }

    protected getPotentialWinDisplayElements(): PIXIElement[] {
        return [
            CenterPivot(this.layer.getSprite("potential_win_frame")),
            CenterPivot(this.layer.getText("potential_win_body"))
        ];
    }

    protected showSpinPriceWarning(): Contract<boolean> {
        const gameProgress = gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult);
        const initialStake = Services.get(SlotBetService).getTotalStake();
        const isGB = GamingRealms.getLocale().toLowerCase().includes("gb");

        let warningLimit: number;

        const atlasValue = GamingRealms.wrapperConfig.getOperatorConfig().getPurchaseSpinConfirmValue();
        if (!isNaN(atlasValue)) { // If nothing set in atlas, getPurchaseSpinConfirmValue will return NaN
            warningLimit = atlasValue * initialStake;
        } else if (isGB) {
            // If wrapper gives no value we need to fall back to the old "10x if GB" logic
            warningLimit = initialStake * 10;
        }

        if (warningLimit !== undefined && gameProgress.nextPurchaseStake > warningLimit) {
            return Components.get(GMRAlertComponent).spinPriceConfirm(gameProgress.nextPurchaseStake);
        } else {
            return new Contract<boolean>((resolve) => { resolve(true); });
        }
    }

    protected purchaseSpin() {
        new Sequence([
            () => this.disableButtons(),
            () => this.layer.defaultScene()
        ]).then(() => this.complete());
    }

    protected collect() {
        new Sequence([
            () => this.disableButtons(),
            () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, true)),
            () => commsManager.request(new CloseRequestPayload()),
            () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, false)),
            () => this.layer.defaultScene()
        ]).then(() => this.complete());
    }

    protected disableButtons() {
        const collectBtn = this.layer.getButton("collect");
        const purchaseBtn = this.layer.getButton("purchase");

        if (collectBtn) { collectBtn.setEnabled(false); }
        if (purchaseBtn) { purchaseBtn.setEnabled(false); }

        return Contract.empty();
    }

    protected checkResponsibleGamingLimits(): string {
        const model = slingoModel.read().responsibleGaming;
        const gameProgress = gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult);

        const nextSpinPrice = gameProgress.nextPurchaseStake;
        const initialStake = Services.get(SlotBetService).getTotalStake();

        // Extra Spins
        if (model.extraSpins.currentValue !== undefined) {
            if (gameProgress.purchaseSpinsUsed >= model.extraSpins.currentValue) {
                return "ExtraSpins";
            }
        }

        // Max Spin Price
        if (model.maxSpinPrice.currentValue !== undefined) {
            const maxPrice = Services.get(SlotBetService).getTotalStake() * model.maxSpinPrice.currentValue;
            if (nextSpinPrice > maxPrice) {
                return "PURCHASE_STAKE_EXCEEDED";
            }
        }

        // Total Stake
        if (model.maxTotalStake.currentValue !== undefined) {
            const totalStakeSoFar = gameState.getCurrentGame().getTotalWagered();
            const stakeLimit = initialStake * model.maxTotalStake.currentValue;
            if (totalStakeSoFar + nextSpinPrice > stakeLimit) {
                return "TOTAL_STAKE_EXCEEDED";
            }
        }

        // Total Loss
        if (model.maxTotalLoss.currentValue !== undefined) {
            const winLoss = gameState.getCurrentGame().getTotalWinLoss();
            const loss = Math.min(winLoss, 0) * -1; // get loss as a positive value
            const winLossAfterSpin = loss + nextSpinPrice;
            const lossLimit = initialStake * model.maxTotalLoss.currentValue;

            if (winLossAfterSpin > lossLimit) {
                return "MAXIMUM_LOSS_EXCEEDED";
            }
        }

        return null;
    }

    protected checkPurchaseButtonEnabled() {
        const purchaseButton = this.layer.getButton("purchase");

        const spinTime = Timer.time - slingoModel.read().lastStakeTime;
        if (spinTime < Services.get(JurisdictionService).getMinimumSpinTime()) {
            purchaseButton?.setEnabled(false);
            Timer.setTimeout(() => this.checkPurchaseButtonEnabled(), 100);
            return;
        }

        purchaseButton?.setEnabled(true);
    }
}