import { Components } from "appworks/components/components";
import { Contract } from "appworks/utils/contracts/contract";
import { GMRAlertComponent } from "gaming-realms/components/gmr-alert-component";
import { SlingoCompletionReason } from "slingo/integration/slingo-schema";

export function showAutoCompletedPrompt(reason: SlingoCompletionReason): Contract {
    let msg: string = null;

    switch (reason) {
        case SlingoCompletionReason.TICKET_MATCHED: // don't show anything
        case null:
            break;

        case SlingoCompletionReason.MAXIMUM_STAKE_LIMIT:
            msg = "PURCHASE_STAKE_EXCEEDED";
            break;

        case SlingoCompletionReason.MAXIMUM_TOTAL_STAKE_LIMIT:
            msg = "TOTAL_STAKE_EXCEEDED";
            break;

        default:
            msg = "SPENDING_BUDGET_EXCEEDED";
            break;
    }

    if (msg) {
        return Components.get(GMRAlertComponent).info(msg, "ACCOUNT_LIMIT_EXCEEDED_TITLE");
    } else {
        return Contract.empty();
    }
}