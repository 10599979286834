import { Layers } from "appworks/graphics/layers/layers";
import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { UIFlagState } from "appworks/ui/flags/ui-flag-state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { UI, UIScene } from "appworks/ui/ui";
import Logger = require("js-logger");

export class UILayer {

    private started: boolean;

    private currentScene: UIScene;

    private activeControls: AbstractControl[];

    constructor(
        public readonly layer: Layers,
        public readonly uiScenes: UIScene[]
    ) {
        uiScenes.forEach((scene) => {
            if (scene.scene === "default") {
                throw new Error("UI Layer cannot use default scene, this scene is reserved for when no scenes match the current flag state");
            }

            if (!scene.show) {
                scene.show = UIFlagState.NEVER;
            }
            if (!scene.enable) {
                scene.enable = scene.show;
            }
        });

        uiFlags.onFlagsUpdated.add(() => this.onFlagsUpdated());
        this.layer.onSceneEnter.add((scene: string) => this.onSceneEnter(scene));
    }

    public start() {
        this.started = true;
        this.updateScene();
    }

    public debug() {
        const allFlags = Object.keys(UIFlag).filter((key) => isNaN(Number(key)));

        const results: any[] = [];

        this.uiScenes.forEach((scene) => {
            const row: any = { scene: scene.scene };
            results.push(row);
            allFlags.forEach((key, index) => {
                const flag = Number(UIFlag[key as any]);
                if ((scene.show.mustMatch & flag) === flag) {
                    row[key] = 1;
                } else if ((scene.show.mustNotMatch & flag) === flag) {
                    row[key] = 0;
                } else {
                    row[key] = "*";
                }
            });
        });

        // This can be changed to "console.table" for a nice output log
        Logger.info(results);
    }

    private onSceneEnter(scene: string) {
        if (this.started) {
            const currentScene = this.currentScene?.scene;
            const correctScene = currentScene || "default";
            const hasActiveControls = this.activeControls && this.activeControls.length > 0;

            if (scene !== correctScene) {
                throw new Error(`Manual scene change on UI layer was undone (${this.layer.id}: ${scene})`);
            } else if (scene === currentScene && !hasActiveControls) {
                this.scanForControls();
                this.setControlsEnabled();
            }
        }
    }

    private onFlagsUpdated() {
        if (this.started) {
            this.updateScene();
            this.setControlsEnabled();
        }
    }

    private updateScene() {
        for (const uiScene of this.uiScenes) {
            // Autoshow or menu show
            if (uiScene.show.test()) {
                if (this.currentScene !== uiScene) {
                    this.destroyCurrentScene();
                    this.currentScene = uiScene;
                    this.layer.setScene(uiScene.scene).execute();
                }
                return;
            }
        }

        this.destroyCurrentScene();
        this.layer.defaultScene().execute();
    }

    private scanForControls() {
        UI.factories.forEach((factory) => {
            if (factory.filter(this.layer)) {
                this.activeControls.push(...factory.build(this.layer));
            }
        });
    }

    private setControlsEnabled() {
        if (this.currentScene) {
            const enabled = this.currentScene.enable.test();

            this.activeControls.forEach((control) => {
                control.setEnabled(enabled);
            });
        }
    }

    private destroyCurrentScene() {
        if (this.currentScene) {
            this.activeControls.forEach((control) => {
                control.destroy();
            });
        }

        this.activeControls = [];
        this.currentScene = null;
    }
}
