import { Record } from "appworks/model/gameplay/records/record";
import { ReelModifierResult } from "./results/reel-modifier-result";

export class SpinRecord extends Record {

    /** ID for debugging purposes */
    public id: string = "SpinRecord";

    public reelsetId: string;
    public reelset: string[][];

    /**
     * 2D array of reels, containing symbiols
     * e.g [reel, reel, reel, reel, reel]
     */
    public grid: string[][];

    /**
     * Optional. Grid of symbols after all modifiers have been applied
     */
    public finalGrid: string[][];

    /**
     * Persistent grid which includes anything that "stuck" from last spin, or added to the reels before the spin stops
     */
    public overlayGrid: string[][];

    public stops: number[];

    /**
     * Returns grid with any ReelModifierResults applied overtop
     */
    public getFinalGrid() {

        const finalGrid = this.grid.map((reel) => reel.map((symbol) => symbol));

        this.getResultsOfType(ReelModifierResult).forEach((reelModifierResult) => {
            reelModifierResult.expansions.forEach((expansion) => {
                finalGrid[expansion.x][expansion.y] = reelModifierResult.targetSymbolID;
            });
        });

        return finalGrid;
    }
}
