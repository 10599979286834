import { Result } from "appworks/model/gameplay/records/results/result";
import { WaysResult } from "slotworks/model/gameplay/records/results/ways-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { symbolWinResultDisplay } from "./scatter-result-display";

export function waysResultDisplay(record: SpinRecord, result: Result) {
    const scatterResult = symbolWinResultDisplay(record, result);

    scatterResult.innerHTML = "Ways " + (result as WaysResult).ways + "<br>" + scatterResult.innerHTML;

    return scatterResult;
}
