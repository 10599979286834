
import { Layers } from "appworks/graphics/layers/layers";
import { Contract } from "appworks/utils/contracts/contract";
import { Signal } from "signals";
import { ReelSubcomponent } from "slotworks/components/matrix/reel/reel-subcomponent";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { AbstractMatrixComponent } from "../../abstract-matrix-component";
export abstract class AbstractReelTransition {

    // Dispatched when reel begins to land
    public onReelLand: Signal = new Signal();
    // Dispatched once when anticipation first begins
    public onAnticipationStart: Signal = new Signal();
    // Dispatched for each reel as it begins to anticipate
    public onAnticipationReel: Signal = new Signal();
    // Dispatched once when anticipation ends for good
    public onAnticipationEnd: Signal = new Signal();
    // Dispatched once reel has updated
    public onReelUpdate: Signal = new Signal();
    // Dispatched when reel has stopped moving completely
    public onReelComplete: Signal = new Signal();

    public animationLayer: Layers;

    public matrix: AbstractMatrixComponent;
    public reels: ReelSubcomponent[];

    public matrixLayer: Layers;

    public defaultReelset: string[][];

    public init(matrix: AbstractMatrixComponent, reels: ReelSubcomponent[], stops: number[], reelset: string[][]) {
        this.matrix = matrix;
        this.reels = reels;

        this.matrixLayer = this.reels[0].getMatrixLayer();
        this.animationLayer = this.reels[0].getAnimationLayer();
        this.defaultReelset = [];
        reelset.forEach((reel: string[]) => {
            this.defaultReelset.push([...reel]);
        });
    }

    public start(reelset?: string[][], jumpStart: boolean = false, quickSpin: boolean = false): Contract<void> {
        return new Contract<void>((resolve) => {
            resolve(null);
        });
    }

    public stop(spinRecord: SpinRecord, quickSpin: boolean = false): Contract<void> {
        return new Contract<void>((resolve) => {
            resolve(null);
        });
    }

    public skip(spinRecord: SpinRecord): void {
        //
    }

    public canSkip() {
        return true;
    }

    public hold(reelIndexes?: number[]) {
        // Hold specified reels
    }

    public jump(stops: number[], reelset: string[][]) {
        // jump to stops
    }

    public update() {
        // Update tick for transition
    }

    public postUpdate() {
        // Update tick for transition, after matrix has redrawn
        this.onReelUpdate.dispatch();
    }

    public setCustomReelset(reelset: string[][]) {
        // Set a custom reelset for future use
    }

    public resetReelsetToDefault() {
        // Reset the reelset to the default value
    }

    public destroy() {
        // .. cleanup
    }
}
