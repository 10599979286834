import { Layers } from "appworks/graphics/layers/layers";
import { SoundEffectsToggleControl } from "appworks/ui/controls/standard/toggle/sound-effects-toggle-control";
import { ControlFactory } from "appworks/ui/factories/control-factory";

export class SoundEffectsToggleControlFactory implements ControlFactory {
    public filter(layer: Layers) {
        if (layer.getFlexiToggle("sound_effects")) {
            return true;
        }

        return false;
    }

    public build(layer: Layers) {
        return [new SoundEffectsToggleControl(layer.getFlexiToggle("sound_effects"))];
    }
}
