import { Record } from "appworks/model/gameplay/records/record";
import { Result } from "appworks/model/gameplay/records/results/result";
import { TrailResult } from "slotworks/model/gameplay/records/results/trail-result";

export function trailResultDisplay(record: Record, result: Result) {
    const container = document.createElement("div");

    const trail = (result as TrailResult);

    container.innerHTML = `Trail ${trail.id}:  ${trail.currentLevel} / ${trail.targetLevel}`;

    return container;
}
