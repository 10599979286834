import { Orientation } from "appworks/graphics/canvas/orientation";
import { Layers } from "appworks/graphics/layers/layers";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { ParticleContainer } from "appworks/graphics/pixi/particle-container";
import { Position } from "appworks/graphics/pixi/position";
import { Services } from "appworks/services/services";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Point } from "appworks/utils/geom/point";
import { Timer } from "appworks/utils/timer";
import { Easing, Interpolation, Tween } from "appworks/utils/tween";
import * as particles from "pixi-particles";
import { Signal } from "signals";
import { ParticleService } from "./particle-service";

export interface TweenConfig {
    duration: number;
    // emitterShutdownDelay can be useful if you want to emit some particles at the destination before stopping emission.
    emitterShutdownDelay: number;
    interpolation: (v: number[], k: number) => number;
    easing: (k: number) => number;
}

/**
 * Will create and tween emitters along a sequence of path points.
 */
export class TrailEmitter {

    public onParticlesGone: Signal;

    protected emitterNames: string[];
    protected path: DualPosition[];
    protected resolveOnComplete: boolean = true;

    protected emitters: Map<string, {
        landscape: particles.Emitter
        portrait: particles.Emitter
    }> = new Map();

    protected tweenConfig = {
        duration: 1000,
        emitterShutdownDelay: 0,
        interpolation: Interpolation.Bezier,
        easing: Easing.Quadratic.In
    };

    /**
     * @param layer Path points should exist on this layer. The emitters will be added to this layer also.
     * @param emitterNames Will create and animate multiple emitters if an array is passed.
     * @param tweenConfig
     */
    public constructor(protected layer: Layers, emitterNames: string | string[], tweenConfig: Partial<TweenConfig>) {
        this.emitterNames = Array.isArray(emitterNames) ? emitterNames : [emitterNames];

        this.emitterNames.forEach((emitterName) => {
            this.emitters.set(emitterName, { landscape: null, portrait: null });
        });

        // We initialise emitters up front so you can access them outside the class and mess with their properties.
        this.initaliseEmitters();

        this.onParticlesGone = new Signal();
        this.tweenConfig = { ...this.tweenConfig, ...tweenConfig };
    }

    /**
     * The contract will resolve as soon as the path tween is complete.
     *
     * The signal onParticlesGone will dispatch after contract resolution, once particle lifetime has been exceeded.
     */
    public play(path?: DualPosition[]): Contract<void> {
        if (path) { this.setPath(path); }

        const orientations = [Orientation.LANDSCAPE, Orientation.PORTRAIT];

        return new Parallel(orientations.map((orientation) => {
            return () => new Parallel(this.emitterNames.map((emitterName) => {
                return () => this.animateEmitter(emitterName, orientation);
            }));
        }));
    }

    /** 
     * Used for when the particle effect needs to be set to resolve or not resolve based on timings.
     * For example: when used in a parallel or other contract that resolves before the tween finishes.
     */
    public setResolveOnComplete(shouldResolve: boolean): void {
        this.resolveOnComplete = shouldResolve;
    }

    public getPath() { return this.path; }
    public setPath(path: DualPosition[]) { this.path = path; }

    public setPathFromPositions(layer: Layers, prefix: string): void {
        const trailPoints: DualPosition[] = [];

        let index = 0;
        while (true) {
            const position: DualPosition = layer.getPosition(`${prefix}${index}`);
            if (position == null) {
                break;
            }
            trailPoints.push(position);
            index++;
        }

        this.path = trailPoints;;
    }

    public getEmitters(name: string) {
        const emitters = this.emitters.get(name);
        return [emitters.landscape, emitters.portrait];
    }

    protected initaliseEmitters() {
        const orientations = [Orientation.LANDSCAPE, Orientation.PORTRAIT];
        orientations.forEach((orientation) => {
            this.emitterNames.forEach((emitterName) => {
                const particleContainer = this.createParticleContainer(orientation);
                const particle = Services.get(ParticleService).add(emitterName, particleContainer);
                const emitter = Services.get(ParticleService).get(particle.id);

                emitter.emit = false;

                this.emitters.get(emitterName)[orientation] = particle.emitter;
            });
        });
    }

    protected animateEmitter(emitterName: string, orientation: Orientation) {
        if (!this.path) {
            return Contract.empty();
        }

        return new Contract((resolve) => {
            // We mutate this for each orientation so we have to make a copy.
            const path = [...this.path];
            const startPosition = path.shift();

            const emitter = this.emitters.get(emitterName)[orientation];
            emitter.updateSpawnPos(startPosition[orientation].x, startPosition[orientation].y);
            emitter.resetPositionTracking();
            emitter.emit = true;

            const emitterPosition = new Point();
            emitterPosition.x = startPosition[orientation].x;
            emitterPosition.y = startPosition[orientation].y;

            const xPositions = path.map((point) => point[orientation].x);
            const yPositions = path.map((point) => point[orientation].y);

            const tween = new Tween(emitterPosition);
            tween.to({ x: xPositions, y: yPositions }, this.tweenConfig.duration);
            tween.interpolation(this.tweenConfig.interpolation);
            tween.easing(this.tweenConfig.easing);

            tween.onUpdate(() => {
                emitter.updateSpawnPos(emitterPosition.x, emitterPosition.y);
            });

            tween.onComplete(() => {
                this.shutdownEmitter(emitter);
                if (this.resolveOnComplete) {
                    resolve();
                }
            });

            tween.start();
        });
    }

    protected createParticleContainer(orientation: Orientation) {
        const particleContainer = new ParticleContainer();
        this.layer.add(particleContainer);

        const inactiveOrientation = orientation === Orientation.LANDSCAPE ? Orientation.PORTRAIT : Orientation.LANDSCAPE;
        particleContainer.dualPosition[inactiveOrientation] = Position.unavailable();

        return particleContainer;
    }

    protected shutdownEmitter(emitter: particles.Emitter) {
        // Pause at the end of the tween for a more satisfying animation ending.
        Timer.setTimeout(() => {
            emitter.emit = false;

            // Wait for particles to die.
            Timer.setTimeout(() => {
                emitter.parent.destroy();
                emitter.destroy();
                this.onParticlesGone.dispatch();
            }, emitter.maxLifetime * 1000);

        }, this.tweenConfig.emitterShutdownDelay);
    }
}
