import { Tween } from "appworks/utils/tween";
import { Timer } from "../timer";
import { Contract, Executor } from "./contract";
import { Parallel } from "./parallel";
import { Sequence } from "./sequence";
import { TweenContract } from "./tween-contract";

export type Cancellable = Contract<any> | Tween | number;

export class CancelGroup {

    private group: Cancellable[] = [];

    public parallel<T = void>(methods: Array<() => Contract<T>>): Parallel<T> {
        return this.add(new Parallel(methods));
    }

    public sequence<T= void>(methods: Array<(...args) => Contract<T>>): Sequence<T> {
        return this.add(new Sequence(methods));
    }

    public contract<T= void>(method: Executor<T>, cancelAfterResolve: boolean = false): Contract<T> {
        return this.add(new Contract<T>(method, cancelAfterResolve));
    }

    public tweenContract<T= void>(tween: Tween): Contract<T> {
        this.add(tween);
        return this.add(TweenContract.wrapTween(tween));
    }

    public tweenCancellableContract<T= void>(tween: Tween): Contract<T> {
        this.add(tween);
        return this.add(TweenContract.wrapCancellableTween<T>(tween));
    }

    public timeout<T= void>(callback: () => T, duration: number): number {
        return this.add(Timer.setTimeout(callback, duration));
    }

    public timeoutContract<T = void>(duration: number, contractCallback?: () => Contract<T>): Contract<T> {
        if (contractCallback) {
            return this.sequence([
                () => this.timeoutContract(duration),
                contractCallback
            ]);
        } else {
            return this.add(Contract.getTimeoutContract(duration));
        }
    }

    public interval<T= void>(callback: () => T, duration: number): number {
        return this.add(Timer.setInterval(callback, duration));
    }

    public tween(object: any): Tween {
        return this.add(new Tween(object)) as Tween;
    }

    public add<T extends Cancellable>(item: T): T {
        this.group.push(item);
        return item;
    }

    /**
     * Cancel all in group
     * @returns {boolean} true if anything was cancelled
     */
    public cancel(): boolean {
        return this.handleCancel(false);
    }

    /**
     * Cancel all in group, forcing contracts to resolve
     * @returns {boolean} true if anything was cancelled
     */
    public skip(): boolean {
        return this.handleCancel(true);
    }

    public clear(): void {
        this.group = [];
    }

    protected handleCancel(skip: boolean) {
        const cancelled: boolean = this.group.length > 0;
        while (this.group.length) {
            const cancellable: Cancellable = this.group.shift();
            if (cancellable instanceof Tween) {
                cancellable.stop();
            } else if (cancellable instanceof Contract) {
                if (skip) {
                    cancellable.forceResolve();
                }
                cancellable.cancel();
            } else if (typeof cancellable === "number") {
                Timer.clearInterval(cancellable);
                Timer.clearTimeout(cancellable);
            }
        }
        this.clear();
        return cancelled;
    }
}
