export enum SoundEvent {
    start_game = "start_game",
    default_button_press = "default_button_press",
    button_press_NAME = "button_press_{0}",
    default_button_up = "default_button_up",
    button_up_NAME = "button_up_{0}",
    default_button_over = "default_button_over",
    button_over_NAME = "button_over_{0}",
    start_freespin = "start_freespin",
    start_spin = "start_spin",
    end_freespin = "end_freespin",
    spin_error = "spin_error",
    end_spin = "end_spin",
    big_win_linear_countup_start = "big_win_linear_countup_start",
    big_win_linear_countup_end = "big_win_linear_countup_end",
    big_win_nonlinear_countup_start = "big_win_nonlinear_countup_start",
    big_win_nonlinear_countup_end = "big_win_nonlinear_countup_end",
    big_win_countup_start = "big_win_countup_start",
    big_win_countup_end = "big_win_countup_end",
    big_win_tier_id = "big_win_tier_{0}",
    celebration_end = "celebration_end",
    celebration_skip = "celebration_skip",
    totalwin_start = "totalwin_start",
    wayswin_start = "wayswin_start",
    tickup_totalwin_start = "tickup_totalwin_start",
    tickup_totalwin_end = "tickup_totalwin_end",
    tickup_linewin_start = "tickup_linewin_start",
    tickup_anywin_start = "tickup_anywin_start",
    tickup_anywin_end = "tickup_anywin_end",
    tickup_win_range_start = "tickup_win_range_start_{0}",
    tickup_win_range_end = "tickup_win_range_end_{0}",
    reel_transition_start = "reel_transition_start",
    reel_transition_start_normal = "reel_transition_start_normal",
    reel_transition_start_quick = "reel_transition_start_quick",
    reel_transition_start_turbo = "reel_transition_start_turbo",
    reel_transition_end = "reel_transition_end",
    reel_transition_skip = "reel_transition_skip",
    reel_transition_skip_end = "reel_transition_skip_end",
    sounds_ready = "sounds_ready",
    sounds_unlocked = "sounds_unlocked",
    symbol_ID_highlight = "symbol_{0}_highlight",
    symbol_ID_MATCHES_highlight = "symbol_{0}_{1}_highlight",
    symbol_any_MATCHES_highlight = "symbol_any_{0}_highlight",
    symbol_ID_win = "symbol_{0}_win",
    symbol_any_win = "symbol_any_win",
    symbol_ID_win_CYCLE = "symbol_{0}_win_{1}",
    symbol_any_win_CYCLE = "symbol_any_win_{0}",
    symbol_ID_MATCHES_win = "symbol_{0}_{1}_win",
    symbol_any_MATCHES_win = "symbol_any_{0}_win",
    symbol_ID_win_MATCHES_CYCLE = "symbol_{0}_win_{1}_{2}",
    symbol_any_win_MATCHES_CYCLE = "symbol_any_win_{0}_{1}",
    // Modulo the cycle, good for playing sounds every 2 or 3 loops
    symbol_ID_win_mod2_CYCLE = "symbol_{0}_win_mod2_{1}",
    symbol_any_win_mod2_CYCLE = "symbol_any_win_mod2_{0}",
    symbol_ID_win_MATCHES_mod2_CYCLE = "symbol_{0}_win_{1}_mod2_{2}",
    symbol_any_win_MATCHES_mod2_CYCLE = "symbol_any_win_{0}_mod2_{1}",
    symbol_ID_win_mod3_CYCLE = "symbol_{0}_win_mod3_{1}",
    symbol_any_win_mod3_CYCLE = "symbol_any_win_mod3_{0}",
    symbol_ID_win_MATCHES_mod3_CYCLE = "symbol_{0}_win_{1}_mod3_{2}",
    symbol_any_win_MATCHES_mod3_CYCLE = "symbol_any_win_{0}_mod3_{1}",
    //
    symbol_ID_celebrate = "symbol_{0}_celebrate",
    symbol_any_celebrate = "symbol_any_celebrate",
    symbol_ID_anticipate_land = "symbol_{0}_anticipate_land",
    symbol_ID_land = "symbol_{0}_land",
    symbol_any_land = "symbol_any_land",
    symbol_ID_land_COUNT = "symbol_{0}_land_{1}",
    symbol_ID_expand = "symbol_{0}_expand",
    symbol_any_expand = "symbol_any_expand",
    symbol_ID_expand_up = "symbol_{0}_expand_up",
    symbol_any_expand_up = "symbol_any_expand_up",
    symbol_ID_expand_down = "symbol_{0}_expand_down",
    symbol_any_expand_down = "symbol_any_expand_down",
    reel_N_spin_start = "reel_{0}_spin_start",
    reel_any_spin_start = "reel_any_spin_start",
    reel_N_spin_end = "reel_{0}_spin_end",
    reel_any_spin_end = "reel_any_spin_end",
    reel_N_skip_start = "reel_{0}_skip_start",
    reel_any_skip_start = "reel_any_skip_start",
    reel_N_skip_end = "reel_{0}_skip_end",
    reel_any_skip_end = "reel_any_skip_end",
    reel_count_N_anticipate_start = "reel_count_{0}_anticipate_start",
    reel_N_anticipate_start = "reel_{0}_anticipate_start",
    reel_any_anticipate_start = "reel_any_anticipate_start",
    anticipate_start = "anticipate_start",
    reel_N_anticipate_end = "reel_{0}_anticipate_end",
    reel_any_anticipate_end = "reel_any_anticipate_end",
    reel_N_anticipate_skip = "reel_{0}_anticipate_skip",
    reel_any_anticipate_skip = "reel_any_anticipate_skip",
    reel_N_spinner_stage_complete = "reel_{0}_spinner_stage_complete_{1}",
    reel_N_land = "reel_{0}_land",
    reel_any_land = "reel_any_land",
    reel_any_land_turbo = "reel_any_land_turbo ",
    reel_any_land_quick = "reel_any_land_quick",
    reel_any_land_normal = "reel_any_land_normal",
    spinner_stage_complete = "spinner_stage_complete_{0}",
    preload_complete = "preload_complete",
    prompt_countup_start = "prompt_countup_start",
    prompt_countup_start_SCENE = "prompt_countup_start_{0}",
    prompt_countup_end = "prompt_countup_end",
    prompt_countup_end_SCENE = "prompt_countup_end_{0}",
    sound_prompt_yes_press = "sound_prompt_yes_press",
    toggle_on_press = "toggle_on_press",
    toggle_off_press = "toggle_off_press",
    enter_scene_LAYER_SCENE = "enter_scene_{0}_{1}",
    exit_scene_LAYER_SCENE = "exit_scene_{0}_{1}",
    highlight_all_wins = "highlight_all_wins",
    bonus_ID_land = "bonus_{0}_land",
    bonus_ID_land_reel_INDEX = "bonus_{0}_land_reel_{1}",
    bonus_ID_land_COUNT = "bonus_{0}_land_{1}",
    bonus_SYMBOL_land = "bonus_{0}_land",
    bonus_SYMBOL_land_COUNT = "bonus_{0}_land_{1}",
    bonus_ID_SYMBOL_land = "bonus_{0}_{1}_land",
    bonus_ID_SYMBOL_land_COUNT = "bonus_{0}_{1}_land_{2}",
    skip_expands = "skip_expands",
    stick_symbol = "stick_symbol",
    stick_symbol_ID = "stick_symbol_{0}",
    prompt_continue_press = "prompt_continue_press",
    winnings_countup = "winnings_countup",
    winnings_countup_end = "winnings_countup_end",
    game_inactive = "game_inactive",
    win_line_N = "win_line_{0}",
    spine_NAME = "spine_{0}",
    win_line_show = "win_line_show",
    win_line_hide = "win_line_hide",
    lock_and_spin_reset = "lock_and_spin_reset",
    lock_and_spin_0_land = "lock_and_spin_0_land",
    lock_and_spin_land = "lock_and_spin_land",
    lock_and_spin_VALUE_land = "lock_and_spin_{0}_land",
    lock_and_spin_spin_start = "lock_and_spin_spin_start",
    lock_and_spin_spin_end = "lock_and_spin_spin_end",
    lock_and_spin_N_spin_start = "lock_and_spin_{0}_spin_start",
    lock_and_spin_N_spin_end = "lock_and_spin_{0}_spin_end",
    enter_basegame = "enter_basegame",
    enter_freespins = "enter_freespins",
    N_of_a_kind = "{0}_of_a_kind",
    stake_radio_select_lower = "stake_radio_select_lower",
    stake_radio_select_higher = "stake_radio_select_higher"
}
