import { FlexiToggle, ToggleEvent } from "appworks/graphics/elements/flexi-toggle";
import { AbstractControl } from "../../abstract-control";

export class ToggleControl extends AbstractControl {
    constructor(protected button: FlexiToggle) {
        super();

        button.on(ToggleEvent.CHANGE, this.onChangeHandler);
    }

    public destroy(): void {
        this.button.off(ToggleEvent.CHANGE, this.onChangeHandler);
    }

    public setEnabled(enabled: boolean) {

        this.button.setEnabled(enabled);

        super.setEnabled(enabled);
    }

    protected onChange(value: boolean): void {
        // Do nothing
    }

    protected onChangeHandler = (value: boolean) => this.onChange(!value);
}
