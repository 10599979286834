import { gameState } from "appworks/model/game-state";
import { Record } from "appworks/model/gameplay/records/record";
import { RequestPayload } from "appworks/model/gameplay/requests/request-payload";
import { builderManager } from "appworks/server/builders/builder-manager";
import { Connector } from "appworks/server/connectors/connector";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import * as Logger from "js-logger";

// TODO: V6: Convert this to service?
class CommsManager {
    public connector: Connector;

    // TODO(steven): 6.x review
    public cacheRecordTree = false;
    protected recordTreeCache: Record[] = [];

    private delayMs: number = 0;

    // You can set pre-built records for a given request type here, and CommsManager will
    // drip feed them on request, instead of actually sending a server request
    // TODO - 6.x review how this works, should probably auto-queue somehow based on builders returning multiple records
    private queuedRecords = new Map<RequestPayload, Record[][]>();

    public request(request: any): Contract<void> {
        this.log("Outgoing: ", request);

        if (this.queuedRecords.get(request.constructor)?.length > 0) {
            return new Contract<any>((resolve) => {
                this.log("Queued record found for " + request.constructor.name + ", skipping request", "");
                this.response(request, null, resolve);
            });
        }

        if (this.cacheRecordTree && this.recordTreeCache.length > 0) {
            return new Contract((resolve) => this.response(request, null, resolve));
        }

        const gameplay = gameState.getCurrentGame();
        const builtRequest = builderManager.buildRequest(gameplay, request);

        return new Contract<void>((resolve) => {
            if (builtRequest) {
                this.connector.sendRequest(builtRequest)
                    .then((response) => {
                        this.response(request, response, resolve);
                    });
            } else {
                Logger.warn("Warning: request had no builder, so nothing was sent via connector");
                this.response(request, {}, resolve);
            }
        });
    }

    public requestRaw(request: any): Contract<void> {
        this.log("Outgoing raw: ", request);

        return new Contract<void>((resolve) => {
            this.connector.sendRequest(request).then((response) => {
                this.response(request, response, resolve);
            });
        });
    }

    public getDelay(): number {
        return this.delayMs;
    }

    public setDelay(delayMs: number) {
        this.delayMs = delayMs;
    }

    public queueRecords(requestType: RequestPayload, records: Record[][], clearExisting: boolean = true) {
        if (clearExisting) {
            this.clearQueuedRecords(requestType);
        }

        const queue = this.queuedRecords.get(requestType) ?? [];
        queue.push(...records);
        this.queuedRecords.set(requestType, queue);
    }

    public clearQueuedRecords(requestType: RequestPayload) {
        this.queuedRecords.delete(requestType);
    }

    private response(request: any, response: any, resolve: Function) {
        new Sequence([
            () => this.delayMs > 0 ? Contract.getTimeoutContract(this.delayMs) : Contract.empty(),
            () => Contract.wrap(() => {
                this.log("Incoming: ", response);

                const gameplay = gameState.getCurrentGame();

                let builtRecords: Record[];
                if (this.queuedRecords.get(request.constructor)?.length > 0) {
                    builtRecords = this.queuedRecords.get(request.constructor).shift();
                    this.log("Returning queued records", builtRecords);
                } else if (this.cacheRecordTree && this.recordTreeCache.length > 0) {
                    builtRecords = [this.recordTreeCache.shift()];
                } else {
                    builtRecords = builderManager.buildRecord(gameplay, request, response);

                    if (builtRecords.length > 0 && this.cacheRecordTree) {
                        this.recordTreeCache = builtRecords;
                        builtRecords = [this.recordTreeCache.shift()];
                    }
                }

                try {
                    if (builtRecords && builtRecords.length > 0) {
                        for (const builtRecord of builtRecords) {
                            gameplay.addRecord(builtRecord);
                        }
                    }
                    resolve(response);
                } catch (e) {
                    Logger.error(e);
                }
            })
        ]).execute();
    }

    /**
     * Log a message with colored label
     *
     * @param message {string}
     */
    private log(message: string, obj: any) {
        Logger.info("%c Comms Manager ", "background: #3f3; color: #666", message, obj);
    }
}

export const commsManager = new CommsManager();
