import { AnimationConfig } from "appworks/config/asset-config-schema";
import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { ScriptedAnimation, ScriptedAnimationFactory } from "appworks/graphics/animation/scripted-animation";
import { Service } from "appworks/services/service";
import { Services } from "appworks/services/services";
import { Texture } from "pixi.js";
import { GraphicsService } from "../graphics-service";
import { DualPosition } from "../pixi/dual-position";
import { Sprite } from "../pixi/sprite";

export class AnimationService extends Service {

    private animatedSprites: AnimatedSprite[] = [];

    private animationDefinitions: Map<string, AnimationConfig> = new Map<string, AnimationConfig>();

    private scriptedAnimationFactories: Map<string, ScriptedAnimationFactory> = new Map<string, ScriptedAnimationFactory>();
    private scriptedAnimations: ScriptedAnimation[] = [];

    public init(): void {
        // Do nothing. Handled in setup due to assetConfig requirements.
    }

    /**
     * Parses the animation config info pixi friendly animation animation configs and caches them for later use
     *
     * @param config {GraphicsConfigSchema}
     */
    public setup(config: AnimationConfig[]) {
        for (const animConfig of config) {
            this.animationDefinitions.set(animConfig.id, animConfig);
        }
    }

    public hasAnimation(id: string) {
        return this.animationDefinitions.has(id);
    }

    /**
     * Generates an animaiton, if the animation is not found it tries the next one in the list.
     * This continues until one is found. If none are found, null is returned.
     * List is read from 0 to end
     *
     * @param ids {string[]}
     */
    public generateAnimationWithFallbacks(ids: string[]): AnimatedSprite | null {
        ids = [...ids];

        while (ids.length) {
            try {
                const animation = this.generateAnimation(ids.shift());
                return animation;
            } catch (e) {
                continue;
            }
        }

        return null;
    }

    /**
     * Creates a new instance of AnimatedSprite which references textures as frames from the Pixi cache
     * @param id {string}
     */
    public generateAnimation(id: string): AnimatedSprite;

    /**
     * Creates a new instance of AnimatedSprite from multiple animaiton definitions. Config (fps, loop ect) is taken from first in the list
     * @param ids {string[]}
     */
    public generateAnimation(ids: string[]): AnimatedSprite;

    /**
     * Creates a new instance of AnimatedSprite which references textures as frames from the Pixi cache
     *
     * @param id {string}
     */
    public generateAnimation(ids: string | string[]): AnimatedSprite {
        if (typeof ids === "string") {
            ids = [ids];
        }

        let config: AnimationConfig;
        const frames: Texture[] = this.getFrames(ids);

        config = this.animationDefinitions.get(ids[0]);

        const sprite = new AnimatedSprite(frames);

        sprite.loop = config.loop;
        sprite.loopFrame = config.loopFrame || 0;
        sprite.animationSpeed = config.fps / 60;

        this.animatedSprites.push(sprite);

        return sprite;
    }

    /**
     * An array of sprites where each sprite is used as a frame in an animation
     */
    public generateSpriteFrameAnimation(frames: Sprite[]) {
        const animatedSprite = new AnimatedSprite([Texture.EMPTY], frames);
        this.animatedSprites.push(animatedSprite);

        return animatedSprite;
    }

    public aliasAnimation(alias: string, animation: string) {
        this.animationDefinitions.set(alias, this.animationDefinitions.get(animation));
    }

    public addScriptedAnimationFactory(id: string, anim: ScriptedAnimationFactory) {
        this.scriptedAnimationFactories.set(id, anim);
    }

    public hasScriptedAnimationFactory(id: string) {
        return this.scriptedAnimationFactories.has(id);
    }

    public createScriptedAnimation(id: string, position: DualPosition) {
        const anim = this.scriptedAnimationFactories.get(id).create(position);
        this.scriptedAnimations.push(anim);
        return anim;
    }

    public removeScriptedAnimation(anim: ScriptedAnimation) {
        const index = this.scriptedAnimations.indexOf(anim);
        this.scriptedAnimations.splice(index, 1)[0].destroy();
    }

    public getFrames(ids: string[]) {
        const frames: Texture[] = [];

        ids.forEach((id) => {
            const config = this.animationDefinitions.get(id);

            if (!config) {
                throw new Error("Animation not found " + id);
            }

            const frameNames: string[] = this.generateFrameNames(config.prefix, config.numFrames, config.suffix, config.zeroPad, config.reverse, config.repeat);

            for (const framename of frameNames) {
                frames.push(Services.get(GraphicsService).getTexture(framename));
            }
        });

        return frames;
    }

    public getConfig(id: string) {
        return this.animationDefinitions.get(id);
    }

    // Registers an animated sprite created outside of this service in order to recieve frame updates
    public registerCustomAnimatedSprite(animation: AnimatedSprite) {
        this.animatedSprites.push(animation);
    }

    public deregisterCustomAnimatedSprite(animation: AnimatedSprite) {
        const index = this.animatedSprites.indexOf(animation);
        if (index > -1) {
            this.animatedSprites.splice(index, 1);
        }
    }
    
    public update(time: number) {
        for (let i = this.animatedSprites.length - 1; i >= 0; i--) {
            const animatedSprite = this.animatedSprites[i];

            if (animatedSprite.destroyed) {
                this.animatedSprites.splice(i, 1);
            } else {
                animatedSprite.update(time);
            }
        }

        for (const scriptedAnimation of this.scriptedAnimations) {
            scriptedAnimation.update(time);
        }
    }

    /**
     * Really handy function for when you are creating arrays of animation data but it"s using frame names and not numbers.
     * For example imagine you've got 30 frames named: "explosion_0000-large" to "explosion_0029-large"
     * You could use this function to generate those by doing: generateFrameNames("explosion_", 30, "-large", 4);
     *
     * @param {string} prefix - The start of the filename. If the filename was "explosion_0000-large" the prefix would be "explosion_".
     * @param {number} numFrames - The number of frames in total in your animation
     * @param {string} [suffix=""] - The end of the filename. If the filename was "explosion_0000-large" the prefix would be "-large".
     * @param {number} [zeroPad=0] - The number of zeros to pad the min and max values with. If your frames are named "explosion_0000" to "explosion_0033" then the zeroPad is 4.
     *
     * @return {string[]} An array of framenames.
     */
    private generateFrameNames(prefix: string, numFrames: number, suffix: string = "", zeroPad: number = 0, reverse: boolean = false, repeat: number = 0): string[] {

        const start = 0;
        const stop = numFrames;

        // Without reverses or repeats applied
        const stdOutput: string[] = [];

        let finalOutput: string[] = [];
        let frame = "";

        for (let i = start; i < stop; i++) {
            if (zeroPad > 0) {
                frame = this.pad(i.toString(), "0", zeroPad);
            } else {
                frame = i.toString();
            }

            frame = prefix + frame + suffix;

            stdOutput.push(frame);
        }

        finalOutput = stdOutput.concat();

        if (reverse) {
            finalOutput.pop();
            finalOutput = finalOutput.concat(stdOutput.reverse());
            stdOutput.reverse();
        }

        for (let i = 0; i < repeat; i++) {
            finalOutput = finalOutput.concat(stdOutput);
        }

        return finalOutput;
    }

    /**
     * Pads out a string with a specified character
     * pad("12", "0", 3); // "012"
     * pad("1", "0", 4); // "0004"
     * pad("172", "0", 3); // "172"
     * @param str {string}
     * @param padChar {string}
     * @param desiredLength {number}
     */
    private pad(str: string, padChar: string, desiredLength: number): string {
        while (str.length < desiredLength) {
            str = padChar + str;
        }

        return str;
    }
}
