import { Result } from "appworks/model/gameplay/records/results/result";
import { Point } from "appworks/utils/geom/point";
import { slotDefinition } from "slotworks/model/slot-definition";
import { SpinRecord } from "../spin-record";

export class SymbolWinResult extends Result {

    /** For debugging purposes */
    public resultType: string = "SymbolWinResult";

    /** SymbolId */
    public symbolType: string;

    public symbols: string[] = [];

    public matches: number;

    public positions: Point[];

    // Optional array of multipliers applied to each symbol, which can be used for various games types
    // e.g Games with multiplier wilds, split symbols
    // This array has no influence on anything else in the object (matches, cashWon etc)
    public multipliers: number[];

    // TODO: V6 potentially move these convenience methods to supplements
    /**
     * If a server doesn't tell you which symbols make up the win, use this to take the grid symbols from this wins positions array
     */
    public calculateSymbolsFromGrid(record: SpinRecord) {
        this.symbols = [];
        this.positions.forEach((position: Point) => {
            this.symbols.push(record.grid[position.x][position.y]);
        });
    }

    /**
     * If a server doesn't tell you which is the main symbol for this win, use this to take the symbols array from this win and find the symbol
     * which is probably the payout (prioritising normal symbols above wilds)
     */
    public calculateSymbolTypeFromSymbols() {
        this.symbolType = "";

        this.symbols.forEach((symbol) => {
            const isWild = slotDefinition.getSymbolDefinition(symbol).wild;
            this.symbolType = isWild ? (this.symbolType === "" ? symbol : this.symbolType) : symbol;
        });
    }

    /**
     * If a server doesn't give you the positions, but you know all instances of a specific symbol are part of the win, this can be used to find them
     */
    public calculatePositionsFromGridById(record: SpinRecord, symbolId: string) {
        this.positions = [];
        record.grid.forEach((reel, x) => {
            reel.forEach((symbol, y) => {
                if (symbol === symbolId) {
                    this.positions.push(new Point(x, y));
                }
            });
        });
    }

    public ofAKind(): number {
        return Math.max(...(this.positions.map((p) => p.x))) + 1;
    }
}
