import { PositionConfig } from "appworks/config/asset-config-schema";
import { AnimationService } from "appworks/graphics/animation/animation-service";
import { ButtonElement } from "appworks/graphics/elements/button-element";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Layers } from "appworks/graphics/layers/layers";
import { Position } from "appworks/graphics/pixi/position";
import { Services } from "appworks/services/services";

export function generateButton(id: string, spriteId: string, layer: Layers, landscapePosition: PositionConfig, portraitPosition?: PositionConfig) {
    const idParts = id.split("-");
    const buttonId = idParts.shift();

    let shortcutKeycode: string = null;
    if (idParts.length > 1) {
        shortcutKeycode = idParts.shift();
        if (shortcutKeycode.toLowerCase() === "space") {
            shortcutKeycode = " ";
        }
    }

    const buttonState = idParts.length > 0 ? idParts.join("-") : "up";

    let button = layer.getButton(buttonId);

    if (!button) {
        button = new ButtonElement(shortcutKeycode);
        button.name = buttonId;

        if (landscapePosition) {
            button.landscape.x = landscapePosition.x;
            button.landscape.y = landscapePosition.y;
        } else {
            button.landscape = Position.unavailable();
        }

        if (portraitPosition) {
            button.portrait.x = portraitPosition.x;
            button.portrait.y = portraitPosition.y;
        } else {
            button.portrait = Position.unavailable();
        }

        layer.add(button, false, true, button.name);
        layer.addButton(buttonId, button);
    }

    let sprite: any;
    if (buttonState === "up" && Services.get(AnimationService).hasAnimation(id)) {
        sprite = Services.get(AnimationService).generateAnimation(id);
        sprite.play();
    } else if (buttonState.indexOf("hit") > -1) {
        sprite = Services.get(GraphicsService).createBlankSprite();
    } else {
        sprite = Services.get(GraphicsService).createSprite(spriteId);
    }

    if (landscapePosition) {
        sprite.landscape.x = landscapePosition.x - button.landscape.x;
        sprite.landscape.y = landscapePosition.y - button.landscape.y;
        sprite.landscape.width = landscapePosition.width;
        sprite.landscape.height = landscapePosition.height;
    } else {
        sprite.landscape = Position.unavailable();
    }

    if (portraitPosition) {
        sprite.portrait.x = portraitPosition.x - button.portrait.x;
        sprite.portrait.y = portraitPosition.y - button.portrait.y;
        sprite.portrait.width = portraitPosition.width;
        sprite.portrait.height = portraitPosition.height;
    } else {
        sprite.portrait = Position.unavailable();
    }

    if (buttonState.indexOf("hit") > -1) {
        sprite.renderable = false;
        button.addChild(sprite);
        button.setHitArea(sprite, buttonState.indexOf("circle") > -1);
    } else {
        button.addState(buttonState, sprite);
    }
    sprite.name = id;
}
