import { Layers } from "appworks/graphics/layers/layers";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { wrapIndex } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Point } from "appworks/utils/geom/point";
import { Graphics } from "pixi.js";
import { SymbolComponentType, SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";

export class ReelSubcomponent<T extends SymbolSubcomponent = SymbolSubcomponent> {

    public readonly index: number;
    public readonly size: number;

    public mask: Sprite | Graphics;
    public validLandSymbols: string[];

    protected stopIndex: number;

    protected symbols: T[];
    protected hiddenSymbols: T[];

    protected matrixLayer: Layers;
    protected animationLayer: Layers;

    protected visible: boolean = true;

    constructor(
        index: number,
        rows: number,
        reelstrip: string[],
        defaultStop: number = 0,
        matrixLayer: Layers,
        animationLayer: Layers,
        protected symbolType: SymbolComponentType<T> = SymbolSubcomponent as any
    ) {

        this.matrixLayer = matrixLayer;
        this.animationLayer = animationLayer;

        this.index = index;
        this.size = rows;

        this.symbols = [];
        this.hiddenSymbols = [];

        for (let row = 0; row < rows; row++) {
            const symbol = new this.symbolType(new Point(index, row), this.matrixLayer, this.animationLayer);

            this.symbols.push(symbol);
        }

        this.resetSymbolPositions();
        this.jump(defaultStop, reelstrip);
    }

    /**
     * Reset symbol positions to default positionRects defined in assetworks (symbol_x_y)
     */
    public resetSymbolPositions() {
        for (const symbol of this.symbols) {
            let positionRect;

            if (this.mask) {
                symbol.setMask(this.mask);
            }

            const x = symbol.gridPosition.x;
            const y = symbol.gridPosition.y;

            positionRect = this.matrixLayer.getPosition(`symbol_${x}_${y}`);

            symbol.setTransform(positionRect);
        }
    }

    public jump(index: number, reelstrip: string[]) {
        this.stopIndex = index;

        for (let i = 0; i < this.symbols.length; i++) {

            const symbol = this.symbols[i];
            const offsetIndex = this.stopIndex + i;

            const symbolId = wrapIndex(offsetIndex, reelstrip);

            symbol.setSymbol(symbolId);
        }
    }

    public spin() {
        for (const symbol of this.symbols.concat(this.hiddenSymbols)) {
            symbol.setMoving(true);
        }
        return Contract.empty();
    }

    public blur() {
        for (const symbol of this.symbols.concat(this.hiddenSymbols)) {
            symbol.setBlurred(true);
        }
    }

    public unblur() {
        for (const symbol of this.symbols.concat(this.hiddenSymbols)) {
            symbol.setBlurred(false);
        }
    }

    public land() {
        for (const symbol of this.symbols.concat(this.hiddenSymbols)) {
            symbol.setBlurred(false);
        }
        return Contract.empty();
    }

    public complete() {
        for (const symbol of this.symbols.concat(this.hiddenSymbols)) {
            symbol.setMoving(false);
        }
        return Contract.empty();
    }

    public setVisible(visible: boolean) {
        this.visible = visible;
    }

    public getVisible() {
        return this.visible;
    }

    // Excluding hidden symbols
    public getSymbols() {
        return this.symbols;
    }

    // All symbols including hidden
    public getAllSymbols() {
        return [...this.symbols, ...this.hiddenSymbols];
    }

    public getSymbolAt(y: number) {
        return this.symbols[y];
    }

    public addHiddenSymbol(symbol: T) {
        this.hiddenSymbols.push(symbol);
    }

    public getHiddenSymbols() {
        return this.hiddenSymbols;
    }

    public clearHiddenSymbols() {
        this.hiddenSymbols = [];
    }

    public getMatrixLayer() {
        return this.matrixLayer;
    }

    public getAnimationLayer() {
        return this.animationLayer;
    }
}
