import { KeyInput } from "appworks/utils/key-input";
import { KeyboardCode } from "appworks/utils/keyboard";
import { IntegrationDebugger } from "slotworks/debug/integration-debugger/integration-debugger";
import { LayerDebugger } from "slotworks/debug/menu/layer-debugger/layer-debugger";
import { DebugTab } from "slotworks/debug/menu/tabs/debug-tab";
import { ReelsetControlDebugger } from "slotworks/debug/reelset-control-debugger";
import { SoundDebugger } from "slotworks/debug/sound-debugger/sound-debugger";
import { UIDebugger } from "slotworks/debug/ui-debugger/ui-debugger";
import { ForceList } from "../force-list";

export class DebuggerTab extends DebugTab {
    public static IntegrationDebuggerType: typeof IntegrationDebugger = IntegrationDebugger;
    public static ReelsetControlDebuggerType: typeof ReelsetControlDebugger = ReelsetControlDebugger;

    private integrationDebugger: IntegrationDebugger;
    private uiDebugger: UIDebugger;
    private soundDebugger: SoundDebugger;
    private layerDebugger: LayerDebugger;
    private reelsetControl: ReelsetControlDebugger;

    constructor(name: string, resultList?: ForceList) {
        super(name, resultList);

        this.integrationDebugger = (window as any).integrationDebugger = new DebuggerTab.IntegrationDebuggerType();
        this.uiDebugger = new UIDebugger();
        this.soundDebugger = new SoundDebugger();
        this.layerDebugger = new LayerDebugger();
        this.reelsetControl = new DebuggerTab.ReelsetControlDebuggerType();
    }

    public render(root: HTMLElement, hide: Function): void {
        this.addIntegrationDebugger(root);
        this.addUIDebugger(root);
        this.addSoundDebugger(root);
        this.addLayerDebugger(root);
        this.addReelsetControl(root);
    }

    private addIntegrationDebugger(root: HTMLElement) {
        const inputBtn = this.makeButton("INTEGRATION");

        const keyInput = new KeyInput();
        keyInput.onKeyUp.add((key: string, e: KeyboardEvent) => {
            if (e.shiftKey) {
                if (key === KeyboardCode.I) {
                    this.integrationDebugger.toggle();
                }
            }
        });

        inputBtn.onclick = () => {
            this.integrationDebugger.toggle();
        };
        root.appendChild(inputBtn);
    }

    private addUIDebugger(root: HTMLElement) {
        const inputBtn = this.makeButton("UI");

        inputBtn.onclick = () => {
            this.uiDebugger.toggle();
        };
        root.appendChild(inputBtn);
    }

    private addSoundDebugger(root: HTMLElement) {
        const inputBtn = this.makeButton("SOUND");

        inputBtn.onclick = () => {
            this.soundDebugger.toggle();
        };
        root.appendChild(inputBtn);
    }

    private addLayerDebugger(root: HTMLElement) {
        const inputBtn = this.makeButton("LAYERS");

        inputBtn.onclick = () => {
            this.layerDebugger.toggle();
        };
        root.appendChild(inputBtn);
    }

    private addReelsetControl(root: HTMLElement) {
        const inputBtn = this.makeButton("REELSET CONTROL");

        inputBtn.onclick = () => {
            this.reelsetControl.toggle();
        };
        root.appendChild(inputBtn);
    }

    private makeButton(label: string) {
        const inputBtn = document.createElement("button");

        inputBtn.style.fontSize = "3vw";
        inputBtn.style.margin = "1vmax 1vw";
        inputBtn.style.borderRadius = "10px";
        inputBtn.style.width = "72vw";
        inputBtn.style.height = "10vmax";
        inputBtn.style.backgroundColor = "#ffffff";
        inputBtn.innerText = label;
        inputBtn.style.outline = "none";
        inputBtn.style.cursor = "pointer";

        return inputBtn;
    }
}
