import { Record } from "appworks/model/gameplay/records/record";
import { lastInArray } from "appworks/utils/collection-utils";
import * as Logger from "js-logger";
import { Signal } from "signals";
import { FreespinRecord } from "slotworks/model/gameplay/records/freespin-record";
import { DataProcessorSupplementCollection } from "slotworks/model/gameplay/supplements/data-processor-supplement-collection";
import { Result } from "./records/results/result";

export class Gameplay {

    public static dataProcessorSupplements: DataProcessorSupplementCollection = new DataProcessorSupplementCollection();

    /**
     * Unique identifier for this gameplay
     */
    public id: string;

    /**
     * Balance at the current moment of this gameplay
     */
    public balance: number;

    /**
     * Optional. If a distinction needs to be made between real and free balance
     */
    public freeBalance: number;

    /**
     * Optional. If a distinction needs to be made between real and free balance
     */
    public realBalance: number;

    /**
     * Balance when the gameplay began, after stake was deducted
     */
    public startBalance: number;

    /**
     * Whether or not a gameplay has been recovered (due to a user disconnect etc)
     */
    public isRecovered: boolean;

    public onRecordAdded: Signal = new Signal();

    /**
     * Root record of this gameplay
     */
    private rootRecord: Record = null;

    /**
     * The record currently being displayed
     */
    private currentRecord: Record = null;

    /**
     * The last record to be added - all further records will be added from here (either as children or as siblings)
     */
    private headRecord: Record = null;

    public addRecord(record: Record) {
        this.headRecord = record;

        for (const dataProcessorSupplements of Gameplay.dataProcessorSupplements.get()) {
            dataProcessorSupplements.process(record);

            for (const child of record.children) {
                dataProcessorSupplements.process(child);
            }
        }

        if (!this.rootRecord) {
            this.rootRecord = this.currentRecord = record;
        } else {
            this.rootRecord.addChildRecord(record);
        }

        this.onRecordAdded.dispatch(record);
    }

    public getCurrentRecord(): Record {
        return this.currentRecord;
    }

    public getCurrentRecordParent(): Record {
        return this.currentRecord.parent;
    }

    public getRootRecord(): Record {
        return this.rootRecord;
    }

    public getLatestRecord(): Record {
        return this.headRecord;
    }

    public hasRecords(): boolean {
        return this.rootRecord !== null;
    }

    /**
     * Returns next record, or null if there are no more records to show
     */
    public nextRecord(): Record {
        if (this.currentRecord.hasMoreChildren()) {
            // Dive into children
            this.currentRecord = this.currentRecord.nextChild();

            return this.currentRecord;
        } else if (this.currentRecord.parent) {
            // Climb to parent and try again
            this.currentRecord = this.currentRecord.parent;

            return this.nextRecord();
        } else {
            // No parents left, we're at root, all records have been traversed
            return null;
        }
    }

    public setToLatestRecord(record: Record = this.rootRecord): void {
        if (!record) {
            return;
        }

        if (record.children.length) {
            record.currentChildIndex = record.children.length - 1;
            this.setToLatestRecord(lastInArray(record.children));
        } else {
            this.currentRecord = record;
        }
    }

    public resetToParentRecord(): Record {
        this.currentRecord = this.currentRecord.parent;

        return this.currentRecord;
    }

    /**
     * Reset pointer to root record but don't touch child index
     */
    public resetToRootRecord(): Record {
        this.currentRecord = this.rootRecord;

        return this.rootRecord;
    }

    /**
     * Reset pointer to root record and reset child indexes to 0
     */
    public resetTree(): Record {
        const family = this.getFamily(this.rootRecord, this.headRecord);
        family.forEach((record) => {
            record.currentChildIndex = -1;
        });

        return this.resetToRootRecord();
    }

    /**
     * Returns total win, regardless of the current record pointer
     */
    public getTotalWin() {
        const family = this.getFamily(this.rootRecord);

        return family.reduce((total, record) => total + record.cashWon, 0);
    }

    /**
     * Returns total win up until now
     */
    public getCurrentTotalWin() {
        const family = this.getFamily(this.rootRecord, this.currentRecord);

        return family.reduce((total, record) => record.cashWon ? total + record.cashWon : total, 0);
    }

    /**
     * Returns total win for all of a records children (good for freespin totals etc)
     * @parent record to find children of
     * @endRecord record to stop at, if that record is one of the children of the parent
     */
    public getTotalChildrenWin(parent: Record, endRecord?: Record) {
        const family = this.getFamily(parent, endRecord);

        return family.reduce((total, record) => total + record.cashWon, parent ? -parent.cashWon : 0);
    }

    /**
     * Total win on the current branch level and below
     */
    public getCurrentBranchWin() {
        let branchWin = this.getTotalChildrenWin(this.currentRecord.parent, this.currentRecord);
        if (this.currentRecord === this.rootRecord) {
            branchWin = this.getTotalWin();
        }
        return branchWin;
    }

    /**
     * How much has been wagered in this gameplay
     */
    public getTotalWagered() {
        const family = this.getFamily(this.rootRecord, this.headRecord);

        return family.reduce((total, record) => total + record.wager, 0);
    }

    /**
     * Win less stake
     */
    public getTotalWinLoss() {
        return this.getCurrentTotalWin() - this.getTotalWagered();
    }

    /**
     * Remove all winnings so far in this game
     */
    public removeCurrentWinnings() {
        const family = this.getFamily(this.rootRecord, this.currentRecord);

        family.forEach((record) => record.cashWon = 0);
    }

    /**
     * Check if the root branch (and therefore the game) is complete
     */
    public isComplete() {
        return this.rootRecord.isBranchComplete();
    }

    public isCurrentRecordRoot() {
        return this.getCurrentRecord() === this.getRootRecord();
    }

    public getLatestResultOfType<T extends Result>(recordType: { new(...args: any[]): T }): T {
        let latestResult: T;

        this.getFamily(this.rootRecord, this.currentRecord).forEach((record) => {
            const result = record.getResultsOfType(recordType)[0];
            if (result) { latestResult = result; }
        });

        return latestResult;
    }

    /**
     * Total freespins won during this gameplay up until lastRecord (or current record if lastRecord is ommitted)
     */
    public getTotalFreespinsWon(lastRecord?: Record, fromRecord?: Record) {
        if (!fromRecord) {
            fromRecord = this.rootRecord;
        }
        if (!lastRecord) {
            lastRecord = this.currentRecord;
        }
        const family = this.getFamily(fromRecord, lastRecord);

        return family.reduce((total, record) => total + record.getFreespinsWon(), 0);
    }

    /**
     * Checks if current spin is a freespin
     */
    public inFreespins(): boolean {
        return this.getTotalFreespinsWon() > 0 && this.getFreespinsPlayed() > 0;
    }

    /**
     * Checks if freespins have been awarded but the first freespin hasnt returned from the server yet
     */
    public freespinsTriggered(): boolean {
        return this.getTotalFreespinsWon() > 0 && this.getFreespinsPlayed() === 0;
    }

    /**
     * Counts all the freespins child records up until lastRecord (or current record if lastRecord is ommitted)
     */
    public getFreespinsPlayed(lastRecord?: Record, parentRecord?: Record) {
        if (!parentRecord) {
            parentRecord = this.rootRecord;
        }
        const family = this.getFamily(parentRecord, lastRecord);
        return family.reduce((total, record) => total + (record instanceof FreespinRecord ? 1 : 0), 0);
    }

    public freespinsInTree() {
        const family = this.getFamily(this.rootRecord, this.headRecord);
        return family.some((record) => record instanceof FreespinRecord);
    }

    /**
     * Checks if the number of freespin child records matches the number of freespins awarded
     */
    public allFreespinsPlayed() {
        return this.getFreespinsPlayed() === this.getTotalFreespinsWon();
    }

    public areFreespinsPending() {
        return this.getTotalFreespinsWon() && !this.allFreespinsPlayed();
    }

    public getFreespinsRemaining() {
        return this.getTotalFreespinsWon() - this.getFreespinsPlayed();
    }

    public isCurrentRecordType<T extends Record>(recordType: { new(): T }) {
        return this.getCurrentRecord() instanceof recordType;
    }

    /**
     * Prints a visual representation of the record tree, for debugging purposes
     */
    public print() {
        let output = "";

        const printBranch = (record: Record, tabs: string) => {
            const branchOutput = tabs + " " + " " + record.id + " " + "(" + (record.parent ? record.parent.id : "ROOT") + ")";
            output += branchOutput;
            Logger.info(branchOutput);

            for (const child of record.children) {
                printBranch(child, tabs + "---");
            }
        };

        printBranch(this.rootRecord, "");

        return output;
    }

    /**
     * Returns all members of a tree, including all children, grandchildren etc, stopping at lastRecord (if specified)
     */
    public getFamily(rootRecord: Record, lastRecord?: Record) {
        this.checkRecordsAreSet();

        const records: Record[] = [];
        let endTree = false;

        const addChildren = (record: Record) => {
            if (!record || endTree) {
                return;
            }
            records.push(record);

            if (record !== lastRecord) {
                for (const child of record.children) {
                    addChildren(child);
                    if (child === lastRecord) {
                        endTree = true;
                        break;
                    }
                }
            }
        };

        addChildren(rootRecord);

        return records;
    }

    private checkRecordsAreSet() {
        if (!this.rootRecord || !this.currentRecord) {
            throw new Error("Information about gameplay requested but it has no records to report upon");
        }
    }
}
