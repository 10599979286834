import { AbstractComponent } from "appworks/components/abstract-component";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { Layers } from "appworks/graphics/layers/layers";
import { Timer } from "appworks/utils/timer";

export class ElapsedTimeComponent extends AbstractComponent {
    private elapsedTimeText: FlexiText;
    private startTime: Date;

    private timer: number;

    constructor() {
        super();
        this.elapsedTimeText = Layers.get("Header").getFlexiText("ukgc_elapsed_time");

        if (this.elapsedTimeText != null) {
            this.startTime = new Date();

            const millisecondsLeft = 1000 - this.startTime.getMilliseconds();

            this.updateElapsedTime(millisecondsLeft);
        }
    }

    public destroy() {
        Timer.clearTimeout(this.timer);
    }

    private updateElapsedTime(milliseconds: number = 1000) {
        this.elapsedTimeText.setText(this.getTime());

        this.timer = Timer.setTimeout(() => {
            this.updateElapsedTime();
        }, milliseconds);
    }

    private getTime() {
        const currentTime = new Date().getTime();
        const startTimeMillisecs = this.startTime.getTime();
        const elapsedDate = new Date(currentTime - startTimeMillisecs).getTime();
        const seconds = Math.floor(elapsedDate / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        return `(${this.leadZeroes(hours)}:${this.leadZeroes(minutes % 60)}:${this.leadZeroes(seconds % 60)})`;
    }

    private leadZeroes(n: number) {
        if (n < 10) {
            return "0" + n.toString();
        } else {
            return n.toString();
        }
    }
}
