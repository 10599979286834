import { ClientController } from "appworks/state-machine/ClientController";
import { Contract } from "appworks/utils/contracts/contract";
import { GMRAlertComponent } from "gaming-realms/components/gmr-alert-component";

export class SocialGMRAlertComponent extends GMRAlertComponent {
    public error(messageId: string, fatal?: boolean): Contract<void> {
        ClientController.somethingWentWrong(messageId);

        return super.error(messageId, fatal);
    }

    public insufficientFunds(): Contract {
        ClientController.getInstance().handleInsufficientFunds();
        
        return new Contract((resolve) => resolve(null));
    }
}