import { gameState } from "appworks/model/game-state";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";
import { SlingoJokerResult } from "slingo/model/results/slingo-joker-result";

export class SlingoHasJokerDecision extends Decision {
    public evaluate(): Exit {
        const record = gameState.getCurrentGame().getLatestRecord();
        const jokerResult = record.getFirstResultOfType(SlingoJokerResult);

        return jokerResult ? Exit.True : Exit.False;
    }
}