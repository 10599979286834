import { Flexi } from "appworks/graphics/elements/abstract-flexi";
import { SliderElement } from "appworks/graphics/elements/slider-element";

export class FlexiSlider extends Flexi<HTMLElement | SliderElement> {

    private value: number;
    private eventListeners: Map<string, (toggleEvent: Event) => void> = new Map();

    public addTarget(target: HTMLElement | SliderElement) {
        super.addTarget(target);

        if (target) {
            if (target instanceof HTMLElement) {
                this.on(SliderEvent.INPUT, (value: number) => this.updateValue(value));
                target.addEventListener("touchstart", this.stopPropagation);
                target.addEventListener("touchmove", this.stopPropagation);
            } else {
                target.onInput.add((value: number) => this.updateValue(value));
            }
        }
    }

    public destroy() {
        super.destroy();

        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.removeEventListener("touchstart", this.stopPropagation);
                target.removeEventListener("touchmove", this.stopPropagation);
                if (this.eventListeners) {
                    this.eventListeners.forEach((listener: (event: Event) => void, name: string) => {
                        target.removeEventListener(name, listener);
                    });
                }
            } else {
                target.onChange.removeAll();
                target.onInput.removeAll();
                target.destroy();
            }
        }

        this.eventListeners = null;
    }

    public on(event: SliderEvent, handler: (value: number) => void) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                const listener = (sliderEvent: Event) => {
                    const value = (sliderEvent.currentTarget as HTMLInputElement).value;
                    handler(parseFloat(value));
                };
                this.eventListeners.set(event.id, listener);
                target.addEventListener(event.id, listener);
            } else {
                if (event === SliderEvent.INPUT) {
                    target.onInput.add(handler);
                } else if (event === SliderEvent.CHANGE) {
                    target.onChange.add(handler);
                }
            }
        }
    }

    public off(event: SliderEvent, handler: Function) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                const listener = this.eventListeners.get(event.id);
                if (listener) {
                    target.removeEventListener(event.id, listener);
                    this.eventListeners.delete(event.id);
                }

            } else {
                if (event === SliderEvent.INPUT) {
                    target.onInput.remove(handler);
                } else if (event === SliderEvent.CHANGE) {
                    target.onChange.remove(handler);
                }
            }
        }
    }

    public setup(min: number, max: number, step: number) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                (target as HTMLInputElement).min = min.toString();
                (target as HTMLInputElement).max = max.toString();
            } else {
                target.setup(min, max, step);
            }
        }
    }

    public setValue(value: number) {
        this.updateValue(value);

        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                (target as HTMLInputElement).value = value.toString();
            } else {
                target.setValue(value);
            }
        }
    }

    public getValue(): number {
        return this.value;
    }

    public setVisible(visible: boolean) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle("visible", visible);
                target.classList.toggle("hidden", !visible);
            } else {
                target.setVisible(visible);
            }
        }
    }

    public setEnabled(enabled: boolean) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle("enabled", enabled);
                target.classList.toggle("disabled", !enabled);
            } else {
                target.setEnabled(enabled);
            }
        }
    }

    private updateValue(value: number) {
        this.value = value;
    }

    private stopPropagation = (event: Event) => {
        event.stopPropagation();
    }
}

export class SliderEvent {
    public static CHANGE: SliderEvent = new SliderEvent("change");
    public static INPUT: SliderEvent = new SliderEvent("input");
    public static DRAG: SliderEvent = new SliderEvent("drag");

    public id: string;

    constructor(id: string) {
        this.id = id;
    }
}
