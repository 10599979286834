// Inclusive of min and max
export function RandomRangeInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function RandomRangeFloat(min: number, max: number) {
    return Math.random() * (max - min) + min;
}

export function RandomBoolean(chance: number = 0.5) {
    return Math.random() < chance;
}

export function RandomSign() {
    return Math.round(Math.random()) * 2 - 1;
}

export function RandomFromArray<T>(arr: T[]): T {
    return arr[Math.floor(Math.random() * arr.length)];
}
