import { LoadbarSubcomponent } from "appworks/components/preloader/loadbar-subcomponent";
import { FileLocation } from "appworks/config/asset-config-schema";
import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { Layers } from "appworks/graphics/layers/layers";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { LoaderService } from "appworks/loader/loader-service";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { LoaderResource } from "pixi.js";
import { Signal } from "signals";
import { JurisdictionService } from "slotworks/services/jurisdiction/jurisdiction-service";
import { AbstractComponent } from "../abstract-component";

export class PreloaderComponent extends AbstractComponent {

    public onProgress: Signal = new Signal();
    public onComplete: Signal = new Signal();

    protected loadBar: LoadbarSubcomponent;

    protected progressText: Text;

    protected completed: boolean = false;

    public start(files: FileLocation[]) {
        if (Layers.get("Background")?.hasScene("preloader")) {
            Layers.get("Background").setScene("preloader").execute();
            Layers.get("Background").show();
        }

        const menusLayer = Layers.get("Menus");

        let bar: Sprite;
        let barMask: Sprite | SmartShape;
        let chaser: Sprite;

        if (menusLayer?.hasScene("preloader")) {
            menusLayer.setScene("preloader").execute();
            menusLayer.show();

            bar = menusLayer.getSprite("loader_bar");
            chaser = menusLayer.getSprite("loader_bar_chaser");
            this.progressText = menusLayer.getText("loader_text");

            barMask =
                menusLayer.getSprite("loader_bar_mask") ||
                menusLayer.getShape("loader_bar_mask");
            if (barMask) {
                bar.mask = barMask;
            }
        }

        this.loadBar = new LoadbarSubcomponent({ files, bar: barMask || bar, chaser });
        this.loadBar.onChange.add(() => this.update());

        if (Services.get(JurisdictionService).getMinPreloaderTime() > 0) {
            Services.get(LoaderService).minPreloaderTime = Services.get(JurisdictionService).getMinPreloaderTime();
        }

        this.onComplete.addOnce(() => this.completed = true);
    }

    public fileLoaded(resource: LoaderResource) {
        if (this.loadBar) {
            this.loadBar.fileLoaded(resource);
        }
    }

    /**
     * Quickly completes the loadbar and returns a Contract for when it's done
     */
    public loadComplete() {
        Services.get(SoundService).event(SoundEvent.preload_complete);

        if (this.loadBar) {
            return new Sequence([
                () => this.loadBar.loadComplete(),
                () => Contract.wrap(() => {
                    this.onComplete.dispatch();
                    this.destroy();
                })
            ]);
        } else {
            this.destroy();
            return Contract.empty();
        }
    }

    public destroy() {
        this.progressText = null;

        Layers.get("Background")?.defaultScene().execute();
        Layers.get("Menus")?.clear();
    }

    public isComplete(): boolean {
        return this.completed;
    }

    protected update() {
        if (this.progressText) {
            const percent = Math.round(this.loadBar.currentPercent * 100);

            if (Services.get(TranslationsService).exists("prl.loa")) {
                this.progressText.text = Services.get(TranslationsService).get("prl.loa", { PROGRESS: percent });
            } else {
                this.progressText.text = `${percent}%`;
            }
        }

        this.onProgress.dispatch(this.loadBar.currentPercent);
    }
}
