import { KeyboardSpeedControl } from "appworks/debug/keyboard-time-control";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Services } from "appworks/services/services";
import { deviceInfo } from "appworks/utils/device-info";
import { KeyInput } from "appworks/utils/key-input";
import { KeyboardCode } from "appworks/utils/keyboard";
import { DebugTab } from "./tabs/debug-tab";
import { DebuggerTab } from "./tabs/debugger-tab";
import { InfoTab } from "./tabs/info-tab";
import { LogTab } from "./tabs/log-tab";
import { UtilTab } from "./tabs/util-tab";

const slotworksConfig = require("../../../../package.json");

declare const __VERSION__: string;

export class DebugMenu {
    public static init() {
        KeyboardSpeedControl();

        if (!this.debugMenu) {
            this.debugMenu = new DebugMenu();
            this.debugMenu.init();

            InfoTab.addInfo("Game", __VERSION__);
            InfoTab.addInfo("Slotworks", slotworksConfig.version);
            InfoTab.addInfo("Screen", deviceInfo.getWindowWidth() + ", " + deviceInfo.getWindowHeight() + " @" + deviceInfo.pixelDensity + "x");
            InfoTab.addInfo("Resolution", Services.get(CanvasService).resolutionName);
            InfoTab.addInfo("Renderer", ["Unknown", "WebGL", "Canvas"][Services.get(CanvasService).renderer?.type]);
            InfoTab.addInfo("User Agent", window.navigator.userAgent);

            this.debugMenu.addTab(new InfoTab("Info"));
            this.debugMenu.addTab(new LogTab("Logs"));
            this.debugMenu.addTab(new DebuggerTab("Debug"));
            this.debugMenu.addTab(new UtilTab("Utils"));

            const keyInput = new KeyInput();
            keyInput.onKeyUp.add((key: string, e: KeyboardEvent) => {
                if ((e.key === KeyboardCode.f || e.key === KeyboardCode.F) && e.shiftKey) {
                    this.debugMenu.toggle();
                }
            });

            if (!deviceInfo.isDesktop) {
                const mc = Services.get(CanvasService).hammer;
                mc.add(new Hammer.Pinch());

                // subscribe to events
                mc.on("pinchout", (e: any) => {
                    this.debugMenu.show();
                });
            }

            (window as any).toggleDebugMenu = () => this.debugMenu.toggle();
        }
    }

    public static add(tab: DebugTab) {
        this.init();

        this.debugMenu.addTab(tab);
    }

    public static get<T extends DebugTab>(tabType: { new(...args: any[]): T }) {
        let tab: T;
        this.debugMenu.tabs.forEach((element) => {
            if (element.controller instanceof tabType) {
                tab = element.controller;
            }
        });

        return tab;
    }

    public static getVisible() {
        return this.debugMenu.visible;
    }

    private static debugMenu?: DebugMenu;

    private domElement: HTMLElement;
    private tabLine: HTMLElement;
    private tabs: Map<string, { tab: HTMLElement, body: HTMLElement, controller: DebugTab }> = new Map<string, { tab: HTMLElement, body: HTMLElement, controller: DebugTab }>();
    private visible: boolean;

    public init() {
        this.domElement = document.createElement("div");

        this.domElement.id = "forces-menu";
        this.domElement.style.width = "76vw";
        this.domElement.style.height = "76vh";
        this.domElement.style.position = "fixed";
        this.domElement.style.top = "10vh";
        this.domElement.style.left = "10vw";
        this.domElement.style.zIndex = "1000";
        this.domElement.style.padding = "2vw";
        this.domElement.style.overflowX = "none";
        this.domElement.style.overflowY = "auto";
        this.domElement.style.borderRadius = "10px";

        this.domElement.style.backgroundColor = "rgba(30, 30, 30, 0.85)";

        this.tabLine = document.createElement("div");
        this.tabLine.id = "tabs";
        this.tabLine.style.width = "100%";
        this.tabLine.style.height = "10vh";
        this.tabLine.style.display = "inline-flex";

        this.domElement.appendChild(this.tabLine);
        document.body.appendChild(this.domElement);

        this.hide();
    }

    public toggle() {
        if (this.visible) {
            this.hide();
        } else {
            this.show();
        }
    }

    public show() {
        this.visible = true;
        this.domElement.style.visibility = "visible";
        const child: HTMLElement = this.tabLine.children[0] as HTMLElement;
        this.setTab(child.dataset.name);
    }

    public hide() {
        this.visible = false;
        this.domElement.style.visibility = "hidden";
    }

    private addTab(tab: DebugTab) {
        if (this.tabs.has(tab.getName())) {
            tab.render(this.tabs.get(tab.getName()).body, () => this.hide());
        } else {
            const div = document.createElement("div");
            div.id = "tab-" + tab.getName().toLowerCase().replace(/[^\w\d]+/g, "-");
            div.style.border = "2px solid grey";
            div.style.display = "none";
            this.domElement.appendChild(div);

            const btn = document.createElement("button");
            btn.dataset.name = tab.getName();
            btn.style.fontSize = "3vw";
            btn.style.borderRadius = "10px 10px 0 0";
            btn.style.border = "2px solid grey";
            btn.style.width = "15vw";
            btn.style.height = "10vh";
            btn.style.backgroundColor = "#ffffff";
            btn.style.outline = "none";
            btn.style.cursor = "pointer";
            btn.innerText = tab.getName();

            btn.onclick = () => {
                this.setTab(tab.getName());
            };

            if (this.tabLine.children.length > 0) {
                this.tabLine.insertBefore(btn, this.tabLine.children[0]);
            } else {
                this.tabLine.appendChild(btn);
            }

            tab.render(div, () => this.hide());
            this.tabs.set(tab.getName(), { tab: btn, body: div, controller: tab });
        }
    }

    private setTab(name: string) {
        this.tabs.forEach((entry, id) => {
            if (name === id && this.visible) {
                entry.tab.style.backgroundColor = "white";
                entry.body.style.display = "block";
            } else {
                entry.tab.style.backgroundColor = "#DDD";
                entry.body.style.display = "none";
            }
        });
    }
}
