import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { Container } from "appworks/graphics/pixi/container";
import { Rectangle } from "appworks/utils/geom/rectangle";
import { DualPosition } from "./dual-position";
import { Sprite } from "./sprite";

export function setTransformRect(displayObject: Container | Sprite | AnimatedSprite, rect: Rectangle | Container | Sprite) {
    displayObject.x = rect.x;
    displayObject.y = rect.y;
    displayObject.width = rect.width;
    displayObject.height = rect.height;
}

export function setDualPosition(displayObject: Container | Sprite | AnimatedSprite, dualPosition: DualPosition) {
    displayObject.landscape.x = dualPosition.landscape.x;
    displayObject.landscape.y = dualPosition.landscape.y;
    displayObject.landscape.width = dualPosition.landscape.width;
    displayObject.landscape.height = dualPosition.landscape.height;

    displayObject.portrait.x = dualPosition.portrait.x;
    displayObject.portrait.y = dualPosition.portrait.y;
    displayObject.portrait.width = dualPosition.portrait.width;
    displayObject.portrait.height = dualPosition.portrait.height;
}
