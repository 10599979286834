import { ResultBuilder } from "appworks/server/builders/result-builder";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoResponse } from "slingo/integration/slingo-schema";

export class SlingoGameProgressResultBuilder implements ResultBuilder {
    protected keysToMultiply = [
        "patternWin",
        "symbolWin",
        "standardWin",
        "purchaseWin",
        "jackpotWin",
        "totalWin",
        "standardStake",
        "purchaseStake",
        "nextPurchaseStake",
        "totalStake",
    ];

    public filter(data: SlingoResponse, record?: SlingoRecord): boolean {
        return Boolean(data.gameProgress);
    }

    public build(record: SlingoRecord, data: SlingoResponse): SlingoGameProgressResult[] {
        const result = new SlingoGameProgressResult();

        for (const key in data.gameProgress) {
            if (this.keysToMultiply.indexOf(key) !== -1) {
                result[key] = data.gameProgress[key] * 100;
            } else {
                result[key] = data.gameProgress[key];
            }
        }

        return [result];
    }
}
