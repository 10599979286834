import { DebugTab } from "slotworks/debug/menu/tabs/debug-tab";

interface InfoDataRow {
    header: string;
    body: string;
}

export class InfoTab extends DebugTab {
    public static addInfo(header: string, body: string): void {
        this.data.push({ header, body });
        this.refreshTable();
    }

    private static data: InfoDataRow[] = [];
    private static table?: HTMLTableElement;

    private static refreshTable(): void {
        if (InfoTab.table) {
            this.table.innerHTML = "";

            InfoTab.data.forEach((entry) => {
                const head = document.createElement("td");
                head.innerText = entry.header;
                head.style.width = "20vw";
                head.style.padding = "1vw";
                head.style.borderRight = "solid #888";
                head.style.borderBottom = "solid #888";

                const body = document.createElement("td");
                body.innerText = entry.body;
                body.style.padding = "1vw";
                body.style.borderBottom = "solid #888";

                const row = document.createElement("tr");
                row.appendChild(head);
                row.appendChild(body);
                this.table.appendChild(row);
            });
        }
    }

    public render(root: HTMLElement, hide: Function): void {
        InfoTab.table = document.createElement("table");
        InfoTab.table.style.width = "100%";
        InfoTab.table.style.height = "100%";
        InfoTab.table.style.backgroundColor = "#222";
        InfoTab.table.style.color = "#CCC";
        InfoTab.table.style.fontFamily = "monospace";
        InfoTab.table.style.fontSize = "2vmax";
        InfoTab.table.style.overflowY = "auto";
        InfoTab.table.style.borderCollapse = "collapse";

        root.appendChild(InfoTab.table);

        InfoTab.refreshTable();
    }
}
