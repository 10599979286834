import { Layers } from "appworks/graphics/layers/layers";
import { LoaderService } from "appworks/loader/loader-service";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { WindowFocusManager } from "appworks/utils/browser-utils";
import { asArray } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { deviceInfo } from "appworks/utils/device-info";
import { logger } from "appworks/utils/logger";
import { Timer } from "appworks/utils/timer";
import * as TweenJS from "appworks/utils/tween";
import * as Howler from "howler";
import * as Logger from "js-logger";
import { Signal } from "signals";
import { Service } from "../service";
import { LocalStorageService } from "../storage/local-storage-service";

export class SoundService extends Service {
    public onMuteChange: Signal;
    public onFXMuteChange: Signal;
    public onMusicMuteChange: Signal;
    public onLoadComplete: Signal;

    public autoMuteOnUnfocus: boolean = true;

    public baseVolume: number = 0.5;

    public debugSoundEvents: boolean = false;

    // Map of events to replace whenever they're called. Good for custom game logic where a sound needs to change without sending a new event
    public eventOverrides: Map<string, string> = new Map();

    protected soundAliases: Map<string, string> = new Map<string, string>();

    protected eventStartSounds: Map<string, SoundPlayConfig[]> = new Map<string, SoundPlayConfig[]>();
    protected eventStopSounds: Map<string, SoundStopConfig[]> = new Map<string, SoundStopConfig[]>();
    protected eventVolumeSounds: Map<string, SoundVolumeConfig[]> = new Map<string, SoundVolumeConfig[]>();
    protected terminators: Map<string, string> = new Map<string, string>();
    protected usedSounds: Map<string, boolean> = new Map();

    protected playingSounds: Map<string, PlayingSound[]> = new Map<string, PlayingSound[]>();
    protected pausedSounds: Map<string, number> = new Map<string, number>();

    protected soundSprites: Howl[] = [];
    protected soundSpriteNameLookup: Map<string, Howl> = new Map();
    protected soundSpriteDefinition: IHowlSoundSpriteDefinition;

    protected muted: boolean = false;
    protected musicMuted: boolean = false;
    protected soundFxMuted: boolean = false;
    protected musicQueue: string[] = [];
    protected fadingMusic: string;

    protected volume: number = 1;

    protected isLoading: boolean = false;
    protected loaded: boolean = false;
    protected focusMuted: boolean = false;
    protected isUnlocked: boolean = false;

    protected muteSaved: boolean;

    protected soundsWaitingForLoad: Map<string, SoundPlayConfig> = new Map<string, SoundPlayConfig>();

    private fadeContracts = new Map<number, Contract<void>>();

    // Manually restart looping sounds when they're done, instead of using howler loop (Fixes Mac Safari bug)
    protected manualLooping: boolean = false;

    public init(): void {
        this.onMuteChange = new Signal();
        this.onFXMuteChange = new Signal();
        this.onMusicMuteChange = new Signal();
        this.onLoadComplete = new Signal();

        this.loadSettings();

        Services.get(LoaderService).onSoundUnlocked.add(() => this.onSoundUnlocked());

        if (deviceInfo.isSafari() || deviceInfo.isFireFox()) {
            this.manualLooping = true;
        }
    }

    public addSoundSprite(sound: Howl, definition: IHowlSoundSpriteDefinition) {
        // Merge sound sprite definitions if multiple sound sprites are used
        if (this.soundSpriteDefinition) {
            this.soundSpriteDefinition = { ...this.soundSpriteDefinition, ...definition };
        } else {
            this.soundSpriteDefinition = definition;
        }
        this.soundSprites.push(sound);

        for (const name in definition) {
            if (definition[name]) {
                this.soundSpriteNameLookup.set(name, sound);
            }
        }

    }

    public loadComplete() {
        this.playPendingSounds();

        this.isLoading = false;
        this.loaded = true;

        Howler.Howler.volume(this.volume * this.baseVolume);

        this.addAutomaticSoundEvents();

        this.onLoadComplete.dispatch();

        if (!WindowFocusManager.visible) {
            this.unfocus();
        }

        this.verifySoundsAllExist();

        this.event(SoundEvent.sounds_ready);
    }

    public customEventContract(event: string): Contract<void> {
        if (this.eventOverrides.has(event)) {
            event = this.eventOverrides.get(event);
        }

        const soundsToPlay: SoundPlayConfig[] = this.eventStartSounds.get(event) || [];
        const soundsToStop: SoundStopConfig[] = this.eventStopSounds.get(event) || [];
        const soundsToVolume: SoundVolumeConfig[] = this.eventVolumeSounds.get(event) || [];

        if (this.debugSoundEvents) {
            Logger.debug("%c Sound Event ", "background: #608548; color: #fff", event);
        }

        let playSounds: Array<() => Contract<void>> = [];
        let terminationSounds: Array<() => Contract<void>> = [];

        if (soundsToPlay.length > 0) {
            playSounds = soundsToPlay.map((soundPlayConfig) => {
                return () => this.playSound(soundPlayConfig);
            });
        } else if (event?.slice(0, 13) === ("button_press_")) {
            this.event(SoundEvent.default_button_press);
        } else if (event?.slice(0, 10) === ("button_up_")) {
            this.event(SoundEvent.default_button_up);
        } else if (event?.slice(0, 12) === ("button_over_")) {
            this.event(SoundEvent.default_button_over);
        }

        if (soundsToStop.length > 0) {
            terminationSounds = soundsToStop.map((soundStopConfig) => {
                const soundsExist: boolean = this.stopSound(
                    soundStopConfig.id,
                    soundStopConfig.fadeTime,
                    soundStopConfig.pause
                );

                const terminationSound: string = this.terminators.get(soundStopConfig.id);

                if (soundsExist && terminationSound != null) {
                    const soundConfig: SoundPlayConfig = { id: terminationSound };
                    return () => this.playSound(soundConfig);
                } else {
                    return () => Contract.empty();
                }
            });
        }

        for (const soundVolumeConfig of soundsToVolume) {
            this.volumeSound(soundVolumeConfig);
        }

        return new Parallel([...playSounds, ...terminationSounds]);
    }

    public customEvent(event: string) {
        this.customEventContract(event).execute();
    }

    public event(event: SoundEvent, ...args: string[]) {
        const id: string = this.getSoundEventName(event, ...args);

        this.customEvent(id);
    }

    public getSoundEventName(event: string, ...args: string[]): string {
        let eventName: string = event;
        for (let i = 0; i < args.length; i++) {
            eventName = eventName.split("{" + i + "}").join(args[i]);
        }

        return eventName;
    }

    public setLoading() {
        Howler.Howler.unload();
        this.isLoading = true;
    }

    public getLoading() {
        return this.isLoading;
    }

    public mute() {
        this.setMute(true);
    }

    public unmute() {
        if (!this.loaded && !this.isLoading) {
            Services.get(LoaderService).loadSounds().execute();
        }

        this.setMute(false);
    }

    public unmuteLoadScreen(): Contract<void> {
        return new Contract<void>((resolve) => {
            if (!this.loaded && !this.isLoading) {

                Layers.get("Prompts").setScene("load_sounds").execute();

                Services.get(LoaderService).loadSounds().then(() => {
                    if (Layers.get("Prompts").currentSceneIs("load_sounds")) {
                        Layers.get("Prompts").defaultScene().execute();
                    }

                    resolve(null);

                    this.event(SoundEvent.sounds_ready);
                });
            } else {
                resolve(null);
            }

            this.setMute(false);
        });
    }

    public setSoundFxMute(mute: boolean) {
        this.soundFxMuted = mute;

        Services.get(LocalStorageService).set("soundFxMuted", this.soundFxMuted);

        this.onFXMuteChange.dispatch(this.soundFxMuted);

        this.updateMute();
    }

    public setMusicMute(mute: boolean) {
        this.musicMuted = mute;

        Services.get(LocalStorageService).set("musicMuted", this.musicMuted);

        this.onMusicMuteChange.dispatch(this.musicMuted);

        this.updateMute();
    }

    public getMuted() {
        return this.muted;
    }

    public getSoundFxMuted() {
        return this.soundFxMuted;
    }

    public getMusicMuted() {
        return this.musicMuted;
    }

    public setVolume(volume: number) {
        this.volume = volume;

        Howler.Howler.volume(volume * this.baseVolume);

        Services.get(LocalStorageService).set("volume", this.volume);
    }

    public getVolume() {
        return this.volume;
    }

    public focus() {
        if (this.autoMuteOnUnfocus) {
            this.focusMuted = false;
        }
        this.updateMute();
        try {
            Howler.Howler.mute(false);
        } catch (e) {
            // Ignore non fatal error
        }
    }

    public unfocus() {
        if (this.autoMuteOnUnfocus) {
            this.focusMuted = true;
        }
        this.updateMute();
        try {
            Howler.Howler.mute(true);
        } catch (e) {
            // Ignore non fatal error
        }
    }

    public isMutePreferenceSet() {
        return this.muteSaved;
    }

    public registerAlias(alias: string, sound: string) {
        this.soundAliases.set(alias, sound);
    }

    public registerPlay(event: string, configOrConfigs: SoundPlayConfig | SoundPlayConfig[]) {
        const configs = asArray(configOrConfigs);
        for (const config of configs) {
            if (!this.eventStartSounds.has(event)) {
                this.eventStartSounds.set(event, []);
            }
            this.eventStartSounds.get(event).push(config);

            this.usedSounds.set(config.id, true);
        }
    }

    public registerPlayArgs(event: string, args: string[], configOrConfigs: SoundPlayConfig | SoundPlayConfig[]) {
        event = this.getSoundEventName(event, ...args);
        this.registerPlay(event, configOrConfigs);
    }

    public registerStop(event: string, configOrConfigs: SoundStopConfig | SoundStopConfig[]) {
        const configs = asArray(configOrConfigs);
        for (const config of configs) {
            if (!this.eventStopSounds.has(event)) {
                this.eventStopSounds.set(event, []);
            }
            this.eventStopSounds.get(event).push(config);
        }
    }

    public registerStopArgs(event: string, args: string[], configOrConfigs: SoundStopConfig | SoundStopConfig[]) {
        event = this.getSoundEventName(event, ...args);
        this.registerStop(event, configOrConfigs);
    }

    public registerVolume(event: string, configOrConfigs: SoundVolumeConfig | SoundVolumeConfig[]) {
        const configs = asArray(configOrConfigs);
        for (const config of configs) {
            if (!this.eventVolumeSounds.has(event)) {
                this.eventVolumeSounds.set(event, []);
            }
            this.eventVolumeSounds.get(event).push(config);
        }
    }

    public registerVolumeArgs(event: string, args: string[], configOrConfigs: SoundVolumeConfig | SoundVolumeConfig[]) {
        event = this.getSoundEventName(event, ...args);
        this.registerVolume(event, configOrConfigs);
    }

    public registerTerminator(playThisSound: string, whenThisSoundStops: string) {
        this.terminators.set(whenThisSoundStops, playThisSound);
    }

    public findUnusedSounds(sounds: string[]) {
        sounds.forEach((sound) => {
            if (!this.usedSounds.has(sound)) {
                logger.warn("Unused sound:", sound);
            }
        });
    }

    public getSeek(soundId: string, howlerId: number) {
        let seekTime: number = 0;
        const syncSoundAlias = this.soundAliases.get(soundId) || soundId;
        const soundToSyncTo = this.playingSounds.get(soundId)[0];
        if (soundToSyncTo) {
            if (this.getSoundSprite(soundId).playing(howlerId)) {
                seekTime = this.getSoundSprite(soundId).seek(howlerId) as number;
            } else {
                seekTime = 0;
            }

            // Make seek time relative
            seekTime -= this.soundSpriteDefinition[syncSoundAlias][0] / 1000;

            return seekTime;
        }

        return 0;
    }

    public getSoundSprites() {
        return this.soundSprites;
    }

    public getSoundDuration(soundName: string): number {
        const sound = this.soundSpriteDefinition[soundName];
        return sound[1];
    }

    protected addAutomaticSoundEvents() {
        Layers.onEnterScene.add((layer: string, scene: string) => Services.get(SoundService).event(SoundEvent.enter_scene_LAYER_SCENE, layer, scene));
        Layers.onExitScene.add((layer: string, scene: string) => Services.get(SoundService).event(SoundEvent.exit_scene_LAYER_SCENE, layer, scene));
    }

    protected playPendingSounds() {
        this.soundsWaitingForLoad.forEach((soundPlayConfig: SoundPlayConfig) => {
            this.playSound(soundPlayConfig).execute();
        });
        this.soundsWaitingForLoad.clear();
    }

    protected onSoundUnlocked() {
        this.isUnlocked = true;
        this.musicQueue.forEach((aliasId) => {
            const musicId = this.playingSounds.get(aliasId)[0].howlerId;
            if (!this.manualLooping) {
                this.getSoundSprite(aliasId).loop(true, musicId as any);
            }
        });
        Services.get(SoundService).event(SoundEvent.sounds_unlocked);
    }

    protected setMute(muted: boolean) {
        const oldMuted = this.muted;
        this.muted = muted;
        Howler.Howler.volume((muted) ? 0 : this.volume * this.baseVolume);

        if (oldMuted !== this.muted) {
            this.onMuteChange.dispatch(this.muted);
        }

        this.muteSaved = true;
        Services.get(LocalStorageService).set("muted", this.muted);
        this.updateMute();
    }

    protected getMutedForSound(soundConfig: SoundPlayConfig) {
        const aliasId = soundConfig.id;

        if (this.focusMuted) {
            return true;
        }

        let muted = this.muted;
        if (!muted) {
            if (soundConfig.music) {

                // Only play last music in list, unless currently fading
                if (this.musicQueue.indexOf(aliasId) < this.musicQueue.length - 1 && this.fadingMusic !== aliasId) {
                    muted = true;
                }

                if (this.musicMuted) {
                    muted = true;
                }
            } else {
                if (this.soundFxMuted) {
                    muted = true;
                }
            }
        }

        return muted;
    }

    protected playSound(soundPlayConfig: SoundPlayConfig): Contract<void> {
        // TODO: Reduce cyclomatic complexity
        // tslint:disable-next-line:cyclomatic-complexity
        let contract = new Contract<void>((resolve) => {
            let aliasId = soundPlayConfig.id;

            if (!aliasId && soundPlayConfig.music) {
                if (!this.musicQueue.length) {
                    resolve(null);
                    return;
                }
                aliasId = this.musicQueue[this.musicQueue.length - 1];
            }

            const soundId = this.soundAliases.get(aliasId) || aliasId;
            let seekTime = 0;
            let fadeFrom = -1;

            if (soundPlayConfig.sync) {
                seekTime = this.getSyncedSeek(soundPlayConfig.sync, soundId);
            }

            if (!this.soundSprites.length) {
                if (soundPlayConfig.loop) {
                    this.soundsWaitingForLoad.set(aliasId, soundPlayConfig);
                }
                resolve(null);
                return;
            }

            if (soundPlayConfig.music) {
                const currentMusic = this.musicQueue[this.musicQueue.length - 1];

                if (this.musicQueue.indexOf(aliasId) === -1) {
                    this.musicQueue.push(aliasId);
                }

                // If music fades in, old music should fade out (cross fade)
                if (currentMusic && currentMusic !== aliasId && soundPlayConfig.fadeTime > 0) {
                    seekTime = this.getSyncedSeek(currentMusic, soundId);
                    fadeFrom = 0;
                    this.fadingMusic = currentMusic;
                    this.stopSound(currentMusic, soundPlayConfig.fadeTime);
                }
            }

            if (!this.playingSounds.has(aliasId)) {
                this.playingSounds.set(aliasId, []);
            }

            let howlerId: number;

            let playingSounds = this.playingSounds.get(aliasId);

            try {
                if (this.pausedSounds.has(aliasId)) {
                    // Reuse paused sound
                    howlerId = this.pausedSounds.get(aliasId);
                    this.getSoundSprite(soundId).off("fade", this.fadeCompletePause, howlerId);
                    this.getSoundSprite(soundId).off("fade", this.fadeCompleteStop, howlerId);
                    this.getSoundSprite(soundId).volume(1, howlerId);
                    if (this.isUnlocked || soundPlayConfig.music) {
                        this.getSoundSprite(soundId).play(howlerId);
                    }
                } else {
                    if (!playingSounds.length || !soundPlayConfig.music) {
                        if (playingSounds.length > 0 && soundPlayConfig.singleton) {
                            this.stopSound(aliasId);
                            playingSounds = this.playingSounds.get(aliasId);
                        }

                        // Create new sound instance
                        if (this.isUnlocked || soundPlayConfig.music) {
                            howlerId = this.getSoundSprite(soundId).play(soundId);
                            this.getSoundSprite(soundId).mute(this.getMutedForSound(soundPlayConfig), howlerId);
                            if (!this.manualLooping) {
                                this.getSoundSprite(soundId).loop(soundPlayConfig.loop, howlerId);
                            }
                            playingSounds.push(new PlayingSound(howlerId, soundPlayConfig));

                            // Sync sound with music if applicable
                            if (seekTime) {
                                this.getSoundSprite(soundId).seek(seekTime, howlerId);
                            }
                        }
                    }
                }
            } catch (e) {
                throw new Error("Error trying to play sound " + aliasId + "(" + soundId + ")");
            }

            this.updateMute();

            for (const playingSound of playingSounds) {
                if (soundPlayConfig.fadeTime > 0) {
                    this.fade(fadeFrom === -1 ? playingSound.config.volume : fadeFrom,
                        soundPlayConfig.volume, soundPlayConfig.fadeTime, playingSound.howlerId, playingSound.config.id, soundPlayConfig).execute();
                } else {
                    this.getSoundSprite(soundId).volume(soundPlayConfig.volume, playingSound.howlerId);
                }
                playingSound.config.volume = soundPlayConfig.volume;
            }

            if (!playingSounds && soundPlayConfig.fadeTime > 0) {
                this.fade(0, soundPlayConfig.volume, soundPlayConfig.fadeTime, howlerId, soundPlayConfig.id, soundPlayConfig).execute();
            }

            const resolveContract = () => {
                if (contract) {
                    contract = null;
                    resolve(null);
                }
            };

            this.getSoundSprite(soundId).once("end", resolveContract, howlerId);
            if (this.manualLooping && soundPlayConfig.loop) {

                const manualLoop = () => {
                    try {
                        this.getSoundSprite(soundId).seek(0, howlerId);
                        this.getSoundSprite(soundId).play(howlerId);
                    } catch (e) {
                        this.getSoundSprite(soundId).off("end", manualLoop, howlerId);
                    }
                };

                this.getSoundSprite(soundId).on("end", manualLoop, howlerId);
            }

            // If music is not looping, it should be removed from queue when finished
            if (soundPlayConfig.music && !soundPlayConfig.loop) {
                this.getSoundSprite(soundId).on("end", () => this.stopSound(aliasId), howlerId);
            }

            this.getSoundSprite(soundId).once("stop", resolveContract, howlerId);
        });

        return contract;
    }

    protected volumeSound(config: SoundVolumeConfig) {
        const adjustVolumes = () => {
            const playingSounds = this.playingSounds.get(config.id);
            if (playingSounds) {
                const volumeContracts = playingSounds.map((sound: PlayingSound) => {
                    if (config.time) {
                        let fromVolume = config.fromVolume;
                        if (isNaN(fromVolume)) {
                            fromVolume = this.getSoundSprite(sound.config.id).volume(sound.howlerId) as number;
                        }
                        return () => this.fade(fromVolume, config.volume, config.time, sound.howlerId, sound.config.id, sound.config);
                    } else {
                        this.getSoundSprite(sound.config.id).volume(config.volume, sound.howlerId);
                        return () => Contract.empty();
                    }
                });

                new Parallel(volumeContracts).then(() => {
                    if (config.completeEvent) {
                        this.customEvent(config.completeEvent);
                    }
                });
            }
        };

        if (config.delay) {
            Timer.setTimeout(adjustVolumes, config.delay);
        } else {
            adjustVolumes();
        }
    }

    protected updateMute() {

        // Global mute.
        if (this.soundSprites.length) {
            this.soundSprites.forEach((soundSprite) => soundSprite.mute(this.muted));

            if (this.muted) {
                return;
            }
        }

        // Toggle individual sounds.
        this.playingSounds.forEach((sounds: PlayingSound[]) => {
            for (const sound of sounds) {
                const howlerId = sound.howlerId;

                this.getSoundSprite(sound.config.id).mute(this.getMutedForSound(sound.config), howlerId);
            }
        });
    }

    protected stopSound(aliasId: string, fadeTime: number = 0, pause: boolean = false): boolean {
        let soundsToStop = this.playingSounds.get(aliasId);

        if (this.soundsWaitingForLoad.has(aliasId)) {
            this.soundsWaitingForLoad.delete(aliasId);
        }

        if (soundsToStop && soundsToStop.length > 0) {
            soundsToStop = [...soundsToStop];
            this.playingSounds.set(aliasId, []);

            for (const sound of soundsToStop) {

                if (sound.config.music) {
                    const musicIndex = this.musicQueue.indexOf(aliasId);
                    if (musicIndex !== -1) {
                        this.musicQueue.splice(musicIndex, 1);
                    }
                    if (this.musicQueue.length && fadeTime > 0) {
                        const newMusic = this.playingSounds.get(this.musicQueue[this.musicQueue.length - 1]);
                        if (newMusic && newMusic.length) {
                            this.fade(0, newMusic[0].config.volume, fadeTime, newMusic[0].howlerId, newMusic[0].config.id, sound.config).execute();
                        }
                    }
                }

                if (pause) {
                    this.pausedSounds.set(aliasId, sound.howlerId);
                    this.playingSounds.get(aliasId).push(new PlayingSound(sound.howlerId, sound.config));
                } else if (this.pausedSounds.has(aliasId)) {
                    this.pausedSounds.delete(aliasId);
                }

                const completeCallback = pause ? this.fadeCompletePause : this.fadeCompleteStop;

                if (fadeTime === 0) {
                    completeCallback(sound.howlerId, sound.config.id);
                } else {
                    const id = sound.howlerId;
                    const fromVolume = this.getSoundSprite(sound.config.id).volume(id) as number;
                    this.fade(fromVolume, 0, fadeTime, sound.howlerId, sound.config.id, sound.config).then(() => completeCallback(id, sound.config.id));
                }
            }

            this.updateMute();

            return true;
        } else {
            return false;
        }
    }

    /**
     * Howlers fade is really temperamental. I suggest using this instead
     */
    protected fade(from: number, to: number, duration: number, id: number, name: string, soundPlayConfig: SoundPlayConfig) {
        if (this.getMutedForSound(soundPlayConfig)) {
            return Contract.empty();
        }

        if (this.fadeContracts.has(id)) {
            this.fadeContracts.get(id).cancel();
        }

        this.getSoundSprite(name).volume(from, id);
        const obj = { v: from };

        const tween = new TweenJS.Tween(obj)
            .to({ v: to }, duration)
            .onUpdate(
                () => this.getSoundSprite(name).volume(obj.v, id)
            );
        const contract = TweenContract.wrapCancellableTween(tween);

        this.fadeContracts.set(id, contract);
        return contract;
    }

    protected fadeCompletePause = (howlerId: number, name: string) => {
        this.getSoundSprite(name).pause(howlerId);
        this.updateMute();
    }

    protected fadeCompleteStop = (howlerId: number, name: string) => {
        this.getSoundSprite(name).stop(howlerId);
        this.updateMute();
    }

    protected loadSettings() {
        const soundFxMuted = Services.get(LocalStorageService).getBoolean("soundFxMuted");
        const musicMuted = Services.get(LocalStorageService).getBoolean("musicMuted");
        const muted = Services.get(LocalStorageService).getBoolean("muted");
        const volume = Services.get(LocalStorageService).getNumber("volume");

        if (soundFxMuted !== null) {
            this.soundFxMuted = soundFxMuted;
        }

        if (musicMuted !== null) {
            this.musicMuted = musicMuted;
        }

        if (volume !== null) {
            this.setVolume(volume);
        }

        if (muted !== null) {
            this.muteSaved = true;
            this.setMute(muted);
        }
    }

    // Gets a seek time for target to begin at in order to sync perfectly with source
    protected getSyncedSeek(source: string, target: string) {
        const syncSoundAlias = this.soundAliases.get(source) || source;
        const soundToSyncTo = (this.playingSounds.get(source) || [])[0];

        let seekTime: number = 0;
        if (soundToSyncTo) {
            seekTime = this.getSoundSprite(soundToSyncTo.config.id).seek(soundToSyncTo.howlerId) as number;
        } else {
            return seekTime;
        }

        // Make seek time relative
        seekTime -= this.soundSpriteDefinition[syncSoundAlias][0] / 1000;
        seekTime += this.soundSpriteDefinition[target][0] / 1000;

        return seekTime;
    }

    protected verifySoundsAllExist() {
        this.soundAliases.forEach((soundName: string) => {
            if (!this.soundSpriteDefinition[soundName]) {
                Logger.error("Missing sound: ", soundName);
            }
        });
    }

    // Return the correct sound sprite for the id you want to play
    private getSoundSprite(soundId: string) {
        if (this.soundSprites.length === 1) {
            return this.soundSprites[0];
        } else {
            return this.soundSpriteNameLookup.get(soundId) || this.soundSpriteNameLookup.get(this.soundAliases.get(soundId));
        }
    }
}

export interface SoundPlayConfig {
    id: string;
    loop?: boolean;
    fadeTime?: number;
    singleton?: boolean;
    music?: boolean;
    volume?: number;
    sync?: string;
}

export interface SoundStopConfig {
    id: string;
    fadeTime?: number;
    pause?: boolean;
}

export interface SoundVolumeConfig {
    id: string;
    volume: number;
    fromVolume?: number;
    time?: number;
    delay?: number;
    completeEvent?: string;
}

export class PlayingSound {
    constructor(public howlerId: number, public config: SoundPlayConfig) {
    }
}
