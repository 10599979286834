/**
 * Converts a JSON object to a map of a specified type keyed by strings
 *
 * @param object {any}
 */
export function parseJSONMap<T>(object: any): Map<string, T> {
    const map = new Map<string, T>();

    for (const key of Object.keys(object)) {
        map.set(key, object[key] as T);
    }

    return map;
}

/**
 * Convert a circular json object, like a spin record, into a json string
 * @param object {any}
 * @param pretty Pretty print the json
 * @returns JSON string
 */
export function stringifyCircularObject(object: any, pretty: boolean): string {
    let cache: any[] = [];

    const json = JSON.stringify(object, (key, value) => {
      if (typeof value === "object" && value !== null) {
        // Duplicate reference found, discard key
        if (cache.indexOf(value) !== -1) {
            return;
        }

        // Store value in our collection
        cache.push(value);
      }
      return value;
    }, (pretty) ? 4 : undefined);

    cache = null;

    return json;
}

/////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////
