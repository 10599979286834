import * as SentryBrowser from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { Record } from "appworks/model/gameplay/records/record";
import { Service } from "appworks/services/service";
import { Services } from "appworks/services/services";
import { uiFlags } from "appworks/ui/flags/ui-flags";
import Logger = require("js-logger");

declare const __DEBUG__: boolean;
declare const __ENVIRONMENT__: string;
declare const __SENTRY_DSN__: string;
declare const __VERSION__: string;
declare const __GAMENAME__: string;

export type SentryLevel = SentryBrowser.Severity;

export class SentryService extends Service {
    public static setup() {
///////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
/////////////////
////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////////////
///////////////////

/////////////////////////////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
/////////////
/////////
//////////////////
    }

    private static initialised: boolean = false;

    public init(): void {
        if (this.isInitialised()) {
            uiFlags.onFlagsUpdated.add(() => this.addUIBreadcrumb());

            Logger.debug("Sentry has initialised and it ready to use.");
        }
    }

    public isInitialised(): boolean {
        return SentryService.initialised;
    }

    public setUser(user: SentryBrowser.User): void {
        this.sendSentryRequest("setUser", () => SentryBrowser.setUser(user));
    }

    public addBreadcrumb(payload: SentryBrowser.Breadcrumb, identifier: string = "addBreadcrumb"): void {
        this.sendSentryRequest(identifier, () => SentryBrowser.addBreadcrumb({
            ...payload,
            level: payload.level ?? SentryBrowser.Severity.Info,
            timestamp: Date.now()
        }));
    }

    public addStateBreadcrumb(state: string, stage: string, superState: boolean): void {
        this.addBreadcrumb({
            type: "Debug",
            category: "game.state",
            message: `${((superState) ? "[superstate]" : "[state]")} ${state} => ${stage}`,
            level: SentryBrowser.Severity.Info
        }, "addStateBreadcrumb");
    }

    public addDecisionBreadcrumb(decision: string, result: boolean): void {
        this.addBreadcrumb({
            type: "Debug",
            category: "game.state",
            message: `[decision] ${decision} => ${result}`,
            level: SentryBrowser.Severity.Info
        }, "addDecisionBreadcrumb");
    }

    public addUIBreadcrumb(): void {
        this.addBreadcrumb({
            type: "Debug",
            category: "ui.flag",
            message: `flags changed > ${uiFlags.bitmask}`,
            level: SentryBrowser.Severity.Info
        }, "addUIBreadcrumb");
    }

    public addRecordBreadcrumb(record: Record): void {
        const logRecord = this.convertRecordToLog(record);

        this.addBreadcrumb({
            type: "Debug",
            category: "game.record",
            message: JSON.stringify(logRecord, null, 4),
            level: SentryBrowser.Severity.Info
        }, "addRecordsBreadcrumb");
    }

    public setContext(key: string, value: { [key: string]: any }): void {
        SentryBrowser.setContext(key, value);
    }

    public sendTestEvent(): void {
        this.sendSentryRequest("sendTestEvent", () => {
            // SentryBrowser.eventFromMessage({}, "Test Event");
            // SentryBrowser.eventFromException({}, new Error(message: "Test Error"));
        });
    }

    protected sendSentryRequest(identifier: string, request: () => void): void {
///////////////////////////////////////////////////////////
///////////////////////////////////
/////////////////
//////////////////////////
///////////////////////////////////////////////////////////////////////////
/////////////////////////
///////////////////////////////////////////////////////////////
/////////////
/////////
//////////////////
    }

    protected convertRecordToLog(record: Record): any {
        return {
            id: record.id,
            cashWon: record.cashWon,
            wager: record.wager,
            results: record.results.map((result) => {
                return { ...result, linkedResult: undefined };
            }),
            children: record.children.map((child) => this.convertRecordToLog(child)),
            hasChildren: record.hasChildren,
            lastChild: record.lastChild
        };
    }
}

SentryService.setup();

//////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
//////////
