import { normalizeIndex, wrapIndex } from "appworks/utils/collection-utils";
import { modulo } from "appworks/utils/math/modulo";
import { RandomFromArray, RandomRangeInt } from "appworks/utils/math/random";
import { ReelSubcomponent } from "slotworks/components/matrix/reel/reel-subcomponent";
import { slotDefinition } from "slotworks/model/slot-definition";
import { SymbolDefinition } from "slotworks/model/symbol-definition";
import { ReelstripPosition } from "./reel-spinner";
import { ReelSpinnerResultBuffer } from "./reel-spinner-result-buffer";
import Logger = require("js-logger");

export class StandardReelSpinnerResultBuffer implements ReelSpinnerResultBuffer {

    // Force a minimum number of symbols to buffer the result with
    public static minResultBufferSize: number = 0;

    // Force a minimum number of symbols to start the next spin with
    public static minStartBufferSize: number = 0;

    protected reel: ReelSubcomponent;

    protected stackFinishBuffer: number = 0;
    protected stackResultBuffer: number = 0;
    protected scatterBuffer: boolean = false;

    public init(reel: ReelSubcomponent) {
        this.reel = reel;
    }

    public getBufferSize(result: ReelstripPosition, current: ReelstripPosition, symbolsUntilStop: number): number {

        let bufferSize = 0;

        // Include stacks in random spin which must be finished
        const currentNextSymbol = slotDefinition.getSymbolDefinition(wrapIndex(current.index + 1, current.reelstrip));
        const currentTopSymbol = slotDefinition.getSymbolDefinition(wrapIndex(current.index, current.reelstrip));
        this.stackFinishBuffer = currentTopSymbol.height - 1;
        for (let y = 1; y < currentTopSymbol.height; y++) {
            const nextSymbol = slotDefinition.getSymbolDefinition(wrapIndex(current.index + y, current.reelstrip));
            this.log(">", nextSymbol.id, currentTopSymbol.id);
            if (nextSymbol.id === currentTopSymbol.id) {
                this.stackFinishBuffer--;
            } else {
                break;
            }
        }
        bufferSize += this.stackFinishBuffer;

        // Include stacks in result which must be finished
        const bottomResultSymbol = slotDefinition.getSymbolDefinition(wrapIndex(result.index + this.reel.size - 1, result.reelstrip));
        this.log(this.reel.index, "BOTTOM", bottomResultSymbol.id);
        if (bottomResultSymbol.height > 1) {

            // Get remaining size of stack
            this.stackResultBuffer = bottomResultSymbol.height - 1;
            for (let y = 1; y < bottomResultSymbol.height; y++) {
                const nextSymbol = slotDefinition.getSymbolDefinition(wrapIndex(result.index + this.reel.size - 1 - y, result.reelstrip));
                this.log(">", nextSymbol.id, bottomResultSymbol.id);
                if (nextSymbol.id === bottomResultSymbol.id) {
                    this.stackResultBuffer--;
                } else {
                    break;
                }
            }
            bufferSize += this.stackResultBuffer;
        }

        // Add buffer symbol if first result symbol + next symbol are both scatters
        this.scatterBuffer = false;
        if ((currentTopSymbol.scatter || currentNextSymbol.scatter) && bottomResultSymbol.scatter) {
            this.scatterBuffer = true;
            bufferSize++;
        }

        // Ensure it's possible to fit available symbol sizes in the remaining stop distance
        const smallestSymbol = result.reelstrip.reduce((smallest, symbol) => Math.min(smallest, slotDefinition.getSymbolDefinition(symbol).height), Infinity);
        bufferSize = Math.max(StandardReelSpinnerResultBuffer.minResultBufferSize, bufferSize);

        const unfillableBuffer = modulo((symbolsUntilStop + bufferSize) - (this.stackFinishBuffer + this.stackResultBuffer + this.reel.size + 1), smallestSymbol);
        if (unfillableBuffer) {
            bufferSize += smallestSymbol - unfillableBuffer;
        }

        this.log("buffer size",
            this.reel.index,
            "smallestSymbol:",
            smallestSymbol,
            "symbolsUntilStop:",
            symbolsUntilStop,
            "stackFinishBuffer:",
            this.stackFinishBuffer,
            "stackResultBuffer:",
            this.stackResultBuffer,
            "scatterBuffer:",
            this.scatterBuffer,
            "unfillableBuffer:",
            unfillableBuffer,
            "bufferSize:",
            bufferSize,
            JSON.stringify(result.reelstrip.slice(result.index,
                result.index + this.reel.size)));

        return bufferSize;
    }

    public generateResultBuffer(incoming: string[], result: ReelstripPosition, current: ReelstripPosition): void {

        // If no buffer is needed, don't run this function (example backward spinning reels where they can just come in straight away)
        if (incoming.length <= this.reel.size) {
            return;
        }

        const bufferSize = incoming.length;
        this.log(this.reel.index, "Incoming", incoming, incoming.length);

        let randomBufferStart = this.reel.size + 1;
        let randomBufferEnd = incoming.length;

        // Keep track of last stack to be added randomly (if any) - sequential stacks should never be present
        let lastRandomStack = "";

        // Check if a stack is already in view and needs to be finished & by how much
        const currentTopSymbol = slotDefinition.getSymbolDefinition(wrapIndex(current.index, current.reelstrip));
        this.log(this.reel.index, "TOP", current.index, currentTopSymbol.id);
        if (this.stackFinishBuffer) {
            randomBufferEnd = incoming.length - this.stackFinishBuffer;

            // Finish off stack
            while (this.stackFinishBuffer > 0) {
                incoming.splice(incoming.length - this.stackFinishBuffer, 1, currentTopSymbol.id);
                // Since we're splicing in the continuation of the stack, the normal continuation should be removed from the current reelstrip (we're essentially moving it down).
                // It must be replaced with other symbols otherwise the splice in will be in the wrong place
                current.reelstrip.splice(normalizeIndex(current.index - 1, current.reelstrip), 1, this.getSafeBufferSymbol().id);
                this.stackFinishBuffer--;
            }

            this.log(this.reel.index, "Finishing off stack", incoming);
        }

        // If result contains a partial stack, buffer must contain the bottom of that stack
        const bottomResultSymbol = slotDefinition.getSymbolDefinition(incoming[this.reel.size] || incoming[incoming.length - 1]);
        this.log(this.reel.index, "BOTTOM", bottomResultSymbol.id);
        if (bottomResultSymbol.height > 1) {
            lastRandomStack = bottomResultSymbol.id;
        }
        if (this.stackResultBuffer) {
            randomBufferStart = this.reel.size + this.stackResultBuffer + 1;

            this.log(this.reel.index, "Adding in remaining wilds below result", this.stackResultBuffer);

            // Finish off stack
            while (this.stackResultBuffer > 0) {
                incoming.splice(this.reel.size + this.stackResultBuffer, 1, bottomResultSymbol.id);
                this.stackResultBuffer--;
            }

            this.log(this.reel.index, "Introducing stack", incoming);
        }

        this.log(this.reel.index, "FILLING IN BUFFER", randomBufferStart, randomBufferEnd, (randomBufferEnd - randomBufferStart), incoming);

        let infinityTimeout = 0;

        // Fill remaining space with non-wild result symbols
        let symbolPool: string[] = [];

        if (randomBufferEnd > randomBufferStart) {
            for (let y = randomBufferStart; y < randomBufferEnd; y++) {

                const remainingBufferSize = randomBufferEnd - y;

                // Replenish symbol pool
                if (!symbolPool.length) {
                    symbolPool = [...result.reelstrip];

                    // Remove actual result from symbolPool
                    for (let i = 0; i < this.reel.size + 2; i++) {
                        symbolPool.splice(result.index, 1);
                    }
                }

                infinityTimeout++;
                if (infinityTimeout > 1000) {
                    throw new Error(`Buffer could not be filled, impossible to fill buffer with valid symbols. remaining buffer size ${remainingBufferSize}`);
                }

                const r = Math.floor(Math.random() * symbolPool.length);

                const symbol = symbolPool.splice(r, 1)[0];
                const symbolDef = slotDefinition.getSymbolDefinition(symbol);

                this.log(this.reel.index, "ATTEMPTING TO INSERT", symbolDef.name);

                if (remainingBufferSize >= symbolDef.height) {
                    // Stacks are allowed in buffer, but can only be complete stacks
                    if (symbolDef.height > 1) {
                        // Check stack is not repeating a neighbouring stack
                        let invalidStack = false;
                        if (remainingBufferSize - symbolDef.height === 0) {
                            if (symbolDef.name === currentTopSymbol.name) {
                                this.log(this.reel.index, "INVALID SEQUENTIAL STACK", symbolDef.name, bottomResultSymbol.name);
                                invalidStack = true;
                            }
                        }

                        if (symbolDef.name === lastRandomStack) {
                            this.log(this.reel.index, "INVALID SEQUENTIAL STACK", symbolDef.name, lastRandomStack);
                            invalidStack = true;
                        }

                        if (invalidStack) {
                            y--;
                            continue;
                        }
                        this.log("STACK DEBUGGING", symbolDef.name, lastRandomStack, bottomResultSymbol.name);

                        lastRandomStack = symbolDef.name;

                        // Inserting a stack into buffer
                        for (let stackY = y; stackY < y + symbolDef.height; stackY++) {
                            incoming[stackY] = symbol;
                        }
                        this.log(this.reel.index, "FILL IN", symbol, symbolDef.height, incoming);
                        y += symbolDef.height - 1;
                    } else {
                        lastRandomStack = "";
                        // Suitable symbol found
                        incoming[y] = symbol;
                        this.log(this.reel.index, "FILL IN", symbol, incoming);
                    }
                } else {
                    // Symbol too big to fit into buffer, try again
                    this.log(this.reel.index, "STACK TOO LARGE", symbolDef.name);
                    y--;
                }
            }
        }

        // Prevent 2 scatters being next to each other (bottom of result)
        if (this.scatterBuffer) {
            incoming.splice(randomBufferStart, 1, this.getSafeBufferSymbol().id);
        }

        if (incoming.length !== bufferSize) {
            throw new Error("Internal error");
        }

        this.log(this.reel.index, "Final incoming", incoming);
    }

    public generateStartBuffer(spinReelstrip: ReelstripPosition, currentView: string[]): string[] {
        let buffer: string[] = [];

        // Populate buffer with symbols from new reelstrip to minimum buffer size
        while (buffer.length < StandardReelSpinnerResultBuffer.minStartBufferSize) {
            const r = Math.floor(Math.random() * spinReelstrip.reelstrip.length);
            const symbol = spinReelstrip.reelstrip[r];

            buffer.push(symbol);
        }

        // Complete unfinished stacks as required
        const topResultSymbol = slotDefinition.getSymbolDefinition(currentView[0]);

        if (topResultSymbol.height > 1) {
            const stackBuffer = [];
            for (let i = 0; i < topResultSymbol.height - 1; i++) {
                stackBuffer.push(topResultSymbol.name);
            }

            for (let y = 1; y < topResultSymbol.height; y++) {
                const nextSymbol = slotDefinition.getSymbolDefinition(currentView[y]);
                if (nextSymbol.name === topResultSymbol.name) {
                    stackBuffer.pop();
                } else {
                    break;
                }
            }

            if (stackBuffer.length) {
                if (buffer.length <= stackBuffer.length) {
                    buffer.splice(buffer.length - stackBuffer.length, stackBuffer.length, ...stackBuffer);
                } else {
                    buffer = stackBuffer;
                }
            }
        }

        return buffer;
    }

    public getRandomStartPosition(reelstrip: string[], currentView: string[]): number {
        return RandomRangeInt(0, reelstrip.length - 1);
    }

    // Returns a non scatter, non stack symbol
    protected getSafeBufferSymbol() {
        let symbol: SymbolDefinition;
        do {
            symbol = RandomFromArray(slotDefinition.symbolDefinitions);
        } while (symbol.scatter || (symbol.height && symbol.height > 1));

        return symbol;
    }

    protected log(...args: any[]) {
        // Logger.info("%c Reel spinner - buffer ", "background: #f4e242; color: #333", ...args);
    }
}
