export enum SlingoSoundEvent {
    // When you place your bet and start the slingo game
    play_game = "play_game",
    end_game_win = "end_game_win",
    end_game_no_win = "end_game_no_win",
    dab = "dab",
    // For when multiple dabs happen in 1 spin (1-5)
    dab_N = "dab_{0}",
    slingo = "slingo",
    // For double slingos etc, 1-4
    slingo_N = "slingo_{0}",
    attract_popup = "attract_popup",
    ladder_level_up = "ladder_level_up",
    // The level which has been achieved
    ladder_level_up_N = "ladder_level_up_{0}",
    purchase_spin = "purchase_spin",
    result_no_win = "result_no_win",
    result_normal_win = "result_normal_win",
    result_big_win = "result_big_win",
    result_full_house_win = "result_full_house_win",
    result_substake_win = "result_substake_win"
}