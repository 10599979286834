import { Components } from "appworks/components/components";
import { Contract } from "appworks/utils/contracts/contract";
import { LinesComponent } from "slotworks/components/lines/lines-component";

/** @deprecated Move to class function */
export function HideAllLinesCommand(): Contract<void> {

    Components.get(LinesComponent)?.hideAllLines();

    return new Contract<void>((resolve) => { resolve(null); });
}
