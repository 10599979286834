import { ClockSubcomponent } from "appworks/components/header/clock-component";
import { SoundToggleComponent } from "appworks/components/sound/sound-toggle-component";
import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { Layers } from "appworks/graphics/layers/layers";
import { RealityMode } from "appworks/model/settings/settings-model";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { ElapsedTimeComponent } from "slotworks/components/header/elapsed-time-component";
import { JurisdictionService } from "slotworks/services/jurisdiction/jurisdiction-service";
import { AbstractComponent } from "../abstract-component";

export class HeaderComponent extends AbstractComponent {
    protected clock: ClockSubcomponent;
    protected elapsedTime: ElapsedTimeComponent;
    protected soundToggle: SoundToggleComponent;
    protected mode: string;
    protected infoButton: FlexiButton;

    protected enabled: boolean = true;

    constructor() {
        super();

        Layers.get("Header")?.onSceneEnter.add(this.setup);

        Layers.get("Header")?.defaultScene().execute();
    }

    public setEnabled(enable: boolean) {
        this.enabled = enable;

        if (this.infoButton) {
            this.infoButton.setEnabled(this.enabled);
        }
    }

    public setMode(mode: RealityMode) {
        this.mode = mode;

        this.updateMode();
    }

    protected createClock() {
        return new ClockSubcomponent();
    }

    protected setup = () => {
        if (this.clock) {
            this.clock.destroy();
        }

        this.clock = this.createClock();

        if (this.elapsedTime) {
            this.elapsedTime.destroy();
        }

        if (Services.get(JurisdictionService).getElapsedTimeEnabled()) {
            this.clock.destroy();
            this.clock = new ClockSubcomponent("ukgc_clock");

            this.elapsedTime = new ElapsedTimeComponent();
        } else {
            Layers.get("Header").getFlexiText("ukgc_elapsed_time")?.setVisible(false);
            Layers.get("Header").getFlexiText("ukgc_clock")?.setVisible(false);
        }

        this.setupSoundToggle();
        this.updateMode();
    }

    protected updateMode() {
        const modeLabel = Layers.get("Header")?.getFlexiText("mode");
        if (modeLabel && this.mode) {
            modeLabel.setText(Services.get(TranslationsService).get(this.mode));
        }
    }

    protected setupSoundToggle() {
        if (this.soundToggle) {
            this.soundToggle.destroy();
            this.soundToggle = null;
        }

        const soundOnButton = Layers.get("Header")?.getFlexiButton("sound_on");
        if (soundOnButton) {
            const soundOffButton = Layers.get("Header").getFlexiButton("sound_off");

            this.soundToggle = new SoundToggleComponent(soundOnButton, soundOffButton);
        }
    }
}
