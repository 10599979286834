import { Result } from "appworks/model/gameplay/records/results/result";
import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { gridDisplay } from "../general-displays/grid-display";

export function symbolWinResultDisplay(record: SpinRecord, result: Result) {
    const container = document.createElement("div");
    container.style.width = "10vw";

    const reelDisplay = gridDisplay(record.grid, record.overlayGrid);

    const symbols = reelDisplay.querySelectorAll(`[id*=symbol_]`);
    [].forEach.call(symbols, (symbol: HTMLElement) => {
        symbol.style.opacity = ".1";
    });

    (result as SymbolWinResult).positions.forEach((position) => {
        const symbol = reelDisplay.querySelector(`#symbol_${position.x}_${position.y}`) as HTMLElement;
        symbol.style.opacity = "1";
    });

    if ((result as SymbolWinResult).multipliers) {
        container.innerHTML = "Multipliers: " + (result as SymbolWinResult).multipliers.join(",") + "<br>";
    }

    container.appendChild(reelDisplay);

    return container;
}
