import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { SlingoCompletionReason } from "slingo/integration/slingo-schema";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { slingoModel } from "slingo/model/slingo-model";
import { BigWinComponent } from "slotworks/components/bigwin/big-win-component";
import { BigWinState } from "slotworks/state-machine/standard/states/big-win-state";

export class SlingoBigWinState extends BigWinState {
    protected bigWinTriggered() {
        const isBigWin = Components.get(BigWinComponent).isBigWin(this.getTotalWin(), gameState.getCurrentGame().getTotalWagered());
        const isFullHouse = this.isFullHouse();

        return this.meetsWinRequirement() && (isBigWin || isFullHouse);
    }

    protected getResultWin(): number {
        // In all instances for Slingo, we'd want to use total win
        return this.getTotalWin();
    }

    protected meetsWinRequirement(): boolean {
        const totalWager = gameState.getCurrentGame().getTotalWagered();
        const totalWin = gameState.getCurrentGame().getTotalWin();
        const winThreshold = slingoModel.read().gameConfig.celebrateWinThreshold; // percentage, i.e. 100 for a 1x minimum

        return ((totalWin / totalWager) * 100) >= winThreshold;
    }

    /**
     * TODO 2.x - this is legacy for Lucky Joker, the full house animation is no longer shown at the end of the game in newer games
     * In Hot Rolls, I'm just overriding this and returning false, but this should eventually be removed completely
     */
    protected isFullHouse(): boolean {
        const result = gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult);
        return result?.completionReason === SlingoCompletionReason.TICKET_MATCHED;
    }
}