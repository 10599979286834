import { UIFlag, uiFlags, UIMenuFlag } from "./ui-flags";

export class UIFlagState {

    public static IDLE = new UIFlagState(UIFlag.IDLE);
    public static NOT_IDLE = new UIFlagState(0, UIFlag.IDLE);
    public static ALWAYS = new UIFlagState(0, 0);
    public static NEVER = new UIFlagState(UIFlag.NEVER, 0);
    public static STAKE_MENU = new UIFlagState(UIFlag.IDLE | UIMenuFlag.STAKE, 0);
    public static AUTOPLAY_MENU = new UIFlagState(UIFlag.IDLE | UIMenuFlag.AUTO, 0);
    public static RULES_MENU = new UIFlagState(UIFlag.IDLE | UIMenuFlag.RULES, 0);
    public static FREESPINS = new UIFlagState(UIFlag.FREE_SPINS, 0);
    public static RESPINS = new UIFlagState(UIFlag.RESPINS, 0);
    public static NOT_FREE_BETS = new UIFlagState(UIFlag.ALWAYS, UIFlag.FREE_BETS);
    public static IDLE_NOT_REPLAY = new UIFlagState(UIFlag.IDLE, UIFlag.REPLAY);

    constructor(public readonly mustMatch: number = 0, public readonly mustNotMatch: number = 0, public readonly anyMatch: number = 0) { }

    public test() {
        const mustMatch = ((uiFlags.bitmask & this.mustMatch) === this.mustMatch);
        const mustNotMatch = ((uiFlags.bitmask & this.mustNotMatch) === 0);
        const anyMatch = this.anyMatch > 0 ? (uiFlags.bitmask & this.anyMatch) > 0 : true;
        return mustMatch && anyMatch && mustNotMatch;
    }
}
