import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { gameState } from "appworks/model/game-state";
import { Record } from "appworks/model/gameplay/records/record";
import { Services } from "appworks/services/services";
import { clearElement } from "appworks/utils/dom-utils";
import { Signal } from "signals";
import Logger = require("js-logger");

export class RecordTree {

    public select: Signal = new Signal();

    private element: HTMLDivElement;
    private recordElements: HTMLAnchorElement[];

    constructor() {
        this.draw();

        this.update();
    }

    public toggle() {
        if (this.element.parentElement) {
            Services.get(CanvasService).rootNode.removeChild(this.element);
        } else {
            Services.get(CanvasService).rootNode.appendChild(this.element);
        }
    }

    public update() {

        const gameplay = gameState.getCurrentGame();
        clearElement(this.element);
        this.recordElements = [];

        const keyElement = document.createElement("div");
        keyElement.innerHTML = "🛑 = last record<br>";
        keyElement.innerHTML += "✔️/ ❌ = is branch complete<br>";
        keyElement.innerHTML += "<hr>";
        this.element.appendChild(keyElement);

        if (!gameplay.hasRecords()) {
            keyElement.innerHTML += "No records";
            return;
        }

        const printBranch = (record: Record, tabs: string) => {

            const lastChildLabel = record.lastChild ? "🛑" : "";
            const hasChildrenLabel = record.isBranchComplete() ? "✔️" : "❌";
            const label = `${tabs} ${record.id} ${lastChildLabel} ${hasChildrenLabel}`;

            const recordElement = document.createElement("a");
            this.recordElements.push(recordElement);
            recordElement.innerText = label;

            recordElement.style.textDecoration = "none";
            recordElement.style.whiteSpace = "pre";
            recordElement.style.color = "#ffffff";
            recordElement.style.display = "block";
            recordElement.style.cursor = "pointer";

            recordElement.onclick = () => {
                this.recordElements.forEach((e) => e.style.fontWeight = "normal");
                recordElement.style.fontWeight = "bold";
                Logger.info(record);
                this.select.dispatch(record);
            };

            this.element.appendChild(recordElement);

            tabs += "    ";
            for (const child of record.children) {
                printBranch(child, tabs);
            }
        };

        printBranch(gameplay.getRootRecord(), "");

        const currentRecord = gameplay.getCurrentRecord();
        this.select.dispatch(currentRecord);

        if (gameplay.isComplete()) {
            const completeElement = document.createElement("div");
            completeElement.innerText = " 🎉 Game complete 🎉";
            this.element.appendChild(completeElement);
        }
    }

    public clear() {
        clearElement(this.element);

        const keyElement = document.createElement("div");
        keyElement.innerHTML = "🛑 = last record<br>";
        keyElement.innerHTML += "✔️/ ❌ = is branch complete<br>";
        keyElement.innerHTML += "<hr>";
        keyElement.innerHTML += "No records";
        this.element.appendChild(keyElement);
    }

    private draw() {
        this.element = document.createElement("div");
        this.element.id = "integrationDebugger_resultTree";

        this.element.style.position = "fixed";
        this.element.style.top = this.element.style.left = "0";
        this.element.style.zIndex = "900";
        this.element.style.width = "20vw";
        this.element.style.height = "95vh";
        this.element.style.paddingTop = "5vh";
        this.element.style.paddingLeft = "1vw";
        this.element.style.pointerEvents = "all";
        this.element.style.overflow = "auto";

        this.element.style.backgroundColor = "#222";
        this.element.style.border = "solid 1px white";

        this.element.style.color = "#fff";
        this.element.style.fontSize = "18px";

        Services.get(CanvasService).rootNode.appendChild(this.element);
    }
}
