import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { deviceInfo } from "appworks/utils/device-info";

export class GMRCanvasService extends CanvasService {
    protected resize(event?: any): void {
        super.resize();

        const metaBar = document.getElementById("metaBar");
        const heightOffset: number = deviceInfo.isIpad() ? -10 : 0;
        const top: number = metaBar ? metaBar.clientHeight : 0;
        const maxHeight: number = window.innerHeight - top + heightOffset;

        this.canvasContainer.style.top = `${top}px`;
        this.canvasContainer.style.maxHeight = `${maxHeight}px`;

        const historyIframe = document.getElementById("history_iframe") as HTMLIFrameElement;
        if (historyIframe) {
            historyIframe.style.top = `${top}px`;
            historyIframe.style.maxHeight = `${maxHeight}px`;
        }
    }
}