
export enum RealityMode {
    FREE = "free_play",
    REAL = "real_play"
}

export class SettingsModel {
    public playMode: RealityMode = RealityMode.FREE;
    // TODO: Deprecate this in favour of TranslationService?
    public language: string = "en";
    public homeURL: string = "#";
    public historyURL: string = "#";

    public spaceToSpin: boolean = true;
    public displayCredits: boolean = false;
    public showIntro: boolean = true;
    public quickSpin: boolean = false;
    public turboSpin: boolean = false;
}
