import { Services } from "../services";
import { TranslationsService } from "./translations-service";

declare const __VERSION__: string;

export function StandardI18nAliases() {
    Services.get(TranslationsService).setAlias("err", "foo");

    Services.get(TranslationsService).setAlias("curr.inf", "infinity");

    // Autoplay
    Services.get(TranslationsService).setAlias("aut.swl", "single_win_limit_reached");
    Services.get(TranslationsService).setAlias("aut.lol", "loss_limit_reached");

    Services.get(TranslationsService).setFormat("debugRed", {
        fill: "red"
    });

    // Version
    Services.get(TranslationsService).set("game.version", "v" + __VERSION__);
}
