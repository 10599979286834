import { SliderComponent } from "appworks/components/ui/slider-component";
import { FlexiSlider } from "appworks/graphics/elements/flexi-slider";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { AbstractControl } from "../../abstract-control";

export class SliderControl extends AbstractControl {
    protected onChangeCallback: (x: number) => void;
    protected slider: SliderComponent;

    constructor(slider: FlexiSlider, label?: FlexiText) {
        super();

        this.slider = new SliderComponent(slider, label);
        this.onChangeCallback = (value: number) => this.onChange(value);
        this.slider.onChange.add(this.onChangeCallback);
        this.slider.onInput.add(this.onChangeCallback);
    }

    public destroy(): void {
        super.destroy();
        this.slider.destroy();
    }

    public setEnabled(enabled: boolean): void {
        super.setEnabled(enabled);
        this.slider.setEnabled(enabled);
    }

    public setupData(array: number[], currentValue: number): void {
        this.slider.setup(0, array.length - 1, 1);
        this.slider.setValue(array.indexOf(currentValue));
    }

    protected onChange(value: number): void {
        // Empty
    }
}
