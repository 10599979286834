import { AnimatedParticleConfig, AssetConfigSchema, FXParticleBundleConfig, ParticleConfig } from "appworks/config/asset-config-schema";
import { Container } from "appworks/graphics/pixi/container";
import { ParticleContainer } from "appworks/graphics/pixi/particle-container";
import { Service } from "appworks/services/service";
import { Services } from "appworks/services/services";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { deviceInfo } from "appworks/utils/device-info";
import { Timer } from "appworks/utils/timer";
import * as particles from "pixi-particles";
import { EffectSequence, FX, ParticleEmitter } from "revolt-fx";
import { GraphicsService } from "../graphics-service";
import Logger = require("js-logger");
import JSZip = require("jszip");
import { deepClone } from "appworks/utils/collection-utils";

export interface PIXIParticle {
    id: number;
    emitter: particles.Emitter;
}

export interface RevoltParticle {
    id: number;
    emitter: ParticleEmitter;
}

export interface RevoltParticleSequence {
    id: number;
    emitter: EffectSequence;
}

export class ParticleService extends Service {

    // Automatically cap particles above 50 to 50% on mobile
    public autoCap: boolean = true;

    private configs: Map<string, ParticleConfig>;
    private revoltFXBundles: string[] = [];

    private fx: FX;

    private lastTime: number;
    private emitters: Map<number, particles.Emitter> = new Map<number, particles.Emitter>();
    private emittersFX: Map<number, ParticleEmitter | EffectSequence> = new Map<number, ParticleEmitter | EffectSequence>();

    private emitterId: number = 0;
    private emitterFXId: number = 0;

    constructor() {
        super();

        this.configs = new Map<string, ParticleConfig>();
        this.fx = new FX();
    }

    public init(): void {
        // Do nothing. Handled in setup due to assetConfig requirements.
    }

    public setup(assetConfig: AssetConfigSchema) {
        const particleConfigs = assetConfig.layouts.all.particles;
        Object.keys(particleConfigs).forEach((key: string) => {
            if (particleConfigs.hasOwnProperty(key)) {
                if ((particleConfigs[key].config as FXParticleBundleConfig).revoltFX) {
                    const config = particleConfigs[key].config as FXParticleBundleConfig;
                    this.revoltFXBundles.push(config.revoltFX);
                } else {
                    this.configs.set(key, particleConfigs[key]);
                }
            }
        });
    }

    public initConfigs(particleConfigs: { [id: string]: ParticleConfig; }) {
        Object.keys(particleConfigs).forEach((key: string) => {
            if (particleConfigs.hasOwnProperty(key)) {
                this.configs.set(key, particleConfigs[key]);
            }
        });
    }

    public loadFXFiles(): Contract<void> {
        return new Parallel([
            ...this.revoltFXBundles.map((x) => {
                return () => Contract.wrapPromise(this.fx.loadBundleFiles(
                    `revoltfx-particles/${x}/settings.json`, 
                    `revoltfx-particles/${x}/revoltfx-spritesheet.json`
                ));
            }),
        ]);
    }

    public add(id: string, container: Container): PIXIParticle {

        this.emitterId++;

        const config = deepClone(this.configs.get(id));

        const processedTextures = config.textures.map((asset: string | AnimatedParticleConfig): any => {
            if (typeof asset === "string") {
                return Services.get(GraphicsService).getTexture(asset);
            } else {
                const processedFrames = asset.textures.map((frame: string) => Services.get(GraphicsService).getTexture(frame));
                return {
                    framerate: asset.framerate,
                    loop: asset.loop,
                    textures: processedFrames
                };
            }
        });

        if (deviceInfo.isPhone && this.autoCap) {
            let cappedParticles = config.config.maxParticles;
            if (cappedParticles > 50) {
                cappedParticles = 50 + (cappedParticles - 50) * 0.5;
            }
            config.config.maxParticles = cappedParticles;
        }

        const emitter = new particles.Emitter(
            container,
            processedTextures,
            config.config
        );

        if (container instanceof ParticleContainer) {
            container.emitterId = this.emitterId;
            container.emitter = emitter;
        }

        if (typeof config.textures[0] !== "string") {
            emitter.particleConstructor = particles.AnimatedParticle;
        }

        this.emitters.set(this.emitterId, emitter);
        emitter.emit = true;

        return { id: this.emitterId, emitter };
    }

    public addFX(id: string, container: Container, autoStart?: boolean, scale?: number): RevoltParticle | null {
        this.emitterFXId++;
        try {
            const emitter = this.fx.getParticleEmitter(id);
            emitter.init(container, autoStart, scale);

            this.emittersFX.set(this.emitterFXId, emitter);

            return { id: this.emitterFXId, emitter };
        } catch (e) {
            Logger.error(e);
            return null;
        }
    }

    public addFXSequence(id: string, container: Container, delay?: number, autoStart?: boolean, scale?: number): RevoltParticleSequence | null {
        try {
            const emitter = this.fx.getEffectSequence(id);
            emitter.init(container, delay, autoStart, scale);

            this.emittersFX.set(this.emitterFXId, emitter);

            return { id: this.emitterFXId, emitter };
        } catch (e) {
            Logger.error(e);
            return null;
        }
    }

    public remove(id: number | PIXIParticle, instant = true) {
        const emitterId = (typeof (id) === "number") ? id : id.id;
        const emitter = this.emitters.get(emitterId);
        if (emitter) {
            const delay = (instant) ? 0 : emitter.maxLifetime * 1000;

            if (!instant) {
                emitter.spawnChance = 0;
            }

            Timer.setTimeout(() => {
                emitter.emit = false;
                emitter.cleanup();
                emitter.destroy();
                this.emitters.delete(emitterId);
            }, delay);
        }
    }

    public removeFX(id: number | RevoltParticle | RevoltParticleSequence) {
        const emitterId = (typeof (id) === "number") ? id : id.id;
        const emitter = this.emittersFX.get(emitterId);
        if (emitter) {
            this.emitters.delete(emitterId);
        }
    }

    public update(time: number) {
        if (this.lastTime) {
            this.emitters.forEach((emitter: particles.Emitter) => {
                emitter.update((time - this.lastTime) * 0.001);
            });

            this.fx.update((time - this.lastTime) * 0.1);
        }

        this.lastTime = time;
    }

    public get(id: number) {
        return this.emitters.get(id);
    }

    public getFX(id: number) {
        return this.emittersFX.get(id);
    }

    public getAll(): particles.Emitter[] {
        return Array.from(this.emitters.values());
    }
}
