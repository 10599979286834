import { Gameplay } from "appworks/model/gameplay/gameplay";
import { Record } from "appworks/model/gameplay/records/record";
import { InitRequestPayload } from "appworks/model/gameplay/requests/init-request-payload";
import { RecordBuilder } from "appworks/server/builders/record-builder";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { GMRBalance } from "gaming-realms/integration/gmr-schema";
import { slingoModel } from "slingo/model/slingo-model";

export abstract class GMRRecordBuilder implements RecordBuilder {
    public id: string = "GMRRecordBuilder";

    public filter(request: InitRequestPayload, response: any): boolean {
        return false;
    }

    public build(gameplay: Gameplay, request: any, response: any): Record[] {
        // Pass through wrapper data from server to wrapper
        if (response?.data?.wrapper) {
            GamingRealms.wrapperInstance.updateWrapper(response.data.wrapper);
        }

        if (response?.data?.gameInstanceId) {
            slingoModel.write({ gameInstanceId: response?.data?.gameInstanceId });
        }

        return [];
    }

    // The wrapper expects an so.Balances object, but we're not sent data in this format in server responses
    protected processBalances(rawBalances: GMRBalance[], updateWrapperBalance: boolean = true): number {
        // History doesn't have access to the wrapper, so manually calculate this
        if (GamingRealms.isHistory()) {
            if (!rawBalances) { return null; }

            return rawBalances
                ?.map(b => b.amount * 100)
                ?.reduce((prev, curr) => prev + curr) ?? 0;
        }

        const hasBonusBalance = Boolean(rawBalances.find((bal) => bal.type === "BONUS"));

        const balances = new so.Balances(
            new so.Balance(so.BalanceType.CASH, rawBalances.find(bal => bal.type === "CASH").amount),
            hasBonusBalance ? new so.Balance(so.BalanceType.BONUS, rawBalances.find(bal => bal.type === "BONUS").amount) : null
        );

        if (updateWrapperBalance) {
            GamingRealms.wrapperInstance.updateBalance(balances);
        }

        return balances.getTotal() * 100;
    }
}


