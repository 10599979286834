import { Layers } from "appworks/graphics/layers/layers";
import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { ControlFactory } from "appworks/ui/factories/control-factory";
import { SlingoStakeTextControl } from "../controls/slingo-stake-text-control";

export class SlingoStakeTextControlFactory implements ControlFactory {
    public filter(layer: Layers): boolean {
        return Boolean(layer.getText("slingo_stake_label") || layer.getText("slingo_stake_value") || layer.getText("slingo_stake"));
    }

    public build(layer: Layers): AbstractControl[] {
        return [new SlingoStakeTextControl(layer)];
    }

}