
import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { BigWinComponent } from "slotworks/components/bigwin/big-win-component";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";

export class BigWinState extends State {
    public onEnter() {
        if (this.bigWinTriggered()) {
            this.bigWin().then(() => this.complete());
        } else {
            this.complete();
        }
    }

    public complete() {
        Services.get(TransactionService).setWinnings(this.getResultWin()).execute();
        Services.get(TransactionService).setTotalWin(this.getTotalWin());

        super.complete();
    }

    protected bigWinTriggered() {
        return Components.get(BigWinComponent).isBigWin(this.getResultWin(), Services.get(SlotBetService).getTotalStake());
    }

    protected bigWin(): Contract<boolean> {

        const gameplay = gameState.getCurrentGame();

        let winnings: number;
        if (gameplay.isCurrentRecordRoot()) {
            winnings = gameState.getCurrentGame().getTotalWin();
        } else {
            winnings = this.getResultWin();
        }
        const stake = Services.get(SlotBetService).getTotalStake();

        uiFlags.set(UIFlag.NO_SKIP, true);

        return new Contract<boolean>((resolve) => {
            Components.get(BigWinComponent).showWin(winnings, stake).then((result) => {
                uiFlags.set(UIFlag.NO_SKIP, false);
                resolve(result);
            });
        });
    }

    protected getResultWin() {
        return gameState.getCurrentGame().getCurrentRecord().cashWon;
    }

    protected getTotalWin() {
        return gameState.getCurrentGame().getCurrentTotalWin();
    }
}
