import { Text } from "appworks/graphics/pixi/text";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { AbstractControl } from "appworks/ui/controls/abstract-control";

export class GameJuriInfoControl extends AbstractControl {
    constructor(txtLine1: Text, txtLine2: Text, txtLine3?: Text, txtLine4?: Text) {
        super();

        const rtp = Services.get(TranslationsService).get("de_juri_rtp", { value: Services.get(TranslationsService).get("rtp_value") });
        const grandPrize = Services.get(TranslationsService).get("grand_prize") + ":";
        const baseWinrate = Services.get(TranslationsService).get("de_juri_base_winrate", { value: Services.get(TranslationsService).get("base_winrate_value") });
        const bonusWinrate = Services.get(TranslationsService).get("de_juri_bonus_winrate", { value: Services.get(TranslationsService).get("bonus_winrate_value") });
        const grandPrizeChance = Services.get(TranslationsService).get("de_juri_grand_prize_chance", { value: Services.get(TranslationsService).get("base_winrate_value") });

        txtLine1.text = rtp;

        const hasBaseAndBonusValues = Services.get(TranslationsService).get("base_winrate_value") && Services.get(TranslationsService).get("bonus_winrate_value");

        if (hasBaseAndBonusValues) {
            txtLine2.text = grandPrize;
            txtLine3.text = baseWinrate;
            txtLine4.text = bonusWinrate;
        } else {
            txtLine2.text = grandPrizeChance;
            txtLine3.visible = false;
            txtLine4.visible = false;
        }
    }
}
