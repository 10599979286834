import { Point } from "appworks/utils/geom/point";
import { Result } from "../../../../../appworks/model/gameplay/records/results/result";

export class AnticipationResult extends Result {

    public resultType: string = "AnticipationResult";

    /**
     * Which reels will show an anticipation
     */
    public reelAnticipations: boolean[] = [false];
    /**
     * Which symbols type on each reel should show a land animation
     */
    public landSymbols: string[][] = [];
    /**
     * Which bonus ids are lands being counted towards on each reel
     */
    public landBonuses: number[][] = [];
    /**
     * Which symbols type on each reel should show an anticipation animation
     */
    public anticipateSymbols: string[][] = [[]];
    /**
     * Specific positions of symbols contributing towards this anticipation
     */
    public positions: Point[] = [];
}
