import { AbstractComponent } from "appworks/components/abstract-component";
import { Layers } from "appworks/graphics/layers/layers";
import { Text } from "appworks/graphics/pixi/text";
import { pulse } from "appworks/utils/animation/scale";
import { Contract } from "appworks/utils/contracts/contract";
import { Easing } from "appworks/utils/tween";

export class SlingoSpinsCounterComponent extends AbstractComponent {
    protected layer: Layers;
    protected text: Text;
    protected value: number;

    constructor(protected layerName: string) {
        super();
    }

    public init(): void {
        this.layer = Layers.get(this.layerName);
        this.layer.onSceneEnter.add(() => this.getText());
        this.getText();
    }

    public setValue(spins: number, animate: boolean = true): Contract {
        if (spins === this.value) { return Contract.empty(); }

        this.value = spins;
        this.text.text = spins.toString();

        return animate ? this.animate() : Contract.empty();
    }

    public increment(animate: boolean = true): Contract { return this.setValue(this.value + 1, animate); }
    public decrement(animate: boolean = true): Contract { return this.setValue(this.value - 1, animate); }

    protected getText() {
        this.text = this.layer.getText("spins_remaining_value");
    }

    protected animate(): Contract {
        this.text.landscape.scale.set(1);
        this.text.portrait.scale.set(1);

        return pulse(this.text, { x: 1.2, y: 1.2 }, 150, Easing.Sinusoidal.Out);
    }
}