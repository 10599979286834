import { ButtonElement, ButtonEvent } from "appworks/graphics/elements/button-element";
import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { UIFlagState } from "appworks/ui/flags/ui-flag-state";
import { uiFlags } from "appworks/ui/flags/ui-flags";

export class GenericActionButtonControl extends AbstractControl {

    /**
     * @param button 
     * @param method Method to call when button is pressed
     */
    constructor(protected button: ButtonElement, protected method?: Function, protected enableState?: UIFlagState) {
        super();

        uiFlags.onFlagsUpdated.add(() => this.checkButtonEnabled());
        this.checkButtonEnabled();
    }

    public destroy(): void {
        this.button.removeAllListeners();
    }

    protected checkButtonEnabled(): void {
        this.button.on(ButtonEvent.CLICK, this.method);
        this.button.setEnabled(true);

        this.button.setEnabled(this.enableState ? this.enableState.test() : true);
    }
}
