import * as TweenJS from "appworks/utils/tween";
import { Contract } from "./contract";

export namespace TweenContract {
    export function wrapTween(tween: TweenJS.Tween): Contract<any> {
        return new Contract<any>((resolve) => {

            let userOnComplete: (object?: any) => void;
            if (tween.getOnComplete()) {
                userOnComplete = tween.getOnComplete();
            }

            tween.onComplete(() => {
                if (userOnComplete) {
                    userOnComplete();
                }
                resolve(null);
            });

            tween.start();
        });
    }

    export function wrapCancellableTween<T = void>(tween: TweenJS.Tween): Contract<T> {
        return new Contract<T>((resolve, cancel) => {
            let userOnComplete: (object?: any) => void;
            if (tween.getOnComplete()) {
                userOnComplete = tween.getOnComplete();
            }

            let cancelCallback: () => void | undefined = () => {
                tween.stop();
                completeCallback();
                completeCallback = undefined;
            };

            let completeCallback: () => void | undefined = () => {
                if (userOnComplete) {
                    userOnComplete();
                }
                resolve();
                cancelCallback = undefined;
            };

            tween.onComplete(() => {
                if (completeCallback) {
                    completeCallback();
                }
            });

            cancel(() => {
                if (cancelCallback) {
                    cancelCallback();
                }
            });

            tween.start();
        });
    }
}