import { gameState } from "appworks/model/game-state";
import { Gameplay } from "appworks/model/gameplay/gameplay";
import { InitRequestPayload } from "appworks/model/gameplay/requests/init-request-payload";
import { builderManager } from "appworks/server/builders/builder-manager";
import { arrayOfValues } from "appworks/utils/collection-utils";
import { GMRRecordBuilder } from "gaming-realms/integration/builders/records/gmr-record-builder";
import { SlingoResponse, SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { slingoModel } from "slingo/model/slingo-model";
export abstract class SlingoRecordBuilder extends GMRRecordBuilder {
    public id: string = "SlingoRecordBuilder";

    public filter(request: InitRequestPayload, response: any): boolean {
        return false;
    }

    public build(gameplay: Gameplay, request: any, response: any): SlingoRecord[] {
        if (!response) { return []; }

        super.build(gameplay, request, response);

        const record = new SlingoRecord();
        const responseData = response.data as SlingoResponse;

        record.actions = responseData.actions;

        if (responseData.balance) {
            // Don't update wrapper balance if state is COMPLETE, as we may update before a bonus i.e. Lucky Joker
            // Init response after game completion will update the wrapper balance
            const balance = this.processBalances(responseData.balance, responseData.state !== SlingoState.COMPLETE);
            gameState.getCurrentGame().balance = balance;
        }

        if (responseData.gameInstanceId) {
            slingoModel.write({ gameInstanceId: responseData.gameInstanceId });
        }

        record.ticketId = responseData?.ticket?.id;
        record.state = responseData.state;

        record.rawTicketData = responseData.ticket.data;
        record.ticketGrid = arrayOfValues(responseData.ticket.columns, []);
        responseData.ticket.data.forEach((value, index) => {
            const x = index % responseData.ticket.columns;
            const y = Math.floor(index / responseData.ticket.rows);
            record.ticketGrid[x][y] = value;
        });

        record.results = builderManager.buildResults(record, responseData);
        for (const result of record.results) {
            record.cashWon += result.cashWon;
        }

        return [record];
    }

}
