import { gameLoop } from "appworks/core/game-loop";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { RandomRangeInt } from "appworks/utils/math/random";
import { Timer } from "appworks/utils/timer";
import { spine } from "pixi.js";
import { Signal, SignalBinding } from "signals";
import { CanvasService } from "../canvas/canvas-service";
import { Orientation } from "../canvas/orientation";
import { GraphicsService } from "../graphics-service";
import { SpineService } from "../spine/spine-service";
import { Container } from "./container";
import { DualPosition } from "./dual-position";
import { Position } from "./position";

export class SpineContainer extends Container {
    // TODO default true in V6, leave toggleable though in case of issues
    public static AUTO_UPDATE_SPEED: boolean = false;

    public onStart: Signal = new Signal();
    public onComplete: Signal = new Signal();
    public onEvent: Signal = new Signal();
    public skin: string;
    public spines: { landscape?: spine.Spine, portrait?: spine.Spine } = {};

    private attachments: Map<string, string> = new Map();
    private spineId: string;
    private playing: boolean = false;
    private looping: boolean = false;
    private playContract?: Contract<void>;
    private animation?: spine.core.Animation;
    private spinePosition: DualPosition;
    private playTimeout?: number;
    private timeScale: number = 1;
    private initialSpineSetup: boolean;
    private spineVisible: boolean = false;
    private onOrientationChangeBinding: SignalBinding;
    private onSpeedChangeBinding: SignalBinding;

    constructor(spineId: string, position: DualPosition) {
        super();

        this.spineId = spineId;
        this.name = spineId + "_" + RandomRangeInt(0, 100);
        this.skin = "default";
        this.spinePosition = position;
        this.initialSpineSetup = true;
        this.spineVisible = false;

        this.onOrientationChangeBinding = Services.get(CanvasService).onOrientationChange.add(() => this.updateSpineVisibility());

        if (SpineContainer.AUTO_UPDATE_SPEED) {
            this.onSpeedChangeBinding = gameLoop.onSpeedChange.add(() => {
                this.setSpeed(this.timeScale);
            })
        }
    }

    public updateTransform(): void {
        super.updateTransform();
        this.setupSpine();
    }

    public getDuration(animation?: number | string | spine.core.Animation, timeScale = 1) {
        animation = this.getAnimation(animation);
        const duration = (animation.duration * 1000) / timeScale;

        return duration;
    }

    public playOnce(animation?: number | string | spine.core.Animation, stopAtEnd: boolean = false, timeScale = 1): Contract<void> {
        animation = this.getAnimation(animation);

        if (this.playing) {
            this.stop();
        }

        this.animation = animation;
        this.timeScale = timeScale;

        const duration = (animation.duration * 1000) / timeScale;

        const contract: Contract<void> = new Contract((resolve, cancel) => {
            this.playTimeout = Timer.setTimeout(() => {
                if (this.playTimeout) {
                    Timer.clearTimeout(this.playTimeout);
                    this.playTimeout = undefined;
                }
                this.playContract = undefined;
                if (stopAtEnd) {
                    this.stop();
                }
                this.playing = false;
                this.onComplete.dispatch();
                resolve();
            }, duration);

            cancel(() => {
                if (this.playTimeout) {
                    Timer.clearTimeout(this.playTimeout);
                    this.playTimeout = undefined;
                }
                if (!this._destroyed && stopAtEnd) {
                    this.stop();
                }
            });

            this.setupPlay(false, timeScale);
            this.onStart.dispatch();
        });

        this.playContract = contract;
        return this.playContract;
    }

    public play(animation?: number | string | spine.core.Animation, timeScale = 1): void {
        // TODO: Refactor to use almost identical code block on playOnce
        animation = this.getAnimation(animation);

        this.onStart.dispatch();

        if (this.playing) {
            if (this.animation.name === animation.name) {
                return;
            } else {
                this.stop();
            }
        }

        this.animation = animation;
        this.timeScale = timeScale;

        const duration = (animation.duration * 1000) / timeScale;

        this.playTimeout = Timer.setTimeout(() => {
            this.stop();
            this.play(animation);
            this.onComplete.dispatch();
        }, duration);

        this.setupPlay(true, timeScale);
    }

    public stop(hide: boolean = true) {
        if (this.playTimeout) {
            Timer.clearTimeout(this.playTimeout);
            this.playTimeout = undefined;
        }

        if (hide) {
            this.spineVisible = false;
            this.updateSpineVisibility();
        }

        if (this.playing) {
            for (const key in this.spines) {
                if (this.spines.hasOwnProperty(key) && this.spines[key].state) {
                    this.spines[key].state.tracks.forEach((track) => track.time = 0);
                    this.spines[key].state.clearListeners();
                    this.spines[key].state.setEmptyAnimation(0, 0);
                    this.playing = false;
                    this.looping = false;
                    this.animation = undefined;
                    if (this.playContract) {
                        this.playContract.forceResolve();
                        this.playContract = undefined;
                    }
                }
            }
        }
    }

    public setSpeed(speed: number): void {
        for (const key in this.spines) {
            if (this.spines.hasOwnProperty(key)) {
                this.spines[key].state.timeScale = gameLoop.speed * speed;
            }
        }
    }

    public getAnimations(): spine.core.Animation[] {
        return this.getFirstSpine().spineData.animations;
    }

    public getAnimationIndex(): number {
        return this.getAnimations().indexOf(this.animation);
    }

    public isLooping(): boolean {
        return this.looping;
    }

    public isPlaying(): boolean {
        return this.playing;
    }

    public setupSpine() {
        if (this.initialSpineSetup) {
            this.createSpine(Orientation.LANDSCAPE, this.spinePosition.landscape);
            this.createSpine(Orientation.PORTRAIT, this.spinePosition.portrait);

            this.updateSpineVisibility();

            this.initialSpineSetup = false;
        }
    }

    public getSpinePosition(): DualPosition {
        return this.spinePosition;
    }

    public setSlotVisible(slotKey: string, visible: boolean) {
        for (const key in this.spines) {
            if (this.spines.hasOwnProperty(key)) {
                const slot = this.spines[key].skeleton.findSlot(slotKey);
                if (slot) {
                    for (const spriteName in slot.sprites) {
                        if (slot.sprites[spriteName]) {
                            const sprite = slot.sprites[spriteName];
                            sprite.visible = visible;
                        }
                    }
                }
            }
        }
    }

    public setAttachment(slot: string, attachment: string) {
        this.attachments.set(slot, attachment);

        this.updateAttachments();
    }

    public getSlot(slot: string) {
        const landscapeIndex = this.spines[Orientation.LANDSCAPE]?.skeleton.slots.findIndex((x) => x.data.name === slot);
        const portraitIndex = this.spines[Orientation.PORTRAIT]?.skeleton.slots.findIndex((x) => x.data.name === slot);
        return {
            containers: {
                landscape: this.spines[Orientation.LANDSCAPE]?.slotContainers[landscapeIndex],
                portrait: this.spines[Orientation.PORTRAIT]?.slotContainers[portraitIndex]
            },
            slots: {
                landscape: this.spines[Orientation.LANDSCAPE]?.skeleton.slots[landscapeIndex],
                portrait: this.spines[Orientation.PORTRAIT]?.skeleton.slots[portraitIndex]
            }
        };
    }

    public setSkin(skin: string) {
        this.skin = skin;
        this.spines.landscape?.skeleton.setSkinByName(skin);
        this.spines.portrait?.skeleton.setSkinByName(skin);
    }

    public destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean; }): void {
        super.destroy(options);

        if (this.onOrientationChangeBinding) {
            this.onOrientationChangeBinding.detach();
            this.onOrientationChangeBinding = undefined;
        }

        if (this.onSpeedChangeBinding) {
            this.onSpeedChangeBinding.detach();
            this.onSpeedChangeBinding = undefined;
        }
    }

    protected getAnimation(animation?: number | string | spine.core.Animation) {
        if (!animation) {
            animation = this.getAnimations()[0];
        }

        switch (typeof (animation)) {
            case "string":
                animation = this.getAnimations().find((x) => x.name === animation);
                break;
            case "number":
                if (animation >= 0 && animation < this.getAnimations().length) {
                    animation = this.getAnimations()[animation];
                } else {
                    throw new Error(`Invalid animation index given for ${this.name} (${animation})`);
                }
                break;
        }

        return animation;
    }

    private createSpine(orientation: Orientation, position: Position) {
        if (!this.spines[orientation] && !position.unavailable) {
            const spine = Services.get(GraphicsService).createSpine(this.spineId);
            this.spines[orientation] = spine;

            spine.update(0);
            let bounds = spine.getBounds();

            // sometimes spines have no width and height on default skeleton, so temporarily play the anim to get the correct bounds
            if (bounds.width === 0 && bounds.height === 0) {
                const animName = spine.spineData.animations[0].name;
                spine.state.setAnimation(0, animName, true);
                // jump into the anim because the bounds can still be 0,0 at frame 0
                // WARNING: spine bounds are not consistent when at a time beyond 0, so it's recommended to use 0 for this if possible
                // See https://epicindustries.atlassian.net/wiki/spaces/AW/pages/586809359/Spine for details
                spine.state.tracks[0].time = SpineService.REFERENCE_TIME;

                spine.update(0);
                bounds = spine.getBounds();
            }

            spine.pivot.x = bounds.x;
            spine.pivot.y = bounds.y;

            this.addChild(spine);

            if (position.width && position.height) {
                spine.width = position.width;
                spine.height = position.height;
            }

            spine.state.setEmptyAnimation(0, 0);
        }
    }

    private setupPlay(looping: boolean, timeScale = 1) {
        for (const key in this.spines) {
            if (this.spines.hasOwnProperty(key)) {

                this.spines[key].stateData.defaultMix = 0;
                this.spines[key].state.setEmptyAnimation(0, 0);
                this.spines[key].skeleton.setToSetupPose();
                this.spines[key].skeleton.setSkinByName(this.skin);

                this.spines[key].state.setAnimation(0, this.animation.name, false);
                this.spines[key].state.tracks[0].time = 0;
                this.spines[key].state.timeScale = gameLoop.speed * timeScale;
                this.spines[key].state.clearListeners();
                this.spines[key].state.addListener({ event: (entry, event) => this.onSpineEvent(entry, event) });

                this.playing = true;
                this.looping = looping;
                this.spineVisible = true;

                this.updateSpineVisibility();
            }
        }

        this.updateAttachments();
    }

    private updateAttachments() {
        for (const key in this.spines) {
            if (this.spines.hasOwnProperty(key)) {
                this.attachments.forEach((attachment: string, slot: string) => {
                    if (this.spines[key].skeleton.findSlot(slot)) {
                        this.spines[key].skeleton.setAttachment(slot, attachment);
                    }
                });
            }
        }
    }

    private onSpineEvent(entry: spine.core.TrackEntry, event: spine.core.Event) {
        if (event?.data?.name) {
            Services.get(SoundService).event(SoundEvent.spine_NAME, event.data.name);
        }

        this.onEvent.dispatch(entry, event);
    }

    private updateSpineVisibility(): void {
        for (const key in this.spines) {
            if (this.spines.hasOwnProperty(key)) {
                this.spines[key].alpha = (this.spineVisible && key === Services.get(CanvasService).orientation) ? 1 : 0;
            }
        }
    }

    private getFirstSpine() {
        return this.spines[Orientation.LANDSCAPE] || this.spines[Orientation.PORTRAIT];
    }
}
