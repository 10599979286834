import { Model } from "appworks/model/model";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { RaceHandler } from "appworks/utils/race-handler";
import { Signal } from "signals";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { ReelSubcomponent } from "slotworks/components/matrix/reel/reel-subcomponent";
import { AbstractReelTransition } from "slotworks/components/matrix/reel/transition-behaviours/abstract-reel-transition";
import { ReelSpinner, SpinStageEvents } from "slotworks/components/matrix/reel/transition-behaviours/spin/reel-spinner";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";
import { AnticipationResult } from "slotworks/model/gameplay/records/results/anticipation-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { JurisdictionService } from "slotworks/services/jurisdiction/jurisdiction-service";

export enum SpinState {
    IDLE = "idle",
    SPINNING = "spinning",
    STOPPING = "stopping",
    SKIPPING = "skipping"
}

// ALWAYS: When skipped, all anticipations will be completely skipped, and current one will skip if possible
// NEVER: Anticipation reels will always play out fully, never skipping
// PER_REEL: Anticipations will play out fully, unless it is currently playing when skipped, in which case it will skip the current one only
export enum AnticipationSkipMode {
    ALWAYS = 1,
    NEVER = 2,
    PER_REEL = 3
}

export class SpinReelTransition extends AbstractReelTransition {
    public static anticipationSkipMode: AnticipationSkipMode = AnticipationSkipMode.PER_REEL;

    public static reelOrder: number[];

    public onReelLand: Signal = new Signal();

    protected spinners: ReelSpinner[];

    protected anticipationResult: AnticipationResult;
    protected anticipatingSymbols: SymbolSubcomponent[];
    protected anticipatingReels: number[] = [];
    protected totalAnticipationsStarted: number;

    protected heldReels: number[] = [];

    protected state: SpinState;
    protected waitForEvent: SpinStageEvents;
    protected currentSpinner: ReelSpinner;
    protected currentSpinnerIndex: number;
    protected pendingSpinners: ReelSpinner[];
    protected completedEvents: Map<SpinStageEvents, number>;
    protected completeRaceHandler: RaceHandler;

    protected currentRecord: SpinRecord;
    protected jumpStart: boolean;
    protected quickSpin: boolean;

    constructor() {
        super();

        this.state = SpinState.IDLE;
    }

    public init(matrix: AbstractMatrixComponent, reels: ReelSubcomponent[], stops: number[], reelset: string[][]) {
        super.init(matrix, reels, stops, reelset);

        this.spinners = [];

        for (let i = 0; i < reels.length; i++) {
            const reel = reels[i];
            const spinner = new ReelSpinner(i, matrix, reel, stops[i], reelset[i]);
            this.spinners.push(spinner);
            spinner.landSignal.add((index: number) => this.reelLandedHandler(index));
            spinner.onAnticipationStart.add((index: number) => this.anticipationStartSoundHandler(index));
            spinner.onAnticipationEnd.add((index: number) => this.anticipatingEndHandler(index));
            spinner.onStageEvent.add((reelIndex: number, stageEvent: SpinStageEvents) => this.onSpinnerStageEvent(spinner, reelIndex, stageEvent));
            spinner.onComplete.add((reelIndex: number) => this.onReelComplete.dispatch(reelIndex));
        }
    }

    public start(reelset?: string[][], jumpStart: boolean = false, quickSpin: boolean = false): Contract<void> {
        this.completedEvents = new Map();

        this.jumpStart = jumpStart;
        this.quickSpin = quickSpin;

        Services.get(SoundService).event(SoundEvent.reel_transition_start);

        const settings = Model.read().settings;
        if (settings.turboSpin) {
            Services.get(SoundService).event(SoundEvent.reel_transition_start_turbo);
        } else if (settings.quickSpin) {
            Services.get(SoundService).event(SoundEvent.reel_transition_start_quick);
        } else {
            Services.get(SoundService).event(SoundEvent.reel_transition_start_normal);
        }

        this.state = SpinState.SPINNING;
        this.waitForEvent = SpinStageEvents.START;

        this.totalAnticipationsStarted = 0;
        this.anticipatingSymbols = [];

        return this.sequenceSpinners();
    }

    public stop(spinRecord: SpinRecord, quickSpin: boolean = false): Contract<void> {

        this.currentRecord = spinRecord;
        this.quickSpin = quickSpin;

        this.state = SpinState.STOPPING;
        this.waitForEvent = SpinStageEvents.STOP;

        return this.sequenceSpinners();
    }

    public skip(spinRecord: SpinRecord): void {
        if (!Services.get(JurisdictionService).getSpinSkippingEnabled()) {
            return;
        }

        this.currentRecord = spinRecord;

        this.state = SpinState.SKIPPING;
        this.waitForEvent = SpinStageEvents.SKIP;

        // Skip spinners that have already been stopped, in case there is instant skip available to interrupt their stop
        this.spinners.forEach((spinner) => {
            if (this.pendingSpinners.indexOf(spinner) === -1) {
                this.skipSpinner(spinner);
            }
        });

        if (this.currentSpinner) {
            const allPreviousSpinnersApprovedSkip = (this.completedEvents.get(this.waitForEvent) || 0) >= (this.currentSpinnerIndex - 1);
            if (allPreviousSpinnersApprovedSkip || this.currentSpinnerIndex === 0) {
                this.skipSpinner(this.currentSpinner);
            }
        }
    }

    public canSkip() {
        const unskippableAnticipation = this.anticipatingReels.length > 0 && SpinReelTransition.anticipationSkipMode === AnticipationSkipMode.NEVER;

        return Services.get(JurisdictionService).getSpinSkippingEnabled() && !unskippableAnticipation;
    }

    public jump(stops: number[], reelset: string[][]) {
        this.state = SpinState.IDLE;

        for (let i = 0; i < this.spinners.length; i++) {

            const spinner = this.spinners[i];

            spinner.jump(stops[i], reelset[i]);
        }
    }

    public hold(reelIndexes?: number[]) {
        if (!reelIndexes) {
            reelIndexes = [];
        }
        this.heldReels = reelIndexes;
    }

    public update() {
        super.update();

        for (const spinner of this.spinners) {
            spinner.update();
        }
    }

    public getSpinners() {
        return this.spinners;
    }

    public setCustomReelset(reelset: string[][]) {
        this.spinners.forEach((spinner, index) => {
            spinner.setReelstrip(reelset[index]);
        });
    }

    public resetReelsetToDefault() {
        this.spinners.forEach((spinner, index) => {
            spinner.setReelstrip(this.defaultReelset[index]);
        });
    }

    protected reelLandedHandler(reelIndex: number) {
        this.onReelLand.dispatch(reelIndex);

        const lands = [];
        const landedReel = this.reels[reelIndex];
        const reelSymbols = landedReel.getSymbols();
        const validLandSymbols = this.anticipationResult?.landSymbols[reelIndex] || [];
        const validPositions = this.anticipationResult?.positions || [];
        const hasAnticipation = (this.anticipationResult && this.anticipationResult.anticipateSymbols.length > reelIndex);
        const symbolsToAnimate = hasAnticipation ? this.anticipationResult.anticipateSymbols[reelIndex] : [];
        for (const symbol of reelSymbols) {

            lands.push(() => symbol.land());

            if (!validLandSymbols || validLandSymbols.indexOf(symbol.symbolId) > -1) {
                if (validPositions.find((validPosition) => symbol.gridPosition.equals(validPosition))) {
                    lands.push(() => symbol.anticipateLand());
                }
            }
        }

        new Parallel(lands).then(() => {
            if (symbolsToAnimate.length) {
                let symbols: SymbolSubcomponent[] = [];
                for (const reel of this.reels) {
                    if (reel.index <= reelIndex) {
                        symbols = symbols.concat(reel.getSymbols());
                    }
                }
                for (const symbol of symbols) {
                    if (symbolsToAnimate.indexOf(symbol.symbolId) > -1) {
                        if (this.anticipatingSymbols.indexOf(symbol) === -1) {
                            symbol.anticipate().execute();
                            this.anticipatingSymbols.push(symbol);
                        }
                    } else {
                        const trackingIndex = this.anticipatingSymbols.indexOf(symbol);
                        if (trackingIndex > -1) {
                            symbol.static();
                            this.anticipatingSymbols.splice(trackingIndex, 1);
                        }
                    }
                }
            } else {
                this.stopAnticipatingSymbols();
            }
        });
    }

    protected sequenceSpinners(): Contract<void> {
        if (this.currentRecord) {
            this.anticipationResult = this.currentRecord.getResultsOfType(AnticipationResult)[0];
        }

        this.currentSpinnerIndex = 0;
        this.pendingSpinners = this.getSpinnersToExecute();

        this.completeRaceHandler = new RaceHandler(this.pendingSpinners.length);

        return new Sequence([
            () => new Parallel([
                () => this.completeRaceHandler.start(),
                () => Contract.wrap(() => this.nextSpinner())
            ]),
            () => Contract.wrap(() => {
                this.stopAnticipatingSymbols();
                if (this.state === SpinState.SKIPPING) {
                    Services.get(SoundService).event(SoundEvent.reel_transition_skip_end);
                } else {
                    Services.get(SoundService).event(SoundEvent.reel_transition_end);
                }
                this.state = SpinState.IDLE;
            })
        ]);
    }

    protected stopAnticipatingSymbols() {
        if (this.anticipatingSymbols) {
            for (const symbol of this.anticipatingSymbols) {
                symbol.static();
            }
        }

        this.anticipatingSymbols = [];
    }

    protected anticipatingStartHandler(index: number) {
        const firstAnticipation = this.totalAnticipationsStarted === 0;

        this.totalAnticipationsStarted++;
        this.anticipatingReels.push(index);

        if (firstAnticipation) {
            this.onAnticipationStart.dispatch(index);
        }
        this.onAnticipationReel.dispatch(index);
    }

    protected anticipationStartSoundHandler(index: number) {
        Services.get(SoundService).event(SoundEvent.reel_count_N_anticipate_start, this.totalAnticipationsStarted.toString());
        Services.get(SoundService).event(SoundEvent.reel_N_anticipate_start, index.toString());
        Services.get(SoundService).event(SoundEvent.reel_any_anticipate_start);
        if (this.anticipationResult?.reelAnticipations?.indexOf(true) === index) {
            Services.get(SoundService).event(SoundEvent.anticipate_start);
        }
    }

    protected anticipatingEndHandler(index: number) {
        this.anticipatingReels.pop();

        if (this.anticipatingReels.length === 0) {
            this.onAnticipationEnd.dispatch(index);
        }
    }

    protected getSpinnersToExecute() {
        let spinnersToExecute: ReelSpinner[] = [...this.spinners];

        // Order spinners
        if (SpinReelTransition.reelOrder) {
            spinnersToExecute = [];
            SpinReelTransition.reelOrder.forEach((index) => {
                spinnersToExecute.push(this.spinners[index]);
            });
        }

        // Don't execute "held" spinners
        spinnersToExecute = spinnersToExecute.filter((spinner) => this.heldReels.indexOf(spinner.index) === -1);

        return spinnersToExecute;
    }

    protected nextSpinner() {
        if (this.pendingSpinners.length) {
            this.currentSpinnerIndex++;
            this.currentSpinner = this.pendingSpinners[0];

            if (this.state === SpinState.SPINNING) {
                this.startSpinner(this.pendingSpinners[0]);
            } else if (this.state === SpinState.STOPPING || this.state === SpinState.SKIPPING) {
                this.stopSpinner(this.pendingSpinners[0]);

                if (this.pendingSpinners.length > 1) {
                    const nextSpinner = this.pendingSpinners[1];

                    const anticipations = this.anticipationResult?.reelAnticipations;

                    if (anticipations && anticipations[nextSpinner.index]) {
                        this.waitForEvent = SpinStageEvents.ANTICIPATE;
                    }
                }
            }

            // In case event has already happened
            this.checkForNextSpinner();
        }
    }

    protected startSpinner(spinner: ReelSpinner) {
        spinner.start(this.jumpStart, this.quickSpin).then(() => this.completeRaceHandler.complete());
    }

    protected stopSpinner(spinner: ReelSpinner) {
        const anticipations = this.anticipationResult?.reelAnticipations;

        if (anticipations && anticipations[spinner.index]) {
            this.anticipateSpinner(spinner);
        } else if (this.state === SpinState.SKIPPING) {
            this.skipSpinner(spinner);
        } else {
            const reelset = this.currentRecord.reelset ?? this.defaultReelset;
            spinner.stop(this.currentRecord.stops[spinner.index], reelset[spinner.index], this.quickSpin).then(() => this.completeRaceHandler.complete());
        }
    }

    protected skipSpinner(spinner: ReelSpinner) {
        if (spinner.anticipating && SpinReelTransition.anticipationSkipMode === AnticipationSkipMode.NEVER) {
            return;
        }

        spinner.skip(this.currentRecord.stops[spinner.index], this.currentRecord.reelset[spinner.index]).then(() => this.completeRaceHandler.complete());
    }

    protected anticipateSpinner(spinner: ReelSpinner) {
        if (SpinReelTransition.anticipationSkipMode === AnticipationSkipMode.ALWAYS && this.state === SpinState.SKIPPING) {
            this.skipSpinner(spinner);
        } else {
            spinner.anticipate(this.currentRecord.stops[spinner.index], this.currentRecord.reelset[spinner.index]).then(() => this.completeRaceHandler.complete());
            this.anticipatingStartHandler(spinner.index);
        }
    }

    protected onSpinnerStageEvent(spinner: ReelSpinner, reelIndex: number, event: SpinStageEvents) {
        // Count all events that come in
        let eventCompleteCount = 1;
        if (this.completedEvents.has(event)) {
            eventCompleteCount += this.completedEvents.get(event);
        }
        this.completedEvents.set(event, eventCompleteCount);

        this.checkForNextSpinner();
    }

    protected checkForNextSpinner() {
        // When all events we're waiting for come in, execute next spinner
        const waitEventReady = (this.completedEvents.get(this.waitForEvent) || 0) >= this.currentSpinnerIndex;
        const jumpStarting = (this.state === SpinState.SPINNING && this.jumpStart);
        if (waitEventReady || jumpStarting) {
            this.pendingSpinners.shift();
            this.nextSpinner();
        }
    }
}
