import { slingoModel } from "slingo/model/slingo-model";

export type SlingoPotentialWin = {
    numbers: number[],
    lines: number
}

/**
 * 
 * @param grid 2D 5x5 array of numbers
 * @param matchedNumbers 1D array of numbers already matched
 * @returns Unique potential wins, one for each total lines possible to get, containing the number of lines you could win, and the numbers you need to get (up to 5) to attain them
 */
export function GetPotentialSlingoWins(grid: number[][], matchedNumbers: number[], minWinLevel: number = 0): SlingoPotentialWin[] {
    const results: SlingoPotentialWin[] = [];
    // Prevent algorithm for taking too long on very rare sparsely filled grids
    let limit = 30000;

    // List of all lines
    const lines: Array<{ x: number, y: number }>[] = [];
    // Verticle / horizontal
    const diagonal1 = [];
    const diagonal2 = [];
    for (let x = 0; x < 5; x++) {
        const vertical = [];
        const horizontal = [];
        diagonal1.push({ x, y: x });
        diagonal2.push({ x: (4 - x), y: x });
        for (let y = 0; y < 5; y++) {
            vertical.push({ x, y });
            horizontal.push({ x: y, y: x });
        }
        lines.push(horizontal);
        lines.push(vertical);
    }
    lines.push(diagonal1);
    lines.push(diagonal2);

    // List incomplete lines
    const unmatchedNumbers: number[] = [];
    const incompleteLines: number[][] = [];
    lines.forEach((line) => {
        const numbersNeeded: number[] = [];
        line.forEach((cell) => {
            const n = grid[cell.y][cell.x];
            if (matchedNumbers.indexOf(n) === -1) {
                numbersNeeded.push(n);
                if (unmatchedNumbers.indexOf(n) === -1) {
                    unmatchedNumbers.push(n);
                }
            }
        });
        if (numbersNeeded.length > 0) {
            incompleteLines.push(numbersNeeded);
        }
    });

    const completedLines = 12 - incompleteLines.length;

    // Function for checking number of newly matched lines given an array of new numbers
    const getNewlyMatchedLines = (newNumbers: number[]) => {
        limit--;
        let modifiedLines = incompleteLines;
        newNumbers.forEach((n) => {
            modifiedLines = modifiedLines.map((line) => {
                return line.filter((v) => v !== n);
            });
        });

        return modifiedLines.reduce((t, line) => t + (line.length === 0 ? 1 : 0), 0);
    }

    // Set up function for permiating numbers to see if we can find a way of making each win
    const permiate = (arr, data, start, end, index, r, target) => {
        if (limit < 0) {
            return;
        }
        if (index == r) {
            const newNumbers = data.slice(0, r);
            if (getNewlyMatchedLines(newNumbers) === target) {
                return newNumbers;
            }
        }

        for (let i = start; i <= end && end - i + 1 >= r - index; i++) {
            data[index] = arr[i];
            const result = permiate(arr, data, i + 1, end, index + 1, r, target);
            if (limit < 0) {
                return;
            }
            if (result) {
                return result;
            }
        }
    }

    // For every target number of lines
    const minLines = (completedLines < minWinLevel ? (minWinLevel + 1) - completedLines : 1);
    for (let numlines = minLines; numlines < 13 - completedLines; numlines++) {
        if ((numlines + completedLines) === 11) {
            // Impossible to match 11 lines
            continue;
        }

        let targetNumbers = [];
        // Loop through all iterations of i (1-5) new numbers 
        for (let i = 1; i < 6; i++) {
            targetNumbers = permiate(unmatchedNumbers, [], 0, unmatchedNumbers.length, 0, i, numlines);
            if (targetNumbers) {
                break;
            }
        }

        if (targetNumbers) {
            targetNumbers = targetNumbers.sort((a, b) => a - b);
            results.push({ numbers: targetNumbers, lines: numlines + completedLines })
        }
    }

    return results;
}
