
import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { Flexi } from "appworks/graphics/elements/abstract-flexi";
import { Sprite } from "appworks/graphics/pixi/sprite";

export class FlexiSprite extends Flexi<AnimatedSprite | Sprite | HTMLElement> {

    public setVisible(visible: boolean) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle(visible ? "hidden" : "visible", false);
                target.classList.toggle(visible ? "visible" : "hidden", true);
            } else {
                target.visible = visible;
            }
        }
    }

    public setAlpha(alpha: number) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.style.opacity = alpha.toString();
            } else {
                target.alpha = alpha;
            }
        }
    }
}
