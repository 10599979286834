import { PickOneRecord } from "slotworks/model/gameplay/records/pick-one-record";

export function pickOneRecordDisplay(record: PickOneRecord) {
    const containerElement = document.createElement("div");

    const values = document.createElement("p");
    values.textContent = "Values: " + record.values?.toString();

    const win = document.createElement("p");
    win.textContent = "Win: " + record.win.toString();

    containerElement.appendChild(values);
    containerElement.appendChild(win);

    return containerElement;
}
