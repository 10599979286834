import { gameState } from "appworks/model/game-state";
import { Gameplay } from "appworks/model/gameplay/gameplay";
import { RequestBuilder } from "appworks/server/builders/request-builder";
import { Services } from "appworks/services/services";
import { AxiosRequestConfig } from "axios";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoPurchaseEntryRequestPayload } from "slingo/model/requests/slingo-purchase-entry-request-payload";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { GamingRealms } from "gaming-realms/gaming-realms";

export class SlingoPurchaseEntryRequestBuilder implements RequestBuilder {
    public filter(data: any): boolean {
        return data instanceof SlingoPurchaseEntryRequestPayload;
    }

    public build(gameplay: Gameplay, data: any) {
        return {
            method: "post",
            data: {
                currencyCode: GamingRealms.wrapperConfig.getLogin().getCurrency(),
                stakeAmount: Services.get(SlotBetService).getTotalStake() / 100,
                ticketId: (gameState.getCurrentGame().getRootRecord() as SlingoRecord).ticketId
            }
        } as AxiosRequestConfig;
    }

}
