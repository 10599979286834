import { Components } from "appworks/components/components";
import { PaginationComponent, PaginationMode } from "appworks/components/ui/pagination-component";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { LoaderService } from "appworks/loader/loader-service";
import { gameState } from "appworks/model/game-state";
import { InitRequestPayload } from "appworks/model/gameplay/requests/init-request-payload";
import { commsManager } from "appworks/server/comms-manager";
import { Services } from "appworks/services/services";
import { uiFlags, UIFlag } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { slingoModel } from "slingo/model/slingo-model";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { InitState } from "slotworks/state-machine/standard/states/init-state";

export class SlingoInitState extends InitState {
    public onExit(): void {
        super.onExit();

        this.configureRules();

        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord() as SlingoRecord;
        Components.get(SlingoTicketMatrixComponent).setGrid(record.ticketGrid);

        Components.get(MatrixComponent).jumpToGrid([["blank"], ["blank"], ["blank"], ["blank"], ["blank"]]);

        Components.get(SlingoSpinsCounterComponent).setValue(slingoModel.read().gameConfig.standardSpins, false).execute();
    }

    protected sendInitRequest(): Contract<void> {
        if (GamingRealms.wrapperConfig?.getLogin()) {
            return new Sequence([
                () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, true)),
                () => commsManager.request(new InitRequestPayload()),
                () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, false)),
                () => Contract.wrap(() => gameState.getCurrentGame().setToLatestRecord())
            ]);
        } else {
            return Contract.never();
        }
    }

    protected showOnBoard(): Contract<any> {
        return new Contract<void>((resolve) => {
            const onboardLayer = Layers.get("Onboard");
            onboardLayer.setScene("onboard").execute();
            onboardLayer.show();

            const backgroundLayer = Layers.get("Background");
            if (backgroundLayer && backgroundLayer.hasScene("onboard")) {
                backgroundLayer.setScene("onboard").execute();
                backgroundLayer.show();
            }

            const paginationComponent = this.setupOnboardPagination();

            const loadBarBacking = onboardLayer.getSprite("bar_backing");
            const loadBar = onboardLayer.getSprite("load_bar");
            const mask = onboardLayer.getSprite("load_bar_mask") || onboardLayer.getShape("load_bar_mask");
            loadBar.mask = mask;

            const targetWidth = { landscape: mask.landscape.width, portrait: mask.portrait.width };
            mask.landscape.width = mask.portrait.width = 0;

            const onProgress = (stage, progress) => {
                mask.landscape.width = targetWidth.landscape * (progress / 100);
                mask.portrait.width = targetWidth.portrait * (progress / 100);
            }

            const continueButton = onboardLayer.getButton("continue");
            continueButton.setVisible(false);
            continueButton.on(ButtonEvent.CLICK, () => {
                if (paginationComponent) { paginationComponent.hide(); }
                onboardLayer.defaultScene().then(resolve);
            });

            const loader = Services.get(LoaderService);
            loader.loadStage(3, onProgress).then(() => {
                uiFlags.set(UIFlag.GAME_STARTED, true);
                continueButton.setVisible(true);

                loadBarBacking.visible = false;
                loadBar.visible = false;
            });
        });
    }

    protected setupOnboardPagination(): PaginationComponent {
        const contentLayer = Layers.get("OnboardPages");

        if (contentLayer) {
            contentLayer.jumpToScene("page_1");
            contentLayer.show();

            const component = new PaginationComponent({
                contentLayerName: "OnboardPages",
                uiLayerName: "Onboard",
                mode: PaginationMode.PAGIFY
            });
            component.autoScroll(5000);
            component.show();

            return component;
        }
    }

    protected configureRules() {
        const rulesFrame = document.getElementById("rules_iframe") as HTMLIFrameElement;
        const rulesDoc = rulesFrame?.contentWindow.document;

        // Any information in the rules page regarding UK play controls needs to be hidden if non-GB
        const isGB = GamingRealms.getLocale().toLowerCase().includes("gb");
        const playControlsElements = rulesDoc.getElementsByClassName("play_controls");
        for (let i = 0; i < playControlsElements.length; i++) {
            const item = playControlsElements.item(i) as HTMLElement;
            item.style.display = isGB ? "initial" : "none";
        }

        // We need to add the correct value to the line about autocompleting abandoned games,
        // or hide the section altogether if disabled
        // But this can't be populated in history since the wrapper is unavailable
        if (!GamingRealms.isHistory()) {
            const autoCompleteDiv = rulesDoc.getElementById("div_autocomplete");
            const autoCompleteEnabled = GamingRealms.wrapperConfig.getOperatorConfig().isAutoCompleteEnabled();
            if (autoCompleteDiv) {
                autoCompleteDiv.style.display = autoCompleteEnabled ? "initial" : "none";
                autoCompleteDiv.innerHTML = autoCompleteDiv.innerHTML.replace("{autocomplete_value}",
                    GamingRealms.wrapperConfig.getOperatorConfig().getAutoCompleteInterval().toString());
            }
        }

        // Hide You Could Win section if disabled
        const youCouldWinDiv = rulesDoc.getElementById("div_youcouldwin");
        const youCouldWinEnabled = GamingRealms.wrapperConfig.getOperatorConfig().isYouCouldWinEnabled();
        if (youCouldWinDiv) {
            youCouldWinDiv.style.display = youCouldWinEnabled ? "initial" : "none";
        }
    }
}
