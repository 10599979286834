import { FileLocation } from "appworks/config/asset-config-schema";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { Contract } from "appworks/utils/contracts/contract";
import { Point } from "appworks/utils/geom/point";
import { Timer } from "appworks/utils/timer";
import * as TweenJS from "appworks/utils/tween";
import { DisplayObject, Graphics, LoaderResource } from "pixi.js";
import { Signal } from "signals";

export interface LoadbarSubcomponentConfig {
    chaserOffset: Point;
    nudgePercentage: number;
    // Time it takes to finish off the load bar when load is done (if it's not already finished)
    finishTime: number;
    // Delay at 100% before completing
    finishDelayTime: number;

    files: FileLocation[];
    bar?: Sprite | Graphics;
    chaser?: Sprite;
}
export class LoadbarSubcomponent {

    public onChange: Signal;
    public onComplete: Signal;

    public currentPercent: number;

    public chaser: Sprite;
    protected bar: Sprite | Graphics;

    protected totalKb: number;
    protected completeKb: number;
    protected msPerKb: number;
    protected files: Map<string, FileLocation>;

    protected loadingPercentage: Text;
    protected loadingLabel: Text;
    protected loadingSprite: DisplayObject;

    protected msSinceLoadStarted: number;

    protected loadTween: TweenJS.Tween;

    protected config: LoadbarSubcomponentConfig = {
        chaserOffset: new Point(0, 0.5),
        nudgePercentage: 0.01,
        finishTime: 1000,
        finishDelayTime: 250,
        files: []
    }

    constructor(config?: Partial<LoadbarSubcomponentConfig>) {
        if (config) {
            this.config = { ...this.config, ...config };
        }

        this.onChange = new Signal();
        this.onComplete = new Signal();

        this.bar = this.config.bar;
        this.chaser = this.config.chaser;

        if (this.bar) {
            this.bar.scale.x = 0;
        }

        if (this.chaser) {
            this.chaser.anchor.set(this.config.chaserOffset.x, this.config.chaserOffset.y);
            if (this.bar instanceof Sprite) {
                this.chaser.landscape.x = this.bar.landscape.x + this.bar.getBounds().width;
                this.chaser.landscape.y = this.bar.landscape.y + this.bar.getBounds().height * 0.5;
                this.chaser.portrait.x = this.bar.portrait.x + this.bar.getBounds().width;
                this.chaser.portrait.y = this.bar.portrait.y + this.bar.getBounds().height * 0.5;
            } else {
                this.chaser.landscape.x = this.bar.x + this.bar.getBounds().width;
                this.chaser.landscape.y = this.bar.y + this.bar.getBounds().height * 0.5;
                this.chaser.portrait.x = this.bar.x + this.bar.getBounds().width;
                this.chaser.portrait.y = this.bar.y + this.bar.getBounds().height * 0.5;
            }
        }

        this.currentPercent = this.totalKb = this.completeKb = 0;
        this.msSinceLoadStarted = new Date().getTime();
        this.msPerKb = 3000;
        this.files = new Map<string, FileLocation>();

        for (const file of this.config.files) {

            let fileName = file.name;

            // When loading atlases only care about the image, not the json
            if (file.url.indexOf("@")) {
                fileName += "_image";
            }

            this.files.set(fileName, file);
            this.totalKb += file.size;
        }

        // Start off load bar with complete guess for how fast the client's internet connection is
        this.loadTween = new TweenJS.Tween(this)
            .to({ currentPercent: 0.25 }, 10000)
            .onUpdate(this.update)
            .onComplete(() => {
                this.nudgeTween();
            })
            .start();
    }

    public addLoadingText(label: Text, percentage: Text, loadingSprite?: DisplayObject) {
        this.loadingLabel = label;
        this.loadingSprite = loadingSprite;
        this.loadingPercentage = percentage;
    }

    public fileLoaded(resource: LoaderResource) {

        // Ignore non registered files
        if (!this.files.has(resource.name)) {
            return;
        }

        // Stop last load tween
        if (this.loadTween) {
            this.loadTween.stop();
        }

        // Get file data
        const fileData = this.files.get(resource.name);

        // Update total kb loaded
        this.completeKb += fileData.size;

        // Update estimated times
        this.msPerKb = ((new Date().getTime()) - this.msSinceLoadStarted) / this.completeKb;
        const timeToComplete = Math.max((this.totalKb - this.completeKb) * this.msPerKb, 0);

        // Try and finish load bar at estimated speed
        this.loadTween = new TweenJS.Tween(this)
            .to({ currentPercent: 1 }, timeToComplete)
            .onUpdate(() => { this.update(); })
            .start();

        this.onChange.dispatch();
    }

    /**
     * Quickly completes the loadbar and returns a Contract for when it's done
     */
    public loadComplete() {
        if (this.loadTween) {
            this.loadTween.stop();
        }

        const onComplete = () => {
            if (this.loadingPercentage) {
                this.loadingPercentage.visible = false;
            }
            if (this.loadingLabel) {
                this.loadingLabel.visible = false;
            }
            if (this.loadingSprite) {
                this.loadingSprite.visible = false;
            }

            this.onComplete.dispatch();

            this.destroy();
        };

        return new Contract<void>((resolve) => {
            const complete = () => {
                onComplete();
                resolve(null);
            };

            const delayComplete = () => {
                if (this.config.finishDelayTime > 0) {
                    Timer.setTimeout(complete, this.config.finishDelayTime);
                } else {
                    complete();
                }
            };

            if (this.currentPercent < 1) {

                this.loadTween = new TweenJS.Tween(this)
                    .to({ currentPercent: 1 }, this.config.finishTime)
                    .onUpdate(() => { this.update(); })
                    .onComplete(delayComplete)
                    .start();
            } else {
                delayComplete();
            }
        });
    }

    public destroy() {
        this.onChange.removeAll();
        this.onComplete.removeAll();

        this.loadTween.stop();
        this.loadTween = null;

        this.bar = null;
    }

    /**
     * To prevent load bar from grinding completely to a halt, if the initial guess load completes
     * before we have any download speed estimates, the bar should nudge along slowly
     */
    protected nudgeTween() {
        let nudgeTarget;
        if (this.currentPercent < 0.97) {
            nudgeTarget = this.currentPercent + this.config.nudgePercentage;
        }

        this.loadTween = new TweenJS.Tween(this)
            .to({ currentPercent: nudgeTarget }, 3000)
            .onUpdate(() => {
                this.update();
            })
            .onComplete(() => {
                this.nudgeTween();
            })
            .start();
    }

    protected update() {
        if (this.bar) {
            if (this.bar instanceof Sprite) {
                this.bar.landscape.scale.x = Math.min(1, this.currentPercent);
                this.bar.portrait.scale.x = Math.min(1, this.currentPercent);
            } else {
                this.bar.scale.x = Math.min(1, this.currentPercent);
            }

            if (this.chaser) {
                if (this.bar instanceof Sprite) {
                    this.chaser.landscape.x = this.bar.landscape.x + this.bar.getBounds().width;
                    this.chaser.landscape.y = this.bar.landscape.y + this.bar.getBounds().height * 0.5;
                    this.chaser.portrait.x = this.bar.portrait.x + this.bar.getBounds().width;
                    this.chaser.portrait.y = this.bar.portrait.y + this.bar.getBounds().height * 0.5;
                } else {
                    this.chaser.landscape.x = this.bar.x + this.bar.getBounds().width;
                    this.chaser.landscape.y = this.bar.y + this.bar.getBounds().height * 0.5;
                    this.chaser.portrait.x = this.bar.x + this.bar.getBounds().width;
                    this.chaser.portrait.y = this.bar.y + this.bar.getBounds().height * 0.5;
                }
            }
        }

        this.updateLabel();

        this.onChange.dispatch();
    }

    protected updateLabel() {
        if (this.currentPercent < 1) {
            if (this.loadingPercentage) {
                this.loadingPercentage.text = Math.round(this.currentPercent * 100) + "%";
            }
        }
    }
}
