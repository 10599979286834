import { Result } from "appworks/model/gameplay/records/results/result";

export class BonusResult extends Result {

    /** For debugging purposes */
    public resultType: string = "BonusResult";

    public readonly id: string;

    // Used for any extra information you may want to include, but doesn't warrant a separate bonus type
    public data: any;

    public played: boolean;

    constructor(id: string, played: boolean = false) {
        super();

        this.id = id;
        this.played = played;
    }
}
