import { Contract } from "appworks/utils/contracts/contract";

export class Parallel<T = void> extends Contract<T> {
    private resolve: (value: T) => any;
    private count: number;

    private childContracts: Array<Contract<any>>;

    constructor(private methods: Array<() => Contract<any>>, private resolveOnAny: boolean = false) {
        super((resolve) => {
            this.count = this.methods.length;

            this.resolve = resolve;

            if (!this.count) {
                this.resolve(null);
            }

            this.childContracts = [];

            for (const method of this.methods) {
                const childContract = method();
                this.childContracts.push(childContract);

                childContract.then((result?: T) => {
                    if (this.resolveOnAny) {
                        resolve(result);
                        return;
                    }

                    this.count--;

                    if (this.count <= 0) {
                        resolve(result);
                    }
                });
            }
        });
    }

    public cancel() {
        super.cancel();

        for (const childContract of this.childContracts) {
            childContract.cancel();
        }
    }

    public forceResolve() {
        super.forceResolve();

        for (const childContract of this.childContracts) {
            childContract.cancel();
        }
    }
}
