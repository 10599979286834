import { Point } from "appworks/utils/geom/point";
import { Result } from "../../../../../appworks/model/gameplay/records/results/result";

export class ReelModifierResult extends Result {

    public resultType: string = "ReelModifierResult";

    /**
     * The source of the expansion (0 indexed)
     * For example a wild landing on the second reel, third row would be {x: 1, y: 2}
     */
    public source: Point;
    /**
     * The ID of the source symbol
     */
    public sourceSymbolID: string;

    /**
     * List of positions which duplicate the source symbol (0 indexed)
     * For example an expanding wild on the second reel on a 5x3 slot, which fills the whole reel:
     * [{x: 1, y: 0}, {x: 1, y: 1}, {x: 1, y: 2}]
     */
    public expansions: Point[];

    /**
     * The position which will control the expansions animation (0 indexed)
     * If not set, the source is used
     */
    public target: Point;
    /**
     * The ID of the target symbol
     */
    public targetSymbolID: string;

    /**
     * Meta data associated with the expand to aid front end display
     */
    public modifierMeta: any;

    constructor(sourceSymbolID: string, source: Point, expansions: Point[], target?: Point, targetSymbolID?: string, metaData?: any) {
        super();
        this.sourceSymbolID = sourceSymbolID;
        this.source = source;
        this.expansions = expansions;
        this.target = target || source;
        this.targetSymbolID = targetSymbolID;
        this.modifierMeta = metaData;
    }
}
