import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Service } from "appworks/services/service";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { logger } from "appworks/utils/logger";
import { Signal } from "signals";
import { AlertComponent } from "slotworks/components/alert/alert-component";
import { AutoplayModel } from "slotworks/model/autoplay/autoplay-model";
import { slotModel } from "slotworks/model/slot-model";
import { SlotBetService } from "../bet/slot-bet-service";

export class AutoplayService extends Service {
    public onConfirm: Signal = new Signal();
    public onStop: Signal = new Signal();

    protected autoplayModel: AutoplayModel;
    protected wasFreeBet = false;

    public init() {
        slotModel.onUpdate.add(() => {
            this.autoplayModel = slotModel.read().autoplay;
        });
    }

    public start() {
        this.autoplayModel.initialAutoplays = this.autoplayModel.autoplays.currentValue;
        this.autoplayModel.autoplays.currentValue--;
        this.autoplayModel.runningWinLoss = 0;
        this.autoplayModel.startConfirmed = true;
        this.autoplayModel.firstAutoplay = true;

        this.writeModel();

        uiFlags.set(UIFlag.AUTO_SPIN, true);

        this.onConfirm.dispatch();
    }

    public spin() {
        if (this.decrease() <= 0) {
            this.complete();
        }
    }

    public updateWinLoss() {
        const win = gameState.getCurrentGame().getCurrentTotalWin();
        const winLoss = gameState.getCurrentGame().getTotalWinLoss();
        const { freeBet } = slotModel.read().bet;
        const { lossLimit, singleWinLimit, totalWinLimit } = this.autoplayModel;

        if (this.isAutoPlaying()) {
            if (this.autoplayModel.stopOnAnyWin && win > 0) {
                this.stop();
            } else {
                let isOverLossLimit = false;
                if (!freeBet && !this.wasFreeBet) {
                    this.autoplayModel.runningWinLoss += winLoss;
                    this.writeModel();

                    const nextSpinWinLoss: number = 
                        this.autoplayModel.runningWinLoss - 
                        Services.get(SlotBetService).getTotalStake();

                    isOverLossLimit =
                        lossLimit.currentValue > 0 && 
                        nextSpinWinLoss < -lossLimit.currentValue;
                }

                this.wasFreeBet = freeBet;

                const isOverSingleWinLimit: boolean = 
                    singleWinLimit.currentValue > 0 && 
                    winLoss > singleWinLimit.currentValue;

                const isOverTotalWinLimit: boolean = 
                    totalWinLimit.currentValue > 0 && 
                    this.autoplayModel.runningWinLoss >= totalWinLimit.currentValue;

                return this.limitReached(isOverLossLimit, isOverSingleWinLimit, isOverTotalWinLimit);
            }
        }

        return Contract.empty();
    }

    public stop() {
        this.autoplayModel.autoplays.currentValue = 0;
        this.autoplayModel.singleWinLimit.currentValue = 0;
        this.autoplayModel.totalWinLimit.currentValue = 0;
        this.autoplayModel.startConfirmed = false;

        this.writeModel();

        uiFlags.set(UIFlag.AUTO_SPIN, false);

        this.onStop.dispatch();
    }

    public complete() {
        this.stop();
    }

    public setEnabled(enabled: boolean) {
        this.autoplayModel.enabled = enabled;
        this.writeModel();
    }

    public isEnabled() {
        return this.autoplayModel.enabled;
    }

    public isAutoPlaying() {
        return this.autoplayModel.startConfirmed;
    }

    public setAutoplays(autoplays: number) {
        if (autoplays < 0) {
            this.autoplayModel.autoplays.currentValue = 0;
        } else {
            this.autoplayModel.autoplays.currentValue = autoplays;
        }
        this.writeModel();
    }

    public getAutoplays(): number {
        return this.autoplayModel.autoplays.currentValue;
    }

    public isFirstAutoplay(): boolean {
        return this.autoplayModel.startConfirmed && this.autoplayModel.firstAutoplay;
    }

    protected decrease() {
        this.autoplayModel.firstAutoplay = false;
        this.autoplayModel.autoplays.currentValue--;

        this.writeModel();

        return this.autoplayModel.autoplays.currentValue;
    }

    protected limitReached(isOverLossLimit: boolean, isOverSingleWinLimit: boolean, isOverTotalWinLimit: boolean, message?: string) {
        if (isOverSingleWinLimit || isOverLossLimit || isOverTotalWinLimit) {

            this.stop();

            if (!message) {
                let messageTag;
                if (isOverSingleWinLimit) {
                    messageTag = "aut.swl";
                }
                if (isOverLossLimit) {
                    messageTag = "aut.lol";
                }
                if (isOverTotalWinLimit) {
                    messageTag = "aut.twl";
                }

                if (messageTag) {
                    message = Services.get(TranslationsService).get(messageTag);
                }

                if (message === messageTag) {
                    message = null;
                }
            }

            if (message) {
                return new Contract<void>((resolve) => {
                    uiFlags.set(UIFlag.PROMPT, true);
                    Components.get(AlertComponent).info(message).then(() => {
                        uiFlags.set(UIFlag.PROMPT, false);
                        resolve();
                    });
                });
            } else {
                logger.warn("Autoplay limit message not found, so no popup could be shown");
            }
        }

        return Contract.empty();
    }

    protected writeModel() {
        slotModel.write({ autoplay: this.autoplayModel });
    }
}
