import { Layers } from "appworks/graphics/layers/layers";
import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { ControlFactory } from "appworks/ui/factories/control-factory";
import { SlingoResponsibleGamingMenuControl } from "../controls/slingo-responsible-gaming-menu-control";

export class SlingoResponsibleGamingMenuFactory implements ControlFactory {
    public filter(layer: Layers): boolean {
        if (layer.id === "Menus" && layer.currentSceneIs("play_controls")) {
            return true;
        }

        return false;
    }

    public build(layer: Layers): AbstractControl[] {
        return [new SlingoResponsibleGamingMenuControl(layer)];
    }
}