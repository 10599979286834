import { Orientation } from "appworks/graphics/canvas/orientation";
import { Renderer, Sprite as PIXISprite, Texture } from "pixi.js";
import { DualPosition } from "./dual-position";
import { RenderOrientation } from "./render-orientation";

export class Sprite extends PIXISprite {
    protected defaultTexture: Texture;
    protected blankTexture: Texture;

    public static from(source: any, options?: any): Sprite {
        const texture = (source instanceof Texture)
            ? source
            : Texture.from(source, options);
        return new Sprite(texture);
    }

    public dualPosition = new DualPosition();

    constructor(texture?: Texture) {
        super(texture);

        this.landscape.width = this.portrait.width = texture?.width || 0;
        this.landscape.height = this.portrait.height = texture?.height || 0;

        this.defaultTexture = texture;
    }

    public updateTransform() {
        const position = this.getOrientationPosition();

        super.x = position.x;
        super.y = position.y;

        if (super.width * (super.scale.x < 0 ? -1 : 1) !== position.width * position.scale.x) {
            super.width = position.width * position.scale.x;
        }
        if (super.height * (super.scale.y < 0 ? -1 : 1) !== position.height * position.scale.y) {
            super.height = position.height * position.scale.y;
        }

        super.updateTransform();
    }

    public _render(renderer: Renderer) {
        if (this.getOrientationPosition().unavailable) {
            return;
        }

        return super._render(renderer);
    }

    public setDualPosition(dualPosition: DualPosition) {
        this.dualPosition = dualPosition.clone();
    }

    public getPosition(orientation: Orientation) {
        return this.dualPosition.get(orientation);
    }

    public hideTexture() {
        if (!this.blankTexture) {
            this.blankTexture = new Texture(Texture.EMPTY.baseTexture, this.defaultTexture.frame, this.defaultTexture.orig, this.defaultTexture.trim, this.defaultTexture.rotate, this.defaultTexture.defaultAnchor);
        }

        this.texture = this.blankTexture;
    }

    public showTexture() {
        this.texture = this.defaultTexture;
    }

    get landscape() {
        return this.dualPosition.landscape;
    }

    set landscape(value) {
        this.dualPosition.landscape = value.clone();
    }

    get portrait() {
        return this.dualPosition.portrait;
    }

    set portrait(value) {
        this.dualPosition.portrait = value.clone();
    }

    // @ts-ignore
    get x() : number {
        throw new Error("Do not change y manually, please use landscape.y or portrait.y");
    }

    set x(value) {
        throw new Error("Do not change x manually, please use landscape.x or portrait.x");
    }

    // @ts-ignore
    get y() : number {
        throw new Error("Do not change y manually, please use landscape.y or portrait.y");
    }

    set y(value) {
        throw new Error("Do not change y manually, please use landscape.y or portrait.y");
    }

    // @ts-ignore
    get width() : number {
        throw new Error("Do not read width manually, please use landscape.width or portrait.width");
    }

    set width(value) {
        throw new Error("Do not change width manually, please use landscape.width or portrait.width");
    }

    // @ts-ignore
    get height() : number {
        throw new Error("Do not read height manually, please use landscape.height or portrait.height");
    }

    set height(value) {
        throw new Error("Do not change height manually, please use landscape.height or portrait.height");
    }

    private getOrientationPosition() {
        return RenderOrientation.isLandscape() ? this.landscape : this.portrait;
    }

}
