import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { deviceInfo } from "appworks/utils/device-info";
import { InteractionEvent } from "pixi.js";
import { Signal, SignalBinding } from "signals";
import { Orientation } from "../canvas/orientation";
import { Position } from "../pixi/position";
import { SmartShape } from "./smart-shape";

export class SliderElement {
    public onChange: Signal;
    public onInput: Signal;

    private enabled: boolean;
    private value: number;
    private percent: number;
    private dragging: boolean;

    private min: number;
    private max: number;
    private step: number;

    private mask: SmartShape;
    private orientationListener: SignalBinding;

    constructor(
        private layer: Layers,
        private track: Sprite,
        private fill: Sprite,
        private handle?: Sprite
    ) {
        this.onChange = new Signal();
        this.onInput = new Signal();

        this.setEnabled(true);

        this.track = track;
        this.fill = fill;

        this.mask = new SmartShape();
        this.mask.drawRect(0, 0, 1, 1);
        this.mask.renderable = false;
        this.mask.landscape = this.fill.landscape.clone();
        this.mask.portrait = this.fill.portrait.clone();
        this.fill.mask = this.mask;
        this.fill.parent.addChild(this.mask);

        if (this.handle) {
            this.handle.anchor.set(0.5, 0);
            this.handle.interactive = true;
            this.handle.buttonMode = true;
            this.handle.on(ButtonEvent.POINTER_DOWN.getPIXIEventString() as any, this.startDrag);
            this.handle.on(ButtonEvent.POINTER_UP.getPIXIEventString() as any, this.stopDrag);
            this.handle.on(ButtonEvent.POINTER_UP_OUTSIDE.getPIXIEventString() as any, this.stopDrag);
            this.handle.on(ButtonEvent.POINTER_MOVE.getPIXIEventString() as any, this.drag);
        }
    }

    public setup(min: number, max: number, step: number): void {
        this.min = min;
        this.max = max;
        this.step = step;
        this.value = this.min;
        this.percent = 0;

        this.update();
    }

    public destroy(): void {
        if (this.track) {
            this.onInput.dispose();
            this.onChange.dispose();
            this.track.destroy();
            this.handle.destroy();
            this.fill.destroy();

            this.onInput = null;
            this.onChange = null;
            this.track = null;
            this.handle = null;
            this.fill = null;
        }

        if (this.orientationListener) {
            this.orientationListener.detach();
            this.orientationListener = null;
        }
    }

    public setValue(value: number): void {
        this.percent = (value - this.min) / (this.max - this.min);
        this.percent = Math.min(1, Math.max(0, this.percent));
        this.update();
    }

    public setVisible(visible: boolean): void {
        this.track.visible = visible;
        this.fill.visible = visible;
        this.handle.visible = visible;
    }

    public setEnabled(enabled: boolean): void {
        this.enabled = enabled;
    }

    private update(): void {

        this.value = Math.round((this.min + (this.max - this.min) * this.percent) / this.step) * this.step;
        const snappedPercent = (this.value - this.min) / (this.max - this.min);

        [Orientation.LANDSCAPE, Orientation.PORTRAIT].forEach((orientation) => {
            const trackPosition: Position = this.track.getPosition(orientation);
            const maskPosition: Position = orientation === Orientation.LANDSCAPE ? this.mask.landscape : this.mask.portrait;
            const fillPosition: Position = this.fill.getPosition(orientation);
            const handlePosition: Position = this.handle.getPosition(orientation);
            maskPosition.width = snappedPercent * fillPosition.width;
            handlePosition.x = trackPosition.x + snappedPercent * trackPosition.width;
        });
    }

    private startDrag = () => {
        this.dragging = true;
    }

    private stopDrag = () => {
        if (this.dragging) {
            this.dragging = false;
            this.onChange.dispatch(this.value);
        }
    }

    private drag = (event: InteractionEvent) => {
        if (this.enabled) {
            if (this.dragging) {
                const orientation: Orientation = deviceInfo.getOrientation();
                const layerPosition: Position = this.layer.container.getPosition(orientation);
                const trackPosition: Position = this.track.getPosition(orientation);

                const localMouse = this.layer.globalToLocal(
                    new Position(event.data.global.x, event.data.global.y),
                    layerPosition
                );
                const positionInBar = Math.max(0, Math.min(trackPosition.width, localMouse.x - trackPosition.x));
                this.percent = positionInBar / trackPosition.width;

                this.update();
                this.onInput.dispatch(this.value);
            }
        }
    }
}
