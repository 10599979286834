import { MotionBlurFilter } from "@pixi/filter-motion-blur";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Layers } from "appworks/graphics/layers/layers";
import { Container } from "appworks/graphics/pixi/container";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { CenterPivot } from "appworks/graphics/pixi/group";
import { setDualPosition } from "appworks/graphics/pixi/pixi-utils";
import { Position } from "appworks/graphics/pixi/position";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Point } from "appworks/utils/geom/point";
import { getOffsetPosition } from "appworks/utils/position-offset-calculator";
import { Timer } from "appworks/utils/timer";
import * as Logger from "js-logger";
import { Graphics, RenderTexture } from "pixi.js";
import { Signal } from "signals";
import { SymbolBehaviourDriver, SymbolBehaviourMethod } from "slotworks/components/matrix/symbol/behaviours/symbol-behaviour-driver";
import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";
import { slotDefinition } from "slotworks/model/slot-definition";
import { SymbolDefinition } from "slotworks/model/symbol-definition";
import { AbstractSymbolBehaviour } from "./behaviours/abstract-symbol-behaviour";

export type SymbolComponentType<T extends SymbolSubcomponent> = new (...args: ConstructorParameters<typeof SymbolSubcomponent>) => T;

export class SymbolSubcomponent extends SymbolBehaviourDriver {

    // TODO: This shouldn't be static
    public static maxMotionBlur: number = 0;

    /**
     * Position in matrix where {x:0, y:0} is top left
     */
    public readonly gridPosition: Point;
    public readonly anticipateLandSignal: Signal;
    public readonly landSignal: Signal;

    public symbolDefinition: SymbolDefinition;

    public background?: Sprite;

    /**
     * The target transform of a "normal" symbol.
     * Note this is always in terms of the MatrixContent layer
     */
    protected transform: DualPosition;
    protected visible: boolean = true;
    protected motionBlurFilter: MotionBlurFilter;
    protected scale: Point;
    protected tintColor: number = 0xFFFFFF;
    protected alpha: number = 1;

    protected staticSprite: Sprite;
    protected staticSpriteBlurred: Sprite;
    protected container: Container;

    protected stackOffset: number = 0;
    protected isStackPart: boolean;
    protected isWidePart: boolean;

    protected waitingToStick: boolean;
    protected moving: boolean;
    protected blurred: boolean;

    protected staticSprites: Map<string, Sprite>;
    protected staticSpritesBlurred: Map<string, Sprite>;
    protected staticSpriteOffsets: Map<string, DualPosition>;

    protected layerLocked: boolean;
    protected layer: Layers;
    protected matrixLayer: Layers;
    protected animationLayer: Layers;

    constructor(gridPosition: Point, matrixLayer: Layers, animationLayer: Layers) {
        super();

        this.matrixLayer = matrixLayer;
        this.animationLayer = animationLayer;

        this.anticipateLandSignal = new Signal();
        this.landSignal = new Signal();

        this.gridPosition = gridPosition.clone();

        this.createBlurredSprites();

        this.generateStaticSprites();

        this.setVisible(true);

        try {
            if (SymbolSubcomponent.maxMotionBlur > 0) {
                this.motionBlurFilter = new MotionBlurFilter([0, 0], 11);
                this.staticSprite.parent.filters = [this.motionBlurFilter];
            }
        } catch (e) {
            Logger.warn("Motion blur filter not supported");
        }
    }

    public destroy() {
        this.container.parent.removeChild(this.container);
        this.staticSprites.clear();
    }

    public static() {
        if (this.waitingToStick) {
            this.stick().execute();
        } else {
            this.executeBehaviour(SymbolBehaviourMethod.Static).execute();
        }
    }

    public idle() {
        this.executeBehaviour(SymbolBehaviourMethod.Idle).execute();
    }

    public land(): Contract<void> {
        this.landSignal.dispatch();
        Services.get(SoundService).event(SoundEvent.symbol_ID_land, this.symbolId);
        return this.executeBehaviour(SymbolBehaviourMethod.Land);
    }

    public anticipateLand(): Contract<void> {
        Services.get(SoundService).event(SoundEvent.symbol_ID_anticipate_land, this.symbolId);

        if (this.symbolDefinition.autoStick) {

            if (this.visible && !this.proxy) {
                Services.get(SoundService).event(SoundEvent.stick_symbol);
                Services.get(SoundService).event(SoundEvent.stick_symbol_ID, this.symbolId);
            }

            this.waitingToStick = true;
        }

        this.anticipateLandSignal.dispatch();

        return new Contract<void>((resolve) => {
            this.executeBehaviour(SymbolBehaviourMethod.AnticipateLand).then(() => {
                if (this.waitingToStick) {
                    this.stick().execute();
                }
                resolve(null);
            });
        });
    }

    public setMoving(moving: boolean) {
        this.moving = moving;
    }

    public isMoving() {
        return this.moving;
    }

    public setBlurred(blurred: boolean) {
        Timer.setTimeout(() => this.blurred = blurred, 16);
    }

    public stick() {
        this.waitingToStick = false;
        return this.executeBehaviour(SymbolBehaviourMethod.Stick);
    }

    public expand(x: number = 0, y: number = 0): Contract<void> {
        Services.get(SoundService).event(SoundEvent.symbol_any_expand);
        Services.get(SoundService).event(SoundEvent.symbol_ID_expand, this.symbolId);
        if (y < 0) {
            Services.get(SoundService).event(SoundEvent.symbol_any_expand_up);
            Services.get(SoundService).event(SoundEvent.symbol_ID_expand_up, this.symbolId);
        } else if (y > 0) {
            Services.get(SoundService).event(SoundEvent.symbol_any_expand_down);
            Services.get(SoundService).event(SoundEvent.symbol_ID_expand_down, this.symbolId);
        }

        return this.executeBehaviour(SymbolBehaviourMethod.Expand, null, x, y);
    }

    public addBehaviourOfType(behaviourType: typeof AbstractSymbolBehaviour) {
        const behaviour = new behaviourType(this);
        this.addBehaviour(behaviour);
    }

    public highlight(result?: SymbolWinResult): Contract<void> {
        return this.executeBehaviour(SymbolBehaviourMethod.Highlight, result);
    }

    public highlightDim(result?: SymbolWinResult): Contract<void> {
        return this.executeBehaviour(SymbolBehaviourMethod.HighlightDim, result);
    }

    public winDim(result?: SymbolWinResult): Contract<void> {
        return this.executeBehaviour(SymbolBehaviourMethod.WinDim, result);
    }

    public cycleDim(result?: SymbolWinResult): Contract<void> {
        return this.executeBehaviour(SymbolBehaviourMethod.CycleDim, result);
    }

    public win(result?: SymbolWinResult): Contract<void> {
        return this.executeBehaviour(SymbolBehaviourMethod.Win, result);
    }

    public cycle(result?: SymbolWinResult): Contract<void> {
        return this.executeBehaviour(SymbolBehaviourMethod.Cycle, result);
    }

    public anticipate(): Contract<void> {
        return this.executeBehaviour(SymbolBehaviourMethod.Anticipate);
    }

    public remove(): Contract<void> {
        return this.executeBehaviour(SymbolBehaviourMethod.Remove);
    }

    public setSymbol(id: string) {
        this.symbolId = id;

        this.symbolDefinition = slotDefinition.getSymbolDefinition(this.symbolId);

        this.executeBehaviour(SymbolBehaviourMethod.SymbolChange).execute();
    }

    public symbolExists(id: string) {
        return this.staticSprites.has(id);
    }

    public refresh() {
        if (this.symbolId) {
            this.setSymbol(this.symbolId);
        }
    }

    /**
     * Sets the position and size of the static symbol
     * Anchor point is always centered
     */
    public setTransform(transform: DualPosition) {
        this.transform = transform;
    }

    /**
     * The current speed at which the symbol is travelling, btween 0 and 1, 1 being an entire symbol position
     */
    public setVelocity(velocity: number) {
        if (this.motionBlurFilter) {
            (this.motionBlurFilter.velocity as any) = [0, SymbolSubcomponent.maxMotionBlur * velocity];
        }
    }

    public offsetStack(offset: number) {
        this.stackOffset = offset;
    }

    public setIsStackPart(stackPart: boolean) {
        this.isStackPart = stackPart;
        this.updateVisible();
    }

    public setIsWidePart(widePart: boolean) {
        this.isWidePart = widePart;
        this.updateVisible();
    }

    public isPartOfStack() {
        return this.isStackPart;
    }

    public getVisible() {
        return this.visible;
    }

    /**
     * Sets visibility for the static symbol
     * @param visible {boolean}
     */
    public setVisible(visible: boolean) {
        this.visible = visible;
        this.updateVisible();
    }

    public setAlpha(alpha: number) {
        this.alpha = alpha;
        this.updateAlpha();
    }

    public getAlpha() {
        return this.alpha;
    }

    public tint(color: number) {
        const tintElementMaps = [this.staticSprites];

        for (const elementMap of tintElementMaps) {
            elementMap.forEach((element) => {
                if (element) {
                    element.tint = color;
                }
            });
        }

        this.tintColor = color;
    }

    public getTint() {
        return this.tintColor;
    }

    public setScale(scale: Point) {
        this.scale = scale.clone();
    }

    public resetScale() {
        this.scale = null;
    }

    public getScale(): Point {
        return this.scale;
    }

    public setMask(mask: Sprite | Graphics) {
        this.container.mask = mask;
    }

    public getMask(): Sprite | Graphics {
        return this.container.mask as (Sprite | Graphics);
    }

    public setLayer(layer: Layers) {
        if (!this.layerLocked) {
            if (this.layer !== layer) {
                this.layer = layer;

                this.layer.add(this.container);
            }
        }
    }

    public moveToTop() {
        if (this.layer && this.layer === this.matrixLayer) {
            this.layer.add(this.container);
        }
    }

    public getZIndex() {
        return this.container.parent.getChildIndex(this.container);
    }

    public lockLayer() {
        this.layerLocked = true;
    }

    public unlockLayer() {
        this.layerLocked = false;
    }

    public getLayer() {
        return this.layer;
    }

    public getStaticPosition() {
        return this.staticSprite.dualPosition.clone();
    }

    public updateTransform() {

        // Translate transform to local co-ordinate space
        let transform = this.transform;

        if (this.layer !== this.matrixLayer) {
            const globalPosition = this.matrixLayer.localToGlobal(transform);
            transform = this.layer.globalToLocal(globalPosition);
        }

        this.updateStatic(transform);

        // Offset stacks
        if (this.stackOffset !== 0) {
            const offsetMultiplier = new Position(this.stackOffset, this.stackOffset);
            const offset = this.getGapBetweenSymbols().multiply(new DualPosition(offsetMultiplier, offsetMultiplier));
            this.staticSprite.landscape.x -= offset.landscape.x;
            this.staticSprite.portrait.x -= offset.portrait.x;
            this.staticSprite.landscape.y -= offset.landscape.y;
            this.staticSprite.portrait.y -= offset.portrait.y;

        }

        this.updateVisible();
        this.updateAlpha();

        this.executeBehaviour(SymbolBehaviourMethod.UpdateTransform).execute();
    }

    public getTransform(): DualPosition {
        return this.transform;
    }

    public getStackOffsetLandscapeY(): number {
        return this.getGapBetweenSymbols().landscape.y * this.stackOffset;
    }

    public getStackOffsetPortraitY(): number {
        return this.getGapBetweenSymbols().portrait.y * this.stackOffset;
    }

    public getStackOffset(): number {
        return this.stackOffset;
    }

    public setMatrixLayer(layer: Layers) {
        if (this.layer === this.matrixLayer) {
            this.layer = layer;
        }
        this.matrixLayer = layer;
    }

    public getMatrixLayer() {
        return this.matrixLayer;
    }

    public getAnimationLayer() {
        return this.animationLayer;
    }

    public createStaticCopy() {
        const sprite = this.createSprite(this.symbolId);

        if (sprite) {
            const position = this.matrixLayer.getPosition(this.symbolId) || this.matrixLayer.getPosition("static");
            const targetPosition = `symbol_${this.gridPosition.x}_${this.gridPosition.y}`;
            const offset = getOffsetPosition(this.matrixLayer, "symbol_0_0", targetPosition);

            sprite.setDualPosition(position.addPosition(offset));

            if (this.scale) {
                sprite.landscape.scale.x *= this.scale.x;
                sprite.landscape.scale.y *= this.scale.y;
                sprite.portrait.scale.x *= this.scale.x;
                sprite.portrait.scale.y *= this.scale.y;
            }

            CenterPivot(sprite);
        }

        return sprite;
    }

    protected createSprite(name: string) {
        return Services.get(GraphicsService).createSprite(name);
    }

    protected updateStatic(transform: DualPosition) {
        // Set correct symbol
        this.staticSprite.visible = false;
        this.staticSprite = this.staticSprites.get(this.symbolId);
        this.staticSprite.visible = true;

        // Update blurred symbol if applicable
        if (this.staticSpriteBlurred) {
            this.staticSpriteBlurred.visible = false;
        }
        this.staticSpriteBlurred = this.staticSpritesBlurred.get(this.symbolId);

        // Update basic transform
        const offset = this.staticSpriteOffsets.get(this.symbolId);

        this.staticSprite.setDualPosition(transform.multiply(offset));

        this.staticSprite.landscape.x = offset.landscape.x + transform.landscape.x + this.staticSprite.landscape.width * 0.5;
        this.staticSprite.landscape.y = offset.landscape.y + transform.landscape.y + this.staticSprite.landscape.height * 0.5;
        this.staticSprite.portrait.x = offset.portrait.x + transform.portrait.x + this.staticSprite.portrait.width * 0.5;
        this.staticSprite.portrait.y = offset.portrait.y + transform.portrait.y + this.staticSprite.portrait.height * 0.5;

        if (this.scale) {
            this.staticSprite.landscape.scale.x *= this.scale.x;
            this.staticSprite.landscape.scale.y *= this.scale.y;
            this.staticSprite.portrait.scale.x *= this.scale.x;
            this.staticSprite.portrait.scale.y *= this.scale.y;
        }

        // Blurred symbol should match static position
        if (this.staticSpriteBlurred) {
            setDualPosition(this.staticSpriteBlurred, this.staticSprite.dualPosition);
            if (this.symbolDefinition.blur) {
                this.staticSpriteBlurred.landscape.height += 400;
                this.staticSpriteBlurred.landscape.y += 100;
                this.staticSpriteBlurred.portrait.height += 400;
                this.staticSpriteBlurred.portrait.y += 100;
            }
        }
    }

    protected updateVisible() {
        this.staticSprite.visible = this.visible && !(this.isStackPart || this.isWidePart);

        // Use blurred static instead of regular one if moving
        if (this.staticSprite.visible && this.blurred && this.staticSpriteBlurred) {
            this.staticSpriteBlurred.visible = true;
            this.staticSprite.visible = false;
        }
    }

    protected updateAlpha() {
        this.staticSprite.alpha = this.alpha;

        if (this.blurred && this.staticSpriteBlurred) {
            this.staticSpriteBlurred.alpha = this.alpha;
        }
    }

    protected generateStaticSprites() {

        this.container = new Container();
        this.matrixLayer.add(this.container);

        this.layer = this.matrixLayer;

        this.staticSprites = new Map<string, Sprite>();
        this.staticSpritesBlurred = new Map<string, Sprite>();
        this.staticSpriteOffsets = new Map<string, DualPosition>();

        for (const symbolDef of slotDefinition.symbolDefinitions) {
            const idList = [symbolDef.id, ...symbolDef.aliases];
            idList.forEach((id) => {
                if (Services.get(GraphicsService).hasTexture("symbol_backing")) {
                    this.createBackground();
                }

                const sprite = this.createSprite(id);

                this.staticSprites.set(id, sprite);

                let offsetRect = new DualPosition();
                if (this.layer.getPosition(id)) {
                    // Symbol specific offsets
                    offsetRect = getOffsetPosition(this.layer, "symbol_0_0", id);
                } else if (this.layer.getPosition("static")) {
                    // Universal offset
                    offsetRect = getOffsetPosition(this.layer, "symbol_0_0", "static");
                }

                this.staticSpriteOffsets.set(id, offsetRect);

                sprite.anchor.set(0.5);
                sprite.visible = false;
                this.container.addChild(sprite);

                let staticSpriteBlurred;
                if (Services.get(GraphicsService).hasTexture(id + "_blur")) {
                    const textureBlurred = Services.get(GraphicsService).getTexture(id + "_blur");
                    staticSpriteBlurred = new Sprite(textureBlurred);
                    this.staticSpritesBlurred.set(id, staticSpriteBlurred);

                    staticSpriteBlurred.anchor.set(0.5);
                    staticSpriteBlurred.visible = false;
                    this.container.addChild(staticSpriteBlurred);
                }

                this.staticSprite = sprite;
                this.staticSpriteBlurred = staticSpriteBlurred;
            });
        }
    }

    protected createBlurredSprites() {
        for (const symbolDef of slotDefinition.symbolDefinitions) {
            const idList = [symbolDef.id, ...symbolDef.aliases];
            idList.forEach((id) => {
                if (symbolDef.blur) {
                    const tempSymbol = Services.get(GraphicsService).createSprite(symbolDef.id);
                    tempSymbol.landscape.y = 200;
                    tempSymbol.portrait.y = 200;
                    const blurFilter = new MotionBlurFilter([0, symbolDef.blurStrength], 23);
                    blurFilter.offset = -100;
                    blurFilter.padding = 200;
                    tempSymbol.filters = [blurFilter];
                    const blurTexture = RenderTexture.create({ width: tempSymbol.texture.width, height: tempSymbol.texture.height + 400 });
                    Services.get(CanvasService).renderer.render(tempSymbol, blurTexture);
                    tempSymbol.destroy();
                    Services.get(GraphicsService).addTexture(`${symbolDef.id}_blur`, blurTexture);
                }
            });
        }
    }

    protected getGapBetweenSymbols() {
        const referenceSymbolPosition = this.matrixLayer.getPosition(`symbol_${this.gridPosition.x}_0`);
        let offsetReferenceSymbolPosition = this.matrixLayer.getPosition(`symbol_${this.gridPosition.x}_1`) ?? referenceSymbolPosition.clone().addPosition(new DualPosition(new Position(0, referenceSymbolPosition.landscape.height), new Position(0, referenceSymbolPosition.portrait.height)));

        if (offsetReferenceSymbolPosition) {
            offsetReferenceSymbolPosition = referenceSymbolPosition;
        }

        return offsetReferenceSymbolPosition.subtract(referenceSymbolPosition);
    }

    protected createBackground() {
        this.background = this.createSprite("symbol_backing");
        this.background.visible = false;
        this.container.addChildAt(this.background, 0);

        const position = this.matrixLayer.getPosition("symbol_backing") || this.matrixLayer.getPosition(this.symbolId) || this.matrixLayer.getPosition("static");
        const targetPosition = `symbol_${this.gridPosition.x}_${this.gridPosition.y}`;
        const offset = getOffsetPosition(this.matrixLayer, "symbol_0_0", targetPosition);

        this.background.setDualPosition(position.addPosition(offset));

        if (this.scale) {
            this.background.landscape.scale.x *= this.scale.x;
            this.background.landscape.scale.y *= this.scale.y;
            this.background.portrait.scale.x *= this.scale.x;
            this.background.portrait.scale.y *= this.scale.y;

            this.background.landscape.x += this.staticSprite.landscape.width / 2;
            this.background.landscape.y -= this.staticSprite.landscape.height / 2;
            this.background.portrait.x += this.staticSprite.portrait.width / 2;
            this.background.portrait.y -= this.staticSprite.portrait.height / 2;
        }

        CenterPivot(this.background);
    }
}
